import { QuestionnaireQuestionType } from "types";
import { CheckCircleIcon, LongAnswerIcon, MultiSelectIcon, ShortAnswerIcon, UploadIcon } from "ui/icons";

export const questionnaireOptions = [
  { value: "SHORT_TEXT", label: "Short Answer", icon: <ShortAnswerIcon /> },
  { value: "LONG_TEXT", label: "Long Answer", icon: <LongAnswerIcon /> },
  { value: "SELECT", label: "Single Select", icon: <CheckCircleIcon /> },
  { value: "MULTI_SELECT", label: "Multi Select", icon: <MultiSelectIcon /> },
  {
    value: "FILE",
    label: "File",
    icon: <UploadIcon />
  }
];

export const mapQuestionnaireValueToIcon: Record<keyof typeof QuestionnaireQuestionType, JSX.Element> = {
  SHORT_TEXT: <ShortAnswerIcon />,
  LONG_TEXT: <LongAnswerIcon />,
  SELECT: <CheckCircleIcon />,
  MULTI_SELECT: <MultiSelectIcon />,
  ITEM: <MultiSelectIcon />,
  FILE: <UploadIcon />
};
