export const CLASSIFICATION_LEVEL = {
  sector: "SECTOR",
  sub_sector: "SUB_SECTOR",
  industry: "INDUSTRY",
  sub_industry: "SUB_INDUSTRY",
  detail: "DETAIL"
} as const;

export const CLASSIFICATION_SOURCE = {
  naics_2012: "NAICS_2012",
  naics_2017: "NAICS_2017",
  naics_2022: "NAICS_2022",
  bea_2021: "BEA_2021"
} as const;
