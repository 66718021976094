import styled from "@emotion/styled";

export const StyledHeader = styled.div`
  font-family: ${props => props.theme.other.font.fontFamily};
  font-style: ${props => props.theme.other.font.fontStyle};
  font-weight: ${props => props.theme.other.font.header2.fontWeight};
  padding-top: ${props => props.theme.other.font.header2.paddingTop};
  font-size: ${props => props.theme.other.font.header2.fontSize};
  line-height: ${props => props.theme.other.font.header2.lineHeight};
  color: ${props => props.theme.other.primaryColor};
  padding-bottom: ${props => props.theme.other.font.header2.paddingBottom};
  text-align: left;
  @media only screen and (max-width: 705px) {
    text-align: center;
  }
`;

export const HeaderWrapperForTable = styled.div`
  display: flex;
  justify-content: space-between;
  @media only screen and (max-width: 440px) {
    display: grid;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
`;
export const StyleHeaderTable = styled.div`
  font-family: ${props => props.theme.other.font.fontFamily};
  font-style: ${props => props.theme.other.font.fontStyle};
  font-weight: 700;
  font-size: ${props => props.theme.other.font.header3.fontSize};
  line-height: ${props => props.theme.other.font.header3.lineHeight};
  color: ${props => props.theme.other.primaryColor};
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
`;

export const TableWrapper = styled.div`
  overflow: auto;
  margin-top: ${props => props.theme.other.table.marginTop};
  margin-bottom: ${props => props.theme.other.table.marginBottom};
  height: auto;
`;
export const Wrapper = styled.div`
  width: 100%;
  position: relative;
  @media only screen and (max-width: 1365px) {
    width: calc(100% - 20px);
    margin-left: 10px;
    margin-right: 10px;
  }
`;
