import React from "react";

import styled from "@emotion/styled";
import { Button } from "@mantine/core";
import { ErrorModalProps } from "modules/imports/components/ImportsTypes";
import Modal from "ui/overlays/Modal";

const StyleTitleMessage = styled.div`
  font-family: Inter;
  font-size: 1.5625rem;
  font-weight: 700;
  line-height: 1.875rem;
  letter-spacing: 0em;
  text-align: center;
  color: #c10840;
`;
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  margin-top: 52px;
  gap: 28px;
  @media only screen and (max-width: 430px) {
    display: grid;
    justify-content: center;
  }
`;
const StyleDescription = styled.div`
  font-family: Inter;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.1875rem;
  letter-spacing: 0em;
  text-align: center;
  padding-top: 23.12px;
  color: #5c5c5c;
`;
const StyledErrorModal = styled(Modal)`
  .mantine-Modal-modal {
    max-width: 618px;
    min-height: 300px;
    padding: 68.75px 36px 24px 27px;
    @media only screen and (max-width: ${props => props.theme.other.breakpoints.lg}) {
      padding: 10px;
    }
  }
`;
export const ErrorModal: React.FC<ErrorModalProps> = ({
  titleText,
  descriptionText,
  openModal,
  setOpenModal,
  closeBtn = false
}) => {
  return (
    <StyledErrorModal opened={openModal} setOpened={setOpenModal} onClose={true} withCloseButton={true} title={""}>
      <StyleTitleMessage>{titleText}</StyleTitleMessage>
      <StyleDescription>{descriptionText}</StyleDescription>
      {closeBtn === true && (
        <ButtonWrapper>
          <Button color="danger.8" onClick={() => setOpenModal(false)}>
            Close
          </Button>
        </ButtonWrapper>
      )}
    </StyledErrorModal>
  );
};
