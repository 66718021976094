import { useQuery } from "@tanstack/react-query";
import { QUERY_KEYS } from "constants/queryKeys";
import { TAnalyticsFilters } from "types";

import { getAnalyticsGroupedListService } from "../api";

export const useGetAnalyticsGrouped = (analyticsFilters: TAnalyticsFilters) => {
  const query = useQuery({
    queryKey: [QUERY_KEYS.breakdown_dashboard, analyticsFilters],
    queryFn: () => getAnalyticsGroupedListService(analyticsFilters),
    enabled: Boolean(analyticsFilters.accountId)
  });
  return query;
};
