import axiosInstance from "services/AxiosInstance";

interface PropsPayload {
  userId: string;
  status: string;
}
export const putUserStatusUpdateService = async ({ userId, status }: PropsPayload) => {
  const payload = {
    status: status
  };
  const response = await axiosInstance.put(`/users/${userId}/status`, payload);
  return response.data;
};
