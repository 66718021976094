import axiosInstance from "services/AxiosInstance";

interface RejectTransactionProps {
  id: string;
  payload: { reason: string };
}
export const putRejectTransactionService = async ({ id, payload }: RejectTransactionProps) => {
  try {
    const response = await axiosInstance.put(`/imports/${id}/transactions/rejection`, payload);
    return response.data;
  } catch (error) {
    console.error("Error approving import:", error);
    throw error;
  }
};
