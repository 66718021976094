import { useMutation, useQueryClient } from "@tanstack/react-query";
import { QUERY_KEYS } from "constants/queryKeys";

import { putTransactionImport } from "../api";

export const useUpdateTransactionImportMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: putTransactionImport,
    mutationKey: [QUERY_KEYS.update_transaction_import],
    onSuccess: () => {
      queryClient.refetchQueries({ queryKey: [QUERY_KEYS.imports_listing] });
    }
  });
};
