import { CLASSIFICATION_SOURCE } from "constants/classification";
import axiosInstance from "services/AxiosInstance";
import { IndustryClassification, ResponseType } from "types";

type GetIndustryClassificationServiceArgs = {
  params?: Record<string, number | string | null>;
  sourceType?: string;
};

export const getClassificationService = async ({
  params,
  sourceType = CLASSIFICATION_SOURCE.naics_2017
}: GetIndustryClassificationServiceArgs): Promise<ResponseType<IndustryClassification[]>> => {
  const response = await axiosInstance.get(`/classification/${sourceType}`, { params });
  return response.data;
};
