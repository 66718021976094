import React from "react";

import styled from "@emotion/styled";
import { Button } from "@mantine/core";
import { ConfirmationDeleteModalProps } from "modules/imports/components/ImportsTypes";
import { ModalFooter } from "ui";
import Modal from "ui/overlays/Modal";

const StyleMessage = styled.div`
  font-family: ${props => props.theme.other.font.fontFamily};
  font-style: ${props => props.theme.other.font.fontStyle};
  font-size: 2.3rem;
  font-weight: 700;
  letter-spacing: 0em;
  text-align: center;
  color: ${props => props.theme.other.dangerColor};
`;
const StyleDescription = styled.div`
  font-family: ${props => props.theme.other.font.fontFamily};
  font-style: ${props => props.theme.other.font.fontStyle};
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.6875rem;
  letter-spacing: 0em;
  text-align: center;
  color: ${props => props.theme.other.monochromeColor[2]};
  padding-top: 19px;
`;

const StyledConfirmationDeleteModal = styled(Modal)`
  .mantine-Modal-modal {
    max-width: 618px;
    min-height: 300px;
    padding: 55px 24px 34px 27px;
    @media only screen and (max-width: ${props => props.theme.other.breakpoints.lg}) {
      padding: 10px;
    }
  }
`;
export const ConfirmationDeleteModal: React.FC<ConfirmationDeleteModalProps> = ({
  openModal = false,
  handleClose,
  cancelBtnText,
  confirmBtnText,
  titleText,
  descriptionText,
  handleConfirm
}) => {
  return (
    <StyledConfirmationDeleteModal opened={openModal} handleClose={handleClose} withCloseButton title="">
      <StyleMessage>{titleText}</StyleMessage>
      <StyleDescription>{descriptionText}</StyleDescription>
      <ModalFooter>
        <Button variant="subtle" color="danger.8" onClick={handleClose}>
          {cancelBtnText ?? "Cancel"}
        </Button>
        <Button
          color="danger.8"
          onClick={() => {
            handleConfirm();
          }}
        >
          {confirmBtnText ?? "Confirm"}
        </Button>
      </ModalFooter>
    </StyledConfirmationDeleteModal>
  );
};
