import { useQuery } from "@tanstack/react-query";
import { ErrorResponse } from "App";
import { errorModal } from "modules/error/ErrorHandlingModals";

import { getImportService } from "../api";

export const useGetImportByIdQuery = (importId: string) => {
  return useQuery({
    queryKey: ["getImportById", importId],
    queryFn: () => getImportService(importId),
    onError: (error: ErrorResponse) => errorModal(error),
    suspense: false,
    useErrorBoundary: true
  });
};
