import { Tooltip } from "@mantine/core";
import { InfoIcon } from "ui/icons";
import { CustomFieldTooptipContent } from "ui/tooltipContent";

import { CustomFieldSection } from "./CustomFieldSection";
import { Content, TextWithTooltip, TooltipIndicator } from "./customFieldsContent.styles";

export const CustomFieldsContent = () => {
  return (
    <Content>
      <TextWithTooltip>
        <span>Custom Fields</span>
        <Tooltip
          label={<CustomFieldTooptipContent />}
          width={660}
          multiline
          position="right-end"
          styles={{ tooltip: { background: "#fff", padding: 36, boxShadow: "0 0 20px 0 rgba(0,0,0,0.2)" } }}
        >
          <TooltipIndicator>
            <InfoIcon />
          </TooltipIndicator>
        </Tooltip>
      </TextWithTooltip>
      <CustomFieldSection />
    </Content>
  );
};
