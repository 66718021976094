import axiosInstance from "services/AxiosInstance";
import { ResponseType, TImportTransactionMeta } from "types";

export const putImportTransactionMetaService = async ({
  importId,
  payload
}: {
  importId: string;
  payload: { columns: (Omit<TImportTransactionMeta, "id"> & { id?: string })[] };
}): Promise<ResponseType<{ columns: TImportTransactionMeta[] }>> => {
  const response = await axiosInstance.put(`/imports/${importId}/transaction-meta`, payload);
  return response.data;
};
