import { useContext, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";

import { Select } from "@mantine/core";
import useGetAccountQuery from "modules/account/queries/GetAccountsQuery";
import { CurrentUserContext } from "modules/login/CurrentUserContext";
import { useGetUserAccountsQuery } from "modules/users";
import { UserAccountInterface } from "modules/users/types/UserDto";
import { UserRoles } from "modules/users/types/UserTypesEnum";
import { TAccount } from "types";

import { StyledAccountName } from "./modalAccountSelect.styles";

type ModalAccountSelectProps = {
  errorMsg: string;
  forceAutoSelect?: boolean;
  onChange: (accountId: string, accountName: string) => void;
  userId?: string;
  value: string;
};

type TAccountSelectData = {
  label: string;
  value: string;
};

const ModalAccountSelect = ({ value, errorMsg, userId = "", onChange, forceAutoSelect }: ModalAccountSelectProps) => {
  const { id } = useParams();

  const { userAccounts, userDetails, isInternal } = useContext(CurrentUserContext);

  const isInternalAdmin = userDetails.userRole === UserRoles.INTERNAL_ADMIN.value;

  const allAccountsQuery = useGetAccountQuery(0, 1000, isInternalAdmin);

  const paramsUserAccountsQuery = useGetUserAccountsQuery(userId);

  const assignedAccounts: TAccountSelectData[] = useMemo(() => {
    if (userAccounts.length < 0) return [];
    return userAccounts.map((account: UserAccountInterface) => ({
      label: account.accountName,
      value: account.accountId
    }));
  }, [userAccounts]);

  const allAccounts: TAccountSelectData[] = useMemo(() => {
    if (!allAccountsQuery.data?.data) return [];
    if (allAccountsQuery.data.data.length < 0) return [];
    return allAccountsQuery.data.data.map((account: TAccount) => ({
      label: account.name,
      value: account.id
    }));
  }, [allAccountsQuery.data]);

  const paramsUserAccounts: TAccountSelectData[] = useMemo(() => {
    if (!paramsUserAccountsQuery.data) return [];
    return paramsUserAccountsQuery.data.map((account: UserAccountInterface) => ({
      label: account.accountName,
      value: account.accountId
    }));
  }, [paramsUserAccountsQuery.data]);

  const defaultAccounts = isInternalAdmin ? allAccounts : assignedAccounts;

  const accounts = userId ? paramsUserAccounts : defaultAccounts;

  const handleSelect = (accountId: string) => {
    const selectedAccount = accounts.find(account => account.value === accountId);
    if (selectedAccount) onChange(selectedAccount.value, selectedAccount.label);
  };

  useEffect(() => {
    if (!accounts.length) return;
    if (forceAutoSelect) return handleSelect(id as string);
    if (!isInternal) handleSelect(userDetails.accountId);
    if (accounts.length == 1) handleSelect(accounts[0].value);
  }, [isInternal, accounts]);

  if (!accounts.length) return <Select placeholder="...Loading" data={[]} value="" disabled />;

  if (forceAutoSelect)
    return <StyledAccountName>{`Account: ${accounts.find(i => i.value === value)?.label}`}</StyledAccountName>;

  if (!isInternal) return <StyledAccountName>{`Account: ${userDetails.accountName}`}</StyledAccountName>;

  return (
    <Select placeholder="Select Account*" data={accounts} value={value} error={errorMsg} onChange={handleSelect} />
  );
};

export default ModalAccountSelect;
