import styled from "@emotion/styled";

export const CommodityMergeModalContainer = styled.div`
  padding: 40px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
`;

export const CommodityMergeInformationWrapper = styled.div`
  position: relative;
  min-height: 160px;
  width: 100%;
`;
