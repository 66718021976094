import { FC } from "react";

import {
  externalRoleData,
  internalRoleData,
  IUserFiltersData,
  userStatusFilterData
} from "modules/filters/userFilters/userFilters.data";
import FilterTile from "ui/tiles/FilterTile/FilterTile";
import { flattenObject } from "utils";
import { parseQueryParams } from "utils/query.utils";

import { StyledUserFilterTilesWrapper } from "./UserFilterTiles.styles";

type Props = {
  filterParams: string;
  setFilters: (data?: IUserFiltersData) => void;
  setUserFiltersData: React.Dispatch<React.SetStateAction<IUserFiltersData>>;
  userFiltersData: IUserFiltersData;
};

const replacementList = [...userStatusFilterData, ...externalRoleData, ...internalRoleData];

export const UserFilterTiles: FC<Props> = ({ filterParams, userFiltersData, setUserFiltersData, setFilters }) => {
  const handleOnClick = (label: string) => {
    let newFilterData = { ...userFiltersData };
    for (const key in userFiltersData) {
      const dataByKey = userFiltersData[key as keyof IUserFiltersData];
      if (dataByKey.includes(label)) {
        if (typeof dataByKey === "string") newFilterData = { ...userFiltersData, [key]: "" };
        if (Array.isArray(dataByKey)) {
          newFilterData = {
            ...userFiltersData,
            [key]: [...dataByKey].filter(i => i !== label)
          };
        }
      }
    }
    setUserFiltersData(newFilterData);
    setFilters(newFilterData);
  };

  const getLabel = (text: string) => {
    if (!replacementList) return text;
    const replacement = replacementList.find(replacementItem => replacementItem.value === text);
    return replacement ? replacement.label : text;
  };

  const renderFilterTiles = () => {
    const filterObject = parseQueryParams(filterParams);
    const filterValuesList = flattenObject(filterObject);

    return filterValuesList.map((filterValue, index) => (
      <FilterTile
        key={"userFilterTile" + index}
        text={getLabel(filterValue)}
        onClick={() => {
          handleOnClick(filterValue);
        }}
      />
    ));
  };

  return <StyledUserFilterTilesWrapper>{renderFilterTiles()}</StyledUserFilterTilesWrapper>;
};
