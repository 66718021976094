import styled from "@emotion/styled";

export const SupplierSelectWrapper = styled.div`
  width: 100%;
  max-width: 360px;

  .mantine-MultiSelect-searchInput {
    min-width: auto !important;
  }

  .mantine-MultiSelect-searchInputInputHidden {
    height: 0 !important;
  }

  @media only screen and (max-width: ${props => props.theme.other.breakpoints.lg}) {
    max-width: 768px;
  }
`;

export const MergeSupplierModalContainer = styled.div`
  box-sizing: border-box;
  padding: 40px;
  position: relative;
  min-height: 280px;
`;
