import { useParams } from "react-router-dom";

import { Button, Divider, LoadingOverlay } from "@mantine/core";
import { useCurrentUserContext } from "modules/login";
import { SupplierDetailsHeader } from "modules/supplierDetails";
import { QuestionnaireStatusMap } from "modules/supplierDetails/supplierDetails.data";
import { UserRoles } from "modules/users";
import moment from "moment";
import Loader from "ui/feedback/Loader";
import { ChevronRight } from "ui/icons";

import { DynamicQuestionnaire } from "./components";
import { useGetQuestionnaireQuery, useGetQuestionnaireRequestQuery } from "./hooks";
import {
  QuestionnaireDetailCard,
  QuestionnaireResponseCenterer,
  QuestionnaireResponseHeader,
  QuestionnairesContainer
} from "./questionnaire.styles";

export const QuestionnaireResponse = () => {
  const { questionnaireRequestId } = useParams();

  const {
    userDetails: { userRole }
  } = useCurrentUserContext();

  const isAdmin = userRole === UserRoles.INTERNAL_ADMIN.value;

  const {
    data: questionnaireRequestData,
    isSuccess: isQuestionnaireRequestDataSuccess,
    isLoading: isQuestionnaireRequestDataLoading
  } = useGetQuestionnaireRequestQuery(questionnaireRequestId);

  const {
    data: questionnaireData,
    isSuccess: isQuestionnaireDataSuccess,
    isLoading: isQuestionnaireDataLoading
  } = useGetQuestionnaireQuery(questionnaireRequestData?.data.questionnaire.id);

  const sentToName = [
    questionnaireRequestData?.data?.sendToFirstName,
    questionnaireRequestData?.data?.sendToLastName,
    "|",
    questionnaireRequestData?.data?.sendTo
  ]
    .filter(Boolean)
    .join(" ");

  const updated = questionnaireRequestData?.data
    ? moment(questionnaireRequestData.data?.updatedAt, moment.ISO_8601).utc().local().format("MM/DD/YYYY HH:MMA")
    : "";

  const onClickReturn = () => window.close();

  return (
    <QuestionnairesContainer>
      <SupplierDetailsHeader
        accountName={questionnaireRequestData?.data?.account?.name || ""}
        name={questionnaireRequestData?.data?.supplier?.name || ""}
      />
      <Divider />
      <QuestionnaireResponseCenterer>
        <QuestionnaireDetailCard>
          <LoadingOverlay
            visible={isQuestionnaireRequestDataLoading || isQuestionnaireDataLoading}
            overlayBlur={2}
            loader={<Loader />}
          />
          {isQuestionnaireDataSuccess && isQuestionnaireRequestDataSuccess && (
            <>
              <QuestionnaireResponseHeader>
                <h1>{questionnaireData?.data?.title}</h1>
                <p>
                  <b>Completed by:</b> {sentToName}
                </p>
                <p>
                  <b>Last update:</b> {updated}
                </p>
                <p>
                  <b>Status:</b> {QuestionnaireStatusMap?.[questionnaireRequestData?.data?.status] || "Unknown Status"}
                </p>
                <p>
                  <b>Description:</b> {questionnaireData?.data?.description}
                </p>
              </QuestionnaireResponseHeader>
              <br />
              <DynamicQuestionnaire
                questionnaireData={questionnaireData.data}
                questionnaireRequestData={questionnaireRequestData.data}
                approveEnabled={isAdmin}
                submitEnabled={false}
                readOnly
              />
            </>
          )}
          <Button
            variant="subtle"
            leftIcon={<ChevronRight style={{ rotate: "180deg" }} />}
            pos="absolute"
            top={16}
            right={16}
            onClick={onClickReturn}
          >
            Return
          </Button>
        </QuestionnaireDetailCard>
      </QuestionnaireResponseCenterer>
    </QuestionnairesContainer>
  );
};
