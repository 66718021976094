import { MantineThemeOverride } from "@mantine/core";
import { ChevronDown } from "ui/icons";

export const componentsOverride: MantineThemeOverride["components"] = {
  Select: {
    defaultProps: theme => ({
      rightSection: <ChevronDown />,
      rightSectionProps: {
        style: {
          color: theme.colors.gray[4]
        }
      }
    })
  },
  MultiSelect: {
    defaultProps: {
      rightSection: <ChevronDown />
    },
    styles: theme => ({
      rightSection: {
        color: theme.colors.gray[4]
      }
    })
  }
};

export const headingsOverride: MantineThemeOverride["headings"] = {
  fontWeight: 400,
  fontFamily: "Inter",
  sizes: {
    h1: { fontWeight: 400, fontSize: "2.6rem", lineHeight: "3rem" },
    h2: { fontSize: "2.3rem", lineHeight: "2.6rem", fontWeight: "700" },
    h3: {
      fontSize: "2rem",
      lineHeight: "2.3rem"
    }
  }
};
