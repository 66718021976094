import { FC, useMemo } from "react";

import { monochromeColor, primaryColor, warningColor } from "constants/colorPalette";
import { MethodologyCell } from "modules/analytics/components/BreakdownDashboardComponents/MethodologyCell";
import { TAnalyticsData } from "types";
import { formatPercentagePoints } from "utils/common.utils";

import { StyledDetail, StyledDetailValue, StyledMethodWrapper } from "../supplierDetails.styles";

type Props = {
  summary: TAnalyticsData;
};

export const SupplierMethodDetail: FC<Props> = ({ summary }) => {
  const { methodology } = summary;
  const { sb, cb, ss } = methodology;

  const sbValue = useMemo(() => formatPercentagePoints(sb), [sb]);
  const cbValue = useMemo(() => formatPercentagePoints(cb), [cb]);
  const ssValue = useMemo(() => formatPercentagePoints(ss), [ss]);

  return (
    <StyledDetail>
      Method:
      <StyledMethodWrapper>
        <StyledDetailValue color={warningColor}>{sbValue}% Spend-Based</StyledDetailValue>
        <StyledDetailValue color={monochromeColor[2]}>{cbValue}% Commodity-Based</StyledDetailValue>
        <StyledDetailValue color={primaryColor}>{ssValue}% Supplier-Specific</StyledDetailValue>
        <MethodologyCell methodology={summary.methodology} width="100%" />
      </StyledMethodWrapper>
    </StyledDetail>
  );
};
