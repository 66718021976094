import * as React from "react";

export const EditIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="18" height="18" viewBox="0 0 19 18" fill="none" {...props}>
    <path
      d="M16.6972 11.296C16.4483 11.296 16.2467 11.4977 16.2467 11.7466V15.747C16.2458 16.4933 15.6412 17.098 14.895 17.0987H2.25279C1.50655 17.098 0.901995 16.4933 0.901115 15.747V4.00596C0.901995 3.2599 1.50655 2.65517 2.25279 2.65429H6.25325C6.50211 2.65429 6.70381 2.45259 6.70381 2.20373C6.70381 1.95504 6.50211 1.75317 6.25325 1.75317H2.25279C1.00918 1.75458 0.00140799 2.76235 0 4.00596V15.7472C0.00140799 16.9908 1.00918 17.9986 2.25279 18H14.895C16.1386 17.9986 17.1464 16.9908 17.1478 15.7472V11.7466C17.1478 11.4977 16.9461 11.296 16.6972 11.296Z"
      fill="currentColor"
    />
    <path
      d="M16.9688 0.593865C16.1769 -0.197955 14.8932 -0.197955 14.1014 0.593865L6.06315 8.6321C6.00806 8.68718 5.96828 8.75547 5.94752 8.83045L4.89046 12.6466C4.84699 12.8031 4.89117 12.9706 5.00592 13.0856C5.12085 13.2003 5.2884 13.2445 5.44486 13.2012L9.26105 12.144C9.33603 12.1232 9.40431 12.0834 9.4594 12.0284L17.4975 3.98994C18.288 3.1976 18.288 1.91491 17.4975 1.12257L16.9688 0.593865ZM7.04487 8.92496L13.6235 2.34611L15.7452 4.46778L9.16636 11.0466L7.04487 8.92496ZM6.62106 9.77539L8.31611 11.4706L5.97145 12.1202L6.62106 9.77539ZM16.8603 3.35283L16.3825 3.83066L14.2607 1.70882L14.7387 1.23098C15.1785 0.79116 15.8916 0.79116 16.3315 1.23098L16.8603 1.75968C17.2995 2.20003 17.2995 2.91265 16.8603 3.35283Z"
      fill="currentColor"
    />
  </svg>
);
