import { Dispatch, SetStateAction, useContext, useEffect } from "react";
import { ErrorBoundary } from "react-error-boundary";

import { getCoreRowModel, useReactTable } from "@tanstack/react-table";
import ErrorHandler from "modules/error/ErrorHandler";
import { TCommodity } from "types";
import { Table } from "ui";
import Pagination from "ui/navigation/Pagination";

import { CommodityContext } from "../context/CommodityContext";
import { CommodityTableWrapper, TableWrapper } from "../styles";
import { columns } from "./columns";

export const CommodityTable = () => {
  const {
    commodityList,
    filters,
    limit,
    totalCount,
    updateFilters,
    setRowSelection,
    rowSelectionState,
    setRowSelectionState
  } = useContext(CommodityContext);

  const table = useReactTable({
    data: commodityList,
    columns,
    state: {
      rowSelection: rowSelectionState
    },
    onRowSelectionChange: setRowSelectionState,
    getCoreRowModel: getCoreRowModel(),
    enableRowSelection: true,
    enableMultiRowSelection: true,
    getRowId: row => JSON.stringify(row)
  });

  const handlePage = (page: number) => updateFilters({ page: String(Number(page) - 1) });

  useEffect(() => setRowSelection(Object.keys(rowSelectionState).map(i => JSON.parse(i))), [rowSelectionState]);

  return (
    <ErrorBoundary FallbackComponent={ErrorHandler}>
      <TableWrapper>
        <CommodityTableWrapper>
          <Table<TCommodity> table={table} hoverable />
        </CommodityTableWrapper>
      </TableWrapper>
      <Pagination
        activePage={Number(filters.page) + 1}
        setPage={handlePage as Dispatch<SetStateAction<number>>}
        totalRecords={totalCount}
        limit={limit}
      />
    </ErrorBoundary>
  );
};
