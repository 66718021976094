import { ISupplier } from "modules/suppliers/suppliers.types";
import axiosInstance from "services/AxiosInstance";

export const getSupplierById = async (id: string | undefined): Promise<ISupplier | undefined> => {
  if (!id) {
    return undefined;
  }
  const response = await axiosInstance.get(`/suppliers/${id}`);
  return response.data.data;
};
