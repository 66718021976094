import {
  StatusActive,
  StatusPending,
  StatusSuspended
} from "modules/account/components/AccountListingTable/AccountListinTable.styles";
import { AccountStatus } from "modules/account/constants/AccountConstants";
import { CancelIcon, SuccessIcon } from "ui/icons";

export const statusMap = {
  [AccountStatus.active.value]: {
    Component: StatusActive,
    Icon: SuccessIcon,
    Label: AccountStatus.active.label
  },
  [AccountStatus.pending.value]: {
    Component: StatusPending,
    Icon: SuccessIcon,
    Label: AccountStatus.pending.label
  },
  [AccountStatus.suspended.value]: {
    Component: StatusSuspended,
    Icon: CancelIcon,
    Label: AccountStatus.suspended.label
  }
};
