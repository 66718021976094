import * as React from "react";

export const CheckCircleIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M10.0001 1.66667C5.40008 1.66667 1.66675 5.4 1.66675 10C1.66675 14.6 5.40008 18.3333 10.0001 18.3333C14.6001 18.3333 18.3334 14.6 18.3334 10C18.3334 5.4 14.6001 1.66667 10.0001 1.66667ZM10.0001 16.6667C6.32508 16.6667 3.33341 13.675 3.33341 10C3.33341 6.325 6.32508 3.33333 10.0001 3.33333C13.6751 3.33333 16.6667 6.325 16.6667 10C16.6667 13.675 13.6751 16.6667 10.0001 16.6667ZM13.8251 6.31667L8.33342 11.8083L6.17508 9.65834L5.00008 10.8333L8.33342 14.1667L15.0001 7.5L13.8251 6.31667Z"
      fill="currentColor"
    />
  </svg>
);
