import styled from "@emotion/styled";

export const Content = styled.section`
  padding-top: 2.5rem;
  width: 100%;
`;

export const TextWithTooltip = styled.div`
  position: relative;
  width: fit-content;

  color: #1a7e35;

  & > span {
    font-weight: 700;
    font-size: ${({ theme }) => theme.other.font.modalGroupHeader.fontSize};
    line-height: ${({ theme }) => theme.other.font.modalGroupHeader.lineHeight};
  }
`;

export const TooltipIndicator = styled.div`
  position: absolute;
  top: -8px;
  right: -14px;
`;
