import { Button } from "@mantine/core";
import { PlusIcon } from "ui/icons";
import { TopHeader } from "ui/layouts/common";

import { QuestionnaireTableHeaderContainer } from "../questionnaire.styles";

type QuestionnaireListHeaderProps = {
  href: string;
  isInternal: boolean;
};

export const QuestionnaireListHeader: React.FC<QuestionnaireListHeaderProps> = ({ href, isInternal = false }) => (
  <QuestionnaireTableHeaderContainer>
    <TopHeader title="Questionnaires" />
    {isInternal && (
      <div>
        <Button size="lg" leftIcon={<PlusIcon />} component="a" href={href} target="_blank" rel="noopener noreferrer">
          New Questionnaire
        </Button>
      </div>
    )}
  </QuestionnaireTableHeaderContainer>
);
