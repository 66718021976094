import { useContext, useEffect, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useNavigate } from "react-router-dom";

import { LoadingOverlay } from "@mantine/core";
import { graphColorKey } from "constants/colorPalette";
import jwt_decode from "jwt-decode";
import ErrorHandler from "modules/error/ErrorHandler";
import { CurrentUserContext } from "modules/login/CurrentUserContext";
import { JwtTokenInterface, useGetUserInfo } from "modules/users";
import Loader from "ui/feedback/Loader";
import { CancelIcon, PencilIcon } from "ui/icons";

import {
  AccountLabel,
  AccountSideIcon,
  CenterContainer,
  DescriptionText,
  EditProfile,
  HeadBox,
  HeadBoxWrapper,
  HeaderText,
  IconLabelWrapper,
  IconLeft,
  ListingWrapper,
  LoaderWrapper,
  PolygonArrow,
  SelectAccountLabel,
  StyleAccountList,
  StyleUserProfileImage,
  StyleUserProfileImageWrapper,
  ToolTipWrapper,
  UserEmail,
  UserEmailWithAccount,
  UserName,
  WelcomeMessage,
  Wrapper,
  WrapperStyle
} from "./AccountSelection.styles";

type Account = {
  accountId: string;
  accountName: string;
  role: string;
  userId: string;
};

export const AccountSelection = () => {
  const navigate = useNavigate();
  const [accountListing, setAccountListing] = useState<Account[]>([]);
  const [queryEnabled, setQueryEnabled] = useState(false);
  const { userDetails, setUserDetails } = useContext(CurrentUserContext);
  const [userId, setUserId] = useState("");
  const { data, isSuccess, isLoading, refetch, fetchStatus } = useGetUserInfo(userId, queryEnabled);

  // API call for account listing
  useEffect(() => {
    const token = localStorage.getItem("jwt_Token") ?? "";
    const decodedJWT_token = jwt_decode<JwtTokenInterface>(token);
    setUserId(decodedJWT_token.sub);
  }, []);

  useEffect(() => {
    if (userId !== "" && queryEnabled) {
      refetch();
    }
    // @todo Consider to remove react-hooks/exhaustive-deps rule
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, queryEnabled]);

  useEffect(() => {
    if (isSuccess) {
      setQueryEnabled(false);
      const userInfo = data.data;
      if (userInfo.accounts.length > 1) {
        setAccountListing(userInfo.accounts);
      } else {
        const accountId = userInfo.accounts[0].accountId;
        const accountName = userInfo.accounts[0].accountName;
        const userRole = userInfo.accounts[0].role;
        setUserDetails({ ...userDetails, accountName: accountName, userRole: userRole });
        localStorage.setItem("accountId", accountId);
        navigate(`/analysis/${accountId}/overview`, { replace: true });
        localStorage.setItem("accountName", accountName ?? "");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, data]);

  const handleRedirectToOverview = (account: Account) => {
    const accountId = account.accountId;
    setUserDetails({
      ...userDetails,
      accountName: account.accountName,
      accountId,
      userRole: account.role
    });
    localStorage.setItem("accountId", accountId);
    navigate(`/analysis/${accountId}/overview`);
    localStorage.setItem("accountName", account.accountName ?? "");
  };

  const message = `Hi ${userDetails.firstName}`;

  const userName = `${userDetails.firstName} ${userDetails.lastName}`;

  if (fetchStatus === "fetching") {
    return <LoadingOverlay visible overlayBlur={2} loader={<Loader />} />;
  }

  return (
    <CenterContainer>
      <Wrapper>
        <HeadBoxWrapper>
          <HeadBox>
            <WelcomeMessage>{message}</WelcomeMessage>
            <HeaderText>Welcome back to Kloopify! </HeaderText>
            <DescriptionText>
              Select which organization’s Kloopify account you would like to continue to below.
            </DescriptionText>
            <StyleUserProfileImageWrapper>
              <StyleUserProfileImage />
              <EditProfile>
                <PencilIcon />
              </EditProfile>
            </StyleUserProfileImageWrapper>
            <UserName>{userName}</UserName>
            {accountListing?.length === 0 && !isLoading ? (
              <UserEmail>
                <div>{userDetails.email}</div>
                <ToolTipWrapper>
                  <WrapperStyle>
                    <CancelIcon />
                    <PolygonArrow />
                  </WrapperStyle>
                </ToolTipWrapper>
              </UserEmail>
            ) : (
              <UserEmailWithAccount>{userDetails.email}</UserEmailWithAccount>
            )}
          </HeadBox>
          {accountListing.length > 0 && (
            <>
              <SelectAccountLabel>Choose an account:</SelectAccountLabel>
              {accountListing.length ? (
                <ErrorBoundary FallbackComponent={ErrorHandler}>
                  <ListingWrapper>
                    {accountListing.map((account: Account, index: number) => (
                      <StyleAccountList key={index} onClick={() => handleRedirectToOverview(account)}>
                        <IconLabelWrapper>
                          <IconLeft style={{ backgroundColor: graphColorKey[index] }} />
                          <AccountLabel>{account.accountName}</AccountLabel>
                        </IconLabelWrapper>
                        <AccountSideIcon />
                      </StyleAccountList>
                    ))}
                  </ListingWrapper>
                </ErrorBoundary>
              ) : (
                <LoaderWrapper>
                  <Loader />
                </LoaderWrapper>
              )}
            </>
          )}
        </HeadBoxWrapper>
      </Wrapper>
    </CenterContainer>
  );
};
