import axiosInstance from "services/AxiosInstance";

export const getAccountListing = async (page: number, limit: number, accountIds: string[] = []) => {
  const params = {
    page: page,
    limit: limit,
    accountIds: accountIds
  };
  const response = await axiosInstance.get(`/accounts`, { params });
  return response.data;
};
