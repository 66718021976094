import { identity, pickBy } from "lodash";
import axiosInstance from "services/AxiosInstance";

export const getSupplierListService = async (
  accountId: string | undefined,
  params: Record<string, number | string | null>
) => {
  const filterParams = pickBy(params, identity);

  const baseUrl = "/suppliers" + (accountId ? `/account/${accountId}` : "");
  const response = await axiosInstance.get(`${baseUrl}`, { params: filterParams });
  return response.data;
};
