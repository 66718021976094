import { Divider, Grid } from "@mantine/core";
import { QuestionnaireNormalized, QuestionnaireQuestionScope } from "types";

import { QuestionnaireResponseContent } from "../questionnaire.styles";
import { QuestionnaireContentHeader } from "./QuestionnaireContentHeader";
import { QuestionnaireQuestions } from "./QuestionnaireQuestions";

type QuestionnaireContentProps = {
  onChange: (response: string | string[], id: string, group: string, loading?: boolean, error?: string) => void;
  questions: QuestionnaireNormalized[];
  setNormalizedQuestionnaire: React.Dispatch<React.SetStateAction<QuestionnaireNormalized[]>>;
  submitted?: boolean;
};

export const QuestionnaireContent = ({
  questions,
  onChange,
  setNormalizedQuestionnaire,
  submitted
}: QuestionnaireContentProps) => {
  const content = questions.map((group, index) => {
    const { id, scope, questions: groupQuestions } = group;

    const span = scope === QuestionnaireQuestionScope.SCOPE_SUPPLIER ? 12 : 6;

    const removable = scope !== QuestionnaireQuestionScope.SCOPE_SUPPLIER && Number(id) >= 2;

    if (!groupQuestions.length) return null;

    return (
      <Grid.Col span={span} key={index}>
        <QuestionnaireContentHeader
          group={group}
          setNormalizedQuestionnaire={setNormalizedQuestionnaire}
          removable={removable && !submitted}
        />
        <Divider mt="sm" />
        <QuestionnaireQuestions questions={groupQuestions} group={id} onChange={onChange} submitted={submitted} />
      </Grid.Col>
    );
  });

  return <QuestionnaireResponseContent>{content}</QuestionnaireResponseContent>;
};
