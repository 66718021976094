import React, { FC, useEffect } from "react";
import { useLocation } from "react-router-dom";

import { Button, Divider, TextInput } from "@mantine/core";
import { ModalFooter, ModalTitle } from "ui";
import { SearchIcon } from "ui/icons";
import GroupCheckbox, { SelectAllCheckbox } from "ui/inputs/GroupCheckbox";
import Modal from "ui/overlays/Modal";
import { parseQueryParams } from "utils/query.utils";

import {
  groupCheckedKeysConfig,
  IUserFiltersData,
  rolesData,
  TGroupType,
  userFiltersInitialData,
  userRolesCheckboxesConfig,
  userStatusFilterData
} from "./userFilters.data";
import {
  CheckboxGroupWrapper,
  SearchWrapper,
  StyledGroupCheckboxPaddingWrapper,
  StyledUserFiltersCheckboxHeader,
  StyledUserStatusCheckboxesWrapper
} from "./userFilters.styles";

type Props = {
  open: boolean;
  setFilters: () => void;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setUserFiltersData: React.Dispatch<React.SetStateAction<IUserFiltersData>>;
  userFiltersData: IUserFiltersData;
};

export const UserFilters: FC<Props> = ({ open, setOpen, userFiltersData, setUserFiltersData, setFilters }) => {
  const { search } = useLocation();

  const setStatuses = (statuses: string[]) => {
    setUserFiltersData(data => ({ ...data, statuses }));
  };

  const setRoles = (roles: string[]) => {
    setUserFiltersData(data => ({ ...data, roles }));
  };

  const setSearch = (search: string) => {
    setUserFiltersData(data => ({ ...data, search }));
  };

  const onFiltersSet = () => {
    setFilters();
    setOpen(false);
  };

  const onModalClose = () => setOpen(false);

  const setInitialFilters = () => {
    if (!search.length) return;
    const paramsObj = parseQueryParams(search);
    setUserFiltersData(prev => ({ ...prev, ...paramsObj }));
  };

  const resetFilters = () => setUserFiltersData(userFiltersInitialData);

  const checkGroupChecked = (key: TGroupType) => {
    const data = groupCheckedKeysConfig[key];
    return userFiltersData.roles.some(i => data.includes(i));
  };

  const handleSelectAllCheckbox = (group: TGroupType) => {
    const currentRoles = userFiltersData.roles;
    const rolesByGroup = rolesData[group].map(roleData => roleData.value);
    const rolesExcludeGroup = currentRoles.filter(role => !rolesByGroup.includes(role));
    const updatedRoles = checkGroupChecked(group) ? rolesExcludeGroup : [...currentRoles, ...rolesByGroup];
    setUserFiltersData(data => ({ ...data, roles: updatedRoles }));
  };

  useEffect(() => {
    if (open) setInitialFilters();
  }, [open]);

  const userRolesCheckboxes = userRolesCheckboxesConfig.map(item => {
    const { header, group, data } = item;
    return (
      <div key={header}>
        <SelectAllCheckbox
          header={header}
          value={checkGroupChecked(group)}
          smallHeader
          onChange={() => {
            handleSelectAllCheckbox(group);
          }}
        />
        <StyledGroupCheckboxPaddingWrapper padding={group === "external"}>
          <GroupCheckbox value={userFiltersData.roles} setValue={setRoles} data={data} offset={0} />
        </StyledGroupCheckboxPaddingWrapper>
      </div>
    );
  });

  return (
    <Modal opened={open} setOpened={setOpen} handleClose={onModalClose} withCloseButton>
      <ModalTitle>User Filters</ModalTitle>
      <SearchWrapper>
        <TextInput
          onChange={e => setSearch(e.target.value)}
          value={userFiltersData.search}
          placeholder="Search user"
          icon={<SearchIcon />}
        />
      </SearchWrapper>
      <CheckboxGroupWrapper>
        <StyledUserStatusCheckboxesWrapper>
          <StyledUserFiltersCheckboxHeader>User Status</StyledUserFiltersCheckboxHeader>
          <GroupCheckbox
            value={userFiltersData.statuses}
            setValue={setStatuses}
            data={userStatusFilterData}
            groupMargin={0}
            offset={0}
          />
        </StyledUserStatusCheckboxesWrapper>
        <div>
          <StyledUserFiltersCheckboxHeader>User Role</StyledUserFiltersCheckboxHeader>
          {userRolesCheckboxes}
        </div>
      </CheckboxGroupWrapper>
      <Divider mt="xl" />
      <ModalFooter>
        <Button variant="subtle" type="button" onClick={resetFilters}>
          Reset Filter
        </Button>
        <Button onClick={onFiltersSet}>Show Results</Button>
      </ModalFooter>
    </Modal>
  );
};
