import { USER_TYPE } from "modules/users/types/UserTypesEnum";
import axiosInstance from "services/AxiosInstance";
import { getAccountId } from "utils/common.utils";

export interface GetUserInterface {
  limit?: number;
}
export const getImportListService = async (
  pageNo: number,
  limit: number,
  userType: string,
  filterParams?: string,
  accountId?: string
) => {
  const params: GetUserInterface = {
    limit: limit
  };
  const queryParamsPart = filterParams ? `?${filterParams}` : "";
  const accountIdentifier = accountId ?? getAccountId();
  let importUrl = "/imports" + queryParamsPart;
  if (userType === USER_TYPE.external) {
    importUrl = "/imports/account/" + accountIdentifier + queryParamsPart;
  }
  const response = await axiosInstance.get(importUrl, { params });
  return response.data;
};
