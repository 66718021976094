import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";

import { Button, LoadingOverlay } from "@mantine/core";
import GetAccountQuery from "modules/account/queries/GetAccountsQuery";
import ErrorHandler from "modules/error/ErrorHandler";
import { ImportsFilters } from "modules/filters";
import { CurrentUserContext } from "modules/login/CurrentUserContext";
import { USER_TYPE } from "modules/users/types/UserTypesEnum";
import axiosInstance from "services/AxiosInstance";
import { TopHeader } from "ui";
import Loader from "ui/feedback/Loader";
import { SettingIcon } from "ui/icons";
import Pagination from "ui/navigation/Pagination";
import { flattenObject } from "utils";
import { getAccountId } from "utils/common.utils";
import { parseQueryParams, stringifyQueryParams } from "utils/query.utils";
import { parseDateOptions } from "utils/timestamp.utils";

import {
  ApproveAnalysisConfirmModal,
  ConfirmationDeleteModal,
  ImportDetailModal,
  RejectAnalysisModal,
  RejectTransactionsModal,
  ReUploadTransactionFile,
  UploadAnalysisFile,
  UploadTransactionFile
} from "../components";
import { useApproveImportMutation, useDeleteImportMutation, useGetUserImportsQuery } from "../hooks";
import { IimportFiltersData, importFiltersInitialData, ImportInterface } from "../types/ImportDto";
import { ImportContext } from "./ImportContext";
import { ImportErrorModal } from "./ImportErrorModal";
import { EditMapModal, ImportMapModal } from "./ImportMapModal";
import { ImportTable } from "./ImportTable";
import { ImportsFilterTiles } from "./ImportsFilterTiles";
import { HeaderWrapperForTable, StyledHeader, StyleHeaderTable, TableWrapper, Wrapper } from "./styles";
import { ImportKeys } from "./types";

const defaultLimit = 10; // Number of records to be displayed on API call

const initialModalState: ImportModalState = { open: null, active: null };

type ImportModalState =
  | {
      active: ImportInterface;
      open: ImportKeys;
    }
  | {
      active: null;
      open: "mapTransactionFile";
    }
  | {
      active: null;
      open: null;
    };

export const FileListing = () => {
  const { id: accountId } = useParams();
  const { userDetails } = useContext(CurrentUserContext);
  const [searchParams] = useSearchParams();
  const { search } = useLocation();
  const navigate = useNavigate();

  // Get Page number from URL
  const getPageNumber = (): number => {
    const pageNumberFromURL = searchParams.get("page");
    if (pageNumberFromURL !== null) {
      return Number(pageNumberFromURL) + 1;
    } else return 1;
  };

  const getInitialImportFilters = () => {
    const urlFilters = parseQueryParams(search);
    const page = { page: getPageNumber() };
    const initialFilters = { ...importFiltersInitialData, ...urlFilters, ...page };
    if (initialFilters.dates.length === 0) return initialFilters;
    return {
      ...initialFilters,
      lastUpdatedMin: null,
      lastUpdatedMax: null
    };
  };

  const [filterParams, setFilterParams] = useState<string>(stringifyQueryParams(parseQueryParams(search)));
  const [importFilters, setImportFilters] = useState<IimportFiltersData>(getInitialImportFilters());

  const [activePage, setPage] = useState<number>(getPageNumber());
  const [openFilters, setOpenFilters] = useState(false);
  const [file, setFile] = useState<File>();
  const [currentImportId, setCurrentImportId] = useState<string | undefined>();
  const [selectedAccountId, setSelectedAccountId] = useState<string | undefined>();

  const [disableAccountByIdQuery, setDisableAccountByIdQuery] = useState(false);
  const [accountIdsArr, setAccountIdsArr] = useState<string[]>([]);
  const [accountMapping, setAccountMapping] = useState(new Map());

  const [modalState, setModalState] = useState<ImportModalState>(initialModalState);

  const onModalClose = () => setModalState(initialModalState);

  const onModalOpen = (modalState: ImportModalState) => {
    setModalState(prevState => ({ ...prevState, ...modalState }));
  };

  const onUploadFile = useCallback((file: File | undefined) => {
    setFile(file);
  }, []);

  const onSelectedAccountId = (accountId: string | undefined) => {
    setSelectedAccountId(accountId);
  };

  const onSelectImportId = useCallback((importId: string | undefined) => {
    setCurrentImportId(importId);
  }, []);

  const { isLoading, data, isError, refetch, isSuccess } = useGetUserImportsQuery(
    activePage,
    defaultLimit,
    userDetails.userType,
    filterParams,
    accountId
  );
  const accountByIdsQuery = GetAccountQuery(0, defaultLimit, disableAccountByIdQuery, accountIdsArr);

  const approveImportMutation = useApproveImportMutation(); // Mutation to Approve import
  const deleteImportMutation = useDeleteImportMutation(); // Mutation to Delete Import
  const showFilterTiles = useMemo(() => {
    const urlFilters = parseQueryParams(search);
    delete urlFilters.page;
    return flattenObject(urlFilters).length > 0;
  }, [filterParams]);

  const updateImportFilters = (data: Partial<IimportFiltersData>) => {
    setImportFilters(prevData => ({ ...prevData, ...data }));
  };

  const getDateRangeFilter = (data: IimportFiltersData) => {
    const { dates, lastUpdatedMax, lastUpdatedMin } = data;
    return dates.length ? parseDateOptions(dates) : { lastUpdatedMin, lastUpdatedMax };
  };

  const getBaseRoute = () => {
    return userDetails.userType === USER_TYPE.external ? `/imports/${getAccountId()}` : "/imports";
  };

  const setFilters = (updatedData?: IimportFiltersData): void => {
    const baseRoute = getBaseRoute();
    const filtersData = updatedData || importFilters;
    const dateFilters = getDateRangeFilter(filtersData);
    const page = { page: activePage - 1 };
    const params = stringifyQueryParams({
      ...filtersData,
      ...dateFilters,
      ...page
    });
    setFilterParams(params);
    setImportFilters(filtersData);
    navigate(`${baseRoute}?${params}`);
  };

  const updatePage = () => {
    const recordsCount = data?.meta?.totalCount;
    const pagesCount = Math.round(recordsCount / defaultLimit);
    if (pagesCount < activePage - 1) setPage(1);
  };

  useEffect(() => {
    if (!isLoading) refetch();
  }, [filterParams]);

  useEffect(() => {
    setFilters();
  }, [activePage]);

  useEffect(() => {
    updatePage();
  }, [data]);

  useEffect(() => {
    if (isSuccess) {
      const accountIdsArrTmp = data.data
        .map((item: ImportInterface) => item.accountId)
        .filter((value: string, index: number, self: string) => self.indexOf(value) === index);
      setAccountIdsArr(accountIdsArrTmp);
      setDisableAccountByIdQuery(true);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (accountByIdsQuery.isSuccess === true) {
      setDisableAccountByIdQuery(false);
      accountByIdsQuery.data.data.forEach((obj: { id: string }) => {
        setAccountMapping(
          (map: {
            set: (arg0: string, arg1: { id: string }) => Iterable<readonly [unknown, unknown]> | null | undefined;
          }) => new Map(map.set(obj.id, obj))
        );
      });
    }
  }, [accountByIdsQuery.isSuccess]);

  // approve import handler
  const approveImportHandler = () => {
    const id = modalState.active?.id;
    if (id) {
      approveImportMutation.mutate(id);
    }
    onModalClose();
  };

  const handleConfirmAction = () => {
    const id = modalState.active?.id;
    if (id) {
      deleteImportMutation.mutate(id);
      onModalClose();
    }
  };

  const downloadFile = async (fileType: "analysis" | "transactions", importData: ImportInterface) => {
    try {
      const { id, accountId } = importData;
      const requestUrl = `/imports/${id}/${fileType}/file/`;
      const file = await axiosInstance.get(requestUrl, { responseType: "blob" });
      if (file) {
        const date = new Date().toLocaleDateString();
        const accountName = accountMapping.get(accountId)?.name || "";
        const fileName = `${accountName}-${fileType}-${date}.csv`;
        const blobUrl = window.URL.createObjectURL(file.data);
        const a = document.createElement("a");
        a.href = blobUrl;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        a.remove();
      }
    } catch (err) {
      console.error(err);
    }
  };

  const downloadStreamlinedTransactionFile = async (importData: ImportInterface) => {
    try {
      const { id, accountId } = importData;
      const requestUrl = `/imports/${id}/streamlinedtransactions/file`;
      const file = await axiosInstance.get(requestUrl, { responseType: "blob" });
      if (file) {
        const date = new Date().toLocaleDateString();
        const accountName = accountMapping.get(accountId)?.name || "";
        const fileName = `${accountName}-streamlined-transaction-file-${date}.csv`;
        const blobUrl = window.URL.createObjectURL(file.data);
        const a = document.createElement("a");
        a.href = blobUrl;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        a.remove();
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <ImportContext.Provider
      value={{
        accounts: accountByIdsQuery?.data?.data ?? [],
        state: modalState,
        file,
        selectedAccountId,
        currentImportId,
        onModalClose,
        onModalOpen,
        onDownloadFile: downloadFile,
        onDownloadStreamlinedFile: downloadStreamlinedTransactionFile,
        onSelectedAccountId,
        onSelectImportId,
        onUploadFile
      }}
    >
      <Wrapper>
        <LoadingOverlay visible={isLoading} overlayBlur={2} loader={<Loader />} />
        <StyledHeader>Imports</StyledHeader>
        <HeaderWrapperForTable>
          {userDetails.userType === USER_TYPE.internal && (
            <TopHeader>
              <Button size="lg" onClick={() => setOpenFilters(true)} leftIcon={<SettingIcon />} variant="default">
                Filter By
              </Button>
            </TopHeader>
          )}
          <UploadTransactionFile />
        </HeaderWrapperForTable>
        {showFilterTiles ? (
          <ImportsFilterTiles filterParams={filterParams} filtersData={importFilters} setFilters={setFilters} />
        ) : null}
        <ErrorBoundary FallbackComponent={ErrorHandler}>
          <TableWrapper>
            <ImportTable data={data?.data || []} />
          </TableWrapper>
        </ErrorBoundary>
        {data?.data.length === 0 && <StyleHeaderTable>No Results Found</StyleHeaderTable>}
        {isError && <StyleHeaderTable>Something went wrong!</StyleHeaderTable>}
        <Pagination
          activePage={activePage}
          setPage={setPage}
          totalRecords={data?.meta?.totalCount}
          limit={defaultLimit}
        />
        <ImportsFilters
          openModal={openFilters}
          setOpenModal={setOpenFilters}
          importFilters={importFilters}
          updateImportFilters={updateImportFilters}
          setFilter={setFilters}
        />
        <ReUploadTransactionFile
          showModal={modalState.open === "reuploadTransaction"}
          transactionId={modalState.active?.id ?? null}
          handleClose={onModalClose}
        />
        <ConfirmationDeleteModal
          openModal={modalState.open === "importDelete"}
          handleClose={onModalClose}
          cancelBtnText="Cancel"
          confirmBtnText="Delete Import"
          titleText="Delete Import?"
          handleConfirm={handleConfirmAction}
          descriptionText="All associated files and transactions will be deleted. This operation can not be reversed."
        />
        <UploadAnalysisFile
          showModal={modalState.open === "uploadAnalysis"}
          handleClose={onModalClose}
          transactionId={modalState.active?.id ?? null}
        />
        <RejectTransactionsModal
          openModal={modalState.open === "rejectTransaction"}
          handleClose={onModalClose}
          importId={modalState.active?.id ?? null}
        />
        <RejectAnalysisModal
          openModal={modalState.open === "rejectAnalysis"}
          handleClose={onModalClose}
          importId={modalState.active?.id ?? null}
        />
        <ApproveAnalysisConfirmModal
          openModal={modalState.open === "approveAnalysis"}
          handleClose={onModalClose}
          approveImportHandler={approveImportHandler}
        />
        <EditMapModal opened={modalState.open === "editMapping"} handleClose={onModalClose} withCloseButton />
        {modalState.active && (
          <>
            <ImportErrorModal
              opened={modalState.open === "importDetailError"}
              handleClose={onModalClose}
              detail={modalState.active}
            />
            <ImportDetailModal
              openModal={modalState.open === "importDetails"}
              handleClose={onModalClose}
              detail={modalState.active}
            />
          </>
        )}

        <ImportMapModal />
      </Wrapper>
    </ImportContext.Provider>
  );
};
