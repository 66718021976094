import axiosInstance from "services/AxiosInstance";
import { TAnalyticsSummaryResponse } from "types";

type GetAnalyticListServiceArgs = (apiArgs: { accountId: string }) => Promise<TAnalyticsSummaryResponse>;

export const getAnalyticListService: GetAnalyticListServiceArgs = async apiArgs => {
  const { accountId } = apiArgs;
  const response = await axiosInstance.get(
    `/analytics/${accountId}/summary?start=2000-01-01T00:00:00Z&end=2030-01-01T00:00:00Z&interval=alltime`
  );
  return response.data;
};
