import { ActionIcon, Button, Grid, TextInput } from "@mantine/core";
import { useForm, yupResolver } from "@mantine/form";
import { ModalFooter, MultiSelect } from "ui";
import { ItemIcon, PlusIcon } from "ui/icons";

import { buyspaceSchema } from "../buyspace.utils";
import { BuyspaceCondition, BuyspaceConditionKey, BuyspaceInitialFormValues } from "../types";
import { BuyspaceSelectComponent } from "./BuyspaceSelectComponent";
import { BuyspaceFormContainer, BuyspaceHeader } from "./buyspaceForm.styles";
import { ActionButton } from "./buyspaceForm.styles";

const options = [
  { value: "SUPPLIER_ID", label: "Supplier" },
  { value: "ITEM_ID", label: "Commodity" },
  { value: "COST_CENTER_ID", label: "Cost Center" },
  { value: "NAICS_CODE", label: "NAICS Code" },
  { value: "NAICS_INDUSTRY", label: "NAICS Industry" },
  { value: "NAICS_SECTOR", label: "NAICS Sector" }
];

const initialFormValues: BuyspaceInitialFormValues = {
  name: "",
  conditions: [
    {
      id: "0",
      key: [],
      value: [],
      operator: "EQUAL"
    }
  ]
};

type BuyspaceFormProps = {
  initialValues?: Omit<BuyspaceInitialFormValues, "newCondition">;
  onClose: () => void;
  onSubmit: (values: { conditions: BuyspaceCondition[]; name: string }) => void;
};

export const BuyspaceForm: React.FC<BuyspaceFormProps> = ({ onClose, onSubmit, initialValues }) => {
  const form = useForm<BuyspaceInitialFormValues>({
    initialValues: { ...initialFormValues, ...initialValues },
    validate: yupResolver(buyspaceSchema)
  });

  const handleAddNewCondition = () => {
    form.insertListItem("conditions", {
      key: [],
      value: []
    });
  };

  const handleSubmit = (values: typeof form.values) => {
    if (form.validate().hasErrors) return;

    const { name, conditions } = values;

    const conditionsWithOperators: BuyspaceCondition[] = conditions.map(condition => {
      const key = condition.key[0] as BuyspaceConditionKey;

      if (condition.value.length > 1) {
        return {
          ...condition,
          key,
          operator: "IN"
        };
      }
      return {
        ...condition,
        key,
        value: condition.value[0],
        operator: "EQUAL"
      };
    });

    onSubmit({
      name,
      conditions: conditionsWithOperators
    });
  };

  const fields = form.values.conditions.map((_, index) => (
    <Grid align="center" key={index} columns={24}>
      <Grid.Col lg={11} md={24} style={{ alignSelf: "flex-end" }}>
        <MultiSelect
          dataSource={options}
          transformData={data => data}
          icon={<ItemIcon />}
          placeholder="Select Buyspace Filter Option"
          {...form.getInputProps(`conditions.${index}.key`)}
          onChange={value => {
            form.getInputProps(`conditions.${index}.key`).onChange(value);
            form.setFieldValue(`conditions.${index}.value`, []);
          }}
          simpleSelect
        />
      </Grid.Col>
      <Grid.Col lg={11} md={22}>
        <BuyspaceSelectComponent name={`conditions.${index}`} formInstance={form} />
      </Grid.Col>
      <Grid.Col lg={2} md={2}>
        <ActionButton btnStyle="default">
          <ActionIcon
            radius="xl"
            variant="filled"
            disabled={form.values.conditions.length < 2}
            sx={{ rotate: "45deg" }}
            onClick={() => form.removeListItem("conditions", index)}
          >
            <PlusIcon width={12} height={12} />
          </ActionIcon>
        </ActionButton>
      </Grid.Col>
    </Grid>
  ));

  return (
    <BuyspaceFormContainer>
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <BuyspaceHeader>1. Name Buyspace</BuyspaceHeader>
        <Grid>
          <Grid.Col span={10}>
            <TextInput placeholder="Input name" my={32} {...form.getInputProps(`name`)} />
          </Grid.Col>
        </Grid>
        <BuyspaceHeader>2. Define Buyspace Filters</BuyspaceHeader>
        {fields}

        <Grid>
          <Grid.Col lg={2} md={2} mt={15}>
            <ActionButton btnStyle="primary">
              <ActionIcon
                radius="xl"
                variant="filled"
                onClick={handleAddNewCondition}
                disabled={!form.isValid("conditions")}
              >
                <PlusIcon width={12} height={12} />
              </ActionIcon>
            </ActionButton>
          </Grid.Col>
        </Grid>

        <ModalFooter>
          <Button onClick={onClose} variant="default" type="button">
            Cancel
          </Button>
          <Button type="submit" disabled={!form.isValid()}>
            Save
          </Button>
        </ModalFooter>
      </form>
    </BuyspaceFormContainer>
  );
};
