import styled from "@emotion/styled";
import { Table } from "@mantine/core";

export const StyledAccountName = styled.div`
  font-family: ${props => props.theme.other.font.fontFamily};
  font-style: ${props => props.theme.other.font.fontStyle};
  font-size: ${props => props.theme.other.font.fontSize};
  line-height: ${props => props.theme.other.font.lineHeight};
  font-weight: 700;
`;

export const StyledAccountWrapper = styled.div`
  max-width: 48%;
  flex: 1 0;
  @media only screen and (max-width: ${props => props.theme.other.breakpoints.lg}) {
    max-width: 100%;
  }
`;

export const StyledModalHeader = styled.div`
  font-family: ${props => props.theme.other.font.fontFamily};
  font-style: ${props => props.theme.other.font.fontStyle};
  font-weight: 700;
  font-size: 1.875rem;
  line-height: 2.25rem;
  color: #32a752;
`;

export const StyledModalHeaderDescription = styled.div`
  font-family: ${props => props.theme.other.font.fontFamily};
  font-style: ${props => props.theme.other.font.fontStyle};
  font-weight: 400;
  font-size: 16px;
  line-height: 1.1875rem;
  color: #5c5c5c;
  margin-top: 7px;
`;

export const StyledModalHeaderTitle = styled.div`
  font-family: ${props => props.theme.other.font.fontFamily};
  font-style: ${props => props.theme.other.font.fontStyle};
  font-weight: 700;
  font-size: ${props => props.theme.other.font.fontSize};
  line-height: ${props => props.theme.other.font.lineHeight};
  text-align: center;
  color: ${props => props.theme.other.monochromeColor[1]};
  margin-top: 2rem;
`;

export const FormRowWrapper = styled.div<{ alignLeft?: boolean; withMargin?: boolean }>`
  display: flex;
  justify-content: ${props => (props.alignLeft ? "flex-start" : "center")};
  align-items: flex-start;
  gap: 1.2rem;
  width: 100%;
  margin-bottom: 1.2rem;
  margin-top: ${props => (props.withMargin ? "1.2rem" : "")};
  &:first-of-type {
    margin-top: 4.375rem;
  }
  @media only screen and (max-width: ${props => props.theme.other.breakpoints.lg}) {
    flex-direction: column;
    width: 100%;
  }
`;

export const FormRowEqualWrapper = styled(FormRowWrapper)`
  justify-content: space-between;
  align-items: center;
`;

export const StyledCityWrapper = styled.div`
  max-width: 355px;
  width: 100%;
  @media only screen and (max-width: ${props => props.theme.other.breakpoints.lg}) {
    max-width: 100%;
  }
`;

export const StyledStateWrapper = styled.div`
  max-width: 113px;
  min-width: 100px;
  width: 100%;
  height: fit-content;
  @media only screen and (max-width: ${props => props.theme.other.breakpoints.lg}) {
    max-width: 100%;
    min-width: 100%;
  }
`;

export const StyledCountryWrapper = styled.div`
  max-width: 113px;
  min-width: 100px;
  width: 100%;
  height: fit-content;
  @media only screen and (max-width: ${props => props.theme.other.breakpoints.lg}) {
    max-width: 100%;
    min-width: 100%;
  }
`;

export const StyledZipCodeWrapper = styled.div`
  max-width: 113px;
  width: 100%;
  @media only screen and (max-width: ${props => props.theme.other.breakpoints.lg}) {
    max-width: 100%;
  }
`;

export const StyledModalButtonWrapper = styled.div<{ marginTop: string }>`
  display: flex;
  justify-content: flex-end;
  align-items: end;
  width: 100%;
  margin-top: ${props => props?.marginTop};
  gap: 1rem;
  @media only screen and (max-width: ${props => props.theme.other.breakpoints.lg}) {
    gap: 5px;
  }
`;

export const StyledButtonWrapper = styled.div<{ maxWidth: string }>`
  max-width: ${props => props?.maxWidth};
  width: 100%;
`;

//table

export const TableWrapper = styled.div`
  overflow: auto;
  height: auto;
  max-height: 500px;
  margin-top: ${props => props.theme.other.table.marginTop};
  margin-bottom: ${props => props.theme.other.table.marginBottom};
`;

export const StyledTable = styled(Table)`
  border-collapse: collapse;
  table-layout: auto;
  width: 100%;
  td:last-child,
  th:last-child {
    border-right: none !important;
  }
`;

export const StyledTableHeader = styled.thead`
  position: sticky;
  top: -1px;
  z-index: 2;

  th:last-child {
    width: 1px;
  }
`;

export const StyleTH = styled.th`
  border-right: 1px solid ${props => props.theme.other.monochromeColor[3]} !important;
  background: ${props => props.theme.other.monochromeColor[4]};
  height: 39.5px;
  padding: 5px 15px !important;
  font-family: ${props => props.theme.other.font.fontFamily};
  font-style: ${props => props.theme.other.font.fontStyle};
  font-weight: 700;
  font-size: ${props => props.theme.other.font.fontSize} !important;
  line-height: ${props => props.theme.other.font.lineHeight};
  color: ${props => props.theme.other.monochromeColor[1]} !important;
  border-top-width: 1px;
`;

export const StyleTD = styled.td`
  border-right: 1px solid ${props => props.theme.other.monochromeColor[3]} !important;
  border-top: none !important;
  font-family: ${props => props.theme.other.font.fontFamily};
  font-style: ${props => props.theme.other.font.fontStyle};
  font-weight: 400;
  font-size: ${props => props.theme.other.font.fontSize} !important;
  line-height: ${props => props.theme.other.font.lineHeight};
  color: ${props => props.theme.other.monochromeColor[1]};
  text-align: left;
  height: auto;
  position: relative;
  padding-top: 13px !important;
  padding-bottom: 13px !important;
  padding-left: 0px !important;
  max-width: 200px;
`;

export const TableTextWrapper = styled.div`
  margin-left: 10px;
  overflow-wrap: break-word;
  overflow: hidden;
`;

export const TableMenuWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const StyledTR = styled.tr`
  td:last-child {
    padding: 0;
  }
`;

export const StyledMenuTarget = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: fit-content;
`;
