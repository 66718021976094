import * as React from "react";

export const WarningIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 0C4.48095 0 0 4.48095 0 10C0 15.519 4.48095 20 10 20C15.519 20 20 15.519 20 10C20 4.48095 15.519 0 10 0ZM10 0.952381C14.9933 0.952381 19.0476 5.00667 19.0476 10C19.0476 14.9933 14.9933 19.0476 10 19.0476C5.00667 19.0476 0.952381 14.9933 0.952381 10C0.952381 5.00667 5.00667 0.952381 10 0.952381Z"
      fill="currentColor"
    />
    <path
      d="M10.0012 15.7134C10.5272 15.7134 10.9536 15.287 10.9536 14.761C10.9536 14.235 10.5272 13.8086 10.0012 13.8086C9.47522 13.8086 9.04883 14.235 9.04883 14.761C9.04883 15.287 9.47522 15.7134 10.0012 15.7134Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.52344 5.23791V11.9046C9.52344 12.1674 9.73677 12.3808 9.99963 12.3808C10.2625 12.3808 10.4758 12.1674 10.4758 11.9046V5.23791C10.4758 4.97505 10.2625 4.76172 9.99963 4.76172C9.73677 4.76172 9.52344 4.97505 9.52344 5.23791Z"
      fill="currentColor"
    />
  </svg>
);
