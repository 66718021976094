import { TImportError } from "types";

import { ImportErrorItem } from "./ImportErrorItem";
import { ImportErrorListContainer } from "./importErrorList.styles";

export const ImportErrorList: React.FC<{ data: TImportError[] | undefined; indexFactor: number }> = ({
  data = [],
  indexFactor
}) => {
  const renderImportItems = data.map((d, idx) => (
    <ImportErrorItem
      dataItem={d.data}
      dataIndex={idx + indexFactor}
      error={d.error}
      key={idx}
      rowNumber={d.rowNumber}
    />
  ));

  return <ImportErrorListContainer>{renderImportItems}</ImportErrorListContainer>;
};
