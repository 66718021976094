import * as React from "react";

export const SearchIcon: React.FC<React.SVGProps<SVGSVGElement> & { size?: number | string }> = ({
  size,
  style,
  ...rest
}) => (
  <svg width="21" height="21" viewBox="0 0 21 21" fill="none" style={{ width: size, height: size, ...style }} {...rest}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.22449 0C6.04322 0 3.95129 0.866506 2.4089 2.4089C0.866506 3.95129 0 6.04322 0 8.22449C0 10.4058 0.866506 12.4977 2.4089 14.0401C3.95129 15.5825 6.04322 16.449 8.22449 16.449C10.1542 16.449 12.0139 15.7708 13.4851 14.5465L18.7753 19.8374C19.0681 20.1303 19.543 20.1304 19.8359 19.8375C20.1288 19.5446 20.1289 19.0697 19.836 18.7768L14.5459 13.4859C15.7706 12.0146 16.449 10.1545 16.449 8.22449C16.449 6.04322 15.5825 3.95129 14.0401 2.4089C12.4977 0.866506 10.4058 0 8.22449 0ZM13.0067 12.952C14.2507 11.6936 14.949 9.99503 14.949 8.22449C14.949 6.44104 14.2405 4.73064 12.9794 3.46956C11.7183 2.20847 10.0079 1.5 8.22449 1.5C6.44104 1.5 4.73064 2.20847 3.46956 3.46956C2.20847 4.73064 1.5 6.44104 1.5 8.22449C1.5 10.0079 2.20847 11.7183 3.46956 12.9794C4.73064 14.2405 6.44104 14.949 8.22449 14.949C9.99474 14.949 11.693 14.2509 12.9514 13.0073C12.9601 12.9977 12.969 12.9882 12.9783 12.9789C12.9876 12.9697 12.9971 12.9607 13.0067 12.952Z"
      fill="currentColor"
    />
  </svg>
);
