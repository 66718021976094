import styled from "@emotion/styled";

export const TopHeaderContainer = styled.div<{ noPadding?: boolean }>`
  padding-top: ${({ theme, noPadding }) => (noPadding ? 0 : theme.other.font.header2.paddingTop)};
  padding-bottom: ${({ theme, noPadding }) => (noPadding ? 0 : theme.other.font.header2.paddingBottom)};
  text-align: left;
  @media only screen and (max-width: 705px) {
    text-align: center;
  }
`;
export const TopHeaderTitle = styled.span`
  font-family: ${({ theme }) => theme.other.font.fontFamily};
  font-style: ${({ theme }) => theme.other.font.fontStyle};
  font-weight: ${({ theme }) => theme.other.font.header2.fontWeight};
  font-size: ${({ theme }) => theme.other.font.header2.fontSize};
  line-height: ${({ theme }) => theme.other.font.header2.lineHeight};
  color: ${({ theme }) => theme.other.primaryColor};
`;

export const TopHeaderDescription = styled.span`
  font-family: ${({ theme }) => theme.other.font.fontFamily};
  font-style: ${({ theme }) => theme.other.font.fontStyle};
  font-weight: 400;
  font-size: ${({ theme }) => theme.other.font.fontSize};
  line-height: ${({ theme }) => theme.other.font.lineHeight};
  color: ${({ theme }) => theme.other.monochromeColor[2]};
  margin-left: 20px;
`;

export const TopHeaderSubtitle = styled.div`
  line-height: ${({ theme }) => theme.other.font.lineHeight};
  font-style: ${({ theme }) => theme.other.font.fontStyle};
  font-weight: 700;
  font-size: 1.25rem;
  color: ${({ theme }) => theme.other.tertiaryColor};
`;
