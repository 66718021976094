import { useParams } from "react-router-dom";

import { Select } from "@mantine/core";
import { QuestionnaireValidateReady } from "types";

import { useGetQuestionnaireItemsQuery } from "../hooks";

type QuestionnaireItemSelectProps = {
  data: QuestionnaireValidateReady;
  group: string;
  onChange: (response: string | string[], id: string, group: string, loading?: boolean, error?: string) => void;
  submitted?: boolean;
};

const QuestionnaireItemSelect = ({ data, group, onChange, submitted }: QuestionnaireItemSelectProps) => {
  const { id, questionnaireRequestId } = useParams();

  const requestId = id || questionnaireRequestId || "";

  const questionnaireItemsQuery = useGetQuestionnaireItemsQuery(requestId);

  const choices = (questionnaireItemsQuery.data?.data || []).map(item => ({
    label: item.name,
    value: item.id
  }));

  return (
    <Select
      data={choices}
      value={data?.response as string}
      error={data.error}
      label={data.question}
      required={data.required}
      disabled={questionnaireItemsQuery.isLoading || questionnaireItemsQuery.isError || choices.length === 0}
      onChange={(value: string) => !submitted && onChange(value, data.id, group)}
      w={300}
      maw="50%"
      placeholder="Select Product"
    />
  );
};

export default QuestionnaireItemSelect;
