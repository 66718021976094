import React from "react";

import styled from "@emotion/styled";
import { useCurrentUserContext } from "modules/login";
import moment from "moment";
import Modal from "ui/overlays/Modal";
import { ModalTitle } from "ui/styles";
import { getImportStatus } from "utils/common.utils";

import { useGetImportErrorListQuery } from "../hooks";
import { ImportInterface } from "../types";
import { extractFilename } from "../utils";
import { useImportContext } from "./ImportContext";
import { ImportErrorModalText } from "./importErrorModal.styles";

const StyleHead = styled.div`
  font-family: ${props => props.theme.other.font.fontFamily};
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.1875rem;
  letter-spacing: 0em;
  text-align: left;
  padding-top: 21px;
  color: ${props => props.theme.other.monochromeColor[2]};
`;
const StyleHeadDetail = styled.div`
  font-family: ${props => props.theme.other.font.fontFamily};
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.1875rem;
  letter-spacing: 0em;
  text-align: left;
  color: ${props => props.theme.other.monochromeColor[2]};
`;

type ImportDetailModalProps = {
  detail: ImportInterface & { accountName: string };
  handleClose: () => void;
  openModal: boolean;
};

export const ImportDetailModal: React.FC<ImportDetailModalProps> = ({ openModal = false, handleClose, detail }) => {
  const { userDetails } = useCurrentUserContext();

  const enabled = userDetails?.userType === "INTERNAL" && !!detail?.id;

  const { data } = useGetImportErrorListQuery(
    { importId: detail.id, params: { limit: 3, page: 0 } },
    { enabled: enabled }
  );

  const { onModalOpen } = useImportContext();

  const hasErrors = !!data?.data.length;

  return (
    <Modal opened={openModal} handleClose={handleClose} withCloseButton title="" mode="message">
      <ModalTitle>Import Details</ModalTitle>
      <StyleHead>Status:</StyleHead>
      {detail?.status === "COMPLETED" ? (
        <StyleHeadDetail>
          {detail?.approvedOn !== null
            ? `${getImportStatus(detail?.status)} on ${moment(detail?.approvedOn).format("MM/DD/YYYY")}`
            : "Approved Date not found."}
        </StyleHeadDetail>
      ) : (
        <StyleHeadDetail>{getImportStatus(detail?.status)}</StyleHeadDetail>
      )}
      <StyleHead>Activity:</StyleHead>
      {detail?.originatedBy !== null && (
        <StyleHeadDetail>
          Uploaded by {`${detail?.originatedBy?.firstName} ${detail?.originatedBy?.lastName}`} on{" "}
          {moment(detail?.createdAt).format("MM/DD/YYYY")}
        </StyleHeadDetail>
      )}
      {detail?.analyzedBy !== null && (
        <StyleHeadDetail>
          Analysis by {`${detail?.analyzedBy?.firstName} ${detail?.analyzedBy?.lastName}`} on{" "}
          {moment(detail?.analyzedOn).format("MM/DD/YYYY")}
        </StyleHeadDetail>
      )}
      {detail?.approvedBy !== null && (
        <StyleHeadDetail>
          Approved by {`${detail?.approvedBy?.firstName} ${detail?.approvedBy?.lastName}`} on{" "}
          {moment(detail?.approvedOn).format("MM/DD/YYYY")}
        </StyleHeadDetail>
      )}
      <StyleHead>Data:</StyleHead>
      <StyleHeadDetail>New Suppliers: {detail?.suppliers}</StyleHeadDetail>
      <StyleHeadDetail>New Commodities: {detail?.items}</StyleHeadDetail>
      <StyleHeadDetail>New Transactions: {detail?.transactions}</StyleHeadDetail>
      <StyleHead>Meta Data:</StyleHead>
      <StyleHeadDetail>Account Name: {detail?.accountName}</StyleHeadDetail>
      <StyleHeadDetail>File Name: {extractFilename(detail?.filename)}</StyleHeadDetail>
      {detail && hasErrors && (
        <ImportErrorModalText onClick={() => onModalOpen({ active: detail, open: "importDetailError" })}>
          Error logs
        </ImportErrorModalText>
      )}
    </Modal>
  );
};
