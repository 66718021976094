import styled from "@emotion/styled";

export const TopHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  @media only screen and (max-width: 705px) {
    display: grid;
    gap: 12px;
    justify-content: center;
    width: 100%;
  }
`;

export const ModalTitle = styled.div<{
  $type?: "danger" | "success" | "warning";
  centered?: boolean;
  noMobileMargin?: boolean;
}>`
  font-family: ${props => props.theme.other.font.fontFamily};
  font-style: ${props => props.theme.other.font.fontStyle};
  font-weight: 700;
  font-size: 2.3rem;
  color: ${props => {
    const theme = props.theme;
    if (props.$type === "success") {
      return theme.other.primaryColor;
    }
    if (props.$type === "danger") {
      return theme.other.dangerColor;
    }
    if (props.$type === "warning") {
      return theme.other.warningColor;
    }
    return theme.other.primaryColor;
  }};

  ${({ centered }) => centered && "text-align: center;"}
  margin-bottom: 5px;
  @media only screen and (max-width: 360px) {
    margin-top: ${props => (props.noMobileMargin ? "" : "20px")};
  }
`;

export const ModalBodyMessage = styled.div<{ centered?: boolean }>`
  font-family: ${props => props.theme.other.font.fontFamily};
  font-style: ${props => props.theme.other.font.fontStyle};
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  color: ${props => props.theme.other.monochromeColor[2]};
  margin-top: 5px;
  ${({ centered }) => centered && "text-align: center;"}
`;

export const ModalFooter = styled.div<{ centered?: boolean }>`
  display: flex;
  justify-content: ${({ centered }) => (centered ? "center" : "flex-end")};
  align-items: center;
  padding-top: 2rem;
  gap: 28px;
  @media only screen and (max-width: 430px) {
    justify-content: center;
    gap: 16px;
    flex-wrap: wrap-reverse;
  }
`;
