import { forwardRef } from "react";

import { useTheme } from "@emotion/react";
import { Group, SelectProps, Text } from "@mantine/core";
import { QuestionnaireQuestionType } from "types";
import { ChevronDown, ShortAnswerIcon } from "ui/icons";

import { mapQuestionnaireValueToIcon } from "../../questionnaireOptions";
import { QCSelectIcon, QCSelectStyled } from "./questionnaireCreator.styles";

type ItemProps = React.ComponentPropsWithoutRef<"div"> & {
  icon: React.ReactNode;
  label: string;
};

const SelectItem = forwardRef<HTMLDivElement, ItemProps>(({ icon, label, ...others }: ItemProps, ref) => (
  <div ref={ref} {...others}>
    <Group noWrap>
      <QCSelectIcon>{icon}</QCSelectIcon>
      <div>
        <Text size="sm">{label}</Text>
      </div>
    </Group>
  </div>
));

export const QCSelect = (props: SelectProps) => {
  const theme = useTheme();

  const currentIcon = props.value ? (
    mapQuestionnaireValueToIcon?.[props.value as QuestionnaireQuestionType]
  ) : (
    <ShortAnswerIcon />
  );

  return (
    <QCSelectStyled
      {...props}
      icon={currentIcon}
      rightSection={<ChevronDown />}
      itemComponent={SelectItem}
      styles={() => ({
        item: {
          "&[data-selected]": {
            "&, &:hover": {
              backgroundColor: theme.other.primaryColor
            }
          }
        }
      })}
    />
  );
};
