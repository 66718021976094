import { ReactNode } from "react";
import { useParams } from "react-router-dom";

import { useCurrentUserContext } from "modules/login/CurrentUserContext";
import { Feature, TFeatureFlagUser } from "types";

type ExternalUserPermissionGuardProps = {
  children: ReactNode;
  fallback?: JSX.Element;
  module: Feature;
};

export const ExternalUserPermissionGuard = ({ module, children, fallback }: ExternalUserPermissionGuardProps) => {
  const { id: paramsId } = useParams();

  const {
    userFeatureFlags,
    userDetails: { accountId },
    isInternal
  } = useCurrentUserContext();

  if (isInternal) {
    return <>{children}</>;
  }

  const isNeedsGuard = !isInternal;

  const nestedAccountRestrictedFeatureList = (userFeatureFlags as TFeatureFlagUser[]).map(i => {
    const accountPermissionList = i[paramsId as string] || i[accountId];
    if (!accountPermissionList) return [];
    return accountPermissionList
      .map(permission => {
        const [featureName, enabled] = Object.entries(permission)[0];
        return enabled ? null : featureName;
      })
      .filter(a => a) as NonNullable<Feature[]>;
  });

  const guardedAccountPermissions = ([] as Feature[]).concat(...nestedAccountRestrictedFeatureList);

  const isModuleNonActive = guardedAccountPermissions.some((p: Feature) => p === module);

  const isGuarded = isNeedsGuard && isModuleNonActive;

  return <>{isGuarded ? fallback : children}</>;
};
