import styled from "@emotion/styled";

export const AccountDetailsWrapper = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-flow: row wrap;
  justify-content: left;
  white-space: nowrap;

  @media only screen and (max-width: 1366px) {
    width: calc(100% - 20px);
    margin-left: 10px;
  }

  @media only screen and (max-width: ${props => props.theme.other.breakpoints.lg}) {
    flex-direction: column;
  }

  @media only screen and (max-width: 555px) {
    width: calc(100% - 20px);
    display: block;
  }
`;
export const StyleAccountLabelDetails = styled.div`
  font-family: ${props => props.theme.other.font.fontFamily};
  font-style: ${props => props.theme.other.font.fontStyle};
  font-weight: 700;
  font-size: ${props => props.theme.other.font.fontSize};
  line-height: ${props => props.theme.other.font.lineHeight};
  color: ${props => props.theme.other.monochromeColor[2]};
  display: flex;
  gap: 7px;
  align-items: center;
  margin-bottom: 15px;
  padding-right: 1rem;

  svg {
    color: ${({ theme }) => theme.other.primaryColor};
  }
`;
export const StyleAccountValueDetails = styled.span`
  font-weight: 400;
`;
export const Wrapper = styled.div`
  display: block;
  margin-top: 40px;
  width: 100%;
`;
export const StyleTableHeader = styled.div`
  font-family: ${props => props.theme.other.font.fontFamily};
  font-style: ${props => props.theme.other.font.fontStyle};
  font-weight: ${props => props.theme.other.font.header2.fontWeight};
  padding-top: ${props => props.theme.other.font.header2.paddingTop};
  font-size: ${props => props.theme.other.font.header2.fontSize};
  line-height: ${props => props.theme.other.font.header2.lineHeight};
  color: ${props => props.theme.other.primaryColor};
  display: flex;
  gap: 14px;
  @media only screen and (max-width: 425px) {
    justify-content: center;
    margin-bottom: 14px;
  }
`;
export const StyleNumberOfAccount = styled.div`
  font-family: ${props => props.theme.other.font.fontFamily};
  font-style: ${props => props.theme.other.font.fontStyle};
  font-weight: 400;
  font-size: ${props => props.theme.other.font.fontSize} !important;
  line-height: ${props => props.theme.other.font.lineHeight};
  color: ${props => props.theme.other.monochromeColor[2]};
  margin-top: auto;
  margin-bottom: 4px;
`;
export const StyleTableHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: end;
  @media only screen and (max-width: 1366px) {
    width: calc(100% - 20px);
    margin-left: auto;
    margin-right: auto;
  }
  @media only screen and (max-width: 425px) {
    display: block;
  }
`;

export const AccountLinkWrapper = styled.div`
  align-items: end;
  display: flex;
  flex-direction: column;
  flex: 1;
  row-gap: 4px;
  & > a {
    color: ${({ theme }) => theme.other.primaryColor};
  }
  @media only screen and (max-width: ${props => props.theme.other.breakpoints.lg}) {
    align-items: start;
  }
`;

export const StyleButtonWrapper = styled.div`
  width: 8rem;
  @media only screen and (max-width: 425px) {
    width: 100%;
  }
`;
