import { useGetCustomFields } from "modules/customFields";
import { ClearableSelect } from "ui/inputs/Select";

import { useImportContext } from "../ImportContext";
import { useImportMapModalContext } from "./ImportMapModalContext";
import { HeaderType } from "./importMapModal.types";

const systemOptions = [
  {
    label: "Date*",
    value: "date"
  },
  {
    label: "Supplier Name*",
    value: "supplierName"
  },
  {
    label: "Total Cost*",
    value: "totalCost"
  },
  {
    label: "Commodity Name*",
    value: "itemName"
  },
  {
    label: "Cost Center*",
    value: "costCenter"
  }
];

export const ImportMapModalSelect: React.FC<HeaderType> = ({ csvHeaderKey, kloopifyFieldKey }) => {
  const { selectedAccountId } = useImportContext();
  const { onChange, headers } = useImportMapModalContext();

  const { data: customFieldData } = useGetCustomFields({ accountId: selectedAccountId as string });

  const optionsFromCustomFields =
    customFieldData?.data?.map(({ id, field, fieldLabel }) => ({ value: id, label: field || fieldLabel })) || [];

  const headerFields = headers.map(header => header.kloopifyFieldKey).filter((header): header is string => !!header);

  const options = [
    ...systemOptions,
    ...optionsFromCustomFields,
    {
      label: "+ Add New Custom Field",
      value: "newCustomField"
    }
  ];

  const filteredOptions = options.map(option => {
    if (headerFields.includes(option.value)) {
      return { ...option, disabled: true, style: { display: "none" } };
    }
    return option;
  });

  return (
    <ClearableSelect
      data={filteredOptions}
      onChange={(klopifyTarget: string) => onChange(csvHeaderKey, klopifyTarget)}
      value={kloopifyFieldKey}
      zIndex={1}
      clearable
    />
  );
};
