import { parse } from "papaparse";

import { ImportHeaderType } from "../types";
import { useGetImportTransactionFileQuery } from "./useGetImportTransactionFileQuery";

export const useHandleMapping = ({
  importId,
  isStatusCreate,
  onSuccess
}: {
  importId: string;
  isStatusCreate: boolean;
  onSuccess: (values: ImportHeaderType[]) => void;
}) => {
  const queryData = useGetImportTransactionFileQuery(
    { importId },
    {
      enabled: isStatusCreate,
      select: data => {
        const results = parse<string[]>(data);

        const headers = results.data[0];

        const samples: string[][] = [];

        results.data.forEach((value, index) => {
          if (index > 0 && index <= 5) {
            samples.push(value);
            return;
          }
          return;
        });

        const mappingHeaders = headers.map((header, idx) => {
          const headerObject = {
            csvHeaderKey: header,
            kloopifyFieldKey: null,
            samples: samples.map(value => value[idx]),
            error: false
          };

          return headerObject;
        });

        return mappingHeaders;
      },
      onSuccess,
      refetchOnWindowFocus: false
    }
  );

  return queryData;
};
