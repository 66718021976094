import { createContext, useContext } from "react";

type SelectOption = { label: string; value: string };

export type BuyspaceContextOptions = {
  data: {
    costCenters: SelectOption[];
    items: SelectOption[];
    naicsCodes: SelectOption[];
    naicsIndustries: SelectOption[];
    naicsSectors: SelectOption[];
    suppliers: SelectOption[];
  };
  handleSetData: (key: keyof BuyspaceContextOptions["data"], data: SelectOption[]) => void;
  resetBuyspaceData: () => void;
};

const initialBuyspaceContext: BuyspaceContextOptions = {
  data: {
    suppliers: [],
    items: [],
    costCenters: [],
    naicsCodes: [],
    naicsIndustries: [],
    naicsSectors: []
  },
  handleSetData: () => {},
  resetBuyspaceData: () => {}
};

export const BuyspaceContext = createContext<BuyspaceContextOptions>(initialBuyspaceContext);

export const useBuyspaceContext = () => {
  const context = useContext(BuyspaceContext);

  if (!context) {
    throw new Error("useBuyspaceContext must be used within a BuyspaceContext.Provider");
  }

  return context;
};
