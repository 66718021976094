import { useState } from "react";

import { CLASSIFICATION_LEVEL } from "constants/classification";
import { uniqBy } from "lodash";
import { useGetClassification } from "modules/classification";
import { useGetClassificationListQueries } from "modules/classification";
import { IndustryClassification } from "types";
import { MultiSelect } from "ui";

import { SupplierSelectWrapper } from "./styles";

export const SupplierSelectComponent: React.FC<{
  label?: React.ReactNode;
  onChange: (values: string[]) => void;
  value: string[] | undefined;
}> = ({ onChange, value, label }) => {
  const [search, setSearch] = useState("");

  const enabled = search.length >= 3;

  const { data } = useGetClassification(
    { params: { "levels[]": CLASSIFICATION_LEVEL.detail, search } },
    { enabled, keepPreviousData: true }
  );

  const codes = !data?.data.length && value ? value : [];

  const getCodeQueries = useGetClassificationListQueries({ codes });

  const naicsCodes = getCodeQueries
    .map(({ data }) => {
      const codeData = data?.data[0] as IndustryClassification;
      return codeData;
    })
    .filter(data => data);

  const dataSource = uniqBy([...(data?.data || []), ...(naicsCodes || [])], ({ code }) => code);

  return (
    <SupplierSelectWrapper>
      <MultiSelect
        label={label}
        dataSource={dataSource}
        transformData={data => ({ value: data.code.toString(), label: data.title })}
        searchValue={search}
        onSearchChange={setSearch}
        onChange={onChange}
        value={value}
        simpleSelect
      />
    </SupplierSelectWrapper>
  );
};
