import moment from "moment";

interface DateRange {
  lastUpdatedMin: string | null;
  lastUpdatedMax: string | null;
}

export const parseDateOptions = (selectedOptions: string[]): DateRange => {
  const now = moment();
  let lastUpdatedMin: string | null = null;
  let lastUpdatedMax: string | null = now.toISOString();
  if (selectedOptions.includes("today")) {
    lastUpdatedMin = moment(now).startOf("day").toISOString();
    lastUpdatedMax = null;
  }
  if (selectedOptions.includes("7days")) {
    lastUpdatedMin = moment(now).subtract(7, "days").toISOString();
  }
  if (selectedOptions.includes("30days")) {
    lastUpdatedMin = moment(now).subtract(30, "days").toISOString();
  }
  if (selectedOptions.includes("6months")) {
    lastUpdatedMin = moment(now).subtract(6, "months").toISOString();
  }
  if (selectedOptions.includes("1year")) {
    lastUpdatedMin = moment(now).subtract(1, "year").toISOString();
  }
  if (selectedOptions.includes("1yearMore")) {
    lastUpdatedMax = moment(now).subtract(1, "year").toISOString();
  }
  return { lastUpdatedMin, lastUpdatedMax };
};
