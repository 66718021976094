import styled from "@emotion/styled";

export const Container = styled.div`
  width: 100%;
  z-index: 0;
  @media only screen and (max-width: 1366px) {
    width: calc(100% - 20px);
    margin-left: auto;
    margin-right: auto;
  }
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: ${props => props.theme.other.font.fontFamily};
  font-style: ${props => props.theme.other.font.fontStyle};
  font-weight: ${props => props.theme.other.font.header2.fontWeight};
  padding-top: ${props => props.theme.other.font.header2.paddingTop};
  padding-bottom: 
  font-size: ${props => props.theme.other.font.header2.fontSize};
  line-height: ${props => props.theme.other.font.header2.lineHeight};
  color: ${props => props.theme.other.primaryColor};
  text-align: left;
  @media only screen and (max-width: 705px) {
    text-align: center;
    display: grid;
    gap: ${props => props.theme.other.font.header2.paddingBottom};
    justify-content: center;
  }
`;

export const HeaderTitle = styled.div`
  @media only screen and (max-width: 705px) {
    padding-bottom: ${props => props.theme.other.font.header2.paddingBottom};
  }
`;

export const TableWrapper = styled.div`
  margin-top: 2.5rem;
  overflow-x: auto;
`;
