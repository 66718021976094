import { isEmpty } from "lodash";
import { TImportError } from "types";

export const renderErrorFromImport = (error: TImportError["error"]) => {
  if (typeof error === "string") {
    return error;
  }

  if (isEmpty(error)) {
    return "Error: The error object is empty";
  }

  if ("message" in error) {
    return error.message;
  }

  return "Error: Unspecified Error";
};
