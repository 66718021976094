import styled from "@emotion/styled";

export const StyleLoginButtonText = styled.div`
  border-left: 1px solid ${props => props.theme.other.monochromeColor[3]};
  height: 80%;
  text-align: center;
  display: flex;
  align-items: center;
  margin-left: 10px;
  padding-left: 10px;
  font-weight: 400;
  font-family: ${props => props.theme.other.font.fontFamily};
  font-style: ${props => props.theme.other.font.fontStyle};
  font-size: ${props => props.theme.other.font.fontSize};
  line-height: ${props => props.theme.other.font.lineHeight};
  color: ${props => props.theme.other.monochromeColor[2]};
`;
export const StyleLoginButton = styled.div`
  border: 1px solid ${props => props.theme.other.monochromeColor[3]};
  border-radius: 50px;
  width: 270px;
  height: 50px;
  padding: 0px 0 0px 16px;
  display: inline-flex;
  align-items: center;
  margin-bottom: 20px;
  cursor: pointer;
`;
export const StyleModalBox = styled.div`
  background: ${props => props.theme.other.monochromeColor[6]};
  box-shadow: 0px 3px 24px rgba(28, 50, 79, 0.1);
  border-radius: 4px;
  width: 100%;
  max-width: 500px;
  height: 300px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10vh;
  margin-bottom: auto;
  z-index: 10;
  position: relative;
  @media only screen and (max-height: 1140px) {
    top: auto;
    margin-top: 85px;
    margin-bottom: 120px;
  }
  @media only screen and (max-width: 550px) {
    margin-left: 10px;
    margin-right: 10px;
    padding: 20px;
  }
`;

export const LoginButtonsCenterer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
