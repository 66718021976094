import { createContext, FC, useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import { RowSelectionState } from "@tanstack/react-table";
import { TCommodity, TCommodityContext, TCommodityContextProps, TCommodityFilter, TCommodityModal } from "types";
import { parseQueryParams, stringifyQueryParams } from "utils/query.utils";

import { initialCommodityFilters, initialCommodityModal } from "../commodity.data";
import { useGetCommodityListQuery } from "../hooks/useCommodityListQuery";

const limit = 10;

export const CommodityContext = createContext({} as TCommodityContext);

export const CommodityContextProvider: FC<TCommodityContextProps> = ({ accountID, children }) => {
  const navigate = useNavigate();
  const { search } = useLocation();

  const [searchParams] = useSearchParams();
  const params = Object.fromEntries(searchParams);

  const naicsCodes = searchParams.getAll("naicsCodes[]");
  const naicsSectors = searchParams.getAll("naicsSectors[]");
  const naicsIndustries = searchParams.getAll("naicsIndustries[]");
  const supplierIds = searchParams.getAll("supplierIds[]");

  const initialQueryParams = stringifyQueryParams(parseQueryParams(search));
  const initialFilters = {
    ...initialCommodityFilters,
    ...parseQueryParams(search),
    naicsCodes: naicsCodes,
    naicsSectors: naicsSectors,
    naicsIndustries: naicsIndustries,
    supplierIds: supplierIds
  };

  const [filters, setFilters] = useState<TCommodityFilter>(initialFilters);

  const [queryParams, setQueryParams] = useState(initialQueryParams);

  const [modal, setModal] = useState<TCommodityModal>(initialCommodityModal);

  const [rowSelectionState, setRowSelectionState] = useState<RowSelectionState>({});
  const [rowSelection, setRowSelection] = useState<TCommodity[]>([]);

  const onRowSelectReset = () => setRowSelectionState({});

  const { data, isLoading, isInitialLoading, refetch } = useGetCommodityListQuery(
    { ...params, "supplierIds[]": supplierIds },
    limit,
    accountID
  );

  const modalController = (updatedModal: Partial<TCommodityModal>) => {
    setModal(prevModal => ({ ...prevModal, ...updatedModal }));
  };

  const updateFilters = (updatedFilters: Partial<TCommodityFilter>, hardUpdate?: boolean) => {
    const newFilters = { ...filters, ...updatedFilters };
    setFilters(newFilters);
    const newQueryParams = stringifyQueryParams(newFilters);
    setQueryParams(newQueryParams);
    if (hardUpdate) applyFilters(newQueryParams);
  };

  const applyFilters = (updatedQueryParams?: string) => {
    const realQueryParams = updatedQueryParams || queryParams;
    const queryObject = parseQueryParams(realQueryParams);
    const resetPageQueryString = stringifyQueryParams({ ...queryObject, page: "0" });
    navigate(`/commodities${accountID ? "/" + accountID : ""}?${resetPageQueryString}`);
  };

  const resetFilters = () => updateFilters({ ...initialCommodityFilters, page: filters.page });

  useEffect(() => {
    if (!modal.filters) {
      setFilters(initialFilters);
      setQueryParams(initialQueryParams);
    }
  }, [modal.filters]);

  useEffect(() => {
    if (search !== "?" + queryParams) navigate(`/commodities${accountID ? "/" + accountID : ""}?${queryParams}`);
  }, [filters.page]);

  useEffect(() => {
    if (!isInitialLoading) refetch();
  }, [search]);

  const providerValue = {
    filters,
    modal,
    rowSelection,
    commodityList: data?.data || [],
    isLoading,
    limit,
    totalCount: data?.meta.totalCount || 0,
    updateFilters,
    applyFilters,
    resetFilters,
    modalController,
    setRowSelection,
    onRowSelectReset,
    rowSelectionState,
    setRowSelectionState
  };

  return <CommodityContext.Provider value={providerValue}>{children}</CommodityContext.Provider>;
};
