import { forwardRef } from "react";

import { Button, MultiSelectProps } from "@mantine/core";

import { useMultiSelectProvider } from "./MultiSelectContext";
import { DropdownContainer, DropdownHeader } from "./styles";

const MultiSelectDropdownValue = () => {
  const { selectedCount } = useMultiSelectProvider();

  return <h3>{selectedCount || "No"} Items Selected</h3>;
};

export const MultiSelectDropdown = forwardRef<HTMLDivElement, MultiSelectProps["dropdownComponent"]>(
  (dropdownProps, ref) => {
    const { children, ...restProps } = dropdownProps;

    const { onClear } = useMultiSelectProvider();

    return (
      <DropdownContainer ref={ref} {...restProps}>
        <DropdownHeader>
          <MultiSelectDropdownValue />
          <Button variant="subtle" radius="xs" size="xs" onClick={onClear}>
            Clear All
          </Button>
        </DropdownHeader>
        {children}
      </DropdownContainer>
    );
  }
);
