import styled from "@emotion/styled";

export const DropzoneWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  text-align: center;
`;

export const DropzoneText = styled.div`
  font-family: ${props => props.theme.other.font.fontFamily};
  font-style: ${props => props.theme.other.font.fontStyle};
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.0625rem;
  margin-top: 1rem;
  color: ${props => props.theme.other.monochromeColor[2]};
  @media only screen and (max-width: 1000px) {
    padding: 5px;
  }
`;

export const DropzoneFilename = styled.div`
  padding: 1rem 0;
`;

export const DropzoneContainer = styled.div`
  border: 0.375rem solid ${props => props.theme.other.monochromeColor[6]};
  outline: ${props => props.theme.other.monochromeColor[4]} dashed 2px;
  .dzu-dropzone {
    background-color: rgba(239, 241, 243, 0.8);
    :hover {
      background-color: rgba(239, 241, 243);
    }
  }
  &,
  .dzu-inputLabel,
  .dzu-dropzone {
    min-height: 22.9375rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    @media only screen and (max-width: 580px) {
      min-height: 15.625rem;
    }
    @media only screen and (max-width: 420px) {
      min-height: 11.25rem;
    }
  }
  .dzu-input {
    display: none;
  }
  .dzu-previewContainer {
    display: none;
  }
  .dzu-submitButtonContainer {
    display: flex !important;
    justify-content: center;
  }
  .dzu-submitButton {
    width: 152px;
    height: 52px;
    font-size: 1rem;
    color: ${props => props.theme.other.monochromeColor[2]};
    border-radius: 50px;
    border: 1px solid ${props => props.theme.other.monochromeColor[2]} !important;
    background: transparent;
    font-family: ${props => props.theme.other.font.fontFamily};
    font-style: ${props => props.theme.other.font.fontStyle};
    line-height: 1.1875rem;
    cursor: pointer;
    padding: 0px;
    margin: 0px;
  }
`;
