import moment from "moment";
import { Maybe } from "yup/lib/types";

export function prettyNumber(n: Maybe<number | string>): string {
  if (isNaN(Number(n)) || n === null) {
    return "--";
  }

  const number = Number(n);
  if (number >= 1e3 && number < 1e6) return +(number / 1e3).toFixed(2) + "K";
  if (number >= 1e6 && number < 1e9) return +(number / 1e6).toFixed(2) + "M";
  if (number >= 1e9 && number < 1e12) return +(number / 1e9).toFixed(2) + "B";
  if (number >= 1e12) return +(number / 1e12).toFixed(1) + "T";
  return number.toFixed(2);
}

// expects cents, hard coded for USD
export function prettyMoney(unit: Maybe<number | string>): string {
  if (isNaN(Number(unit))) {
    return "--";
  }
  return "$" + prettyNumber(Number(unit) / 100);
}

export const prettyCents = (value: number) => Number((value * 100).toFixed(0));

export const formatCO2eValue = (value: number | string = 0): string => {
  const valueToNumber = Number(value);

  if (isNaN(valueToNumber)) {
    return "-";
  }

  if (valueToNumber === 0) {
    return "0.0000";
  }

  if (valueToNumber > 0 && valueToNumber < 0.0001) {
    return "< 0.0001";
  }

  return valueToNumber.toFixed(4);
};

export const formatDateString = (dateString: string) => {
  const date = new Date(dateString);
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const year = date.getFullYear();
  const formattedMonth = month < 10 ? `0${month}` : `${month}`;
  const formattedDay = day < 10 ? `0${day}` : `${day}`;
  return `${formattedMonth}/${formattedDay}/${year}`;
};

export const formatDateStringFull = (dateString: string) => {
  const date = new Date(dateString).toLocaleString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: true
  });
  return date;
};

export const getFullDayYear = (dateString: string) => {
  const date = new Date(dateString).toLocaleDateString("en-US", {
    month: "long",
    day: "numeric",
    year: "numeric"
  });
  return date;
};

export const getFullDayYearTime = (dateString: string) => {
  const date = new Date(dateString)
    .toLocaleDateString("en-US", {
      month: "long",
      day: "numeric",
      year: "numeric",
      hour12: true,
      hour: "numeric",
      minute: "numeric"
    })
    .replace("at", "")
    .replace(" PM", "pm");
  return date;
};

export const getDateNoTime = (date: Date | null) => {
  if (!date) return null;
  return moment(date).format("YYYY-MM-DD");
};

export const formatArrayText = (value: string | string[], endConnector = "or") => {
  if (Array.isArray(value)) {
    const spacerIndex = value.length - 1;
    return value.length > 1
      ? structuredClone(value).splice(0, spacerIndex).join(", ") + ` ${endConnector} ` + value[spacerIndex]
      : value[spacerIndex];
  }
  return value;
};
