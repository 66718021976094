import axiosInstance from "services/AxiosInstance";
import { ResponseType, TQuestionnaireRequest, TQuestionnaireRequestListPayload } from "types";

export const getQuestionnaireRequestListService = async (
  payload: TQuestionnaireRequestListPayload
): Promise<ResponseType<TQuestionnaireRequest[], { itemsCount: number; totalCount: number }>> => {
  const { pathArgs, apiArgs } = payload;
  const response = await axiosInstance.get(
    `/questionnaire/request/account/${pathArgs.accountId}/supplier/${pathArgs.supplierId}`,
    { params: apiArgs }
  );
  return response.data;
};
