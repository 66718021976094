import * as React from "react";

export const PencilIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
    <path
      d="M17.9694 1.26305C16.8192 0.112838 14.9544 0.112838 13.8042 1.26305L2.12773 12.9395C2.04771 13.0195 1.98993 13.1187 1.95976 13.2276L0.424273 18.7711C0.361126 18.9984 0.425296 19.2417 0.591986 19.4087C0.758931 19.5754 1.00232 19.6396 1.2296 19.5767L6.77305 18.0409C6.88196 18.0107 6.98115 17.953 7.06118 17.8729L18.7374 6.19624C19.8858 5.04526 19.8858 3.18202 18.7374 2.03105L17.9694 1.26305ZM3.55379 13.3649L13.1101 3.80839L16.192 6.89036L6.6355 16.4469L3.55379 13.3649ZM2.93817 14.6003L5.40042 17.0628L1.99453 18.0064L2.93817 14.6003ZM17.8119 5.27076L17.1178 5.96487L14.0355 2.88265L14.7299 2.18853C15.3688 1.54964 16.4047 1.54964 17.0436 2.18853L17.8119 2.95653C18.4498 3.59619 18.4498 4.63135 17.8119 5.27076Z"
      fill="currentColor"
    />
  </svg>
);
