import { Fragment, useContext } from "react";
import { NavLink, useLocation } from "react-router-dom";

import { Menu } from "@mantine/core";
import { CurrentUserContext } from "modules/login";

import { NavigationTab, NavigationTabsContainer } from "./styles";
import { useRoutesNavigation } from "./useRoutesNavigation";

export const NavigationTabs = () => {
  const { tabs, dropdowns } = useRoutesNavigation();

  const { pathname } = useLocation();

  const {
    userDetails: { userType }
  } = useContext(CurrentUserContext);

  const items = tabs.map(tab => {
    // Account tab should be active if analysis route is active for INTERNAL users. This is the exception.
    const accountAnalysisExceptionActive =
      tab.path === "/accounts" && userType === "INTERNAL" && pathname.includes("analysis");

    const supplierExceptionActive = tab.path.includes("suppliers") && pathname.includes("supplier");

    const commodityExceptionAction = tab.path.includes("commodities") && pathname.includes("commodity");

    const exceptionActive = accountAnalysisExceptionActive || supplierExceptionActive || commodityExceptionAction;

    return (
      <NavLink to={tab.path} key={tab.path}>
        {({ isActive }) => {
          return (
            <NavigationTab isActive={isActive || exceptionActive} tabContent={tab.name} id={tab.name + "NavTab"}>
              {tab.name}
            </NavigationTab>
          );
        }}
      </NavLink>
    );
  });

  const dropdownItems = dropdowns.map(({ name, routes }) => {
    const isActive = routes.some(route => pathname.includes(route.path));
    return routes.length ? (
      <Menu width={181} id={`${name}NavTab`} key={name}>
        <Menu.Target>
          <NavigationTab isActive={isActive} tabContent={name}>
            {name}
          </NavigationTab>
        </Menu.Target>
        <Menu.Dropdown>
          {routes.map((route, routeIndex) => (
            <Fragment key={routeIndex}>
              <NavLink
                to={route.path}
                key={route.path}
                style={{ textDecoration: "none" }}
                id={route.name.replaceAll(" ", "") + "DropdownBtn"}
              >
                <Menu.Item>{route.name}</Menu.Item>
              </NavLink>
              {routeIndex !== routes.length - 1 && <Menu.Divider />}
            </Fragment>
          ))}
        </Menu.Dropdown>
      </Menu>
    ) : null;
  });

  return (
    <NavigationTabsContainer>
      {items}
      {dropdownItems}
    </NavigationTabsContainer>
  );
};
