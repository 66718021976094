import { Country, State } from "country-state-city";
import { IDropdownDataInterface } from "modules/suppliers/suppliers.types";

const countries = Country.getAllCountries();

export const getStates = (countryCode: string | null): IDropdownDataInterface[] => {
  if (!countryCode) return [];
  const states = State.getStatesOfCountry(countryCode);
  return states?.map(state => ({ label: state.name, value: state.isoCode }));
};

export const getCountries = () => {
  return countries.map(country => ({ label: country.name, value: country.isoCode }));
};

export const getStateByIso = (iso: string | null, countryCode: string | null) => {
  if (!countryCode) return iso;
  const stateList = State.getStatesOfCountry(countryCode);
  const state = stateList.find(i => i.isoCode === iso);
  if (state) return state.name;
  return iso;
};

export const getCountryByIso = (iso: string | null) => {
  if (!iso) return iso;
  const country = countries.find(i => i.isoCode === iso);
  if (country) return country.name;
  return iso;
};
