import { ColumnDef } from "@tanstack/react-table";
import { ImportInterface } from "modules/imports/types";
import moment from "moment";

import { ImportApproveCell } from "../ImportApproveCell";
import { ImportStatusTag } from "../ImportStatusTag";
import { ImportAccountCell } from "./ImportAccountCell";
import { ImportFilenameCell } from "./ImportFilenameCell";
import { ImportMenu } from "./ImportMenu";

export const columns: ColumnDef<ImportInterface, string>[] = [
  {
    header: "File Name",
    accessorKey: "filename",
    enableSorting: false,
    cell: props => <ImportFilenameCell {...props.row.original} />,
    size: 400
  },
  {
    header: "Account",
    accessorKey: "accountId",
    enableSorting: false,
    enableResizing: false,
    size: 200,
    cell: props => <ImportAccountCell {...props.row.original} />
  },
  {
    header: "Date Uploaded",
    accessorKey: "createdAt",
    enableSorting: false,
    size: 160,
    cell: props => {
      const value = props.getValue();
      return moment(value).format("MM/DD/YYYY");
    }
  },
  {
    header: "Date Completed",
    accessorKey: "approvedOn",
    enableSorting: false,
    size: 160,
    cell: props => <ImportApproveCell {...props.row.original} />
  },
  {
    header: "Status",
    accessorKey: "status",
    enableSorting: false,
    size: 460,
    cell: props => <ImportStatusTag {...props.row.original} />,
    meta: {
      align: "center"
    }
  },
  {
    header: "Uploaded By",
    accessorKey: "originatedBy",
    enableSorting: false,
    size: 160,
    cell: props =>
      `${props.row.original.originatedBy?.firstName ?? ""} ${props.row.original.originatedBy?.lastName ?? ""}`
  },
  {
    header: "Transactions",
    accessorKey: "transactions",
    enableSorting: false,
    size: 140
  },
  {
    header: "Action",
    id: "action",
    enableSorting: false,
    size: 50,
    cell: props => <ImportMenu {...props.row.original} />,
    meta: {
      align: "center"
    }
  }
];
