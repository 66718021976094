import { ErrorBoundary } from "react-error-boundary";

import { useGetAccount } from "hooks";
import { Analytics } from "modules/analytics";
import ErrorHandler from "modules/error/ErrorHandler";
import PrivateLayout from "ui/layouts/PrivateLayout";

const AnalyticsScreen = () => {
  const account = useGetAccount();

  return (
    <PrivateLayout accountName={account?.name}>
      <ErrorBoundary FallbackComponent={ErrorHandler}>
        <Analytics />
      </ErrorBoundary>
    </PrivateLayout>
  );
};
export default AnalyticsScreen;
