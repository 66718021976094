import styled from "@emotion/styled";

export const Pending = styled.div`
  font-family: ${props => props.theme.other.font.fontFamily};
  font-style: ${props => props.theme.other.font.fontStyle};
  font-weight: 700;
  font-size: ${props => props.theme.other.font.fontSize} !important;
  line-height: ${props => props.theme.other.font.lineHeight};
  text-align: center;
  color: ${props => props.theme.other.warningColor};
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const StyleText = styled.div<{ background?: string }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 20px;
  gap: 10px;
  background: ${theme => (theme.background ? theme.background : "rgba(251, 176, 59, 0.2)")};
  border-radius: 22px;
`;
export const Rejected = styled.div`
  font-family: ${props => props.theme.other.font.fontFamily};
  font-style: ${props => props.theme.other.font.fontStyle};
  font-weight: 700;
  font-size: ${props => props.theme.other.font.fontSize};
  line-height: ${props => props.theme.other.font.lineHeight};
  text-decoration-line: underline;
  color: ${props => props.theme.other.monochromeColor[7]};
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const Complete = styled.div`
  font-family: ${props => props.theme.other.font.fontFamily};
  font-style: ${props => props.theme.other.font.fontStyle};
  font-weight: 700;
  font-size: ${props => props.theme.other.font.fontSize} !important;
  line-height: ${props => props.theme.other.font.lineHeight};
  color: ${props => props.theme.other.primaryColor};
  display: flex;
  justify-content: center;
  align-items: center;
`;
