import axiosInstance from "services/AxiosInstance";
import { ResponseType } from "types";
import { TQuestionnaireRequest } from "types";

export const getQuestionnaireRequestService = async (
  questionnaireRequestId?: string
): Promise<ResponseType<TQuestionnaireRequest>> => {
  const response = await axiosInstance.get(`/questionnaire/request/${questionnaireRequestId}`);
  return response.data;
};
