import { useQuery } from "@tanstack/react-query";
import { QUERY_KEYS } from "constants/queryKeys";
import { TBuyspacesRequestParams } from "types";

import { getBuyspacesService } from "../api";

export const useGetBuyspacesQuery = (params: TBuyspacesRequestParams) =>
  useQuery({
    queryFn: () => getBuyspacesService(params),
    queryKey: [QUERY_KEYS.get_buyspaces, params.id],
    enabled: Boolean(params.id)
  });
