import { useEffect, useState } from "react";

import { debounce, uniqBy } from "lodash";
import { useGetClassification } from "modules/classification";
import { IndustryClassification } from "types";
import { MultiSelect } from "ui";
import Tooltip from "ui/tooltip/commonTooltip/Tooltip";
import NaicsTooltipContent from "ui/tooltipContent/naicsTooltipContent/NaicsTooltipContent";

import { FilterMultiSelectComponent, FilterTitle } from "./styles";
import { FilterMultiSelectProps } from "./types";

export const ClassificationMultiSelect: React.FC<FilterMultiSelectProps<IndustryClassification>> = ({
  title,
  placeholder,
  placement = "bottom",
  params,
  onChange,
  value,
  initialData
}) => {
  const [search, setSearch] = useState<string | null>(null);
  const [options, setOptions] = useState<IndustryClassification[]>([]);

  const onSearch = debounce((value: string) => (value ? setSearch(value) : setSearch(null)), 300);

  const fetchEnabled = !!(search && search?.length >= 3);

  const { data, error, isFetching } = useGetClassification(
    { params: { ...params, search } },
    { retry: 0, enabled: fetchEnabled }
  );

  useEffect(() => {
    if (data?.data) {
      setOptions(prev => {
        const newOptions = [...prev, ...data.data].filter(option => option.code) ?? [];
        return uniqBy(newOptions, ({ code }) => code);
      });
    }
  }, [data?.data]);

  useEffect(() => {
    if (initialData) {
      setOptions(prev => {
        const newOptions = [...prev, ...initialData];
        return uniqBy(newOptions, ({ code }) => code);
      });
    }
  }, [initialData]);

  return (
    <FilterMultiSelectComponent>
      <Tooltip header={() => <FilterTitle>{title}</FilterTitle>}>
        <NaicsTooltipContent />
      </Tooltip>
      <MultiSelect
        dataSource={options}
        transformData={d => ({ label: d.title ?? "", value: d.code ?? "" })}
        loading={isFetching}
        placeholder={placeholder}
        searchValue={search ?? ""}
        onSearchChange={onSearch}
        error={error?.response?.data?.errorMessage}
        clearSearchOnBlur={false}
        onChange={onChange}
        value={value}
        dropdownPosition={placement}
      />
    </FilterMultiSelectComponent>
  );
};
