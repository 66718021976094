import { useQuery, UseQueryOptions, UseQueryResult } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { QUERY_KEYS } from "constants/queryKeys";
import { ResponseError } from "types";

import { getImportTransactionFileService } from "../api";
import { ImportHeaderType } from "../types";

type UseGetImportTransactionFileQueryFunction = (
  apiArgs: { importId: string },
  options?: UseQueryOptions<string, AxiosError<ResponseError>, ImportHeaderType[], [string, string]>
) => UseQueryResult<ImportHeaderType[], AxiosError<ResponseError>>;

export const useGetImportTransactionFileQuery: UseGetImportTransactionFileQueryFunction = ({ importId }, options) => {
  const query = useQuery({
    queryKey: [QUERY_KEYS.get_import_transaction_file, importId],
    queryFn: () => getImportTransactionFileService(importId),
    ...options
  });
  return query;
};
