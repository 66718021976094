import { Select } from "@mantine/core";
import { QuestionnaireValidateReady } from "types";

type QuestionnaireSelectProps = {
  data: QuestionnaireValidateReady;
  group: string;
  onChange: (response: string | string[], id: string, group: string, loading?: boolean, error?: string) => void;
  submitted?: boolean;
};

const QuestionnaireSelect = ({ data, group, onChange, submitted }: QuestionnaireSelectProps) => {
  const normalizedData = data.choices.map(i => ({ label: i.choice, value: i.choice }));

  return (
    <Select
      data={normalizedData}
      value={data?.response as string}
      error={data.error}
      label={data.question}
      required={data.required}
      onChange={(value: string) => !submitted && onChange(value, data.id, group)}
      w={300}
      maw="50%"
      placeholder="Select Answer"
    />
  );
};

export default QuestionnaireSelect;
