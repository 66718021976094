import { FC, Fragment, useEffect, useState } from "react";

import { Button, Grid, Select, Textarea, TextInput } from "@mantine/core";
import { useForm, yupResolver } from "@mantine/form";
import { ModalHeader, StyleModalHeaderDescription } from "modules/users/components/styles";
import ModalAccountSelect from "ui/inputs/ModalAccountSelect/ModalAccountSelect";
import ErrorModal from "ui/overlays/ErrorModal";
import Modal from "ui/overlays/Modal";
import SuccessModal from "ui/overlays/SuccessModal";
import { getCountries, getStates } from "utils/input.utils";

import { useCreateNewSupplierMutation } from "../hooks";
import { FormRowWrapper, StyledAccountWrapper, StyledModalButtonWrapper } from "../styles/addSupplierModal.styles";
import { addNewSupplierInitialValues, addNewSupplierSchema, initialMutationResultModal } from "../suppliers.data";
import { IAddSupplierMutationResultModal } from "../suppliers.types";
import { SupplierSelectComponent } from "./SupplierSelectComponent";

type Props = {
  createSupplierOpened: boolean;
  setOpened: () => void;
};

export const AddSupplierModal: FC<Props> = ({ createSupplierOpened, setOpened }) => {
  const [mutationResultModal, setMutationResultModal] = useState(initialMutationResultModal);

  const { isSuccess, isError, error, mutate } = useCreateNewSupplierMutation();

  const addNewSupplierForm = useForm({
    initialValues: addNewSupplierInitialValues,
    validate: yupResolver(addNewSupplierSchema)
  });

  const handleMutationResultModal = (update: Partial<IAddSupplierMutationResultModal>) => {
    setMutationResultModal(prevResultModal => ({ ...prevResultModal, ...update }));
  };

  const closeModals = () => handleMutationResultModal(initialMutationResultModal);

  const onCreateNewSupplier = (values: typeof addNewSupplierInitialValues) => {
    const { accountId, naicsCode, ...payload } = values;
    const code = naicsCode ? naicsCode[0] : "";
    mutate({ accountId: accountId, payload: { ...payload, naicsCode: code } });
  };

  useEffect(() => {
    if (!createSupplierOpened) {
      addNewSupplierForm.reset();
    }
  }, [createSupplierOpened]);

  useEffect(() => {
    if (isSuccess) {
      setOpened();
      handleMutationResultModal({ success: true });
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      setOpened();
      handleMutationResultModal({ error: true, errorMessage: error.message });
    }
  }, [isError]);

  const modalTitle = (
    <div>
      <ModalHeader>Add New Supplier</ModalHeader>
      <StyleModalHeaderDescription>
        Enter in the Supplier information. Required fields are marked with an asterisk*.
      </StyleModalHeaderDescription>
    </div>
  );

  return (
    <Fragment>
      <Modal
        opened={createSupplierOpened}
        setOpened={setOpened}
        handleClose={() => setOpened()}
        withCloseButton
        title={modalTitle}
      >
        <form onSubmit={addNewSupplierForm.onSubmit(onCreateNewSupplier)}>
          <FormRowWrapper alignLeft>
            <StyledAccountWrapper>
              <ModalAccountSelect
                value={addNewSupplierForm.values.accountId}
                errorMsg={addNewSupplierForm.getInputProps("accountId").error}
                onChange={(value: string) => {
                  addNewSupplierForm.setFieldValue("accountId", value);
                }}
              />
            </StyledAccountWrapper>
          </FormRowWrapper>
          <Grid gutter={12}>
            <Grid.Col xs={12} sm={12} md={6}>
              <TextInput label="Supplier Name" withAsterisk {...addNewSupplierForm.getInputProps("name")} />
            </Grid.Col>
            <Grid.Col xs={12} sm={12} md={6}>
              <SupplierSelectComponent
                onChange={value => addNewSupplierForm.setFieldValue("naicsCode", value)}
                value={addNewSupplierForm.getInputProps("naicsCode").value}
                label="NAICS Code"
              />
            </Grid.Col>
            <Grid.Col xs={12} sm={12} md={2}>
              <Select
                searchable
                placeholder="Country"
                label="Country"
                data={getCountries()}
                value={addNewSupplierForm.values.country}
                onChange={(value: string) => {
                  addNewSupplierForm.setFieldValue("country", value);
                  addNewSupplierForm.setFieldValue("region", "");
                }}
              />
            </Grid.Col>
            <Grid.Col xs={12} sm={12} md={2}>
              <Select
                searchable
                label="State"
                placeholder="State"
                data={getStates(addNewSupplierForm.values.country)}
                value={addNewSupplierForm.values.region}
                onChange={(value: string) => addNewSupplierForm.setFieldValue("region", value)}
              />
            </Grid.Col>
            <Grid.Col xs={12} sm={12} md={6}>
              <TextInput label="City" {...addNewSupplierForm.getInputProps("city")} />
            </Grid.Col>
            <Grid.Col xs={12} sm={12} md={2}>
              <TextInput label="Zip Code" />
            </Grid.Col>
            <Grid.Col span={12}>
              <Textarea label="Description" {...addNewSupplierForm.getInputProps("description")} />
            </Grid.Col>
          </Grid>

          <StyledModalButtonWrapper marginTop={"4.5rem"}>
            <Button
              variant="white"
              type="button"
              onClick={() => {
                setOpened();
                addNewSupplierForm.reset();
              }}
            >
              Cancel
            </Button>
            <Button type="submit">Add</Button>
          </StyledModalButtonWrapper>
        </form>
      </Modal>
      <ErrorModal
        openModal={mutationResultModal.error}
        setOpenModal={closeModals}
        message={mutationResultModal.errorMessage}
      />
      <SuccessModal
        openModal={mutationResultModal.success}
        setOpenModal={closeModals}
        message="Supplier is successfuly created!"
        closeOpenedModal={closeModals}
      />
    </Fragment>
  );
};
