import { MantineThemeOverride, Tuple } from "@mantine/core";
import * as ColorPalette from "constants/colorPalette";

import { SCREEN_LG, SCREEN_MD, SCREEN_SM, SCREEN_XL, SCREEN_XS, SCREEN_XXL } from "./breakPoints";
import { menuProperties } from "./menuProperties";
import { font } from "./typography";

type MantineColorTuple = Tuple<string, 10>;

const klpColorPalette: MantineColorTuple = [
  "#e0f8e6",
  "#c2f0ce",
  "#92e3a7",
  "#5bcd7a",
  "#32a752",
  "#279244",
  "#227338",
  "#1f5c30",
  "#1b4c2a",
  "#0a2914"
];
const warningColorPalette: MantineColorTuple = [
  "#fff6e0",
  "#ffeccc",
  "#fed89b",
  "#fcc267",
  "#fbaf3a",
  "#faa41d",
  "#fa9e09",
  "#df8900",
  "#c77900",
  "#ad6800"
];

const dangerColorPalette: MantineColorTuple = [
  "#ffe9f2",
  "#fdd1df",
  "#f9a1bb",
  "#f66e96",
  "#f34477",
  "#f22b63",
  "#f21e58",
  "#d81249",
  "#c10840",
  "#a90036"
];

export const theme: MantineThemeOverride = {
  other: {
    primaryColor: ColorPalette.primaryColor,
    secondaryColor: ColorPalette.secondaryColor,
    tertiaryColor: ColorPalette.tertiaryColor,
    warningColor: ColorPalette.warningColor,
    dangerColor: ColorPalette.dangerColor,
    monochromeColor: ColorPalette.monochromeColor,
    otherColor: ColorPalette.otherColor,
    otherColorRGBA: ColorPalette.otherColorRGBA,
    font: font,
    table: {
      marginTop: "2.5rem",
      marginBottom: "1.8rem"
    },
    menu: menuProperties,
    breakpoints: {
      xs: SCREEN_XS,
      sm: SCREEN_SM,
      md: SCREEN_MD,
      lg: SCREEN_LG,
      xl: SCREEN_XL,
      xxl: SCREEN_XXL
    }
  },
  colors: {
    klp: klpColorPalette,
    warning: warningColorPalette,
    danger: dangerColorPalette
  },
  primaryColor: "klp",
  primaryShade: 4
};
