import { useMutation, useQueryClient } from "@tanstack/react-query";
import { QUERY_KEYS } from "constants/queryKeys";
import { postImportService } from "modules/imports/api";

export const useCreateImportMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: postImportService,
    mutationKey: [QUERY_KEYS.create_import],
    onSuccess: () => {
      queryClient.refetchQueries({ queryKey: [QUERY_KEYS.imports_listing] });
    }
  });
};
