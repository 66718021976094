import { useEffect } from "react";

import { Button, Text } from "@mantine/core";
import { useForm, yupResolver } from "@mantine/form";
import { QuestionnaireQuestionScope, QuestionnaireQuestionType, TQuestionnairePayload } from "types";
import { ViewIcon } from "ui/icons";

import { questionnaireFormValidationSchema } from "../../questionnaire.data";
import { QCFormValues, QCPreviewValues } from "../../questionnaire.types";
import { createQuestionInitialDataValues } from "../../questionnaire.utils";
import { QCMainForm } from "./QCMainForm";
import { QCSection } from "./QCSection";
import { initialValues as questionnaireCreatorInitialValues } from "./questionnaireCreator.data";
import { QCFooter } from "./questionnaireCreator.styles";

type QCFormProps = {
  initialValues: QCFormValues;
  isLoading: boolean;
  onPreview: (previewValues: QCPreviewValues, payload: TQuestionnairePayload) => void;
  onSubmit: (values: TQuestionnairePayload) => void;
};

export const QCForm: React.FC<Partial<QCFormProps>> = ({ initialValues, onSubmit, isLoading, onPreview }) => {
  const form = useForm<QCFormValues>({
    initialValues: questionnaireCreatorInitialValues,
    validate: yupResolver(questionnaireFormValidationSchema)
  });

  const transformValuesToQuestionnaire = (values: QCFormValues) => {
    const { supplierQuestionList, productQuestionList, ...rest } = values;

    const supplierQuestions = supplierQuestionList.map(question => ({
      ...question,
      scope: QuestionnaireQuestionScope.SCOPE_SUPPLIER
    }));
    const productQuestions = productQuestionList.map(question => ({
      ...question,
      scope: QuestionnaireQuestionScope.SCOPE_ITEM
    }));

    const isSelectType = (type: QuestionnaireQuestionType) =>
      [QuestionnaireQuestionType.MULTI_SELECT, QuestionnaireQuestionType.SELECT].includes(type);

    const cleanQuestionFromNotUsedChoices = [...supplierQuestions, ...productQuestions].map(question => ({
      ...question,
      choices: isSelectType(question.type) ? question.choices : undefined
    }));

    return { ...rest, questions: cleanQuestionFromNotUsedChoices } as TQuestionnairePayload;
  };

  const handlePreview = () => {
    const values = transformValuesToQuestionnaire(form.values);
    // To fulfil type TQuestionniareQuestion needs for DynamicQuestionnaire component
    const questionnaireValues = {
      ...values,
      questions: createQuestionInitialDataValues(values.questions)
    };

    form.validate();

    const isValid = form.isValid();

    if (isValid) {
      onPreview?.(questionnaireValues, values);
    }
  };

  useEffect(() => {
    if (initialValues) {
      form.setValues({ ...initialValues });
    }
  }, [initialValues]);

  return (
    <form
      onSubmit={form.onSubmit(values => {
        if (onSubmit) {
          const transformedValues = transformValuesToQuestionnaire(values);
          onSubmit?.(transformedValues);
        }
      })}
    >
      <QCMainForm form={form} />
      <QCSection form={form} scope="supplierQuestionList" title="Supplier Questions" />
      {form.errors?.supplierQuestionList && (
        <Text align="center" c="red" fz="xs">
          {form.errors.supplierQuestionList}
        </Text>
      )}
      <QCSection form={form} scope="productQuestionList" title="Product Questions" />
      {form.errors?.productQuestionList && (
        <Text align="center" c="red" fz="xs">
          {form.errors.productQuestionList}
        </Text>
      )}
      <QCFooter>
        <Button type="button" onClick={handlePreview} leftIcon={<ViewIcon />}>
          View Draft
        </Button>
        <Button type="submit" loading={isLoading}>
          Save
        </Button>
      </QCFooter>
    </form>
  );
};
