import * as React from "react";

export const TrashIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="16" height="20" viewBox="0 0 16 20" fill="none" {...props}>
      <path
        d="M14.5638 2.18536H11.5159V1.21932C11.5152 0.896142 11.3865 0.586398 11.158 0.357879C10.9295 0.12936 10.6198 0.000678228 10.2966 0H5.42746C5.1041 0.000395562 4.79408 0.128952 4.56533 0.357502C4.33658 0.586052 4.20775 0.895957 4.20707 1.21932V2.18536H1.15921C0.85187 2.1857 0.557218 2.30794 0.339899 2.52526C0.122579 2.74258 0.000339724 3.03723 0 3.34456V4.59747C0.000396212 4.90478 0.122654 5.1994 0.339961 5.41671C0.557269 5.63402 0.851887 5.75628 1.15921 5.75667H1.17439V18.3864C1.17485 18.8142 1.34496 19.2243 1.64742 19.5268C1.94988 19.8293 2.35998 19.9995 2.78774 20H12.9352C13.363 19.9995 13.7731 19.8293 14.0756 19.5268C14.378 19.2243 14.5481 18.8142 14.5486 18.3864V5.75667H14.5638C14.8711 5.75628 15.1657 5.63401 15.3829 5.4167C15.6002 5.19938 15.7224 4.90476 15.7228 4.59747V3.34456C15.7225 3.03725 15.6003 2.7426 15.383 2.52527C15.1657 2.30794 14.8711 2.1857 14.5638 2.18536ZM4.95577 1.21932C4.95617 1.09444 5.00603 0.974805 5.09443 0.886603C5.18283 0.7984 5.30258 0.748816 5.42746 0.748703H10.2955C10.4202 0.749098 10.5397 0.798807 10.6279 0.886979C10.716 0.97515 10.7657 1.09462 10.7661 1.21932V2.18536H4.95577V1.21932ZM13.7999 18.3864C13.7997 18.6157 13.7085 18.8355 13.5464 18.9977C13.3843 19.1598 13.1645 19.251 12.9352 19.2513H2.78774C2.55847 19.251 2.33868 19.1598 2.17658 18.9977C2.01449 18.8355 1.92332 18.6157 1.9231 18.3864V5.75667H13.7999V18.3864ZM14.9741 4.59747C14.974 4.70628 14.9308 4.81063 14.8538 4.8876C14.7769 4.96456 14.6726 5.00785 14.5638 5.00797H1.15921C1.05037 5.00785 0.946022 4.96457 0.869062 4.88761C0.792102 4.81065 0.748816 4.7063 0.748703 4.59747V3.34456C0.748816 3.23573 0.792102 3.13138 0.869062 3.05442C0.946022 2.97746 1.05037 2.93417 1.15921 2.93406H14.5638C14.6726 2.93417 14.7769 2.97747 14.8538 3.05443C14.9308 3.1314 14.974 3.23575 14.9741 3.34456V4.59747Z"
        fill="currentColor"
      />
      <path
        d="M7.86166 16.7465C7.91082 16.7465 7.95952 16.7369 8.00495 16.7181C8.05038 16.6993 8.09166 16.6717 8.12643 16.6369C8.16119 16.6022 8.18877 16.5609 8.20757 16.5155C8.22637 16.47 8.23604 16.4213 8.23601 16.3722V8.6357C8.23601 8.53642 8.19657 8.4412 8.12636 8.371C8.05616 8.30079 7.96094 8.26135 7.86166 8.26135C7.76237 8.26135 7.66715 8.30079 7.59695 8.371C7.52674 8.4412 7.4873 8.53642 7.4873 8.6357V16.3722C7.4873 16.4714 7.52674 16.5667 7.59695 16.6369C7.66715 16.7071 7.76237 16.7465 7.86166 16.7465Z"
        fill="currentColor"
      />
      <path
        d="M11.1419 16.7465C11.2412 16.7465 11.3364 16.7071 11.4066 16.6369C11.4768 16.5667 11.5163 16.4714 11.5163 16.3722V8.6357C11.5163 8.53642 11.4768 8.4412 11.4066 8.371C11.3364 8.30079 11.2412 8.26135 11.1419 8.26135C11.0426 8.26135 10.9474 8.30079 10.8772 8.371C10.807 8.4412 10.7676 8.53642 10.7676 8.6357V16.3722C10.7676 16.4714 10.807 16.5667 10.8772 16.6369C10.9474 16.7071 11.0426 16.7465 11.1419 16.7465Z"
        fill="currentColor"
      />
      <path
        d="M4.58138 16.7465C4.68067 16.7465 4.77589 16.7071 4.84609 16.6369C4.91629 16.5667 4.95573 16.4714 4.95573 16.3722V8.6357C4.95573 8.53642 4.91629 8.4412 4.84609 8.371C4.77589 8.30079 4.68067 8.26135 4.58138 8.26135C4.4821 8.26135 4.38688 8.30079 4.31668 8.371C4.24647 8.4412 4.20703 8.53642 4.20703 8.6357V16.3722C4.20703 16.4714 4.24647 16.5667 4.31668 16.6369C4.38688 16.7071 4.4821 16.7465 4.58138 16.7465Z"
        fill="currentColor"
      />
    </svg>
  );
};
