import {
  QuestionnaireQuestionScope,
  QuestionnaireQuestionType,
  QuestionnaireRequestStatus,
  TQuestionnaireQuestionPayload
} from "types";
import * as Yup from "yup";

import QuestionnaireFile from "./components/QuestionnaireFile";
import QuestionnaireItemSelect from "./components/QuestionnaireItemSelect";
import QuestionnaireLongText from "./components/QuestionnaireLongText";
import QuestionnaireMultiSelect from "./components/QuestionnaireMultiSelect";
import QuestionnaireSelect from "./components/QuestionnaireSelect";
import QuestionnaireShortText from "./components/QuestionnaireShortText";
import { QCFormValues } from "./questionnaire.types";
import { validateFileResponse, validateMultiResponse, validateSingleResponse } from "./questionnaire.utils";

export const SelectorMap = {
  [QuestionnaireQuestionType.LONG_TEXT]: QuestionnaireLongText,
  [QuestionnaireQuestionType.SHORT_TEXT]: QuestionnaireShortText,
  [QuestionnaireQuestionType.SELECT]: QuestionnaireSelect,
  [QuestionnaireQuestionType.MULTI_SELECT]: QuestionnaireMultiSelect,
  [QuestionnaireQuestionType.ITEM]: QuestionnaireItemSelect,
  [QuestionnaireQuestionType.FILE]: QuestionnaireFile
};

export const titleMap = {
  [QuestionnaireQuestionScope.SCOPE_ITEM]: "Product Information",
  [QuestionnaireQuestionScope.SCOPE_SUPPLIER]: "Supplier Information"
};

export const titleDetailMap = {
  [QuestionnaireQuestionScope.SCOPE_ITEM]: "Product Questions",
  [QuestionnaireQuestionScope.SCOPE_SUPPLIER]: "Supplier Questions"
};

export const validationMap = {
  [QuestionnaireQuestionType.LONG_TEXT]: validateSingleResponse,
  [QuestionnaireQuestionType.SHORT_TEXT]: validateSingleResponse,
  [QuestionnaireQuestionType.SELECT]: validateSingleResponse,
  [QuestionnaireQuestionType.ITEM]: validateSingleResponse,
  [QuestionnaireQuestionType.MULTI_SELECT]: validateMultiResponse,
  [QuestionnaireQuestionType.FILE]: validateFileResponse
};

export const statusMap = {
  [QuestionnaireRequestStatus.APPROVED]: "Approved",
  [QuestionnaireRequestStatus.SUBMITTED]: "Submitted",
  [QuestionnaireRequestStatus.IN_PROGRESS]: "In Progress",
  [QuestionnaireRequestStatus.REJECTED]: "Rejected",
  [QuestionnaireRequestStatus.SENT]: "Sent"
};

export const questionnaireResponseSchema = Yup.object().shape({
  questionnaireId: Yup.string().required("Questionnaire has to be selected."),
  sendTo: Yup.string()
    .matches(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Please specify a valid email."
    )
    .required("Please specify email."),
  sendToFirstName: Yup.string()
    .matches(/^[a-z ,.'-]+$/i, "Must only contain letters")
    .required("Please specify the recipient's first name"),
  sendToLastName: Yup.string()
    .matches(/^[a-z ,.'-]+$/i, "Must only contain letters")
    .required("Please specify the recipient's last name"),
  respondBy: Yup.string().required("Due date has to be selected.")
});

export const forbiddenExtensions = ["exe", "batch", "sh"];

export const questionnaireFormValidationSchema = Yup.object<Record<keyof QCFormValues, Yup.AnySchema>>().shape({
  title: Yup.string().required("Title is required."),
  description: Yup.string().required("Description is required."),
  supplierQuestionList: Yup.array().of(
    Yup.object().shape({
      question: Yup.string().required("Question cannot be empty."),
      choices: Yup.array()
        .of(
          Yup.object().shape({
            choice: Yup.string()
          })
        )
        .when("type", {
          is: (type: QuestionnaireQuestionType) =>
            type === QuestionnaireQuestionType.MULTI_SELECT || type === QuestionnaireQuestionType.SELECT,
          then: schema => schema.min(1, "At least one choice must be filled")
        })
        .when("type", {
          is: (type: QuestionnaireQuestionType) =>
            type === QuestionnaireQuestionType.MULTI_SELECT || type === QuestionnaireQuestionType.SELECT,
          then: Yup.array().of(
            Yup.object().shape({
              choice: Yup.string().required("Choice cannot be empty.")
            })
          )
        })
    })
  ),
  productQuestionList: Yup.array()
    .of(
      Yup.object().shape({
        question: Yup.string().required("Question cannot be empty."),
        choices: Yup.array()
          .of(
            Yup.object().shape({
              choice: Yup.string().when("type", {
                is: (type: QuestionnaireQuestionType) =>
                  type === QuestionnaireQuestionType.MULTI_SELECT || type === QuestionnaireQuestionType.SELECT,
                then: Yup.string().required("Choice cannot be empty.")
              })
            })
          )
          .when("type", {
            is: (type: QuestionnaireQuestionType) =>
              type === QuestionnaireQuestionType.MULTI_SELECT || type === QuestionnaireQuestionType.SELECT,
            then: schema => schema.min(1, "At least one choice must be filled")
          })
          .when("type", {
            is: (type: QuestionnaireQuestionType) =>
              type === QuestionnaireQuestionType.MULTI_SELECT || type === QuestionnaireQuestionType.SELECT,
            then: Yup.array().of(
              Yup.object().shape({
                choice: Yup.string().required("Choice cannot be empty.")
              })
            )
          })
      })
    )
    .when("supplierQuestionList", {
      is: (questions: TQuestionnaireQuestionPayload[]) => questions.length === 0,
      then: schema => schema.min(1, "At least one question must be filled")
    })
});
