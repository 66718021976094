import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ErrorResponse } from "App";
import { QUERY_KEYS } from "constants/queryKeys";
import { errorModal } from "modules/error/ErrorHandlingModals";

import { putUserStatusUpdateService } from "../api";

export const useChangeUserStatusMutation = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: putUserStatusUpdateService,
    mutationKey: [QUERY_KEYS.change_user_status],
    onSuccess: () => {
      queryClient.refetchQueries({ queryKey: [QUERY_KEYS.user_list] });
    },
    onError: (error: ErrorResponse) => errorModal(error)
  });
  return mutation;
};
