import axiosInstance from "services/AxiosInstance";

import { UserListingInterface } from "../components/userTable/userTable.types";

interface IGetUserDetailsResponse {
  data: UserListingInterface;
  meta: {
    type: string;
  };
}

export const getUserDetailsService = async (id: string): Promise<IGetUserDetailsResponse> => {
  const response = await axiosInstance.get(`/users/${id}`);
  return response.data;
};
