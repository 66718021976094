import { Anchor, Container, createStyles, Group, useMantineTheme } from "@mantine/core";
import { KloopifyFooterLogo } from "ui/icons";

import {
  FooterInner,
  FooterLinks,
  FooterTag,
  StyleFooterContactUsEmail,
  StyleFooterLinks
} from "./Footer.styles";

// @todo get rid of createStyles approach
const useStyles = createStyles(theme => ({
  logo: {
    maxWidth: 200,

    [theme.fn.smallerThan(1100)]: {
      maxWidth: "100%"
    },

    [theme.fn.smallerThan("sm")]: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center"
    }
  },

  description: {
    marginTop: 5,

    [theme.fn.smallerThan("sm")]: {
      marginTop: theme.spacing.xs,
      textAlign: "center"
    }
  },

  inner: {
    display: "flex",
    justifyContent: "space-between",
    maxWidth: "1366px",
    width: "100%",
    paddingLeft: "0px",
    paddingRight: "0px",
    gap: 16,
    [theme.fn.smallerThan(1366)]: {
      width: "calc(100% - 20px)"
    },
    [theme.fn.smallerThan(1100)]: {
      flexDirection: "column",
      alignItems: "center"
    }
  },

  groups: {
    display: "flex",
    flexWrap: "wrap",

    [theme.fn.smallerThan("sm")]: {
      display: "none"
    }
  },

  links: {
    [theme.fn.smallerThan("sm")]: {
      display: "grid",
      marginTop: theme.spacing.lg,
      marginBottom: theme.spacing.sm
    }
  },
  contact: {
    display: "flex",
    justifyContent: "flex-end",
    maxWidth: "1366px",
    width: "100%",
    paddingLeft: "0px",
    paddingRight: "0px",
    [theme.fn.smallerThan(1366)]: {
      width: "calc(100% - 20px)"
    },

    [theme.fn.smallerThan(1100)]: {
      flexDirection: "column",
      alignItems: "center"
    }
  },

  wrapper: {
    width: 160
  },

  linkButtonSpacing: {
    marginLeft: "16px",
    [theme.fn.smallerThan("sm")]: {
      marginLeft: "0px"
    }
  },

  link: {
    display: "block",
    color: theme?.other?.monochromeColor[6],
    fontStyle: "normal",
    fontSize: theme.other.font.fontStyle,
    lineHeight: theme.other.font.fontStyle,
    paddingTop: 3,
    paddingBottom: 3,
    fontFamily: "Inter",
    marginLeft: "23px",

    [theme.fn.smallerThan("sm")]: {
      marginLeft: "0px"
    },

    "&:hover": {
      textDecoration: "underline"
    }
  },

  title: {
    fontSize: theme.fontSizes.lg,
    fontWeight: 700,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    marginBottom: theme.spacing.xs / 2,
    color: theme.colorScheme === "dark" ? theme.white : theme.black
  },

  afterFooter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: "9px",
    paddingBottom: "9px",
    paddingLeft: "0px",
    paddingRight: "0px",
    backgroundColor: theme?.other?.tertiaryColor,
    width: "100%",
    marginLeft: "0px",
    marginRight: "0px",
    maxWidth: "100%",

    [theme.fn.smallerThan("sm")]: {
      flexDirection: "column"
    }
  },

  social: {
    [theme.fn.smallerThan("sm")]: {
      marginTop: theme.spacing.xs
    }
  }
}));

type FooterProps = {
  links: { label: string; link: string }[];
  preview?: boolean;
};

export const Footer = ({ links, preview }: FooterProps) => {
  const { classes } = useStyles();
  const theme = useMantineTheme();

  const items = links.map(link => (
    <Anchor<"a">
      className={classes.link}
      key={link.label}
      href={link.link}
      sx={{
        fontFamily: theme.other.font.fontFamily,
        fontStyle: theme.other.font.fontStyle,
        fontWeight: 400,
        fontSize: theme.other.font.fontSize,
        lineHeight: theme.other.font.lineHeight,
        color: theme?.other?.monochromeColor[6]
      }}
      onClick={event => event.preventDefault()}
      size="sm"
    >
      {link.label}
    </Anchor>
  ));

  return (
    <>
      <FooterTag preview={preview}>
        <FooterInner>
          <Container className={classes.inner}>
            <div className={classes.logo}>
              <KloopifyFooterLogo />
            </div>
            <Group className={classes.links}>{items}</Group>
          </Container>
          <Container className={classes.contact}>
            <StyleFooterContactUsEmail>contact@kloopify.com</StyleFooterContactUsEmail>
          </Container>
        </FooterInner>
      </FooterTag>
      <Container className={classes.afterFooter}>
        <StyleFooterLinks>
          <FooterLinks href="https://www.kloopify.com/footer/privacy-policy">Privacy Policy</FooterLinks> |{" "}
          <FooterLinks href="https://www.kloopify.com/footer/terms">Terms of Use</FooterLinks> |{" "}
          <FooterLinks href="https://www.kloopify.com/footer/copyright-policy ">Copyrights</FooterLinks>
        </StyleFooterLinks>
      </Container>
    </>
  );
};
