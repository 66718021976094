import styled from "@emotion/styled";
import { Text } from "@mantine/core";
import { Button } from "@mantine/core";
import { closeAllModals, openModal } from "@mantine/modals";
import { OpenConfirmModal } from "@mantine/modals/lib/context";
import { dangerColor, monochromeColor, primaryColor } from "constants/colorPalette";
import { fontFamily, fontStyle } from "constants/typography";

const StyleModal = {
  height: "fit-content",
  width: "100%",
  background: monochromeColor[6],
  boxShadow: "0px 6px 20px rgba(0, 0, 0, 0.1)",
  borderRadius: "4px"
};
const StyleTitle = {
  fontFamily: fontFamily,
  fontStyle: fontStyle,
  fontWeight: 700
};
const StyleBody = {
  fontFamily: fontFamily,
  fontStyle: fontStyle,
  fontSize: "1rem",
  lineHeight: "1.1875rem",
  color: monochromeColor[2]
};
const StyleClose = {
  top: "14px",
  right: "16px"
};
export const errorModal = (_: unknown) => {
  return openModal({
    title: "Error",
    centered: true,
    children: "An unexpected error has occurred. If the problem persists, please notify support.",
    styles: {
      modal: {
        ...StyleModal,
        maxWidth: "calc(596px - 67px)",
        minHeight: "calc(194px - 10px)",
        padding: "5px 12px 5px 45px",
        position: "relative"
      },
      title: {
        ...StyleTitle,
        fontSize: "1.5625rem",
        lineHeight: "1.875rem",
        textAlign: "left",
        color: dangerColor,
        marginTop: "32px"
      },
      body: {
        ...StyleBody,
        fontWeight: 400,
        marginTop: "5px"
      },
      close: { ...StyleClose, position: "absolute" }
    }
  });
};

const StyleButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 29.75px;
  margin-right: 19px;
`;
export const successModal = (message: string) => {
  return openModal({
    title: `Success!`,
    centered: true,
    children: (
      <>
        {message}
        <StyleButtonWrapper>
          <Button onClick={() => closeAllModals()}>Close</Button>
        </StyleButtonWrapper>
      </>
    ),
    styles: {
      modal: {
        ...StyleModal,
        maxWidth: "calc(685.004px - 67px)",
        minHeight: "calc(310px - 10px)",
        padding: "5px 12px 36px 12px",
        position: "relative"
      },
      title: {
        ...StyleTitle,
        fontSize: "3.125rem",
        lineHeight: "3.8125rem",
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
        color: primaryColor,
        marginTop: "63.75px",
        marginRight: 0,
        width: "100%"
      },
      body: {
        ...StyleBody,
        fontWeight: 700,
        margin: "5px auto 0px auto",
        width: "100%",
        textAlign: "center"
      },
      close: { ...StyleClose, position: "absolute" }
    }
  });
};

const StyledMultiButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 2rem;
  padding: 30px 20px 0 20px;
`;

export const confirmationModal = (
  payload: OpenConfirmModal & { message: string } & { color?: string } & { buttonChildren?: JSX.Element }
) => {
  const { color = "klp", onCancel = () => {}, onConfirm = () => {}, message, buttonChildren, ...rest } = payload;

  const buttons = buttonChildren ?? (
    <>
      <Button
        variant="subtle"
        color={color}
        onClick={() => {
          onCancel();
          closeAllModals();
        }}
      >
        Cancel
      </Button>
      <Button
        color={color}
        onClick={() => {
          onConfirm();
          closeAllModals();
        }}
      >
        Confirm
      </Button>
    </>
  );

  openModal({
    ...rest,
    centered: true,
    children: (
      <>
        <Text size="sm">{message}</Text>
        <StyledMultiButtonWrapper>{buttons}</StyledMultiButtonWrapper>
      </>
    ),
    styles: {
      modal: {
        ...StyleModal,
        maxWidth: "calc(685.004px - 67px)",
        padding: "5px 12px 36px 12px",
        position: "relative"
      },
      title: {
        ...StyleTitle,
        fontSize: "2.3rem",
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
        marginRight: 0,
        width: "100%"
      },
      body: {
        ...StyleBody,
        fontWeight: 700,
        margin: "5px auto 0px auto",
        width: "100%",
        textAlign: "center"
      },
      close: { ...StyleClose, position: "absolute" }
    }
  });
};
