import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import jwt_decode from "jwt-decode";
import { useGetUserInfo } from "modules/users";
import { JwtTokenInterface, UserInterface } from "modules/users/types/UserDto";
import { LOGIN_TYPE, USER_TYPE } from "modules/users/types/UserTypesEnum";
import { MSWindowLogo } from "ui/icons";

import { CurrentUserContext } from "../CurrentUserContext";
import { useAuthenticateUserMutation } from "../hooks";
import { StyleLoginButton, StyleLoginButtonText } from "../styles/Login.styles";
import { signIn } from "./AzureLogin/api";

export const AzureLogin = () => {
  const navigate = useNavigate();
  const { setUserDetails, setUserId: setGlobalUserid, setJWT_Token } = useContext(CurrentUserContext);
  const authenticateUserMutate = useAuthenticateUserMutation();
  const [accessToken, setAccessToken] = useState("");
  const [queryEnabled, setQueryEnabled] = useState(false);
  const [userId, setUserId] = useState("");
  const getUserInfoQuery = useGetUserInfo(userId, queryEnabled);

  useEffect(() => {
    if (accessToken) {
      localStorage.setItem("token", accessToken);
      if (accessToken) {
        authenticateUserMutate.mutate({ accessToken: accessToken, signWith: LOGIN_TYPE.azure });
      }
    }
  }, [accessToken]);

  useEffect(() => {
    if (authenticateUserMutate.isSuccess === true) {
      const jwtToken = authenticateUserMutate?.data?.data?.jwtToken || "";
      if (jwtToken) {
        localStorage.setItem("jwt_Token", jwtToken);
        setJWT_Token(jwtToken);
        const decodedJWT_token = jwt_decode<JwtTokenInterface>(jwtToken);
        setUserId(decodedJWT_token.sub);
        setGlobalUserid(decodedJWT_token.sub);
        setQueryEnabled(true);
      }
    }
  }, [authenticateUserMutate.isSuccess]);

  const handleSignIn = async () => {
    const signInAccessToken = await signIn();
    if (signInAccessToken) {
      setAccessToken(signInAccessToken);
    }
  };

  /**
   * Get User Info Call to get userInfo
   */

  useEffect(() => {
    if (userId && queryEnabled === true) {
      getUserInfoQuery.refetch();
    }
  }, [userId]);

  useEffect(() => {
    if (getUserInfoQuery.isSuccess === true) {
      const userDetailsObj: UserInterface = {
        firstName: getUserInfoQuery.data?.data?.firstName,
        lastName: getUserInfoQuery.data?.data?.lastName,
        email: getUserInfoQuery.data?.data?.email,
        userType: getUserInfoQuery.data?.data?.userType,
        userId: getUserInfoQuery.data?.data?.id
      };

      if (getUserInfoQuery.data?.data?.accounts && getUserInfoQuery.data?.data?.accounts.length > 0) {
        const account = getUserInfoQuery.data?.data?.accounts[0];
        userDetailsObj.accountId = account.accountId;
        userDetailsObj.userRole = account.userRole;
        userDetailsObj.accountName = account.accountName;
        userDetailsObj.userRole = account.role;
        localStorage.setItem("accountId", account.accountId ?? "");
      }
      setUserDetails(userDetailsObj);
      if (getUserInfoQuery.data?.data.userType === USER_TYPE.internal) {
        navigate(`/accounts`);
      } else {
        navigate("/account-selection");
      }
    }
  }, [getUserInfoQuery.isSuccess]);

  return (
    <StyleLoginButton onClick={handleSignIn}>
      <MSWindowLogo />
      <StyleLoginButtonText>Sign in with Microsoft 365</StyleLoginButtonText>
    </StyleLoginButton>
  );
};
