import { getAnalyticListService } from "modules/analytics";
import { TAccount } from "types";

import { getAccountListService } from "./getAccountListService";

type GetAccountListServiceWithSummaryArgs = (apiArgs: {
  accountIds: string[];
  limit: number;
  page: number;
}) => Promise<{ data: (TAccount & { suppliers: number; transactions: number })[]; meta: { totalCount: number } }>;

export const getAccountListWithSummaryService: GetAccountListServiceWithSummaryArgs = async apiArgs => {
  const accounts = await getAccountListService(apiArgs);

  const promises = accounts.data.map(async account => {
    const accountId = account.id;

    const summaryData = await getAnalyticListService({ accountId });

    return {
      ...account,
      suppliers: summaryData.summary.suppliers,
      transactions: summaryData.summary.transactions
    };
  });

  const data = await Promise.all(promises);

  return { data, meta: accounts.meta };
};
