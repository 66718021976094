import React from "react";

import styled from "@emotion/styled";
import { Button, LoadingOverlay } from "@mantine/core";
import Loader from "ui/feedback/Loader";
import Modal from "ui/overlays/Modal";

const StyleMessage = styled.div`
  font-family: ${props => props.theme.other.font.fontFamily};
  font-size: 2.3rem;
  font-weight: 700;
  letter-spacing: 0em;
  text-align: center;
  color: ${props => props.theme.other.dangerColor};
`;
const StyleDescription = styled.div`
  font-family: ${props => props.theme.other.font.fontFamily};
  font-style: ${props => props.theme.other.font.fontStyle};
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.6875rem;
  letter-spacing: 0em;
  text-align: center;
  color: ${props => props.theme.other.monochromeColor[2]};
  padding-top: 19px;
`;
const StyleDescriptionSecond = styled.div`
  font-family: ${props => props.theme.other.font.fontFamily};
  font-style: ${props => props.theme.other.font.fontStyle};
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.6875rem;
  letter-spacing: 0em;
  text-align: center;
  color: ${props => props.theme.other.monochromeColor[2]};
`;
const ButtonWrapper = styled.div`
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  @media only screen and (max-width: ${props => props.theme.other.breakpoints.lg}) {
    display: grid;
    justify-content: center;
    gap: 15px;
    align-items: center;
  }
`;

interface ConfirmationModalProps {
  openModal: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  cancelBtnText?: string;
  confirmBtnText?: string;
  titleText: string;
  descriptionText: string;
  descriptionTextSecond?: string;
  handleConfirm(): void;
  loading: boolean;
}

const StyleConfirmationModal = styled(Modal)`
  .mantine-Modal-modal {
    max-width: 618px;
    min-height: 300px;
    padding: 55px 13px 34px 26px;
    width: 100%;
    height: auto;
    @media only screen and (max-width: ${props => props.theme.other.breakpoints.lg}) {
      padding: 10px;
    }
  }
`;
const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  openModal = false,
  setOpenModal,
  cancelBtnText,
  confirmBtnText,
  titleText,
  descriptionText,
  descriptionTextSecond,
  handleConfirm,
  loading = false
}) => {
  return (
    <StyleConfirmationModal
      opened={openModal}
      setOpened={setOpenModal}
      onClose={true}
      withCloseButton={true}
      title={""}
    >
      <StyleMessage>{titleText}</StyleMessage>
      <StyleDescription>{descriptionText}</StyleDescription>
      <StyleDescriptionSecond>{descriptionTextSecond}</StyleDescriptionSecond>
      <LoadingOverlay visible={loading} overlayBlur={2} loader={<Loader />} />
      <ButtonWrapper>
        <Button
          variant="subtle"
          onClick={() => {
            setOpenModal(false);
          }}
        >
          {cancelBtnText ?? "Cancel"}
        </Button>
        <Button
          onClick={() => {
            handleConfirm();
          }}
        >
          {confirmBtnText ?? "Confirm"}
        </Button>
      </ButtonWrapper>
    </StyleConfirmationModal>
  );
};
export default ConfirmationModal;
