import { TImportError } from "types";

import {
  ImportErrorItemContainer,
  ImportErrorItemErrorRowText,
  ImportErrorItemErrorText
} from "./importErrorItem.styles";
import { renderErrorFromImport } from "./importErrorItem.utils";

export const ImportErrorItem: React.FC<{
  dataIndex: number;
  dataItem: Record<string, string>;
  error: TImportError["error"];
  rowNumber: TImportError["rowNumber"];
}> = ({ dataItem, dataIndex, error, rowNumber }) => {
  const headers = Object.keys(dataItem).map((key, idx) => <th key={`${key}-${idx}`}>{key}</th>);
  const values = Object.values(dataItem).map((value, idx) => <td key={`${value}-${idx}`}>{value}</td>);

  const errorDescription = renderErrorFromImport(error);

  return (
    <ImportErrorItemContainer>
      <ImportErrorItemErrorText>
        {dataIndex}. {errorDescription}
      </ImportErrorItemErrorText>
      <ImportErrorItemErrorRowText>Row: {rowNumber || "???"}</ImportErrorItemErrorRowText>
      <table>
        <thead>
          <tr>{headers}</tr>
        </thead>
        <tbody>
          <tr>{values}</tr>
        </tbody>
      </table>
    </ImportErrorItemContainer>
  );
};
