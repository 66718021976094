import { FC } from "react";

import * as ColorPalette from "constants/colorPalette";
import { USER_TYPE } from "modules/users/types/UserTypesEnum";
import { TUser } from "types";

import { userTableRowsItemsConfig } from "../configs/userTableRowsConfig";
import { statusMap } from "../configs/userTableStatusConfig";
import { UserTableAccounts } from "./UserTableAccounts";
import { UserTableAvatar } from "./UserTableAvatar";
import { UserTableMenu } from "./UserTableMenu";
import { StyleTD, TableTextWrapper } from "./userTable.styles";
import { Accounts, IUserTableMenuConfig, TUserTableRowType, UserListingInterface } from "./userTable.types";

type UserTableRowsProps = {
  getMenuConfigFunc: (element: UserListingInterface) => IUserTableMenuConfig[];
  handleAccountCross?: (accountDetails: Accounts) => void;
  userListingList: TUser[];
};

export const UserTableRows: FC<UserTableRowsProps> = ({ userListingList, getMenuConfigFunc, handleAccountCross }) => {
  const getBackground = (index: number, user_role: string): string => {
    const isEven = index % 2 === 0;
    const isInternal = user_role === USER_TYPE.internal;
    if (isEven && isInternal) return ColorPalette.otherColorRGBA[1];
    if (isEven && !isInternal) return ColorPalette.otherColorRGBA[0];
    return ColorPalette.monochromeColor[6];
  };

  const renderStatus = (status: string) => {
    const { Component, Icon, Label } = statusMap[status];
    return (
      <Component>
        <Icon />
        {Label}
      </Component>
    );
  };

  const renderRowItem = (type: TUserTableRowType, userListing: UserListingInterface, keyToLabel: string) => {
    const { status } = userListing;
    const text = userListing[keyToLabel as keyof UserListingInterface] as string;

    switch (type) {
      case "avatar": {
        return <UserTableAvatar element={userListing} />;
      }
      case "account": {
        return <UserTableAccounts element={userListing} handleAccountCross={handleAccountCross} />;
      }
      case "status": {
        return <TableTextWrapper>{renderStatus(status)}</TableTextWrapper>;
      }
      case "menu": {
        return <UserTableMenu userListing={userListing} getMenuConfigFunc={getMenuConfigFunc} />;
      }
      case "default": {
        return <TableTextWrapper>{text}</TableTextWrapper>;
      }
    }
  };

  const renderRow = (userListing: UserListingInterface, rowIndex: number) =>
    userTableRowsItemsConfig.map((rowItem, rowItemIndex) => {
      const { type, keyToLabel } = rowItem;
      const { userType = "" } = userListing;
      const backgroundColor = getBackground(rowIndex, userType);
      const row = renderRowItem(type, userListing, keyToLabel);
      return (
        <StyleTD style={{ backgroundColor }} key={"rowItem" + rowItemIndex + rowIndex}>
          {row}
        </StyleTD>
      );
    });

  return (
    <>
      {userListingList.map((userListing: UserListingInterface, index: number) => (
        <tr key={userListing.id}>{renderRow(userListing, index)}</tr>
      ))}
    </>
  );
};

export default UserTableRows;
