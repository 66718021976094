import styled from "@emotion/styled";
import { Switch } from "@mantine/core";

export const StyledSwitch = styled(Switch)`
  align-items: center;
  .mantine-Switch-label {
    font-family: Inter;
    font-weight: 400;
  }
  .mantine-Switch-body {
    height: 30px;
    align-items: center;
  }
  .mantine-Switch-track {
    height: 8px;
    overflow: visible;
  }
  .mantine-Switch-thumb {
    box-shadow: 0px 0px 5px 0.5px rgba(0, 0, 0, 0.25);
    height: 13px;
    width: 13px;
    left: -2px;
  }
  .mantine-Switch-trackLabel {
    min-width: 16px;
  }
  input:checked + * > .mantine-Switch-thumb {
    left: calc(100% - 12px - -2px);
    border-color: ${({ theme }) => theme.other.primaryColor};
    background-color: ${({ theme }) => theme.other.primaryColor};
  }
  input:checked + .mantine-Switch-track {
    background-color: ${({ theme }) => theme.other.secondaryColor};
    border-color: ${({ theme }) => theme.other.secondaryColor};
  }
`;
