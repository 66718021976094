import axiosInstance from "services/AxiosInstance";
import { ResponseType, TCommodity, TCommodityMergePayload } from "types";

type PostQuestionnaireRequestApiArgs = {
  accountId: string;
  payload: TCommodityMergePayload;
};

export const postCommodityMergeService = async (
  apiArgs: PostQuestionnaireRequestApiArgs
): Promise<ResponseType<TCommodity>> => {
  const { accountId, payload } = apiArgs;
  const response = await axiosInstance.post(`items/account/${accountId}/merge`, payload);
  return response.data;
};
