import { useContext, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";

import { isArray, omit } from "lodash";
import { getClassificationService } from "modules/classification";
import { getSupplierName } from "modules/transactions";
import { TCommodityFilter } from "types";
import FilterTile from "ui/tiles/FilterTile/FilterTile";
import { parseQueryParams } from "utils";

import { annotationReplacementList, initialCommodityFilters } from "../commodity.data";
import { CommodityContext } from "../context/CommodityContext";
import { CommodityFilterTileWrapper } from "../styles/commodityFilterTile.styles";

type TNormalizedEntries = [keyof TCommodityFilter, string | string[]][];

export const CommodityFilterTile = () => {
  const { search } = useLocation();

  const { filters, updateFilters } = useContext(CommodityContext);

  const [codesNamesList, setCodesNamesList] = useState<Record<string, string>>({});

  const [supplierNamesList, setSupplierNamesList] = useState<string[]>([]);

  const normalizedParams = useMemo((): Partial<TCommodityFilter> => omit(parseQueryParams(search), "page"), [search]);

  const normalizedEntries = useMemo(() => Object.entries(normalizedParams) as TNormalizedEntries, [normalizedParams]);

  const normalizeKey = (key: string) =>
    annotationReplacementList.find(annotation => annotation.key === key)?.label || "unknown option: ";

  const normalizeValue = (entryValue: string | string[]) => (isArray(entryValue) ? entryValue : [entryValue]);

  const fetchSupplierNamesList = async () => {
    const supplierIds = normalizedParams.supplierIds;
    if (supplierIds) {
      const requestList = supplierIds.map(supplierId => getSupplierName(supplierId));
      const responseList = await Promise.all(requestList);
      const namesList = responseList.map(response => response.data.name);
      setSupplierNamesList(namesList);
      return;
    }
    setSupplierNamesList([]);
  };

  const fetchCodesNames = async () => {
    const codes = [
      ...(normalizedParams.naicsCodes || []),
      ...(normalizedParams.naicsIndustries || []),
      ...(normalizedParams.naicsSectors || [])
    ];

    if (codes.length) {
      const requestList = codes.map(code => getClassificationService({ params: { code } }));
      const responseList = await Promise.all(requestList);

      const codesList = responseList
        .map(response => response.data[0])
        .filter(classification => classification.code)
        .reduce((prev, curr) => ({ ...prev, [curr.code as string]: curr.title }), {});
      setCodesNamesList(codesList);
      return;
    }
    setCodesNamesList({});
  };

  const onClick = (key: keyof TCommodityFilter, value: string) => {
    const patch = isArray(initialCommodityFilters[key])
      ? (normalizedParams[key] as string[]).filter(i => i !== value)
      : initialCommodityFilters[key];

    updateFilters({ ...filters, [key]: patch, page: "0" }, true);
  };

  useEffect(() => {
    fetchCodesNames();
    fetchSupplierNamesList();
  }, [search]);

  const renderTiles = () => {
    return normalizedEntries.map((entry, index) => {
      const [key, value] = entry;
      const [annotation, optionList] = [normalizeKey(key), normalizeValue(value)];

      const isNaics = isArray(value) && key.includes("naics");

      const codeValueList = isNaics ? value.map(value => codesNamesList[value]) : [];

      const normalizedValueList = [
        ...(key === "supplierIds" ? supplierNamesList : []),
        ...(key.includes("naics") ? codeValueList : []),
        ...(!key.includes("naics") && key !== "supplierIds" ? optionList : [])
      ];

      return normalizedValueList.map((normalizedValue, normalizedIndex) => (
        <FilterTile
          key={"CommodityFilterTile" + index + normalizedIndex}
          text={annotation + normalizedValue}
          onClick={() => onClick(key, isArray(value) ? value[normalizedIndex] : value)}
        />
      ));
    });
  };

  return <CommodityFilterTileWrapper>{renderTiles()}</CommodityFilterTileWrapper>;
};
