import * as Yup from "yup";

import { IAddSupplierMutationResultModal, ISupplierFilters, ISupplierModals } from "./suppliers.types";

export const initialSupplierModals: ISupplierModals = {
  filters: false,
  addSupplier: false,
  updateSupplier: false
};

export const addNewSupplierSchema = Yup.object().shape({
  name: Yup.string().required("Account name is required"),
  accountId: Yup.string().required("Account is required")
});

export const updateSupplierSchema = Yup.object().shape({
  name: Yup.string().required("Account name is required"),
  naicsCode: Yup.array().of(Yup.string().required("Must be a valid NAICS code"))
});

export const addNewSupplierInitialValues = {
  name: "",
  description: "",
  city: "",
  region: "",
  country: "",
  postalCode: "",
  naicsCode: [] as string[],
  accountId: ""
};

export const initialMutationResultModal: IAddSupplierMutationResultModal = {
  success: false,
  error: false,
  errorMessage: undefined
};

export const initialSuppliersFilters: ISupplierFilters = {
  page: 0,
  name: "",
  createdDateMin: null,
  createdDateMax: null,
  city: "",
  region: "",
  country: "",
  minItems: null,
  maxItems: null,
  minTransactions: null,
  maxTransactions: null
};

export const annotationList: { key: keyof ISupplierFilters; label: string }[] = [
  {
    key: "name",
    label: "Supplier Name: "
  },
  {
    key: "city",
    label: "Supplier City: "
  },
  {
    key: "region",
    label: "Supplier State: "
  },
  {
    key: "country",
    label: "Supplier Country: "
  },
  {
    key: "createdDateMax",
    label: "Created Date Max: "
  },
  {
    key: "createdDateMin",
    label: "Created Date Min: "
  }
];
