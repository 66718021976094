import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Button, Select } from "@mantine/core";
import { pickBy } from "lodash";
import {
  dateFilterConfig,
  dateFilterMap,
  defaultDateFilter,
  groupFilterConfig
} from "modules/analytics/analytics.data";
import { useGetCustomFields } from "modules/customFields";
import { TAnalyticsFilters, TAnalyticsGroup, TOverviewDashboardModal } from "types";
import { SettingIcon } from "ui/icons";
import BuyspaceSelect from "ui/inputs/BuyspaceSelect/BuyspaceSelect";
import { stringifyQueryParams } from "utils";

import { StyleSelectWrapperOverview } from "../style/StyleComponents";

type BreakDownDashboardFiltersProps = {
  breakdownFilters: TAnalyticsFilters;
  initialBreakdownFiters: Partial<TAnalyticsFilters>;
  setBreakDownFilters: React.Dispatch<React.SetStateAction<TAnalyticsFilters>>;
  setModal: React.Dispatch<React.SetStateAction<TOverviewDashboardModal>>;
  setPage: ((page: number) => void) | React.Dispatch<React.SetStateAction<number>>;
};

export const BreakDownDashboardFilters = ({
  breakdownFilters,
  setBreakDownFilters,
  setPage,
  setModal,
  initialBreakdownFiters
}: BreakDownDashboardFiltersProps) => {
  const navigate = useNavigate();

  const { id: accountId } = useParams() as { id: string };

  const { data: customFieldListData } = useGetCustomFields({ accountId });

  const handleAdditionalFilter = () => setModal(prev => ({ ...prev, additionalFilter: true }));

  const updateGroupByFilter = (group: TAnalyticsGroup) => {
    setBreakDownFilters(prevState => ({ ...prevState, group }));
    setPage(0);
  };

  const updateDateFilter = (timeWindow: string) => {
    if (timeWindow === "custom") {
      setModal(prevModal => ({ ...prevModal, customInterval: true }));
      setBreakDownFilters(prevState => ({ ...prevState, timeWindow }));
      return;
    }
    const selected = dateFilterMap.get(timeWindow) || defaultDateFilter;
    const { start, end } = selected;
    setBreakDownFilters(prevState => ({ ...prevState, start, end, timeWindow }));
  };

  const updateBuyspaceSelect = (buyspaceId: string | null) => {
    setBreakDownFilters(prevState => ({ ...prevState, buyspaceId }));
  };

  const bookmarkParams = () => {
    const copy = structuredClone(breakdownFilters);
    const bookmark = pickBy(copy, (v, k) => {
      const key = k as keyof TAnalyticsFilters;
      return v !== initialBreakdownFiters[key] && key !== "accountId";
    });
    navigate("?" + stringifyQueryParams(bookmark));
  };

  useEffect(() => {
    bookmarkParams();
  }, [breakdownFilters]);

  const customFieldOptions =
    customFieldListData?.data?.map(customField => ({
      label: customField.fieldLabel || customField.field,
      value: customField.id
    })) || [];

  const extendedOptions = [...groupFilterConfig, ...customFieldOptions];

  return (
    <StyleSelectWrapperOverview>
      <Select
        placeholder="Select Type"
        readOnly={false}
        data={extendedOptions}
        value={breakdownFilters.group}
        onChange={updateGroupByFilter}
        size="lg"
      />

      <Select
        readOnly={false}
        data={dateFilterConfig}
        value={breakdownFilters.timeWindow}
        onChange={updateDateFilter}
        size="lg"
      />
      <BuyspaceSelect accountId={accountId} buyspaceId={breakdownFilters.buyspaceId} onChange={updateBuyspaceSelect} />
      <Button size="lg" variant="outline" onClick={handleAdditionalFilter} leftIcon={<SettingIcon />}>
        Additional Filters
      </Button>
    </StyleSelectWrapperOverview>
  );
};
