import { useTheme } from "@emotion/react";
import { HoverCard, Progress } from "@mantine/core";
import { TMethodologyObject } from "types";
import { formatPercentagePoints } from "utils/common.utils";

import { MethodologyText, MethodologyTextGroup } from "./MethodologyCell.styles";

export type MethodologyCellProps = {
  methodology: TMethodologyObject;
  width: number | string;
};

export const MethodologyCell = ({ methodology, width }: MethodologyCellProps) => {
  const { sb = 0, cb = 0, ss = 0 } = methodology;

  const sbValue = formatPercentagePoints(sb);
  const cbValue = formatPercentagePoints(cb);
  const ssValue = formatPercentagePoints(ss);

  const {
    other: { primaryColor, monochromeColor, warningColor }
  } = useTheme();

  return (
    <HoverCard width={258} shadow="md" position="bottom-start" withinPortal offset={2}>
      <HoverCard.Target>
        <Progress
          radius={0}
          h={12}
          w={width}
          sections={[
            { value: sbValue, color: warningColor },
            { value: cbValue, color: monochromeColor[2] },
            { value: ssValue, color: primaryColor }
          ]}
        />
      </HoverCard.Target>
      <HoverCard.Dropdown style={{ padding: 10, border: 0, marginLeft: 24 }} onClick={e => e.stopPropagation()}>
        <MethodologyTextGroup>
          <MethodologyText color={warningColor}>{sbValue}% Spend-Based</MethodologyText>
          <MethodologyText color={monochromeColor[2]}>{cbValue}% Commodity-Based</MethodologyText>
          <MethodologyText color={primaryColor}>{ssValue}% Supplier-Specific</MethodologyText>
        </MethodologyTextGroup>
      </HoverCard.Dropdown>
    </HoverCard>
  );
};
