import { MenuProps } from "@mantine/core";
import * as ColorPalette from "constants/colorPalette";

export const menuProperties: MenuProps = {
  styles: {
    itemLabel: {
      fontFamily: `Inter !important`,
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "1rem",
      lineHeight: "1.3rem",
      color: `${ColorPalette.monochromeColor[1]} !important`
    },
    dropdown: {
      background: ColorPalette.monochromeColor[6],
      boxShadow: "0px 6px 20px rgba(0, 0, 0, 0.1)",
      borderRadius: "4px",
      minWidth: "192px"
    },
    divider: {
      borderColor: ColorPalette.monochromeColor[4]
    }
  },
  transition: "scale",
  position: "left-start",
  offset: 0,
  shadow: "md",
  zIndex: 2
};
