import styled from "@emotion/styled";
import { monochromeColor } from "constants/colorPalette";
import Modal from "ui/overlays/Modal";

export const OverviewDashboardContainer = styled.div`
  position: relative;
`;

export const LabelWrapper = styled.div`
  width: 100%;
  margin-bottom: 40px;

  @media (max-width: 768px) {
    min-height: 680px;
    overflow-x: auto;
    overflow-y: hidden;
  }
`;

export const StyledCustomIntervalModal = styled(Modal)`
  .mantine-Modal-modal {
    width: auto;
    min-height: 41px;
    padding: 2.8rem 3.75rem 2.8rem 3.75rem;
    @media only screen and (max-width: ${props => props.theme.other.breakpoints.lg}) {
      padding: 3rem 4rem;
    }
  }
`;

export const StyledCustomIntervalHeader = styled.div`
  font-family: ${props => props.theme.other.font.fontFamily};
  font-style: ${props => props.theme.other.font.fontStyle};
  font-weight: 700;
  font-size: 1.8rem;
  line-height: 3.8125rem;
  text-align: left;
  color: ${props => props.theme.other.primaryColor};
  margin-bottom: 1.5rem;
`;

export const StyledCustomIntervalContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 3rem;

  @media screen and (max-width: ${props => props.theme.other.breakpoints.lg}) {
    gap: 2rem;
  }
`;

export const StyledCustomIntervalRow = styled.div`
  width: 100%;
  gap: 4rem;
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: ${props => props.theme.other.breakpoints.lg}) {
    flex-direction: column;
    gap: 2rem;
  }
`;

export const StyledCustomIntervalRowTitle = styled.div<{ inline?: boolean }>`
  padding-bottom: ${props => (props.inline ? "" : "0.5rem")};
  font-size: ${props => props.theme.other.font.modalGroupHeader.fontSize};
  line-height: ${props => props.theme.other.font.modalGroupHeader.lineHeight};
  font-weight: ${props => props.theme.other.font.modalGroupHeader.fontWeight};
  color: ${monochromeColor[2]};
`;

export const StyledCustomIntevalInputWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  gap: 1rem;
`;

export const StyledCustomIntevalFooter = styled.div`
  max-width: 627px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  border-top: 1px solid #c4c4c4;
  padding-top: 36px;
  margin-top: 2.5rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;
