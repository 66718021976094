import { ReactNode, useRef, useState } from "react";

import { Tooltip as MantineTooltip } from "@mantine/core";
import { InfoIcon } from "ui/icons";

import { TextWithTooltip, TooltipContent, TooltipIndicator } from "./tooltip.styles";

type TooltipProps = {
  children: ReactNode;
  header: (() => ReactNode) | string;
};

const Tooltip = ({ header, children }: TooltipProps) => {
  const timeoutRef = useRef<NodeJS.Timeout>();

  const [show, setShow] = useState(false);

  const showToopltip = () => {
    clearTimeout(timeoutRef.current);
    setShow(true);
  };

  const hideTooltip = () => {
    timeoutRef.current = setTimeout(() => setShow(false), 100);
  };

  const title = typeof header === "string" ? <span>{header}</span> : header();

  return (
    <>
      <TooltipContent onMouseLeave={hideTooltip}>
        <TextWithTooltip>
          {title}
          <MantineTooltip
            onMouseOver={showToopltip}
            withinPortal
            label={children}
            multiline
            styles={{
              tooltip: {
                background: "#fff",
                padding: 36,
                boxShadow: "0 0 20px 0 rgba(0,0,0,0.2)",
                pointerEvents: "all"
              }
            }}
            opened={show}
          >
            <TooltipIndicator onMouseOver={showToopltip}>
              <InfoIcon />
            </TooltipIndicator>
          </MantineTooltip>
        </TextWithTooltip>
      </TooltipContent>
    </>
  );
};

export default Tooltip;
