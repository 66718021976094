import { merge } from "lodash";
import { TFeatureFlag } from "types";

export const convertToPaginated = <T>(data: T[], limit = 10) => {
  const pages = Math.ceil(data.length / limit);
  return Array(pages)
    .fill("")
    .map((i, index) => {
      const start = limit * index;
      const end = index * limit + limit;
      return data.slice(start, end);
    });
};

export const flattenObject = (obj: Record<string, unknown>) => {
  const keys = Object.keys(obj);
  const arrayFromObject = keys.reduce((prev: string[], acc) => {
    const value = obj?.[acc];
    if (Array.isArray(value)) return [...prev, ...value];
    return [...prev, value];
  }, []);
  return arrayFromObject;
};

export const mergeFeaturesFlags = (baseFeaures: TFeatureFlag[], extensionFeatures: TFeatureFlag[]) =>
  baseFeaures.map(base => {
    const extension = extensionFeatures.find(e => e.name === base.name);
    if (extension) return merge({ ...base }, { ...extension });

    return base;
  });
