import axiosInstance from "services/AxiosInstance";
import { ResponseType, TAccount } from "types";

type GetAccountListServiceArgs = (apiArgs: {
  accountIds: string[];
  limit: number;
  page: number;
}) => Promise<ResponseType<TAccount[], { totalCount: number }>>;

export const getAccountListService: GetAccountListServiceArgs = async apiArgs => {
  const response = await axiosInstance.get(`/accounts`, { params: apiArgs });
  return response.data;
};
