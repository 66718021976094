import { useCallback, useState } from "react";

import { Loader, MultiSelectProps, SelectItem } from "@mantine/core";

import { ChevronDown, SearchIcon } from "../../icons";
import { MultiSelectContext } from "./MultiSelectContext";
import { MultiSelectDropdown } from "./MultiSelectDropdown";
import { MultiSelectItem } from "./MultiSelectItem";
import { MultiSelectValue } from "./MultiSelectValue";
import { NoDataFound, StyledMultiSelect } from "./styles";

type MultiSelectAdditionalProps<T> = {
  dataSource: T[];
  loading?: boolean;
  simpleSelect?: boolean;
  transformData: (dataItem: T) => SelectItem;
};

export const MultiSelect = <T,>(props: Omit<MultiSelectProps, "data"> & MultiSelectAdditionalProps<T>) => {
  const { dataSource, transformData, loading, defaultValue, simpleSelect, ...selectProps } = props;

  const [search, setSearch] = useState("");

  const onSearchChange = (search: string) => {
    props?.onSearchChange?.(search);
    setSearch(search);
  };

  const onChange = (values: SelectItem["value"][]) => {
    if (simpleSelect && values.length) {
      const lastValue = values.pop();
      lastValue && props?.onChange?.([lastValue]);
      return;
    }
    props?.onChange?.(values ?? []);
  };

  const onClear = useCallback(() => {
    props?.onChange?.([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.onChange]);

  const data = dataSource.map(transformData);

  const stringValue = typeof props.value === "string" || typeof props.value === "number" ? [props.value] : [];

  const arrayValue = Array.isArray(props.value) && props.value.length ? props.value : [];

  const value = arrayValue || stringValue || undefined;

  const nothingFound = <NoDataFound>No Data Found</NoDataFound>;

  const icon = loading ? <Loader size="xs" color="klp" /> : <ChevronDown />;

  return (
    <MultiSelectContext.Provider value={{ selectedCount: value?.length ?? 0, search, onClear, simpleSelect }}>
      <StyledMultiSelect
        icon={<SearchIcon />}
        searchable
        clearSearchOnBlur
        clearable={false}
        value={value}
        {...selectProps}
        simpleSelect={simpleSelect}
        nothingFound={nothingFound}
        data={data}
        clearSearchOnChange={false}
        filter={(value, selected, item) => {
          if (search) {
            return item.label?.toLowerCase()?.includes(search.toLowerCase()) ?? false;
          }
          return true;
        }}
        searchValue={search}
        onSearchChange={onSearchChange}
        onChange={onChange}
        rightSection={icon}
        valueComponent={MultiSelectValue}
        disableSelectedItemFiltering
        itemComponent={MultiSelectItem}
        styles={() => ({
          item: {
            "&[data-selected]": {
              "&, &:hover": {
                backgroundColor: "transparent"
              },
              "&[data-hovered]": {
                backgroundColor: "#f1f3f5"
              }
            }
          }
        })}
        dropdownComponent={MultiSelectDropdown}
      />
    </MultiSelectContext.Provider>
  );
};
