import axiosInstance from "services/AxiosInstance";
import { ResponseType, TSupplierPayload, TUpdateSupplierResponseData } from "types";

type PutSupplierServiceArgs = (apiArgs: {
  accountId: string;
  payload: TSupplierPayload;
}) => Promise<ResponseType<TUpdateSupplierResponseData>>;

export const putSupplierService: PutSupplierServiceArgs = async apiArgs => {
  const { accountId, payload } = apiArgs;
  const response = await axiosInstance.put(`/suppliers/${accountId}`, { ...payload });
  return response.data;
};
