import styled from "@emotion/styled";

export const StyleAccountTaps = styled.div`
  background: ${props => props.theme.other.monochromeColor[6]};
  border: 1px solid ${props => props.theme.other.monochromeColor[2]};
  border-radius: 50px;
  padding: 4px 11px;
  width: fit-content;
  display: inline-flex;
  align-items: center;
  gap: 10px;
  margin: 3.5px 5px 3.5px 6px;
  min-width: max-content;
`;

export const TextWrapper = styled.div`
  display: flex;
  gap: 5px;
`;

export const AccountNameStyle = styled.div<{ isIntenal: boolean }>`
  font-family: ${props => props.theme.other.font.fontFamily};
  font-style: ${props => props.theme.other.font.fontStyle};
  font-size: ${props => props.theme.other.font.fontSize} !important;
  line-height: ${props => props.theme.other.font.lineHeight};
  color: ${props => (props.isIntenal ? props.theme.other.primaryColor : props.theme.other.monochromeColor[1])};
  font-weight: 500;
`;

export const RoleStyle = styled.div`
  font-family: ${props => props.theme.other.font.fontFamily};
  font-style: ${props => props.theme.other.font.fontStyle};
  font-weight: 400;
  font-size: ${props => props.theme.other.font.fontSize} !important;
  line-height: ${props => props.theme.other.font.lineHeight};
  color: ${props => props.theme.other.monochromeColor[1]};
`;
