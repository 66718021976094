import { QuestionnaireValidateReady, TQuestionnaireChoice } from "types";
import { MultiSelect } from "ui";

type QuestionnaireMultiSelectProps = {
  data: QuestionnaireValidateReady;
  group: string;
  onChange: (response: string | string[], id: string, group: string, loading?: boolean, error?: string) => void;
  submitted?: boolean;
};

const QuestionnaireMultiSelect = ({ data, group, onChange, submitted }: QuestionnaireMultiSelectProps) => {
  return (
    <MultiSelect
      dataSource={data.choices as TQuestionnaireChoice[]}
      error={data.error}
      transformData={data => ({ value: data.choice, label: data.choice })}
      value={data.response as string[]}
      w={300}
      maw="50%"
      onChange={value => !submitted && onChange(value, data.id, group)}
      required={data.required}
      label={data.question}
      placeholder="Select Answer"
    />
  );
};

export default QuestionnaireMultiSelect;
