import React from "react";

import styled from "@emotion/styled";
import { Button } from "@mantine/core";
import Modal from "ui/overlays/Modal";
import { ModalFooter, ModalTitle } from "ui/styles";

const StyleDescription = styled.div`
  font-family: ${props => props.theme.other.font.fontFamily};
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5625rem;
  text-align: left;
  padding-top: 21px;
  color: ${props => props.theme.other.monochromeColor[2]};
`;

type ApproveAnalysisConfirmModalProps = {
  approveImportHandler: () => void;
  handleClose: () => void;
  openModal: boolean;
};

export const ApproveAnalysisConfirmModal: React.FC<ApproveAnalysisConfirmModalProps> = ({
  openModal,
  handleClose,
  approveImportHandler
}) => {
  return (
    <>
      <Modal opened={openModal} handleClose={handleClose} withCloseButton title="" mode="message">
        <ModalTitle>Approve Analysis</ModalTitle>
        <StyleDescription>Please confirm that the Analysis File is approved.</StyleDescription>
        <StyleDescription>
          This will change the status of this import to “Completed” and the file will be available for download by both
          internal and external users.
        </StyleDescription>
        <ModalFooter>
          <Button variant="subtle" onClick={() => handleClose()}>
            Cancel Approval
          </Button>
          <Button onClick={() => approveImportHandler()}>Submit</Button>
        </ModalFooter>
      </Modal>
    </>
  );
};
