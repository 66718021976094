import React from "react";

export const LongAnswerIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M10.1667 6.83329H6.83333C6.375 6.83329 6 6.45829 6 5.99996C6 5.54163 6.375 5.16663 6.83333 5.16663H10.1667C10.625 5.16663 11 5.54163 11 5.99996C11 6.45829 10.625 6.83329 10.1667 6.83329Z"
      fill="currentColor"
      stroke="white"
      strokeWidth="0.4"
    />
    <path
      d="M10.1667 9.33329H6.83333C6.375 9.33329 6 8.95829 6 8.49996C6 8.04163 6.375 7.66663 6.83333 7.66663H10.1667C10.625 7.66663 11 8.04163 11 8.49996C11 8.95829 10.625 9.33329 10.1667 9.33329Z"
      fill="currentColor"
      stroke="white"
      strokeWidth="0.4"
    />
    <path
      d="M14.75 2.25L13.5 1L12.25 2.25L11 1L9.75 2.25L8.5 1L7.25 2.25L6 1L4.75 2.25L3.5 1V12.6667H1.83333C1.375 12.6667 1 13.0417 1 13.5V15.1667C1 16.55 2.11667 17.6667 3.5 17.6667H13.5C14.8833 17.6667 16 16.55 16 15.1667V1L14.75 2.25ZM11 16H3.5C3.04167 16 2.66667 15.625 2.66667 15.1667V14.3333H5.16667H8.5H11V16ZM14.3333 15.1667C14.3333 15.625 13.9583 16 13.5 16C13.0417 16 12.6667 15.625 12.6667 15.1667V13.5C12.6667 13.0417 12.2917 12.6667 11.8333 12.6667H10.1667H8.5H5.16667V3.5H14.3333V15.1667Z"
      fill="currentColor"
      stroke="white"
      strokeWidth="0.4"
    />
    <path
      d="M12.6666 6.83329C13.1268 6.83329 13.4999 6.4602 13.4999 5.99996C13.4999 5.53972 13.1268 5.16663 12.6666 5.16663C12.2063 5.16663 11.8333 5.53972 11.8333 5.99996C11.8333 6.4602 12.2063 6.83329 12.6666 6.83329Z"
      fill="currentColor"
      stroke="white"
      strokeWidth="0.4"
    />
    <path
      d="M12.6666 9.33329C13.1268 9.33329 13.4999 8.9602 13.4999 8.49996C13.4999 8.03972 13.1268 7.66663 12.6666 7.66663C12.2063 7.66663 11.8333 8.03972 11.8333 8.49996C11.8333 8.9602 12.2063 9.33329 12.6666 9.33329Z"
      fill="currentColor"
      stroke="white"
      strokeWidth="0.4"
    />
  </svg>
);
