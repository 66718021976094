import { Navigate, Outlet, useLocation, useParams } from "react-router-dom";

import { Wrapper } from "modules/users/components/styles";

export const Analytics = () => {
  const { id } = useParams();
  const { pathname } = useLocation();

  if (pathname === `/analysis/${id}`) {
    return <Navigate to={`/analysis/${id}/overview`} replace />;
  }

  return (
    <Wrapper>
      <Outlet />
    </Wrapper>
  );
};
