import { TCommodityFilter } from "types";

export const initialCommodityFilters = {
  name: "",
  description: "",
  search: "",
  page: "0",
  naicsCodes: [],
  naicsSectors: [],
  naicsIndustries: [],
  supplierIds: []
};

export const initialCommodityModal = {
  filters: false,
  details: false
};

export const annotationReplacementList: { key: keyof TCommodityFilter; label: string }[] = [
  {
    key: "description",
    label: "Description: "
  },
  {
    key: "name",
    label: "Name: "
  },
  {
    key: "naicsSectors",
    label: "NAICS Sectors: "
  },
  {
    key: "naicsCodes",
    label: "NAICS Codes: "
  },
  {
    key: "naicsIndustries",
    label: "NAICS Industries: "
  },
  {
    key: "supplierIds",
    label: "Supplier: "
  }
];
