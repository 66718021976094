import { ComponentPropsWithoutRef } from "react";

import { Select as MT_Select, SelectProps } from "@mantine/core";
import { ChevronDown, CrossIcon } from "ui/icons";

export interface ItemProps extends ComponentPropsWithoutRef<"div"> {
  image: string;
  label: string;
  name: string;
  description: string;
}

export const ClearableSelect = (props: SelectProps) => {
  const { clearable, value } = props;

  const onChange = props.onChange ? props.onChange : () => {};

  const rightSection = clearable && value ? <CrossIcon onClick={() => onChange(null)} /> : <ChevronDown />;

  return <MT_Select {...props} rightSection={rightSection} />;
};
