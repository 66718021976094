import { useState } from "react";

import { uniqBy } from "lodash";

import { BuyspaceContext, BuyspaceContextOptions } from "./BuyspaceContext";

const initialBuyspaceData: BuyspaceContextOptions["data"] = {
  suppliers: [],
  items: [],
  costCenters: [],
  naicsCodes: [],
  naicsIndustries: [],
  naicsSectors: []
};

export const BuyspaceProvider = ({ children }: { children: React.ReactNode }) => {
  const [data, setData] = useState<BuyspaceContextOptions["data"]>(initialBuyspaceData);

  const handleSetData = <T extends keyof typeof data>(key: T, data: BuyspaceContextOptions["data"][T]) => {
    setData(prev => {
      const currentData = prev[key];
      const newData = uniqBy([...currentData, ...data], d => d.value);

      return { ...prev, [key]: newData };
    });
  };

  const resetBuyspaceData = () => setData(initialBuyspaceData);

  return (
    <BuyspaceContext.Provider
      value={{
        data,
        handleSetData,
        resetBuyspaceData
      }}
    >
      {children}
    </BuyspaceContext.Provider>
  );
};
