import { IndustryClassification } from "types";
import * as Yup from "yup";

export const buyspaceSchema = Yup.object().shape({
  name: Yup.string().required("The name of the buyspace is required"),
  conditions: Yup.array()
    .of(
      Yup.object().shape({
        key: Yup.array().of(Yup.string()).min(1, "Buyspace filter option should not be empty").required(),
        value: Yup.array().of(Yup.string()).min(1, "Filter should not be empty").required()
      })
    )
    .min(1, "There should be at least one condition")
});

export const transformNaicsToOptions = (naicsCode: IndustryClassification[]) =>
  naicsCode.map(({ code, title }) => ({ label: title, value: code }));

export const transformEntitiesToOptions = (entities: Required<{ id: string; name: string }>[]) =>
  entities.map(({ name, id }) => ({ value: id, label: name }));
