import { useQuery, UseQueryOptions, UseQueryResult } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { QUERY_KEYS } from "constants/queryKeys";
import { ResponseError, ResponseType, TImportError } from "types";

import { getImportErrorListService } from "../api";

type UseGetImportErrorListQueryArgs = (
  apiArgs: { importId: string; params?: { limit?: number; page: number } },
  options: UseQueryOptions<ResponseType<TImportError[], { totalCount: number }>, AxiosError<ResponseError>>
) => UseQueryResult<ResponseType<TImportError[], { totalCount: number }>, AxiosError<ResponseError>>;

export const useGetImportErrorListQuery: UseGetImportErrorListQueryArgs = (apiArgs, options) => {
  return useQuery<ResponseType<TImportError[], { totalCount: number }>, AxiosError<ResponseError>>({
    queryFn: () => getImportErrorListService({ ...apiArgs }),
    queryKey: [QUERY_KEYS.get_import_errors, apiArgs.importId, apiArgs.params?.page],
    ...options
  });
};
