import axiosInstance from "services/AxiosInstance";

type PutUpdateAnalysisServiceArgs = {
  analysisFileLocation: string;
  id: string;
};
export const putUpdateAnalysisService = async (payLoad: PutUpdateAnalysisServiceArgs) => {
  const requestPayload = {
    analysisFileLocation: payLoad.analysisFileLocation
  };
  const response = await axiosInstance.put(`imports/${payLoad.id}/analysis`, requestPayload);
  return response.data;
};
