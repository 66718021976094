import styled from "@emotion/styled";

export const Wrapper = styled.div`
  width: 100%;
  @media only screen and (max-width: ${props => props.theme.other.breakpoints.lg}) {
    display: block;
    width: 100%;
  }
`;

export const TableHeaderOptionsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  @media only screen and (max-width: 705px) {
    display: grid;
    gap: 12px;
    justify-content: center;
    width: 100%;
  }
`;

export const StyleButtonSearchWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
  @media only screen and (max-width: 705px) {
    display: grid;
    gap: 12px;
    justify-content: center;
    align-items: center;
  }
`;

export const ButtonWrapper = styled.div`
  max-width: 106px;
  width: 100%;
  @media only screen and (max-width: 705px) {
    max-width: 100%;
  }
`;

export const AutocompleteWrapper = styled.div`
  max-width: 255px;
  width: 100%;
  @media only screen and (max-width: 705px) {
    max-width: 100%;
  }
`;

export const SearchContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 12px;
  align-items: center;
`;

export const ActionButtonsWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 1rem;
  justify-content: flex-end;
`;

export const TableWrapper = styled.div`
  overflow-x: auto;
  margin-top: ${props => props.theme.other.table.marginTop};
  margin-bottom: ${props => props.theme.other.table.marginBottom};
`;

export const StyledMenuTarget = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
