import { Button } from "@mantine/core";

import { QuestionnaireResponseFooter } from "../questionnaire.styles";

type QuestionnaireFooterProps = {
  disabled: boolean;
  save: () => void;
  submit: () => void;
};

export const QuestionnaireFooter = ({ submit, save, disabled }: QuestionnaireFooterProps) => {
  return (
    <QuestionnaireResponseFooter>
      <Button type="button" onClick={submit} disabled={disabled}>
        Submit
      </Button>
      <Button variant="outline" type="button" onClick={save} disabled={disabled}>
        Save for Later
      </Button>
    </QuestionnaireResponseFooter>
  );
};
