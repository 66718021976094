import { FC, Fragment } from "react";

import { Button } from "@mantine/core";
import RoleGuardWrapper from "ui/wrappers/RoleGuardWrapper";

import {
  StyledAccountName,
  StyledEditSupplierButton,
  StyledSupplierName,
  StyledSupplierNameWrapper
} from "../supplierDetails.styles";

type SupplierDetailsHeaderProps = {
  accountName: string;
  name: string;
  onEdit?: () => void;
};

export const SupplierDetailsHeader: FC<SupplierDetailsHeaderProps> = ({ name, accountName, onEdit }) => (
  <Fragment>
    <StyledAccountName>{accountName}</StyledAccountName>
    <StyledSupplierNameWrapper>
      <StyledSupplierName>{name}</StyledSupplierName>
      {onEdit && (
        <RoleGuardWrapper roles={["ACCOUNT_ADMIN", "INTERNAL_ADMIN", "INTERNAL_ANALYST"]}>
          <StyledEditSupplierButton>
            <Button size="lg" onClick={() => onEdit()}>
              Edit Supplier
            </Button>
          </StyledEditSupplierButton>
        </RoleGuardWrapper>
      )}
    </StyledSupplierNameWrapper>
  </Fragment>
);
