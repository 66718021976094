import { SortingState } from "@tanstack/react-table";
import _ from "lodash";
import qs from "qs";

export const stringifyQueryParams = (data: object) => {
  const clearedData = _.pickBy(data, v => String(v)?.length > 0);
  return qs.stringify(clearedData, {
    arrayFormat: "brackets",
    skipNulls: true,
    encode: false
  });
};

export const stringifySorting = (sorting: SortingState) => {
  if (sorting.length) {
    const { desc, id } = sorting[0];
    const sortType = desc ? "desc" : "asc";
    return id + "," + sortType;
  }
  return "";
};

export const parseSorting = (sorting: string) => {
  if (sorting) {
    const [id, sort] = sorting.split(",");
    return [
      {
        id,
        desc: sort === "desc"
      }
    ];
  }
  return [];
};

export const parseQueryParams = (data: string, options?: qs.IParseOptions) =>
  qs.parse(data.replace("?", ""), { ...options });
