import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { LoadingOverlay } from "@mantine/core";
import { getCoreRowModel, getPaginationRowModel, getSortedRowModel, Row, useReactTable } from "@tanstack/react-table";
import {
  TAnalyticsChartData,
  TAnalyticsCustomInterval,
  TAnalyticsFilters,
  TAnalyticsGroup,
  TAnalyticsTableData,
  TAnalyticsTabularData,
  TBreakdownDashboardDataSource,
  TOverviewDashboardModal
} from "types";
import { Table } from "ui";
import Loader from "ui/feedback/Loader";
import DonutGraph from "ui/graphs/donut-chart/DonutChart";
import { CashLogo, IndustryLogo } from "ui/icons";
import { AnalyticSubNavigation } from "ui/layouts/common";
import Pagination from "ui/navigation/Pagination";
import { convertToPaginated } from "utils";
import { prettyMoney, prettyNumber } from "utils/formatting.utils";
import { parseQueryParams, stringifyQueryParams } from "utils/query.utils";

import {
  customIntervalInitial,
  initialTableData,
  overviewDashboardModalInitial,
  transactionRedirectConfig
} from "../analytics.data";
import { useGetAnalyticsGrouped } from "../hooks";
import { BreakDownDashboardFilters, columns } from "./BreakdownDashboardComponents";
import { BreakdownDashboardAdditionalFilter } from "./BreakdownDashboardComponents";
import { Wrapper } from "./BreakdownDashboardComponents/styles";
import { CustomIntervalModal } from "./CustomIntervalModal";
import {
  ChartContainer,
  ChartContentWrapper,
  ChartWrapper,
  Container,
  NoResultsFound,
  PaginationWrapper,
  StyleDescription,
  StyleLabel,
  StyleValue,
  TableWrapper
} from "./style/StyleComponents";
import { convertTableDataWithOthers, convertToTabular, generateRandomColors } from "./utils";

const limit = 10;

type BreakdownDashboardProps = {
  end: string;
  group: TAnalyticsGroup;
  interval?: string;
  start: string;
};

export const BreakdownDashboard = (props: BreakdownDashboardProps) => {
  const navigate = useNavigate();

  const { search } = useLocation();

  const { id: accountId } = useParams() as { id: string };

  const bookmarkedParams = parseQueryParams(search, { decoder: c => c });

  const initialFilters = {
    ...props,
    timeWindow: "alltime",
    buyspaceId: null
  };

  const [filters, setFilters] = useState<TAnalyticsFilters>({
    accountId,
    ...initialFilters,
    ...bookmarkedParams
  });

  const [modal, setModal] = useState<TOverviewDashboardModal>(overviewDashboardModalInitial);
  const [customInterval, setCustomInterval] = useState<TAnalyticsCustomInterval>(customIntervalInitial);

  const [tableData, setTableData] = useState<TAnalyticsTableData>(initialTableData);
  const [chartData, setChartData] = useState<TAnalyticsChartData[]>([]);
  const [selectedGroup, setSelectedGroup] = useState<string | null>(null);
  const [page, setPage] = useState(0);
  const { data, isLoading } = useGetAnalyticsGrouped(filters);

  const updateData = () => {
    const { data: analyticsData, summary } = data;
    const tableColors = generateRandomColors(analyticsData);
    const tabularTableData = convertToTabular(analyticsData, summary, tableColors);
    const updatedPaginatedTableData = convertToPaginated(tabularTableData);
    const updatedChartData = convertTableDataWithOthers(tabularTableData, summary, tableColors);
    setTableData({ standard: tabularTableData, paginated: updatedPaginatedTableData, summary });
    setChartData(updatedChartData);
  };

  const lookupWholeGroup = (lookupKey: keyof TAnalyticsTabularData) => {
    switch (lookupKey) {
      case "CO2Tons": {
        return tableData?.summary?.co2e || "--";
      }
      case "spend": {
        return tableData?.summary?.spend || "--";
      }
      default: {
        return "--";
      }
    }
  };

  const lookupGroup = function (id: string, lookupKey: keyof TAnalyticsTabularData) {
    const { standard } = tableData;
    const lookupData = [...chartData, ...standard];
    const group = lookupData.find(lookup => lookup.id === id) as TAnalyticsTabularData;
    if (!group) return lookupWholeGroup(lookupKey);
    if (lookupKey) {
      return group[lookupKey] as number | string;
    }
    return "--";
  };

  useEffect(() => {
    if (!isLoading) {
      updateData();
    }
  }, [data]);

  const breakdownTableData = useMemo(
    () =>
      tableData.standard?.map(tableItem => {
        const { CO2TonsPercent, id } = tableItem;
        const group = Number(CO2TonsPercent) < 1 ? "Tail Spend" : id;
        return { ...tableItem, group } as TBreakdownDashboardDataSource;
      }),
    [tableData]
  );

  const table = useReactTable({
    data: breakdownTableData,
    columns,
    meta: {
      activeRow: selectedGroup
    },
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    pageCount: Math.ceil(breakdownTableData.length / limit),
    autoResetPageIndex: true,
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: limit
      },
      sorting: [
        {
          id: "CO2Tons",
          desc: true
        }
      ]
    }
  });

  const onPageChange = (page: number) => {
    setPage(page);
    table.setPageIndex(page);
  };

  const onTransactionRedirect = (row: Row<TBreakdownDashboardDataSource>) => {
    const redirectData = transactionRedirectConfig[filters.group];
    if (!redirectData) return;
    const { redirectKey, redirectIdKey, type } = redirectData;
    const id = row.original[redirectIdKey];
    if (!id) return;
    const paramsObject = { [redirectKey]: type === "array" ? [id] : id };
    const queryParams = stringifyQueryParams(paramsObject);
    const newRoute = `/transactions?${queryParams}`;
    navigate(newRoute);
  };

  const onRowEnter = (row: Row<TBreakdownDashboardDataSource>) => {
    if (row.original.id) {
      setSelectedGroup(row.original.id.toString());
    }
  };

  const onRowLeave = () => {
    setSelectedGroup(null);
  };

  useEffect(() => {
    setCustomInterval(customIntervalInitial);
  }, [filters.timeWindow]);

  return (
    <div>
      <AnalyticSubNavigation
        extra={
          <BreakDownDashboardFilters
            initialBreakdownFiters={initialFilters}
            breakdownFilters={filters}
            setBreakDownFilters={setFilters}
            setPage={setPage}
            setModal={setModal}
          />
        }
      />

      <Container>
        <LoadingOverlay visible={isLoading} overlayBlur={2} loader={<Loader />} zIndex={1} />
        {tableData.standard?.length ? (
          <>
            <div>
              <TableWrapper>
                <Wrapper>
                  <Table<TBreakdownDashboardDataSource>
                    table={table}
                    onRow={{
                      onClick: onTransactionRedirect,
                      onMouseOver: onRowEnter,
                      onMouseLeave: onRowLeave
                    }}
                  />
                </Wrapper>
                <PaginationWrapper>
                  <Pagination
                    activePage={page + 1}
                    setPage={(page: number) => onPageChange(page - 1)}
                    totalRecords={tableData.standard.length}
                    limit={limit}
                  />
                </PaginationWrapper>
              </TableWrapper>
            </div>
            <ChartWrapper>
              <ChartContainer>
                <ChartContentWrapper>
                  <StyleLabel>
                    <IndustryLogo />
                    CO₂e Emissions
                  </StyleLabel>
                  <StyleValue>{prettyNumber(lookupGroup(String(selectedGroup), "CO2Tons"))}</StyleValue>
                  <StyleDescription>{selectedGroup ? `tons of CO₂e emissions` : "Hover over chart"}</StyleDescription>
                  <StyleDescription>
                    {selectedGroup
                      ? `${lookupGroup(String(selectedGroup), "CO2TonsPercent")}% of all emissions`
                      : "for detailed breakdown"}
                  </StyleDescription>
                </ChartContentWrapper>
                <ChartContentWrapper>
                  <DonutGraph
                    selectedID={selectedGroup}
                    width={276}
                    height={250}
                    donutChartData={chartData}
                    tableData={tableData}
                    frequencyHolder="CO2e"
                    setHover={(s: TAnalyticsChartData) => {
                      s.id && setSelectedGroup(s.id);
                    }}
                    setHoverOff={() => {
                      setSelectedGroup(null);
                    }}
                  />
                </ChartContentWrapper>
              </ChartContainer>
              <ChartContainer>
                <ChartContentWrapper>
                  <StyleLabel>
                    <CashLogo />
                    Spend
                  </StyleLabel>
                  <StyleValue>{prettyMoney(lookupGroup(String(selectedGroup), "spend"))}</StyleValue>
                  <StyleDescription>{selectedGroup ? `USD` : "Hover over chart"}</StyleDescription>
                  <StyleDescription>
                    {selectedGroup
                      ? `${lookupGroup(String(selectedGroup), "spendPercent")}% of all Spend`
                      : "for detailed breakdown"}
                  </StyleDescription>
                </ChartContentWrapper>
                <ChartContentWrapper>
                  <DonutGraph
                    selectedID={selectedGroup}
                    width={276}
                    height={250}
                    donutChartData={chartData}
                    tableData={tableData}
                    frequencyHolder="Spend"
                    setHover={(s: TAnalyticsChartData) => {
                      s.id && setSelectedGroup(s.id);
                    }}
                    setHoverOff={() => {
                      setSelectedGroup(null);
                    }}
                  />
                </ChartContentWrapper>
              </ChartContainer>
            </ChartWrapper>
          </>
        ) : (
          <NoResultsFound>No Result Found</NoResultsFound>
        )}
      </Container>
      <CustomIntervalModal
        opened={modal.customInterval}
        setModal={setModal}
        applyChanges={customInterval => setFilters(prevFilters => ({ ...prevFilters, ...customInterval }))}
        customInterval={customInterval}
        setCustomInterval={setCustomInterval}
      />
      <BreakdownDashboardAdditionalFilter filters={filters} setFilters={setFilters} modal={modal} setModal={setModal} />
    </div>
  );
};
