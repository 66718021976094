import axiosInstance from "services/AxiosInstance";
import { ResponseType } from "types";

import { CustomField } from "../types";

export const postCustomField = async ({
  payload
}: {
  payload: Omit<CustomField, "id">;
}): Promise<ResponseType<CustomField>> => {
  const response = await axiosInstance.post(`/customfields`, payload);
  return response.data;
};
