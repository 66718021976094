import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { LoadingOverlay } from "@mantine/core";
import { GoogleOAuthProvider, useGoogleLogin } from "@react-oauth/google";
import jwt_decode from "jwt-decode";
import { CurrentUserContext } from "modules/login/CurrentUserContext";
import { StyleLoginButton, StyleLoginButtonText } from "modules/login/styles/Login.styles";
import { useGetUserInfo } from "modules/users/hooks/useGetUserInfo";
import { JwtTokenInterface, UserInterface } from "modules/users/types/UserDto";
import { LOGIN_TYPE, USER_TYPE } from "modules/users/types/UserTypesEnum";
import Loader from "ui/feedback/Loader";
import { GoogleLogo } from "ui/icons";

import { useAuthenticateUserMutation, useGetGoogleUserInfoQuery } from "../hooks";

const GoogleLoginFunction = () => {
  const navigate = useNavigate();
  const { setUserDetails, setUserId: setGlobalUserId, setJWT_Token } = useContext(CurrentUserContext);
  const [accessToken, setAccessToken] = useState("");
  const [userId, setUserId] = useState("");
  const [googleQueryEnabled, setGoogleQueryEnabled] = useState(false);
  const [queryEnabled, setQueryEnabled] = useState(false);
  const getGoogleLoginQuery = useGetGoogleUserInfoQuery(accessToken, googleQueryEnabled);
  const authenticateUserMutate = useAuthenticateUserMutation();
  const getUserInfoQuery = useGetUserInfo(userId, queryEnabled);

  /**
   * Google Login call after Access token
   */
  useEffect(() => {
    if (accessToken !== "" && googleQueryEnabled) {
      getGoogleLoginQuery.refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken, googleQueryEnabled]);

  /**
   * Autentication call to check authorisation once google login done
   */
  useEffect(() => {
    if (getGoogleLoginQuery.isSuccess) {
      setGoogleQueryEnabled(false);
      localStorage.setItem("token", accessToken);
      if (accessToken) {
        authenticateUserMutate.mutate({ accessToken: accessToken, signWith: LOGIN_TYPE.google });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getGoogleLoginQuery.isSuccess]);

  /**
   * Get User Info Call to get userInfo
   */
  useEffect(() => {
    if (userId !== "" && queryEnabled) {
      getUserInfoQuery.refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, queryEnabled]);

  /**
   * Google Login to get access token
   */
  const googleLogin = useGoogleLogin({
    onSuccess: async tokenResponse => {
      setGoogleQueryEnabled(true);
      setAccessToken(tokenResponse.access_token);
    }
  });

  useEffect(() => {
    if (authenticateUserMutate.isSuccess) {
      const jwtToken = authenticateUserMutate?.data?.data?.jwtToken;
      if (authenticateUserMutate?.data?.data?.jwtToken !== "") {
        localStorage.setItem("jwt_Token", jwtToken);
        setJWT_Token(jwtToken);
        const decodedJWT_token = jwt_decode<JwtTokenInterface>(jwtToken);
        setUserId(decodedJWT_token.sub);
        setGlobalUserId(decodedJWT_token.sub);
        setQueryEnabled(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticateUserMutate.isSuccess]);

  useEffect(() => {
    if (getUserInfoQuery.isSuccess) {
      const userDetailsObj: UserInterface = {
        firstName: getUserInfoQuery.data?.data?.firstName,
        lastName: getUserInfoQuery.data?.data?.lastName,
        email: getUserInfoQuery.data?.data?.email,
        userType: getUserInfoQuery.data?.data?.userType,
        userId: getUserInfoQuery.data?.data?.id
      };

      if (getUserInfoQuery.data?.data?.accounts && getUserInfoQuery.data?.data?.accounts.length > 0) {
        const account = getUserInfoQuery.data?.data?.accounts[0];
        userDetailsObj.accountId = account.accountId;
        userDetailsObj.userRole = account.userRole;
        userDetailsObj.accountName = account.accountName;
        userDetailsObj.userRole = account.role;
        localStorage.setItem("accountId", account.accountId ?? "");
      }
      setUserDetails(userDetailsObj);
      if (getUserInfoQuery.data?.data.userType === USER_TYPE.internal) {
        navigate(`/accounts`);
      } else {
        navigate("/account-selection");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getUserInfoQuery.isSuccess]);

  if (
    getGoogleLoginQuery.fetchStatus === "fetching" ||
    getUserInfoQuery.fetchStatus === "fetching" ||
    authenticateUserMutate.isLoading === true
  ) {
    return <LoadingOverlay visible={true} overlayBlur={2} loader={<Loader />} />;
  }

  return (
    <React.Fragment>
      <StyleLoginButton onClick={() => googleLogin()}>
        <GoogleLogo fontSize="17px" />
        <StyleLoginButtonText>Sign in with Google</StyleLoginButtonText>
      </StyleLoginButton>
    </React.Fragment>
  );
};

export const GoogleLogin = () => {
  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_APP_ID as string}>
      <GoogleLoginFunction />
    </GoogleOAuthProvider>
  );
};
