import { DefaultMantineColor, MantineTheme, MenuProps, Tuple } from "@mantine/core";

import { BreakPointInterface } from "./breakPoints";
import { FontTypographyInterface } from "./typography";

export interface ThemeInterface {
  other: {
    breakpoints: BreakPointInterface;
    dangerColor: string;
    font: FontTypographyInterface;
    menu: MenuProps;
    monochromeColor: string | string[];
    otherColor: string | string[];
    otherColorRGBA: string | string[];
    primaryColor: string;
    secondaryColor: string;
    table: {
      marginBottom: string;
      marginTop: string;
    };
    tertiaryColor: string;
    warningColor: string;
  };
  colors: {
    error: Tuple<string, 10>;
    klp: Tuple<string, 10>;
    warning: Tuple<string, 10>;
  };
  primaryColor: "klp";
  primaryShade: MantineTheme["primaryShade"];
}

type ExtendedCustomColors = "danger" | "klp" | "warning" | DefaultMantineColor;

declare module "@mantine/core" {
  export interface MantineThemeColorsOverride {
    colors: Record<ExtendedCustomColors, Tuple<string, 10>>;
  }
}

export const primaryColor = "#32A752";
export const secondaryColor = "#A0D8AF";
export const tertiaryColor = "#1A7E35";

export const warningColor = "#FBB03B";

export const dangerColor = "#C10840";

export const monochromeColor = [
  "#000000", // 0
  "#292E2A", // 1
  "#5C5C5C", // 2
  "#C0C0C0", // 3
  "#E0E0E0", // 4
  "#EFF1F3", // 5
  "#FFFFFF", // 6
  "#B20B33", // 7
  "#EBF9F1", // 8
  "#A0D8Af" // 9
];

export const otherColor = ["#003647", "#F6935A"];
// otherColor Index            0          1

export const otherColorRGBA = ["rgba(239, 241, 243, 0.3)", "rgba(160, 216, 175, 0.07)"];
// otherColorRGBA Index                     0                            1

export const graphColorKey = [
  "#FBB03B",
  "#3792DA",
  "#32A752",
  "#7B54AC",
  "#5482AC",
  "#5C5C5C",
  "#FF8038",
  "#A0D8AF",
  "#3BCDFB",
  "#B20B33",
  "#FF6361",
  "#BC5090",
  "#58508D",
  "#9F1853",
  "#FA4D56",
  "#002D9C",
  "#A56EFF",
  "#005d5d",
  "#ee538b"
];
