import { Menu, Text } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import { theme } from "constants/theme";
import { confirmationModal, successModal } from "modules/error/ErrorHandlingModals";
import { UserRoles } from "modules/users";
import { QuestionnaireStatus, TQuestionnaire } from "types";
import { MenuHamburgerIcon, SuccessIcon, TrashIcon, ViewIcon } from "ui/icons";
import RoleGuardWrapper from "ui/wrappers/RoleGuardWrapper";

import { useDeleteQuestionnaireMutation, usePutQuestionnaireMutation } from "../hooks";
import { QuestionnaireActionCellMenuTarget } from "../questionnaire.styles";

export const QuestionnaireListActionCell = (questionnaire: TQuestionnaire) => {
  const questionnairePutMutation = usePutQuestionnaireMutation();

  const onSuccessNotification = () =>
    showNotification({
      message: "Questionnaire has been successfully deleted",
      color: "klp",
      icon: <SuccessIcon />
    });

  const { mutate: handleQuestionnaireDelete } = useDeleteQuestionnaireMutation({ onSuccess: onSuccessNotification });

  const onQuestionnaireDelete = () =>
    confirmationModal({
      message: "Are you sure you want to delete this Questionnaire?",
      title: <Text c="danger.8">Confirm Delete</Text>,
      color: "danger.8",
      onConfirm: () => handleQuestionnaireDelete({ questionnaireId: questionnaire.id })
    });

  const archive = () =>
    questionnairePutMutation.mutate(
      { questionnaire: { ...questionnaire, status: QuestionnaireStatus.ARCHIVED } },
      { onSuccess: () => successModal("Questionnaire Archived Sucessfully.") }
    );

  const detailsLink = `${window.location.origin}/questionnaire/${questionnaire.id}/view`;

  return (
    <Menu {...theme?.other?.menu} withinPortal zIndex={20}>
      <Menu.Target>
        <QuestionnaireActionCellMenuTarget onClick={() => {}}>
          <MenuHamburgerIcon />
        </QuestionnaireActionCellMenuTarget>
      </Menu.Target>
      <Menu.Dropdown>
        <Menu.Item icon={<ViewIcon />} component="a" href={detailsLink} target="_blank" rel="noopener noreferrer">
          View Details
        </Menu.Item>
        <RoleGuardWrapper roles={[UserRoles.ACCOUNT_ADMIN.value, UserRoles.INTERNAL_ADMIN.value]}>
          <Menu.Item icon={<ViewIcon />} component="div" onClick={archive}>
            Archive
          </Menu.Item>
        </RoleGuardWrapper>
        <RoleGuardWrapper roles={[UserRoles.INTERNAL_ADMIN.value]}>
          <Menu.Item icon={<TrashIcon />} onClick={onQuestionnaireDelete}>
            Delete
          </Menu.Item>
        </RoleGuardWrapper>
      </Menu.Dropdown>
    </Menu>
  );
};
