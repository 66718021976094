// Create the main myMSALObj instance

import { InteractionRequiredAuthError, PublicClientApplication } from "@azure/msal-browser";

import { apiConfig, loginRequest, msalConfig, tokenRequest } from "./authConfig";

// configuration parameters are located at authConfig.js
const myMSALObj = new PublicClientApplication(msalConfig);

let username = "";
export const selectAccount = () => {
  /**
   * See here for more info on account retrieval:
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-common/docs/Accounts.md
   */

  const currentAccounts = myMSALObj.getAllAccounts();

  if (!currentAccounts || currentAccounts.length < 1) {
    return;
  } else if (currentAccounts.length > 1) {
    // Add your account choosing logic here
    console.warn("Multiple accounts detected.");
  } else if (currentAccounts.length === 1) {
    username = currentAccounts[0].username;
    return currentAccounts[0].accessToken;
  }
};

const getAccessToken = response => {
  /**
   * To see the full list of response object properties, visit:
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/request-response-object.md#response
   */

  if (response !== null) {
    username = response.account.username;
    return response.accessToken;
  } else {
    selectAccount();
  }
};

export const signIn = async () => {
  /**
   * You can pass a custom request object below. This will override the initial configuration. For more information, visit:
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/request-response-object.md#request
   */

  try {
    const response = await myMSALObj.loginPopup(loginRequest);
    return getAccessToken(response);
  } catch (error) {
    console.error(error);
  }
};

/*
 * This function is not currently used by our code. We can implement this is the future to log the user out from the Azure realm.
 * At the moment we only destroy the session in the app.
 */
export const signOut = () => {
  /**
   * You can pass a custom request object below. This will override the initial configuration. For more information, visit:
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/request-response-object.md#request
   */

  // Choose which account to logout from by passing a username.
  const logoutRequest = {
    account: myMSALObj.getAccountByUsername(username)
  };

  myMSALObj.logout(logoutRequest);
};

selectAccount();

/*
 * Below method is generic and currently not used by our app
 */
const getTokenPopup = request => {
  /**
   * See here for more information on account retrieval:
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-common/docs/Accounts.md
   */

  request.account = myMSALObj.getAccountByUsername(username);

  return myMSALObj.acquireTokenSilent(request).catch(error => {
    console.warn(error);
    console.warn("silent token acquisition fails. acquiring token using popup");
    if (error instanceof InteractionRequiredAuthError) {
      // fallback to interaction when silent call fails
      return myMSALObj
        .acquireTokenPopup(request)
        .then(response => {
          console.log(response);
          return response;
        })
        .catch(error => {
          console.error(error);
        });
    } else {
      console.warn(error);
    }
  });
};

/*
 * Below method is generic and currently not used by our app
 */
const callApi = (endpoint, token) => {
  const headers = new Headers();
  const bearer = `Bearer ${token}`;

  headers.append("Authorization", bearer);

  const options = {
    method: "GET",
    headers: headers
  };

  console.log("Calling Web API...");

  fetch(endpoint, options)
    .then(response => response.json())
    .then(response => {
      if (response) {
        console.log("Web API responded: Hello " + response["name"] + "!");
      }

      return response;
    })
    .catch(error => {
      console.error(error);
    });
};

/*
 * Below method is generic and currently not used by our app
 */
export const passTokenToApi = () => {
  getTokenPopup(tokenRequest).then(response => {
    if (response) {
      console.log("access_token acquired at: " + new Date().toString());
      try {
        callApi(apiConfig.uri, response.accessToken);
      } catch (error) {
        console.warn(error);
      }
    }
  });
};
