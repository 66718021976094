import { IBuyspacesConditions } from "types";
import { formatArrayText } from "utils";

import { buyspaceConditionKeys, buyspaceOperator } from "../buyspaces.data";
import {
  BuyspaceFilterItemContainer,
  BuyspaceFilterTileContainer,
  Description,
  FilterTileContainer,
  Operator,
  Value
} from "../styles";

type BuyspacesFilterCellProps = {
  conditions: IBuyspacesConditions[];
};

const BuyspaceFilterItem = ({ condition }: { condition: IBuyspacesConditions }) => (
  <BuyspaceFilterItemContainer>
    <Description>{buyspaceConditionKeys[condition.key]}</Description>
    <Operator>{buyspaceOperator[condition.operator]}</Operator>
    <Value>{formatArrayText(condition.label)}</Value>
  </BuyspaceFilterItemContainer>
);

const BuyspaceFilterTiles = ({ conditions }: BuyspacesFilterCellProps) => {
  return (
    <>
      {conditions.map(condition => (
        <FilterTileContainer key={condition.id + "tile"}>
          <BuyspaceFilterItem condition={condition} />
        </FilterTileContainer>
      ))}
    </>
  );
};

export const BuyspacesFilterCell = ({ conditions }: BuyspacesFilterCellProps) => {
  return (
    <BuyspaceFilterTileContainer>
      <BuyspaceFilterTiles conditions={structuredClone(conditions)} />
    </BuyspaceFilterTileContainer>
  );
};
