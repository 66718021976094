import React from "react";

import styled from "@emotion/styled";
import { Button } from "@mantine/core";
import { ConfirmationUploadModalProps } from "modules/imports/components/ImportsTypes";
import { ModalFooter } from "ui";
import { SuccessIcon } from "ui/icons";
import Modal from "ui/overlays/Modal";

const TitleWrapper = styled.div`
  display: flex;
  justify-content: center;
  color: ${({ theme }) => theme.colors.klp[4]};
`;
const StyleMessage = styled.div`
  font-family: ${props => props.theme.other.font.fontFamily};
  font-style: ${props => props.theme.other.font.fontStyle};
  font-weight: 700;
  font-size: 3.125rem;
  line-height: 3.8125rem;
  text-align: center;
  color: #32a752;
`;

const ButtonWrapperImports = styled.div`
  @media only screen and (max-width: 515px) {
    margin-left: auto;
    margin-right: auto;
  }
`;
const StyleDescription = styled.div`
  font-family: ${props => props.theme.other.font.fontFamily};
  font-style: ${props => props.theme.other.font.fontStyle};
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.1875rem;
  color: #5c5c5c;
  text-align: center;
  padding-top: 30.6px;
`;
const StyledConfirmationUploadModal = styled(Modal)`
  .mantine-Modal-modal {
    max-width: ${props => props?.maxModalWidth} !important;
    padding: 30.4px 56px 66px 41px !important;
    @media only screen and (max-width: ${props => props.theme.other.breakpoints.lg}) {
      padding: 10px;
    }
  }
`;
export const ConfirmationUploadModal: React.FC<ConfirmationUploadModalProps> = ({
  openModal = false,
  setOpenModal,
  handleClose,
  title,
  description,
  addMoreBtnText,
  type
}) => {
  return (
    <StyledConfirmationUploadModal
      opened={openModal}
      setOpened={setOpenModal}
      handleClose={handleClose}
      modalPadding={""}
      maxModalWidth={type === "analysis" ? "632px" : "560px"}
      minHeight={type === "analysis" ? "527px" : "540px"}
      titleWidth="100%"
      title={
        <TitleWrapper>
          <SuccessIcon width="80" height={type === "analysis" ? "90.86px" : "80"} />
        </TitleWrapper>
      }
    >
      <StyleMessage>{title}</StyleMessage>
      <StyleDescription>{description}</StyleDescription>
      <ModalFooter>
        <Button
          variant="default"
          onClick={() => {
            setOpenModal(false);
          }}
        >
          {addMoreBtnText}
        </Button>
        <ButtonWrapperImports>
          <Button
            onClick={() => {
              setOpenModal(false);
              handleClose?.();
            }}
          >
            View Imports
          </Button>
        </ButtonWrapperImports>
      </ModalFooter>
    </StyledConfirmationUploadModal>
  );
};
