import { useContext } from "react";
import { useParams } from "react-router-dom";

import { Button, Grid, TextInput } from "@mantine/core";
import { ClassificationMultiSelect, SupplierMultiSelect } from "components";
import { useGetSupplierListQueries } from "hooks";
import { ISupplier } from "modules/suppliers";
import { useGetClassificationData } from "modules/transactions";
import { AutocompleteComponent, ModalFooter } from "ui";
import Checkbox from "ui/inputs/Checkbox";

import { CommodityContext } from "../context/CommodityContext";
import { useGetCommodityListQuery } from "../hooks";
import {
  CommodityFilterHeader,
  CommodityFilterModalContainer,
  FiltersContainer,
  StyledFilterTitle,
  StyledInputWrapper
} from "../styles/commodityFilterModal.styles";

export const CommodityFilterModal = () => {
  const { filters, modal, updateFilters, resetFilters, applyFilters, modalController } = useContext(CommodityContext);

  const { id } = useParams();

  const handleCloseFilters = () => modalController({ filters: false });

  const handleShowResults = () => {
    applyFilters();
    handleCloseFilters();
  };

  const naicsCodes = filters.naicsCodes;
  const naicsSectors = filters.naicsSectors;
  const naicsIndustries = filters.naicsIndustries;
  const supplierIds = filters.supplierIds;

  const { naicsDetailCodes, naicsIndustryCodes, naicsSectorCodes } = useGetClassificationData();
  const supplierListData = useGetSupplierListQueries({ supplierIds });

  const initialSupliers = supplierListData
    ?.map(supplierData => supplierData.data)
    .filter((data): data is ISupplier => data !== undefined);

  const { data } = useGetCommodityListQuery({ name: filters.name }, 5, id, {
    enabled: filters.name.length >= 3 && !!id
  });

  const options = data?.data?.map(commodity => commodity.name) || [];

  return (
    <CommodityFilterModalContainer
      opened={modal.filters}
      setOpened={handleCloseFilters}
      withCloseButton
      handleClose={handleCloseFilters}
    >
      <CommodityFilterHeader>Commodity Filters</CommodityFilterHeader>
      <FiltersContainer>
        <Grid gutter={24}>
          <Grid.Col span={6}>
            <AutocompleteComponent
              label="Commodity Name"
              placeholder="Search Commodity"
              data={options}
              onChange={value => updateFilters({ name: value })}
              onItemSubmit={item => updateFilters({ name: item.value })}
              value={filters.name}
              checkboxComponent={<Checkbox checked={!!filters.name} onChange={() => updateFilters({ name: "" })} />}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <StyledFilterTitle>Commodity Description</StyledFilterTitle>
            <StyledInputWrapper>
              <Checkbox checked={!!filters.description} onChange={() => updateFilters({ description: "" })} />
              <TextInput
                value={filters.description}
                onChange={e => {
                  updateFilters({ description: e.target.value });
                }}
                w="100%"
                placeholder="Commodity Description"
              />
            </StyledInputWrapper>
          </Grid.Col>
          <Grid.Col>
            <SupplierMultiSelect
              title="Suppliers"
              placeholder="Search Supplier"
              onChange={values => updateFilters({ supplierIds: values })}
              value={supplierIds}
              initialData={initialSupliers}
            />
          </Grid.Col>
          <Grid.Col>
            <ClassificationMultiSelect
              title="NAICS Code"
              placeholder="Search NAICS Code"
              params={{ "levels[]": "DETAIL" }}
              onChange={values => updateFilters({ naicsCodes: values })}
              value={naicsCodes}
              initialData={naicsDetailCodes}
            />
          </Grid.Col>
          <Grid.Col>
            <ClassificationMultiSelect
              title="NAICS Sector"
              placeholder="Search NAICS Sector"
              params={{ "levels[]": "SECTOR" }}
              onChange={values => updateFilters({ naicsSectors: values })}
              value={naicsSectors}
              initialData={naicsSectorCodes}
            />
          </Grid.Col>
          <Grid.Col>
            <ClassificationMultiSelect
              title="NAICS Industry"
              placeholder="Search NAICS Industry"
              params={{ "levels[]": "INDUSTRY" }}
              onChange={values => updateFilters({ naicsIndustries: values })}
              value={naicsIndustries}
              initialData={naicsIndustryCodes}
              placement="top"
            />
          </Grid.Col>
        </Grid>
      </FiltersContainer>
      <ModalFooter>
        <Button onClick={resetFilters} variant="subtle">
          Reset Filter
        </Button>
        <Button onClick={handleShowResults}>Show Results</Button>
      </ModalFooter>
    </CommodityFilterModalContainer>
  );
};
