import { Stack } from "@mantine/core";
import { AddIcon } from "ui/icons";

import { QCFormInstance, QCFormValues } from "../../questionnaire.types";
import { QCQuestion } from "./QCQuestion";
import { QCButton, QCButtonContainer, QCSubtitle } from "./questionnaireCreator.styles";

export const QCSection: React.FC<{
  form: QCFormInstance<QCFormValues>;
  scope: "productQuestionList" | "supplierQuestionList";
  title: string;
}> = ({ form, scope, title }) => {
  const handleInsertNewQuestion = () => {
    form.insertListItem(scope, {
      type: "SHORT_TEXT",
      required: false,
      question: "",
      choices: []
    });
  };

  const handleRemoveQuestion = (index: number) => form.removeListItem(scope, index);

  const values = form.values?.[scope] || [];

  const questions = values.map((_, index) => (
    <QCQuestion key={index} index={index} onRemove={() => handleRemoveQuestion(index)} form={form} scope={scope} />
  ));

  return (
    <>
      <QCSubtitle>{title}</QCSubtitle>
      <Stack>{questions}</Stack>
      <QCButtonContainer>
        <QCButton variant="subtle" leftIcon={<AddIcon />} color="dark" radius="xl" onClick={handleInsertNewQuestion}>
          Add New Question
        </QCButton>
      </QCButtonContainer>
    </>
  );
};
