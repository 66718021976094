import { ColumnDef } from "@tanstack/react-table";
import { LinkComponent } from "components";
import Checkbox from "ui/inputs/Checkbox";

import { ISupplier } from "../suppliers.types";
import { SuppliersLinkCell } from "./SuppliersLinkCell";
import { SuppliersTableBodyMenu } from "./SuppliersTableBodyMenu";

export const columns: ColumnDef<ISupplier>[] = [
  {
    id: "select",
    cell: ({ row }) => (
      <div>
        <Checkbox
          checked={row.getIsSelected()}
          onChange={row.getToggleSelectedHandler()}
          disabled={!row.getCanSelect()}
        />
      </div>
    ),
    size: 20
  },
  {
    accessorKey: "name",
    header: "Supplier Name",
    size: 420,
    enableSorting: false,
    cell: ({ row }) => <LinkComponent to={`/supplier/${row.original.id}`}>{row.original.name}</LinkComponent>
  },
  {
    id: "place",
    header: "Supplier Location",
    enableSorting: false,
    cell: props => {
      const { city, region } = props.row.original;

      const renderCity = city ? city : "Unknown City";
      const renderRegion = region ? region : "Unknown Region";
      return (
        <>
          {renderCity}, {renderRegion}
        </>
      );
    },
    size: 420
  },
  {
    accessorKey: "items",
    enableSorting: false,
    header: "Commodities",
    size: 160,
    cell: props => (
      <SuppliersLinkCell {...props.row.original} redirectDestination="commodities" content={props.row.original.items} />
    )
  },
  {
    accessorKey: "transactions",
    enableSorting: false,
    header: "Transactions",
    size: 260,
    cell: props => (
      <SuppliersLinkCell
        {...props.row.original}
        redirectDestination="transactions"
        content={props.row.original.transactions}
      />
    )
  },
  {
    id: "action",
    enableSorting: false,
    header: "Action",
    cell: props => <SuppliersTableBodyMenu supplier={props.row.original} />,
    meta: {
      align: "center"
    },
    size: 50
  }
];
