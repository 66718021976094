import styled from "@emotion/styled";
import { Badge, BadgeProps } from "@mantine/core";

export const CustomFieldSectionContainer = styled.section`
  margin-top: 20px;

  .mantine-Badge-root {
    text-transform: none;
    font-size: 0.75rem;
    font-weight: 400;
    color: ${({ theme }) => theme.other.primaryColor};
    border: 1px solid ${({ theme }) => theme.other.primaryColor};

    .mantine-UnstyledButton-root {
      color: ${({ theme }) => theme.other.primaryColor};
    }
  }

  .mantine-Button-root {
    background-color: ${({ theme }) => theme.other.primaryColor};

    :hover {
      filter: brightness(105%);
    }
  }
`;

export const CustomFieldBadge = styled(Badge)<BadgeProps>`
  & > span {
    font-size: ${({ theme }) => theme.other.font.fontSize};
    line-height: ${({ theme }) => theme.other.font.lineHeight};
  }
`;
