import React from "react";

export const MultiSelectIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="20" height="13" viewBox="0 0 20 13" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M6.1412 0.969568H19.076C19.5347 0.969568 19.8999 1.32173 19.8999 1.73913C19.8999 2.15654 19.5347 2.5087 19.076 2.5087H6.1412C5.68248 2.5087 5.31729 2.15654 5.31729 1.73913C5.31729 1.32173 5.68248 0.969568 6.1412 0.969568Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="0.2"
    />
    <path
      d="M1.73913 0C0.77913 0 0 0.77913 0 1.73913C0 2.69913 0.77913 3.47826 1.73913 3.47826C2.69913 3.47826 3.47826 2.69913 3.47826 1.73913C3.47826 0.77913 2.69913 0 1.73913 0Z"
      fill="currentColor"
    />
    <path
      d="M6.1412 5.31739H19.076C19.5347 5.31739 19.8999 5.66955 19.8999 6.08696C19.8999 6.50436 19.5347 6.85652 19.076 6.85652H6.1412C5.68248 6.85652 5.31729 6.50436 5.31729 6.08696C5.31729 5.66955 5.68248 5.31739 6.1412 5.31739Z"
      fill="currentColor"
      stroke="#fff"
      strokeWidth="0.2"
    />
    <path
      d="M1.73913 4.34782C0.77913 4.34782 0 5.12695 0 6.08695C0 7.04695 0.77913 7.82608 1.73913 7.82608C2.69913 7.82608 3.47826 7.04695 3.47826 6.08695C3.47826 5.12695 2.69913 4.34782 1.73913 4.34782Z"
      fill="currentColor"
    />
    <path
      d="M1.73919 5.21739C1.25919 5.21739 0.869629 5.60696 0.869629 6.08696C0.869629 6.56696 1.25919 6.95652 1.73919 6.95652C2.21919 6.95652 2.60876 6.56696 2.60876 6.08696C2.60876 5.60696 2.21919 5.21739 1.73919 5.21739Z"
      fill="#fff"
    />
    <path
      d="M6.1412 9.66522H19.076C19.5347 9.66522 19.8999 10.0174 19.8999 10.4348C19.8999 10.8522 19.5347 11.2043 19.076 11.2043H6.1412C5.68248 11.2043 5.31729 10.8522 5.31729 10.4348C5.31729 10.0174 5.68248 9.66522 6.1412 9.66522Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="0.2"
    />
    <path
      d="M1.73913 8.69565C0.77913 8.69565 0 9.47478 0 10.4348C0 11.3948 0.77913 12.1739 1.73913 12.1739C2.69913 12.1739 3.47826 11.3948 3.47826 10.4348C3.47826 9.47478 2.69913 8.69565 1.73913 8.69565Z"
      fill="currentColor"
    />
  </svg>
);
