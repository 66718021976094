import { useEffect, useState } from "react";

import _ from "lodash";

type Props = {
  percent: number;
};

const useViewportPercent = ({ percent }: Props) => {
  const [viewport, setViewport] = useState({ heigth: window.innerHeight, width: window.innerWidth });
  const [maxWidthLaptop, setMaxWidthLaptop] = useState((viewport.width / 100) * percent);

  const onResize = () => {
    const dimensions = { heigth: window.innerHeight, width: window.innerWidth };
    setViewport(dimensions);
    setMaxWidthLaptop((dimensions.width / 100) * percent);
  };

  const debouncedResize = _.debounce(onResize, 500);

  const mount = () => {
    window.addEventListener("resize", debouncedResize);
  };

  const unMount = () => {
    window.removeEventListener("resize", debouncedResize);
  };

  useEffect(() => {
    mount();
    return () => {
      unMount();
    };
  }, []);

  return maxWidthLaptop;
};

export default useViewportPercent;
