import { Link } from "react-router-dom";

import styled from "@emotion/styled";

export const HoverableLink = styled(Link)`
  text-decoration: none;
  color: inherit;

  :hover {
    text-decoration: underline;
  }
`;
