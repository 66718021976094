import { useDisclosure } from "@mantine/hooks";
import { TopHeader } from "ui/layouts/common";

import {
  CommodityFilterModal,
  CommodityFilterTile,
  CommodityHeader,
  CommodityMergeModal,
  CommodityTable
} from "./components";
import { Wrapper } from "./styles";

export const Commodity = () => {
  const [opened, { open, close }] = useDisclosure();

  return (
    <Wrapper>
      <TopHeader title="Commodities" />
      <CommodityHeader onMergeOpen={open} />
      <CommodityFilterTile />
      <CommodityFilterModal />
      <CommodityTable />
      <CommodityMergeModal opened={opened} onClose={close} />
    </Wrapper>
  );
};
