import axiosInstance from "services/AxiosInstance";

type PostUserAuthentificationServiceArgs = {
  accessToken: string;
  signWith: string;
};

export const postUserAuthentificationService = async (payLoad: PostUserAuthentificationServiceArgs) => {
  const response = await axiosInstance.post(`auth/authenticate`, payLoad);
  return response.data;
};
