import * as React from "react";

export const ChevronRight = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="10" height="16" viewBox="0 0 10 16" fill="none" {...props}>
    <path
      d="M1.29042 15.8194C1.542 16.061 1.94781 16.0601 2.19827 15.8174L9.81155 8.44081C10.0628 8.19735 10.0628 7.80264 9.81155 7.55919L2.19827 0.182588C1.9478 -0.0600913 1.542 -0.0609725 1.29042 0.180616L0.190492 1.23683C-0.0641649 1.48137 -0.0633956 1.88041 0.192202 2.12402L5.89095 7.55558C6.14722 7.79984 6.14722 8.20016 5.89095 8.44441L0.192201 13.876C-0.0633962 14.1196 -0.0641649 14.5186 0.190491 14.7632L1.29042 15.8194Z"
      fill="currentColor"
    />
  </svg>
);
