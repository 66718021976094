import { createContext, useContext } from "react";

import { TAccount } from "types";

import { ImportInterface } from "../types";
import { ImportKeys, ImportModalState } from "./types";

type ImportContextOptions = {
  accounts: TAccount[];
  currentImportId: string | undefined;
  file: File | undefined;
  onDownloadFile: (fileType: "analysis" | "transactions", importData: ImportInterface) => Promise<void>;
  onDownloadStreamlinedFile: (importData: ImportInterface) => Promise<void>;
  onModalClose: () => void;
  onModalOpen: ({ active, open }: ImportModalState) => void;
  onSelectedAccountId: (accountId: string | undefined) => void;
  onSelectImportId: (importId: string | undefined) => void;
  onUploadFile: (file: File | undefined) => void;
  selectedAccountId: string | undefined;
  state: {
    active: ImportInterface | null;
    open: ImportKeys | null;
  };
};

const initialImportContext: ImportContextOptions = {
  accounts: [],
  state: {
    active: null,
    open: null
  },
  file: undefined,
  selectedAccountId: undefined,
  currentImportId: undefined,
  onModalOpen: () => {},
  onModalClose: () => {},
  onDownloadFile: async () => {},
  onDownloadStreamlinedFile: async () => {},
  onSelectedAccountId: async () => {},
  onSelectImportId: () => {},
  onUploadFile: () => {}
};

export const ImportContext = createContext<ImportContextOptions>(initialImportContext);

export const useImportContext = () => {
  const context = useContext(ImportContext);

  if (!context) {
    throw new Error("useImportContext must be used within a ImportContext.Provider");
  }

  return context;
};
