import styled from "@emotion/styled";

export const HeaderWrapper = styled.div<{ maxWidthLaptop: number }>`
  width: 100%;
  background-color: transparent;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 1px 12px rgba(0, 0, 0, 0.08);

  @media only screen and (max-width: 1100px) {
    display: grid;
    justify-content: center;
  }
`;

export const StyledHeader = styled.div<{ maxWidthLaptop: number }>`
  max-width: 1366px;
  width: 100%;
  font-family: ${props => props.theme.other.font.fontFamily};
  font-style: ${props => props.theme.other.font.fontStyle};
  font-weight: ${props => props.theme.other.font.header2.fontWeight};
  padding-top: ${props => props.theme.other.font.header2.paddingTop};
  padding-bottom: ${props => props.theme.other.font.header2.paddingBottom};
  font-size: ${props => props.theme.other.font.header2.fontSize};
  line-height: ${props => props.theme.other.font.header2.lineHeight};
  color: ${props => props.theme.other.primaryColor};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 13px;
  @media only screen and (max-width: 1440px) {
    max-width: ${props => props.maxWidthLaptop}px;
  }
  @media only screen and (max-width: 1366px) {
    width: calc(100% - 20px);
  }
`;

export const MenuHeaderWrapper = styled.div`
  width: 100%;
  min-height: 70px;
  padding-top: 25px;
`;
export const TabWrapper = styled.div<{ maxWidthLaptop: number }>`
  max-width: 1366px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  @media only screen and (max-width: 1440px) {
    width: ${props => props.maxWidthLaptop}px;
    flex-wrap: wrap;
    justify-content: flex-start;
    & > div:first-of-type {
      flex: 1;
      width: 100%;
    }
  }

  @media only screen and (max-width: 1000px) {
    & > div:nth-of-type(2) {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }
`;
