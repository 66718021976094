import { useQueries } from "@tanstack/react-query";
import { QUERY_KEYS } from "constants/queryKeys";
import { getSupplierById } from "modules/supplierDetails/api/getSupplierById";

export const useGetSupplierListQueries = ({ supplierIds = [] }: { supplierIds: string[] }) =>
  useQueries({
    queries: supplierIds.map(supplierId => {
      return {
        queryKey: [QUERY_KEYS.supplier_by_id, { supplierId }],
        queryFn: () => getSupplierById(supplierId)
      };
    })
  });
