import { useQuery } from "@tanstack/react-query";
import { ErrorResponse } from "App";
import { QUERY_KEYS } from "constants/queryKeys";
import { errorModal } from "modules/error/ErrorHandlingModals";

import { getAccountListing } from "../apis/GetAccountListing";

const useGetAccountQuery = (page: number, limit: number, enabled: boolean, accountIds: string[] = []) => {
  const query = useQuery({
    queryKey: [QUERY_KEYS.accounts_listing, page, limit],
    queryFn: () => getAccountListing(page, limit, accountIds),
    enabled: enabled,
    onError: (error: ErrorResponse) => errorModal(error),
    useErrorBoundary: true
  });
  return query;
};
export default useGetAccountQuery;
