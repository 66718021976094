import React from "react";

export const InfoIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M6 0C2.68617 0 0 2.68617 0 6C0 9.31383 2.68617 12 6 12C9.31383 12 12 9.31383 12 6C12 2.68617 9.31383 0 6 0ZM6 2.34783C6.43226 2.34783 6.78261 2.69817 6.78261 3.13043C6.78261 3.5627 6.43226 3.91304 6 3.91304C5.56774 3.91304 5.21739 3.5627 5.21739 3.13043C5.21739 2.69817 5.56774 2.34783 6 2.34783ZM7.04348 9.3913H6.52174H5.47826H4.95652V8.86957H5.47826V5.47826H4.95652V4.95652H5.47826H6.52174V5.47826V8.86957H7.04348V9.3913Z"
      fill="currentColor"
    />
  </svg>
);
