import { useEffect } from "react";

import { Button } from "@mantine/core";
import { useCounter } from "@mantine/hooks";
import { ModalBodyMessage, ModalFooter, ModalTitle } from "ui";
import Modal from "ui/overlays/Modal";

import { useDeleteCustomField } from "../hooks";

type DeleteCustomFieldModalProps = {
  accountId: string;
  id: string | null;
  onClose: () => void;
};

export const DeleteCustomFieldModal: React.FC<DeleteCustomFieldModalProps> = ({ accountId, id, onClose }) => {
  const { mutate, isLoading } = useDeleteCustomField({ accountId });

  const [count, { decrement, set }] = useCounter(3, { min: 0, max: 3 });

  useEffect(() => {
    if (id && count > 0) {
      setTimeout(decrement, 1000);
    }

    if (!id) {
      return set(3);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, count]);

  const onDelete = () => {
    if (id) {
      mutate({ customFieldId: id });
      onClose();
    }
  };

  return (
    <Modal handleClose={onClose} opened={!!id} withCloseButton>
      <ModalTitle $type="danger" centered>
        Delete custom field
      </ModalTitle>
      <ModalBodyMessage centered>
        Deleting a custom field will remove all data for that custom field in the system. <br /> Are you sure you want
        to proceed with deleting the field?
      </ModalBodyMessage>
      <ModalFooter>
        <Button color="danger.8" variant="outline" disabled={isLoading} onClick={onClose}>
          No
        </Button>
        <Button color="danger.8" loading={isLoading} disabled={count > 0} onClick={onDelete}>
          {count > 0 && count} Yes
        </Button>
      </ModalFooter>
    </Modal>
  );
};
