import { useContext } from "react";

import { ActionIcon, Menu } from "@mantine/core";
import { ImportInterface } from "modules/imports/types";
import { CurrentUserContext } from "modules/login";
import { MenuHamburgerIcon } from "ui/icons";

import { ExternalUserImportMenu } from "./ExternalUserImportMenu";
import { InternalUserImportMenu } from "./InternalUserImportMenu";
import { CenteredCell } from "./styles";

export const ImportMenu: React.FC<ImportInterface> = props => {
  const {
    userDetails: { userType }
  } = useContext(CurrentUserContext);

  const menu = userType === "INTERNAL" ? <InternalUserImportMenu {...props} /> : <ExternalUserImportMenu {...props} />;

  return (
    <Menu withinPortal position="bottom-end">
      <Menu.Target>
        <CenteredCell>
          <ActionIcon variant="transparent" c="gray.8">
            <MenuHamburgerIcon />
          </ActionIcon>
        </CenteredCell>
      </Menu.Target>
      <Menu.Dropdown>{menu}</Menu.Dropdown>
    </Menu>
  );
};
