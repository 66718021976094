import * as React from "react";

export const ViewIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={props.width ?? "19"} height={props.height ?? "13"} viewBox="0 0 19 13" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.20464 6.50002C3.66247 8.79359 5.95621 10.9 9.49952 10.9C13.0428 10.9 15.3366 8.79359 16.7944 6.50002C15.3366 4.20646 13.0428 2.10002 9.49952 2.10002C5.95621 2.10002 3.66247 4.20646 2.20464 6.50002ZM0.985023 6.19133C2.53925 3.60095 5.20131 0.900024 9.49952 0.900024C13.7977 0.900024 16.4598 3.60095 18.014 6.19133L18.1992 6.50002L18.014 6.80872C16.4598 9.3991 13.7977 12.1 9.49952 12.1C5.20131 12.1 2.53925 9.3991 0.985023 6.80872L0.799805 6.50002L0.985023 6.19133ZM9.49951 3.40002C7.78743 3.40002 6.39951 4.78794 6.39951 6.50002C6.39951 8.21211 7.78743 9.60002 9.49951 9.60002C11.2116 9.60002 12.5995 8.21211 12.5995 6.50002C12.5995 4.78794 11.2116 3.40002 9.49951 3.40002Z"
      fill="currentColor"
    />
  </svg>
);
