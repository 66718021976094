import { FC } from "react";

import { Grid } from "@mantine/core";
import { MILLIONS_OF_DOLLARS } from "modules/analytics/components/utils";
import { TAnalyticsData } from "types";
import Card from "ui/data-display/Card";
import { CashLogo, IndustryLogo, PriceTagIcon } from "ui/icons";
import { prettyMoney, prettyNumber } from "utils/formatting.utils";

import { StyledCardsWrapper } from "../supplierDetails.styles";

type Props = {
  summary: TAnalyticsData;
};

export const SupplierDetailsCards: FC<Props> = ({ summary }) => {
  const { spend, co2e, transactions } = summary;
  return (
    <StyledCardsWrapper>
      <Grid gutter={16} align="stretch">
        <Grid.Col lg={4} md={12}>
          <Card
            showLabel={false}
            label=""
            labelIcon={null}
            cardName="Spend"
            cardUnit={prettyMoney(spend)}
            cardIcon={<CashLogo />}
            cardDescription={`Across ${transactions} transactions`}
            backgroundColor="transparent"
          />
        </Grid.Col>
        <Grid.Col lg={4} md={12}>
          <Card
            showLabel={false}
            label=""
            labelIcon={null}
            cardName="CO₂e Emissions"
            cardUnit={prettyNumber(co2e)}
            cardIcon={<IndustryLogo />}
            cardDescription="Tons of CO₂ equivalent emissions"
            backgroundColor="#eeeeee"
          />
        </Grid.Col>
        <Grid.Col lg={4} md={12}>
          <Card
            showLabel={false}
            label=""
            labelIcon={null}
            cardName="Carbon Intensity"
            cardUnit={prettyNumber(co2e / (spend / MILLIONS_OF_DOLLARS))}
            cardIcon={<PriceTagIcon />}
            cardDescription="Tons of CO₂ equivalent emissions per $1M USD"
            backgroundColor="#eeeeee"
          />
        </Grid.Col>
      </Grid>
    </StyledCardsWrapper>
  );
};
