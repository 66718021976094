import { useEffect, useState } from "react";

import { useCurrentUserContext } from "modules/login";
import moment from "moment";
import { ModalBodyMessage, ModalTitle } from "ui";
import Loader from "ui/feedback/Loader";
import Pagination from "ui/navigation/Pagination";
import Modal from "ui/overlays/Modal";

import { useGetImportErrorListQuery } from "../hooks";
import { ImportInterface } from "../types";
import { ImportErrorList } from "./ImportErrorList";
import { ImportErrorName, ImportErrorText } from "./importErrorModal.styles";

type ImportErrorModalProps = {
  detail: ImportInterface;
  handleClose: () => void;
  opened: boolean;
};

const defaultLimit = 3;

export const ImportErrorModal: React.FC<ImportErrorModalProps> = ({ detail, opened, handleClose }) => {
  const [page, setPage] = useState(0);

  const { userDetails } = useCurrentUserContext();

  const onChange = (value: number) => {
    setPage(value - 1);
  };

  const enabled = userDetails?.userType === "INTERNAL" && !!detail?.id;

  const { data, isLoading, remove } = useGetImportErrorListQuery(
    { importId: detail.id, params: { limit: defaultLimit, page } },
    { enabled, keepPreviousData: true }
  );

  const hasErrors = !!data?.data.length;

  useEffect(() => {
    return remove;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const uploadedDate = moment(detail.createdAt).utc().format("MM/DD/YYYY h:mm A");

  const indexFactor = page * defaultLimit + 1;

  const content = (
    <>
      <ModalTitle>Import Errors</ModalTitle>
      <ModalBodyMessage>
        <ImportErrorName>
          <b>Filename: {detail.filename}</b>
          <br /> <span>Uploaded: {uploadedDate} UTC</span>
        </ImportErrorName>
      </ModalBodyMessage>
      <ImportErrorText>Errors:</ImportErrorText>
      {hasErrors ? (
        <>
          <ImportErrorList data={data.data} indexFactor={indexFactor} />
          <Pagination
            activePage={page + 1}
            setPage={onChange}
            centered
            totalRecords={data?.meta?.totalCount || 0}
            siblings={1}
            limit={defaultLimit}
          />
        </>
      ) : (
        <ImportErrorName>Upload rejected</ImportErrorName>
      )}
    </>
  );

  const loader = <Loader />;

  return (
    <Modal opened={opened} handleClose={handleClose} withCloseButton>
      {isLoading ? loader : content}
    </Modal>
  );
};
