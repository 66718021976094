import { UserRoles } from "modules/users";
import { AddIcon, CancelIcon, EditIcon, TrashIcon } from "ui/icons";

import { IUserTableMenuConfig, TUserTableMenuHandler, UserListingInterface } from "../userTable/userTable.types";

export const generateUserTableMenuConfig = (
  userDetails: { userId: string; userRole: keyof typeof UserRoles },
  handlers: TUserTableMenuHandler[],
  userListing: UserListingInterface
): IUserTableMenuConfig[] => {
  const isOwner = userListing.id !== userDetails.userId;

  const isInternalAdmin = userDetails.userRole === UserRoles.INTERNAL_ADMIN.value;

  return [
    {
      label: "Edit User",
      icon: <EditIcon />,
      visible: true,
      handler: handlers[0]
    },
    {
      label: "Delete User",
      icon: <TrashIcon width="15.72" height="20" />,
      visible: isOwner,
      handler: handlers[1]
    },
    {
      label: userListing.status === "ACTIVE" ? "Suspend User" : "Activate User",
      icon: <CancelIcon />,
      visible: isOwner,
      handler: handlers[2]
    },
    {
      label: "Add to Account",
      icon: <AddIcon width="16" height="16" />,
      visible: true,
      handler: handlers[3]
    },
    {
      label: "Feature Flags",
      icon: <EditIcon />,
      visible: isInternalAdmin,
      handler: handlers[4]
    }
  ];
};
