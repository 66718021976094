import styled from "@emotion/styled";
import * as ColorPalette from "constants/colorPalette";

export const BuyspaceFilterTileContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
export const FilterItemContainer = styled.span`
  gap: 5px;
`;

export const FilterTileContainer = styled(FilterItemContainer)`
  padding: 4px 11px;
  margin: 3.5px 5px 3.5px 6px;
`;

export const BuyspaceFilterItemContainer = styled.span`
  & > *:not(:last-child) {
    margin-right: 5px;
  }
`;

export const BuyspaceText = styled.span`
  font-family: ${props => props.theme.other.font.fontFamily};
  font-style: ${props => props.theme.other.font.fontStyle};
  font-size: ${props => props.theme.other.font.fontSize} !important;
  line-height: ${props => props.theme.other.font.lineHeight};
`;

export const Description = styled(BuyspaceText)`
  color: ${ColorPalette.primaryColor};
  font-weight: 500;
`;

export const Operator = styled(BuyspaceText)`
  color: ${ColorPalette.warningColor};
  font-weight: 500;
`;

export const Value = styled(BuyspaceText)`
  font-weight: 400;
  color: ${props => props.theme.other.monochromeColor[1]};
  text-wrap: wrap;
`;
