import styled from "@emotion/styled";

export const StyledAutocompleteComponent = styled.div`
  width: 100%;

  .mantine-InputWrapper-root {
    width: 100%;
  }
`;

export const AutocompleteLabel = styled.div`
  font-size: 1.25rem;
  line-height: 1.5rem;
  font-weight: 700;
  color: #5c5c5c;
  margin-bottom: 0.5rem;
`;

export const AutocompleteInputWrapper = styled.div`
  width: 100%;
  display: flex;
  column-gap: 1rem;
  align-items: center;
`;
