import * as React from "react";

export const MSWindowLogo = () => (
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_879_59953)">
      <path d="M15.9375 14.0625H30V0.9375C30 0.42 29.58 0 29.0625 0H15.9375V14.0625Z" fill="#4CAF50" />
      <path d="M14.0625 14.0625V0H0.9375C0.42 0 0 0.42 0 0.9375V14.0625H14.0625Z" fill="#F44336" />
      <path d="M14.0625 15.9375H0V29.0625C0 29.58 0.42 30 0.9375 30H14.0625V15.9375Z" fill="#2196F3" />
      <path d="M15.9375 15.9375V30H29.0625C29.58 30 30 29.58 30 29.0625V15.9375H15.9375Z" fill="#FFC107" />
    </g>
    <defs>
      <clipPath id="clip0_879_59953">
        <rect width="30" height="30" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
