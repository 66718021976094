import styled from "@emotion/styled";
import { Button, ButtonProps, createPolymorphicComponent } from "@mantine/core";

const _RemoveButton = styled(Button)<ButtonProps>`
  background-color: ${({ variant, theme }) =>
    !variant || variant === "filled" ? theme.other.dangerColor : "transparent"};
  border-color: ${({ theme }) => theme.other.dangerColor};
  color: ${({ variant, theme }) => (variant !== "outline" ? theme.other.monochromeColor[6] : theme.other.dangerColor)};
  font-size: 1rem;

  :hover {
    filter: brightness(105%);
  }
`;

export const RemoveButton = createPolymorphicComponent<"button", ButtonProps>(_RemoveButton);
