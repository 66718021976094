import styled from "@emotion/styled";
import { CheckboxGroupProps, CheckboxProps, Checkbox as MT_Checkbox } from "@mantine/core";
import { theme } from "constants/theme";
import CheckboxChecked from "ui/icons/CheckboxChecked.png";
import CheckboxCheckedRed from "ui/icons/CheckboxCheckedRed.png";
import CheckboxUnchecked from "ui/icons/CheckboxUnchecked.png";

interface CheckBoxListingData {
  label: string;
  value: string;
}
interface GroupCheckboxInterface {
  data: CheckBoxListingData[];
  value: string[];
  setValue: ((data: string[]) => void) | React.Dispatch<React.SetStateAction<string[]>>;
  groupMargin?: number;
  offset?: number;
  showSelectAll?: boolean;
  showPrimaryChecked?: boolean;
  spacing?: number;
  radioMode?: boolean;
  nonArray?: boolean;
  orientation?: CheckboxGroupProps["orientation"];
}
interface SelectAllCheckboxProps {
  header: string;
  value: boolean;
  smallHeader?: boolean;
  showPrimaryChecked?: boolean;
  onChange?: CheckboxProps["onChange"];
}

// @todo These components should be refactored
export const SelectAllCheckbox: React.FC<SelectAllCheckboxProps> = ({
  header,
  value,
  showPrimaryChecked = true,
  smallHeader = false,
  ...props
}) => {
  const CheckboxIcon: CheckboxProps["icon"] = () => <></>;
  return (
    <MT_Checkbox
      label={header}
      checked={value}
      icon={CheckboxIcon}
      {...props}
      styles={{
        label: {
          fontFamily: "Inter",
          fontStyle: "normal",
          fontWeight: smallHeader ? 400 : 700,
          fontSize: "1rem",
          lineHeight: smallHeader ? "17px" : "24px",
          paddingLeft: smallHeader ? "7px" : "12px",
          paddingBottom: smallHeader ? "8px" : "0px",
          color: "#5C5C5C"
        },
        input: {
          backgroundRepeat: "no-repeat",
          cursor: "pointer",
          background: `url(${CheckboxUnchecked})`,
          backgroundSize: "20px 20px",
          border: "none !important",
          borderRadius: "2px",
          width: "20px",
          height: "20px",
          "&:checked": {
            backgroundRepeat: "no-repeat",
            background: `url(${showPrimaryChecked ? CheckboxChecked : CheckboxCheckedRed})`,
            backgroundSize: "20px 20px",
            border: "none !important"
          },
          icon: {
            display: "none !important"
          }
        }
      }}
    />
  );
};
const CheckBoxGroupWrapper = styled.div<{ groupMargin?: number }>`
  margin-left: ${props => props.groupMargin}px;
`;
const GroupCheckbox: React.FC<GroupCheckboxInterface> = ({
  showPrimaryChecked = true,
  data,
  value,
  spacing = 7,
  offset = 15,
  groupMargin = 30,
  setValue,
  radioMode,
  nonArray,
  orientation = "vertical"
}) => {
  const CheckboxIcon: CheckboxProps["icon"] = () => <></>;

  const radioModeOnChange = (value: string[]) => {
    const lastChecked = value.at(value.length - 1);
    const values = [lastChecked]?.filter(i => i) as string[];
    setValue(nonArray ? (values[0] as any) : values);
  };

  const onChange = radioMode ? radioModeOnChange : setValue;

  return (
    <div>
      <CheckBoxGroupWrapper groupMargin={groupMargin}>
        <MT_Checkbox.Group
          offset={offset}
          spacing={spacing}
          value={value}
          onChange={onChange}
          orientation={orientation}
        >
          {data?.map((checkBoxElement: CheckBoxListingData, key: number) => {
            return (
              <MT_Checkbox
                key={key}
                value={checkBoxElement.value}
                label={checkBoxElement.label}
                icon={CheckboxIcon}
                styles={{
                  label: {
                    fontFamily: "Inter",
                    fontStyle: "normal",
                    fontWeight: 400,
                    fontSize: theme?.other?.font.fontSize,
                    lineHeight: theme?.other?.font.lineHeight,
                    color: "#5C5C5C",
                    paddingLeft: "7px",
                    marginTop: "auto",
                    marginBottom: "auto"
                  },
                  input: {
                    backgroundRepeat: "no-repeat",
                    cursor: "pointer",
                    background: `url(${CheckboxUnchecked})`,
                    backgroundSize: "20px 20px",
                    border: "none !important",
                    borderRadius: "2px",
                    width: "20px",
                    height: "20px",
                    "&:checked": {
                      backgroundRepeat: "no-repeat",
                      background: `url(${showPrimaryChecked ? CheckboxChecked : CheckboxCheckedRed})`,
                      backgroundSize: "20px 20px",
                      border: "none !important"
                    },
                    icon: {
                      display: "none !important"
                    }
                  }
                }}
              />
            );
          })}
        </MT_Checkbox.Group>
      </CheckBoxGroupWrapper>
    </div>
  );
};
export default GroupCheckbox;
