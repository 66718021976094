import { ErrorBoundary } from "react-error-boundary";

import { getCoreRowModel, useReactTable } from "@tanstack/react-table";
import ErrorHandler from "modules/error/ErrorHandler";
import Pagination from "ui/navigation/Pagination";
import { Table } from "ui/table";

import { AccountListing } from "../types";
import { TableWrapper } from "./AccountListinTable.styles";
import { AccountListingTableWrapper } from "./AccountListingTable.styles";
import { columns } from "./columns";

type AccountListingTableProps = {
  activePage: number;
  data: AccountListing[];
  setPage: React.Dispatch<React.SetStateAction<number>>;
  totalRecords: number | undefined;
};

const defaultLimit = 10;

export const AccountListingTable = ({ data, activePage, setPage, totalRecords }: AccountListingTableProps) => {
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel()
  });

  return (
    <ErrorBoundary FallbackComponent={ErrorHandler}>
      <TableWrapper>
        <AccountListingTableWrapper>
          <Table<AccountListing> table={table} />
        </AccountListingTableWrapper>
      </TableWrapper>
      <Pagination activePage={activePage} setPage={setPage} totalRecords={totalRecords} limit={defaultLimit} />
    </ErrorBoundary>
  );
};
