import axiosInstance from "services/AxiosInstance";
import { QuestionnaireStatus, ResponseType, TQuestionnaire } from "types";

export type TGetQuestionnaireListServiceArgs = {
  accountId: string;
  apiParams?: Partial<{
    includeDefaults: boolean;
    status: QuestionnaireStatus[];
  }>;
};

export const getQuestionnaireListService = async ({
  accountId,
  apiParams
}: TGetQuestionnaireListServiceArgs): Promise<ResponseType<TQuestionnaire[]>> => {
  const response = await axiosInstance.get(`questionnaire/account/${accountId}`, { params: apiParams });
  return response.data;
};
