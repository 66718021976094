import { UserRoles } from "modules/users/types/UserTypesEnum";

export const roleStatusMap = {
  [UserRoles["ACCOUNT_ADMIN"].value]: {
    label: "Account Admin"
  },
  [UserRoles["ACCOUNT_CATEGORY_MANAGER"].value]: {
    label: "Category Manager"
  },
  [UserRoles["INTERNAL_ADMIN"].value]: {
    label: "Super Admin"
  },
  [UserRoles["INTERNAL_ANALYST"].value]: {
    label: "Analyst"
  }
};
