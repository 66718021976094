import axiosInstance from "services/AxiosInstance";

export const getAccounts = async () => {
  const response = await axiosInstance.get("accounts");
  return response.data;
};

export interface AddUserToAccountInterface {
  accountId: string;
  userId: string;
  userRole: string;
}

export const addUserToAccount = async (payLoad: AddUserToAccountInterface) => {
  const response = await axiosInstance.post(`accounts/${payLoad.accountId}/users`, payLoad);
  return response.data;
};
