import axiosInstance from "services/AxiosInstance";

export interface PutRejectAnalysis {
  reason: string;
  importId: string;
}

export const putRejectAnalysisService = async ({ reason, importId }: PutRejectAnalysis) => {
  const response = await axiosInstance.put(`/imports/${importId}/analysis/rejection`, { reason });
  return response.data;
};
