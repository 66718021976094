import styled from "@emotion/styled";

export const CenterCell = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SupplierCell = styled.a`
  color: ${props => props.theme.other.monochromeColor[0]};
  cursor: pointer;
  text-decoration: none;
  :hover {
    text-decoration: underline;
  }
`;
