import { RoutesConfig } from "./types";

export const routesConfig: RoutesConfig[] = [
  // INTERNAL
  {
    name: "Imports",
    path: "/imports",
    allowRoles: ["INTERNAL_ADMIN", "INTERNAL_ANALYST"],
    restrictFeatures: []
  },
  {
    name: "Transactions",
    path: "/transactions",
    allowRoles: ["INTERNAL_ADMIN", "INTERNAL_ANALYST"],
    restrictFeatures: []
  },
  {
    name: "Suppliers",
    path: "/suppliers",
    allowRoles: ["INTERNAL_ADMIN", "INTERNAL_ANALYST"],
    restrictFeatures: []
  },
  {
    name: "Commodities",
    path: "/commodities",
    allowRoles: ["INTERNAL_ADMIN", "INTERNAL_ANALYST"],
    restrictFeatures: []
  },
  // EXTERNAL & Others
  {
    name: "Analytics",
    path: "/analysis/:accountId",
    allowRoles: ["ACCOUNT_ADMIN", "ACCOUNT_CATEGORY_MANAGER"],
    restrictFeatures: []
  },
  {
    name: "Imports",
    path: "/imports/:accountId",
    allowRoles: ["ACCOUNT_ADMIN", "ACCOUNT_CATEGORY_MANAGER"],
    restrictFeatures: []
  },
  {
    name: "Transactions",
    path: "/transactions/:accountId",
    allowRoles: ["ACCOUNT_ADMIN", "ACCOUNT_CATEGORY_MANAGER"],
    restrictFeatures: []
  },
  {
    name: "Suppliers",
    path: "/suppliers/:accountId",
    allowRoles: ["ACCOUNT_ADMIN", "ACCOUNT_CATEGORY_MANAGER"],
    restrictFeatures: []
  },
  {
    name: "Commodities",
    path: "/commodities/:accountId",
    allowRoles: ["ACCOUNT_ADMIN", "ACCOUNT_CATEGORY_MANAGER"],
    restrictFeatures: []
  }
];
