import { ColumnDef } from "@tanstack/react-table";
import { LinkComponent } from "components";
import { formatDateString } from "utils";
import { prettyMoney } from "utils/formatting.utils";

import { TransactionMenu, TransactionSupplierCell } from "./components";
import { ITransactions } from "./transactions.types";

export const columns: ColumnDef<ITransactions, string>[] = [
  {
    header: "Date",
    accessorKey: "date",
    enableSorting: false,
    size: 80,
    cell: props => formatDateString(props.getValue())
  },
  {
    header: "Supplier",
    accessorKey: "supplierName",
    enableSorting: false,
    enableResizing: true,
    size: 400,
    cell: props => <TransactionSupplierCell {...props.row.original} />
  },
  {
    header: "Commodity",
    accessorKey: "itemName",
    enableSorting: false,
    size: 280,
    cell: props => (
      <LinkComponent to={`/commodity/${props.row.original.itemId}`}>{props.row.original.itemName}</LinkComponent>
    )
  },
  {
    header: "Cost",
    accessorKey: "totalCost",
    enableSorting: true,
    size: 80,
    cell: props => prettyMoney(props.getValue())
  },
  {
    header: "CO₂e",
    accessorKey: "co2e",
    enableSorting: true,
    cell: props => props.getValue() ?? "0.0",
    size: 80
  },
  {
    header: "Method",
    accessorKey: "method",
    enableSorting: false,
    size: 60
  },
  {
    header: "Source",
    accessorKey: "source",
    enableSorting: false,
    size: 140,
    cell: props => props.getValue() || "-"
  },
  {
    header: "Action",
    id: "action",
    enableSorting: false,
    size: 50,
    cell: props => <TransactionMenu transaction={props.row.original} />
  }
];
