import { Button } from "@mantine/core";
import { ChevronRight } from "ui/icons";
import PrivateLayout from "ui/layouts/PrivateLayout";

import {
  QuestionnaireDetailCard,
  QuestionnaireResponseCenterer,
  QuestionnaireResponseHeader,
  QuestionnairesContainer
} from "../../questionnaire.styles";

export const QCPreviewLayout: React.FC<{
  children: React.ReactNode;
  description: string;
  isLoading: boolean;
  onClickReturn: () => void;
  onSave: () => void;
  title: string;
}> = ({ children, title, description, onClickReturn, onSave, isLoading }) => {
  return (
    <PrivateLayout showSubHeader={false} preview>
      <QuestionnairesContainer>
        <QuestionnaireResponseCenterer>
          <QuestionnaireDetailCard>
            <QuestionnaireResponseHeader>
              <h1>{title}</h1>
              <p>
                {description && (
                  <>
                    <b>Description:</b> <span> {description}</span>
                  </>
                )}
              </p>
              {children}
            </QuestionnaireResponseHeader>
            <Button
              variant="subtle"
              leftIcon={<ChevronRight style={{ rotate: "180deg" }} />}
              pos="absolute"
              top={16}
              right={16}
              onClick={onClickReturn}
            >
              Edit Again
            </Button>
            <QuestionnaireResponseCenterer>
              <Button type="button" loading={isLoading} onClick={onSave}>
                Save
              </Button>
            </QuestionnaireResponseCenterer>
          </QuestionnaireDetailCard>
        </QuestionnaireResponseCenterer>
      </QuestionnairesContainer>
    </PrivateLayout>
  );
};
