import axiosInstance from "services/AxiosInstance";
import { TAnalyticsSummaryResponse } from "types";

export const getAnalyticsSummaryService = async (
  accountId: string,
  start = "1970-01-01T00:00:00Z",
  end = "2030-01-01T00:00:00Z",
  buyspaceId: string | null,
  interval = "month"
): Promise<TAnalyticsSummaryResponse> => {
  const params = {
    start,
    end,
    interval,
    buyspaceId
  };
  const response = await axiosInstance.get(`/analytics/${accountId}/summary`, { params });
  return response.data;
};
