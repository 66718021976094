import { FC, Fragment, useCallback } from "react";

import { Menu } from "@mantine/core";
import * as ColorPalette from "constants/colorPalette";
import { theme } from "constants/theme";
import * as TypographyProperties from "constants/typography";
import { useCurrentUserContext } from "modules/login";
import { UserRoles } from "modules/users";
import { RoleTile } from "ui";
import { XIcon } from "ui/icons";

import { roleStatusMap } from "../configs/userTableAccountConfig";
import { AndMoreButton, StyleMenuWrapper, TableTextWrapper } from "./userTable.styles";
import { Accounts, UserListingInterface } from "./userTable.types";

type UserTableAccountsProps = {
  element: UserListingInterface;
  handleAccountCross?: (accountDetails: Accounts) => void;
};

export const UserTableAccounts: FC<UserTableAccountsProps> = ({ element, handleAccountCross }) => {
  const {
    userDetails: { userRole, userId }
  } = useCurrentUserContext();

  const isAccountCategoryManager = userRole === UserRoles.ACCOUNT_CATEGORY_MANAGER.value;
  const isAccountAdmin = userRole === UserRoles.ACCOUNT_ADMIN.value;

  const determineRemoveUserPermission = useCallback(
    (accountDetails: Accounts) => {
      if (isAccountAdmin && accountDetails.role.includes("INTERNAL")) {
        return false;
      }
      if (isAccountCategoryManager) {
        return false;
      }
      return true;
    },
    [isAccountCategoryManager, isAccountAdmin]
  );

  return (
    <TableTextWrapper>
      {element?.accounts?.length !== 0 &&
        element?.accounts?.map((accountDetails, index) => {
          if (element.accounts && element.accounts.length > 2 && index === 2) {
            return (
              <StyleMenuWrapper key={"account" + index}>
                <Menu
                  transition="scale"
                  offset={0}
                  shadow="md"
                  zIndex={2}
                  styles={{
                    itemLabel: {
                      fontFamily: `${TypographyProperties.fontFamily} !important`,
                      fontStyle: TypographyProperties.fontStyle,
                      fontWeight: 700,
                      fontSize: `${theme.other?.font.fontSize}`,
                      lineHeight: `${theme.other?.font.lineHeight}`,
                      color: `${ColorPalette.monochromeColor[1]} !important`,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center"
                    },
                    dropdown: {
                      background: ColorPalette.monochromeColor[6],
                      boxShadow: " 0px 6px 20px rgba(0, 0, 0, 0.1)",
                      borderRadius: "4px",
                      minWidth: "207px",
                      marginTop: "10px",
                      maxHeight: "300px",
                      overflowY: "auto",
                      overflowX: "hidden"
                    },
                    item: {
                      cursor: "default",
                      "&[data-hovered]": {
                        background: ColorPalette.monochromeColor[6]
                      }
                    },
                    divider: {
                      borderColor: ColorPalette.monochromeColor[4]
                    }
                  }}
                >
                  <Menu.Target>
                    <TableTextWrapper
                      style={{
                        cursor: "pointer",
                        width: "fit-content",
                        margin: "0px !important"
                      }}
                    >
                      <AndMoreButton>And More</AndMoreButton>
                    </TableTextWrapper>
                  </Menu.Target>
                  <Menu.Dropdown>
                    {element?.accounts?.map((accountDetails, accountIndex) => {
                      if (accountIndex > 1) {
                        const isUserMyself = accountDetails.userId === userId;
                        const isUserRemovePermitted = determineRemoveUserPermission(accountDetails);
                        const isRemoveButtonShown = !isUserMyself && isUserRemovePermitted;

                        return (
                          <Fragment key={accountIndex + "roleItem"}>
                            <Menu.Item key={accountIndex}>
                              <div>
                                {accountDetails.accountName + " "}
                                <span
                                  style={{
                                    fontWeight: 400
                                  }}
                                >
                                  {roleStatusMap[accountDetails.role].label}
                                </span>
                              </div>
                              {isRemoveButtonShown && (
                                <XIcon
                                  width="8.8"
                                  height="8.8"
                                  style={{
                                    marginLeft: "10px",
                                    cursor: "pointer"
                                  }}
                                  onClick={() => handleAccountCross?.(accountDetails)}
                                />
                              )}
                            </Menu.Item>
                            {element.accounts && accountIndex !== element.accounts.length - 1 && <Menu.Divider />}
                          </Fragment>
                        );
                      }
                      return null;
                    })}
                  </Menu.Dropdown>
                </Menu>
              </StyleMenuWrapper>
            );
          } else if (index <= 1) {
            const isRemoveButtonShown = determineRemoveUserPermission(accountDetails);

            return (
              <Fragment key={"roletile" + index}>
                <RoleTile
                  accountDetails={accountDetails}
                  handleCloseButton={handleAccountCross}
                  closeButton={isRemoveButtonShown}
                />
              </Fragment>
            );
          }
          return null;
        })}
    </TableTextWrapper>
  );
};
