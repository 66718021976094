import { useMutation, useQueryClient } from "@tanstack/react-query";
import { QUERY_KEYS } from "constants/queryKeys";
import { putUpdateAnalysisService } from "modules/imports";

export const useUpdateAnalysisImportMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: putUpdateAnalysisService,
    mutationKey: [QUERY_KEYS.update_analysis_import],
    onSuccess: () => {
      queryClient.refetchQueries({ queryKey: [QUERY_KEYS.imports_listing] });
    }
  });
};
