import * as React from "react";

export const CancelIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
    <g clipPath="url(#clip0_8195_2922)">
      <path
        d="M8 0C3.58685 0 0 3.58685 0 8C0 12.4131 3.58685 16 8 16C12.4131 16 16 12.4131 16 8C16 3.58685 12.4131 0 8 0ZM8 15.1925C4.03756 15.1925 0.807512 11.9624 0.807512 8C0.807512 4.03756 4.03756 0.807512 8 0.807512C11.9624 0.807512 15.1925 4.03756 15.1925 8C15.1925 11.9624 11.9624 15.1925 8 15.1925Z"
        fill="currentColor"
      />
      <path
        d="M8.56339 7.96293L10.9108 5.61551C11.061 5.46527 11.061 5.20236 10.9108 5.05213C10.7606 4.90189 10.4977 4.90189 10.3474 5.05213L8.00001 7.39955L5.65259 5.05213C5.50235 4.90189 5.23944 4.90189 5.08921 5.05213C4.93897 5.20236 4.93897 5.46527 5.08921 5.61551L7.43663 7.96293L5.08921 10.2916C4.93897 10.4418 4.93897 10.7047 5.08921 10.8549C5.16433 10.9301 5.277 10.9676 5.3709 10.9676C5.46479 10.9676 5.57747 10.9301 5.65259 10.8549L8.00001 8.50753L10.3474 10.8549C10.4225 10.9301 10.5352 10.9676 10.6291 10.9676C10.723 10.9676 10.8357 10.9301 10.9108 10.8549C11.061 10.7047 11.061 10.4418 10.9108 10.2916L8.56339 7.96293Z"
        fill="currentColor"
      />
    </g>
  </svg>
);
