import { useQuery, UseQueryOptions, UseQueryResult } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { QUERY_KEYS } from "constants/queryKeys";
import { ResponseError, ResponseType, TImportTransactionMeta } from "types";

import { getImportTransactionMetaService } from "../api";

type UseGetImportTransactionMetaQueryHook = (
  apiArgs: { importId: string },
  options?: UseQueryOptions<
    ResponseType<{ columns: TImportTransactionMeta[] }>,
    AxiosError<ResponseError>,
    ResponseType<{ columns: TImportTransactionMeta[] }>,
    [string, string]
  >
) => UseQueryResult<ResponseType<{ columns: TImportTransactionMeta[] }>, AxiosError<ResponseError>>;

export const useGetImportTransactionMetaQuery: UseGetImportTransactionMetaQueryHook = ({ importId }, options) => {
  const query = useQuery({
    queryKey: [QUERY_KEYS.get_import_transaction_meta, importId],
    queryFn: () => getImportTransactionMetaService(importId),
    ...options
  });
  return query;
};
