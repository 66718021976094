import { Space } from "@mantine/core";

import { QCFormInstance, QCFormValues } from "../../questionnaire.types";
import { QCDescriptionInput, QCTitleInput, QuestionnaireCreatorCard } from "./questionnaireCreator.styles";

type QCMainFormProps = {
  form: QCFormInstance<QCFormValues>;
};

export const QCMainForm: React.FC<QCMainFormProps> = ({ form }) => {
  return (
    <QuestionnaireCreatorCard>
      <QCTitleInput {...form.getInputProps("title")} placeholder="QUESTIONNAIRE TITLE*" />
      <Space h="xl" />
      <QCDescriptionInput {...form.getInputProps("description")} placeholder="Questionnaire Description*" />
    </QuestionnaireCreatorCard>
  );
};
