import styled from "@emotion/styled";
import { Container } from "@mantine/core";

export const HeaderContentContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  max-width: 1366px;
  width: 100%;
  padding-left: 0px;
  padding-right: 0px;
  @media only screen and (max-width: 1440px) {
    max-width: 75%;
  }
`;

export const Header = styled.header`
  padding-top: ${({ theme }) => theme.other.font.header2.paddingTop};
  padding-bottom: ${({ theme }) => theme.other.font.header2.paddingBottom};
  color: ${({ theme }) => theme.other.primaryColor};

  h1 {
    font-size: ${({ theme }) => theme.other.font.modalGroupHeader.fontSize};
    line-height: ${({ theme }) => theme.other.font.modalGroupHeader.lineHeight};
    margin: 0;
    color: #1a7e35;
  }
  h2 {
    font-size: ${({ theme }) => theme.other.font.header2.fontSize};
    line-height: ${({ theme }) => theme.other.font.header2.lineHeight};
    margin: 0;
  }
`;

export const HeaderBottomDivider = styled.div`
  margin-left: auto;
  margin-right: auto;
  width: 75%;
  border-bottom: 1px solid ${({ theme }) => theme.other.primaryColor};
`;
