import { useQuery, UseQueryOptions, UseQueryResult } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { QUERY_KEYS } from "constants/queryKeys";
import { ResponseError } from "types";

import { getCostCenterListService } from "../api";
import { CostCenterResponse } from "../types";

type UseGetCostCenterQueryArgs = (
  apiArgs: {
    accountId: string;
    params: Record<string, number | string | null>;
  },
  options?: UseQueryOptions<CostCenterResponse, AxiosError<ResponseError>>
) => UseQueryResult<CostCenterResponse, AxiosError<ResponseError>>;

export const useGetCostCenterQuery: UseGetCostCenterQueryArgs = (apiArgs, options) =>
  useQuery<CostCenterResponse, AxiosError<ResponseError>>({
    queryKey: [QUERY_KEYS.get_cost_centers, { ...apiArgs }],
    queryFn: () => getCostCenterListService({ ...apiArgs }),
    ...options
  });
