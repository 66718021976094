import { useMutation, useQueryClient } from "@tanstack/react-query";
import { QUERY_KEYS } from "constants/queryKeys";
import { ResponseType, TFeatureFlag, TFeatureFlagAccount } from "types";

import { putFeatureFlagAccountService } from "../api/putFeatureFlagAccountService";

type usePutFeatureFlagMutationArgs = {
  accountId: string;
  features: TFeatureFlag[];
  previousData: TFeatureFlag[];
  userId: string;
};

export const usePutFeatureFlagMutation = ({ onSuccess, onError }: { onError: () => void; onSuccess: () => void }) => {
  const queryClient = useQueryClient();

  const featureFlagsQueryKey = QUERY_KEYS.get_feature_flag_list_account;

  return useMutation({
    mutationKey: [QUERY_KEYS.put_feature_flag],
    mutationFn: async (args: usePutFeatureFlagMutationArgs): Promise<ResponseType<TFeatureFlagAccount>[]> => {
      const { features, accountId, userId, previousData } = args;

      const changedFeatures = features.filter(feature => {
        const prev = previousData.find(i => i.name === feature.name);
        if (prev) return prev.isActive !== feature.isActive;
        return false;
      });

      const accountFeatures = changedFeatures.map(({ isActive, name }) => ({ isActive, userId, featureName: name }));

      const promiseList = accountFeatures.map(feature => putFeatureFlagAccountService(accountId, feature));

      const response = await Promise.all(promiseList);

      return response;
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [featureFlagsQueryKey] });
    },
    onSuccess,
    onError
  });
};
