import * as React from "react";

export const FileUploadIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="39" height="49" viewBox="0 0 39 49" fill="none" {...props}>
    <path
      d="M26.2293 0.376174C26.0061 0.141112 25.7001 0 25.383 0H6.3957C2.89204 0 0 2.88053 0 6.38381V42.2889C0 45.7926 2.89204 48.6731 6.3957 48.6731H32.1664C35.67 48.6731 38.5621 45.7926 38.5621 42.2889V13.7788C38.5621 13.4732 38.421 13.1795 38.2212 12.9559L26.2293 0.376174ZM26.5702 4.15017L34.6002 12.5797H29.3802C27.8283 12.5797 26.5702 11.3335 26.5702 9.78163V4.15017ZM32.1664 46.3217H6.3957C4.19733 46.3217 2.35136 44.4995 2.35136 42.2889V6.38381C2.35136 4.18544 4.18544 2.35136 6.3957 2.35136H24.2188V9.78163C24.2188 12.6384 26.5234 14.9311 29.3802 14.9311H36.2107V42.2889C36.2107 44.4995 34.3766 46.3217 32.1664 46.3217Z"
      fill="currentColor"
    />
    <path
      d="M28.957 38.2097H9.60537C8.95886 38.2097 8.42969 38.7385 8.42969 39.3854C8.42969 40.0319 8.95886 40.5611 9.60537 40.5611H28.9688C29.6154 40.5611 30.1445 40.0319 30.1445 39.3854C30.1445 38.7385 29.6154 38.2097 28.957 38.2097Z"
      fill="currentColor"
    />
    <path
      d="M13.2622 25.5594L18.106 20.3509V33.1894C18.106 33.836 18.6352 34.3651 19.2817 34.3651C19.9286 34.3651 20.4574 33.836 20.4574 33.1894V20.3509L25.3012 25.5594C25.5363 25.806 25.8423 25.9356 26.1594 25.9356C26.4416 25.9356 26.7357 25.8297 26.9589 25.6181C27.4294 25.1713 27.4647 24.4305 27.018 23.9604L20.1284 16.5654C19.9048 16.3303 19.5992 16.1892 19.2702 16.1892C18.9408 16.1892 18.6352 16.3303 18.412 16.5654L11.5224 23.9604C11.0757 24.4305 11.111 25.1828 11.5811 25.6181C12.075 26.0648 12.8154 26.0295 13.2622 25.5594Z"
      fill="currentColor"
    />
  </svg>
);
