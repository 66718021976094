import { useMutation } from "@tanstack/react-query";
import { ErrorResponse } from "App";
import { errorModal } from "modules/error/ErrorHandlingModals";

import { postUserAuthentificationService } from "../api";

export const useAuthenticateUserMutation = () => {
  return useMutation({
    mutationFn: postUserAuthentificationService,
    onSuccess: async () => {},
    onError: (error: ErrorResponse) => errorModal(error)
  });
};
