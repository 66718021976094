import { Divider, Grid } from "@mantine/core";
import { QuestionnaireNormalized, QuestionnaireQuestionScope } from "types";

import { titleDetailMap } from "../questionnaire.data";
import { QuestionnaireContentHeaderContainer, QuestionnaireResponseContent } from "../questionnaire.styles";
import { QuestionnaireQuestions } from "./QuestionnaireQuestions";

type QuestionnaireDetailQuestionsProps = {
  questions: QuestionnaireNormalized[];
  submitted?: boolean;
};

export const QuestionnaireDetailQuestions = ({ questions, submitted }: QuestionnaireDetailQuestionsProps) => {
  const content = questions.map((group, index) => {
    const { id, scope, questions: groupQuestions } = group;

    const title = titleDetailMap[scope];

    const span = scope === QuestionnaireQuestionScope.SCOPE_SUPPLIER ? 12 : 6;

    if (!groupQuestions.length) return null;

    return (
      <Grid.Col span={span} key={index} mt={40}>
        <QuestionnaireContentHeaderContainer>
          <h1>{title}</h1>
        </QuestionnaireContentHeaderContainer>
        <Divider mt="sm" />
        <QuestionnaireQuestions questions={groupQuestions} group={id} onChange={() => {}} submitted={submitted} />
      </Grid.Col>
    );
  });

  return <QuestionnaireResponseContent>{content}</QuestionnaireResponseContent>;
};
