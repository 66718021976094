export const Strings = {
  no__internet: "No Internet Connection",
  something__went__wrong__header: "Something Went Wrong",
  access__denied: "Access Denied",
  enter__fname: "Please Enter First Name",
  enter__lname: "Please Enter Last Name",
  invalid__email__address: "Invalid Email Address",
  enetr__email__address: "Please Enter Email Address",
  select__account: "Please Select Account",
  select__user__role: "Please Select User Role",
  account__created__success: "A Kloopify Account was successfully created for this user!",
  user__updated__success: "Changes to this user’s account have been saved.",
  user__updated__error: "Failed to update user, try again.",
  email__already__exists__conf:
    "A user with that email already exists as a Kloopify User. Would you like to invite that existing User to your account?",
  user__created__success__error__account:
    "A Kloopify User was successfully created, but issue with attaching account, Please try Again!",
  please_select_account: "Please Select Account.",
  please_select_role: "Please Select Role.",
  A_new_user_was_successfully_added_to_this_account: "A new user was successfully added to this account!",
  user_details_updated_successfully: "User details updated successfully."
};
