import { TAnalyticsTabularData } from "types";

export const isEven = (n: number) => n % 2 === 0;

// moved to the formatting lib, gut this...
// The Function returns commas separated thousands digit numbers.
export const FormatCash = (unit: number, type: "CO2E" | "amount" = "CO2E"): string | undefined => {
  const n = type === "amount" ? unit / 100 : unit;
  if (n < 1e3) return n.toFixed(2);
  if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(2) + "K";
  if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(2) + "M";
  if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(2) + "B";
  if (n >= 1e12) return +(n / 1e12).toFixed(1) + "T";
};

interface SortArrayInterface {
  CO2Tons: number;
  CO2TonsPercent: string;
  cost: number;
  costPercent: string;
  description: string;
  id: number | string;
}
// Sorting the Array to ascending / descending order
export const sortArray = (array: TAnalyticsTabularData[], field: string, order: string) => {
  switch (true) {
    case field === "description":
      // sort by String property ASCENDING (A - Z)
      if (order === "asc") {
        return [...array].sort((a: SortArrayInterface, b: SortArrayInterface) =>
          a.description > b.description ? 1 : -1
        );
      }
      // sort by String property DESCENDING (Z - A)
      else {
        return [...array].sort((a: SortArrayInterface, b: SortArrayInterface) =>
          a.description > b.description ? -1 : 1
        );
      }
    case field === "CO2Tons":
      // sort by Numeric property ASCENDING (1 - 100)
      if (order === "asc") {
        return [...array].sort((a: SortArrayInterface, b: SortArrayInterface) => a.CO2Tons - b.CO2Tons);
      }
      // sort by Numeric property DESCENDING (100 - 1)
      else {
        return [...array].sort((a: SortArrayInterface, b: SortArrayInterface) => b.CO2Tons - a.CO2Tons);
      }
    case field === "cost":
      // sort by Numeric property ASCENDING (1 - 100)
      if (order === "asc") {
        return [...array].sort((a: SortArrayInterface, b: SortArrayInterface) => a.cost - b.cost);
      }
      // sort by Numeric property DESCENDING (100 - 1)
      else {
        return [...array].sort((a: SortArrayInterface, b: SortArrayInterface) => b.cost - a.cost);
      }
    default: {
      return [...array];
    }
  }
};

// TODO KILL THAT AND USE DATA FROM CURRENTUSERCONTEXT INSTEAD
// Retrieves Account ID from Local Storage
export const getAccountId = () => {
  const AccountID = localStorage.getItem("accountId") ?? "";
  return AccountID;
};

// Retrieves Account Name from Local Storage
export const getAccountName = () => {
  const AccountID = localStorage.getItem("accountName") ?? "";
  return AccountID;
};

export const getImportStatus = (status: string | undefined) => {
  switch (status) {
    case "UPLOAD_REJECTED":
      return "Upload Rejected";
    case "PENDING_ANALYSIS":
      return "Pending Analysis";
    case "PENDING_APPROVAL":
      return "Pending Approval";
    case "ANALYSIS_REJECTED":
      return "Analysis Rejected";
    case "PROCESSING_TRANSACTION_FILE":
      return "Processing Transactions";
    case "PROCESSING_ANALYSIS_FILE":
      return "Processing Analysis";
    case "PENDING_ANALYSIS_PROCESSING":
      return "Pending Analysis";
    case "COMPLETED":
      return "Completed";
    case "CREATED":
      return "Created";
    default:
      return "Pending";
  }
};

export const getBrowserLocal = () => {
  return navigator.language || "en_US";
};

export const getBrowserTimeZone = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone || "America/New_York";
};

export const isLocalStorageEnabled = () => {
  try {
    const key = `__storage__test`;
    window.localStorage.setItem(key, "");
    window.localStorage.removeItem(key);
    return true;
  } catch (e) {
    return false;
  }
};

export const formatPercentagePoints = (value: number | string) => Number(`${(Number(value) * 100).toFixed(0)}`);

export const getFileExtension = (fileName: string) => fileName.substring(fileName.lastIndexOf(".") + 1);

export const fitElement = (id: string) => {
  const el = document.getElementById(id);
  if (el) el.scrollIntoView({ behavior: "smooth" });
};
