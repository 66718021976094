import { useEffect } from "react";

import { Button, LoadingOverlay, TextInput } from "@mantine/core";
import { useForm, yupResolver } from "@mantine/form";
import { Strings } from "constants/translations";
import useGetAccounts from "modules/account/queries/Account";
import Loader from "ui/feedback/Loader";
import Modal from "ui/overlays/Modal";
import { ModalBodyMessage, ModalFooter, ModalTitle } from "ui/styles";
import { getBrowserLocal, getBrowserTimeZone } from "utils/common.utils";
import * as Yup from "yup";

import { useUpdateUserMutation } from "../hooks";
import { UserInterface } from "../types/UserDto";
import { USER_TYPE, UserRoles } from "../types/UserTypesEnum";
import {
  FormRowWrapper,
  StyleEmailInfoMsg,
  StyleEmailWrapper,
  StyleFNameWrapper,
  StyleLNameWrapper,
  StyleModalHeaderDescription,
  StyleModalHeaderTitle,
  StyleWrapper,
  Wrapper
} from "./styles";

interface UpdateInternalUserInterface {
  openModal: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  details?: UserInterface;
}
export interface UserForm {
  accountId: string;
  emailFormat?: string;
  firstName: string;
  lastName: string;
  email: string;
  userRole: string;
}

const UpdateInternalUser: React.FC<UpdateInternalUserInterface> = ({
  openModal,
  setOpenModal,
  details
}): JSX.Element => {
  const getAccountsQuery = useGetAccounts();
  const updateUserMutation = useUpdateUserMutation();

  const onCloseModal = () => setOpenModal(false);

  const onFormSubmitHandler = (values: UserForm) => {
    let userType = "";
    switch (values.userRole) {
      case UserRoles["INTERNAL_ADMIN"].value:
        userType = USER_TYPE.internal;
        break;
      case UserRoles["INTERNAL_ANALYST"].value:
        userType = USER_TYPE.internal;
        break;
      case UserRoles["ACCOUNT_ADMIN"].value:
        userType = USER_TYPE.external;
        break;
      case UserRoles["ACCOUNT_CATEGORY_MANAGER"].value:
        userType = USER_TYPE.external;
        break;
      default:
        userType = USER_TYPE.external;
        break;
    }

    if (details?.id) {
      updateUserMutation.mutate(
        {
          id: details.id,
          payload: {
            ...values,
            userType: userType,
            locale: getBrowserLocal(),
            localTimeZone: getBrowserTimeZone()
          }
        },
        {
          onSuccess: () => {
            updateUserForm.reset();
            setOpenModal(false);
          }
        }
      );
    }
  };

  const schema = Yup.object().shape({
    firstName: Yup.string().required(`${Strings.enter__fname}`),
    lastName: Yup.string().required(`${Strings.enter__lname}`),
    email: Yup.string().email(`${Strings.invalid__email__address}`).required(`${Strings.enetr__email__address}`)
  });

  const updateUserForm = useForm({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      isAccountId: true,
      accountId: "",
      isUserRole: true,
      userRole: ""
    },
    validate: yupResolver(schema)
  });

  // Set User Details in form
  useEffect(() => {
    if (openModal) {
      updateUserForm.setFieldValue("firstName", details?.firstName ?? "");
      updateUserForm.setFieldValue("lastName", details?.lastName ?? "");
      updateUserForm.setFieldValue("email", details?.email ?? "");
    }
  }, [openModal]);

  if (getAccountsQuery.isLoading) {
    return <LoadingOverlay loader={<Loader />} visible />;
  }

  return (
    <>
      <Modal opened={openModal} setOpened={setOpenModal} withCloseButton handleClose={onCloseModal}>
        <ModalTitle centered>Edit User Information</ModalTitle>
        <ModalBodyMessage>
          <StyleModalHeaderTitle>Edit information for an existing User!</StyleModalHeaderTitle>
          <StyleModalHeaderDescription>
            Edit the fields below. Required fields are marked with an asterisk*.
          </StyleModalHeaderDescription>
        </ModalBodyMessage>

        <Wrapper>
          <LoadingOverlay visible={updateUserMutation.isLoading} overlayBlur={2} loader={<Loader />} />
          <form onSubmit={updateUserForm.onSubmit(values => onFormSubmitHandler(values))}>
            <StyleWrapper>
              <FormRowWrapper style={{ marginBottom: "27px", marginTop: "65px" }}>
                <StyleFNameWrapper>
                  <TextInput label="First Name" withAsterisk {...updateUserForm.getInputProps("firstName")} />
                </StyleFNameWrapper>
                <StyleLNameWrapper>
                  <TextInput label="Last Name" withAsterisk {...updateUserForm.getInputProps("lastName")} />
                </StyleLNameWrapper>
              </FormRowWrapper>
              <FormRowWrapper>
                <StyleEmailWrapper>
                  <TextInput
                    label="Email Address*"
                    withAsterisk
                    {...updateUserForm.getInputProps("email")}
                    type="email"
                  />
                  <StyleEmailInfoMsg>
                    User will be notified at the email address entered above that their information has been updated.
                  </StyleEmailInfoMsg>
                </StyleEmailWrapper>
              </FormRowWrapper>
            </StyleWrapper>
            <ModalFooter>
              <Button
                variant="default"
                type="button"
                onClick={() => {
                  setOpenModal(false);
                  updateUserForm.reset();
                }}
              >
                Cancel
              </Button>
              <Button type="submit">Confirm Changes</Button>
            </ModalFooter>
          </form>
        </Wrapper>
      </Modal>
    </>
  );
};
export default UpdateInternalUser;
