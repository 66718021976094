import { createContext, useContext } from "react";

import { HeaderType } from "./importMapModal.types";

type ImportMapModalContextOptions = {
  headers: HeaderType[];
  onChange: (fieldSource: string, klopifyTarget: string) => void;
  onCreateCustomField: () => void;
  onError: (csvHeaderKey: string, error: boolean) => void;
};

const initialImportMapModalContext: ImportMapModalContextOptions = {
  headers: [],
  onChange: () => {},
  onCreateCustomField: () => {},
  onError: () => {}
};

export const ImportMapModalContext = createContext<ImportMapModalContextOptions>(initialImportMapModalContext);

export const useImportMapModalContext = () => {
  const context = useContext(ImportMapModalContext);

  if (!context) {
    throw new Error("useImportMapModalContext must be used within a ImportMapModalContext.Provider");
  }

  return context;
};
