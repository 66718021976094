import styled from "@emotion/styled";
import { UploadRejectedModalProps } from "modules/imports/components/ImportsTypes";
import Modal from "ui/overlays/Modal";
import { ModalTitle } from "ui/styles";

const Message = styled.div`
  font-family: ${props => props.theme.other.font.fontFamily};
  font-style: ${props => props.theme.other.font.fontStyle};
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #5c5c5c;
`;

const defaultReason = "No reason provided.";

export const UploadRejectedModal = ({
  openModal = true,
  setOpenModal,
  handleClose,
  reason = defaultReason
}: UploadRejectedModalProps) => {
  return (
    <Modal
      opened={openModal}
      setOpened={setOpenModal}
      handleClose={handleClose}
      withCloseButton
      title={<ModalTitle $type="danger">Import Rejected</ModalTitle>}
      mode="message"
    >
      <Message>This file was rejected for the following reason: {reason}</Message>
    </Modal>
  );
};
