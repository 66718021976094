import styled from "@emotion/styled";

export const ImportErrorListContainer = styled.div`
  overflow: auto;
  width: 100%;
  min-height: 480px;
  & > div:nth-of-type(odd) {
    background-color: #fafbfb;
    width: max-content;
  }
`;
