import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useLocation, useParams } from "react-router-dom";

import { Button, TextInput } from "@mantine/core";
import { debounce } from "lodash";
import { UserRoles } from "modules/users";
import { TCommodityFilter } from "types";
import { SearchIcon, SettingIcon } from "ui/icons";
import RoleGuardWrapper from "ui/wrappers/RoleGuardWrapper";
import { parseQueryParams } from "utils";

import { CommodityContext } from "../context/CommodityContext";
import { CommodityHeaderContainer, HeaderLeftContent } from "../styles";

type CommodityHeaderProps = {
  onMergeOpen: () => void;
};

export const CommodityHeader: React.FC<CommodityHeaderProps> = ({ onMergeOpen }) => {
  const { modalController, updateFilters, rowSelection, filters } = useContext(CommodityContext);

  const { search } = useLocation();

  const { id: accountId } = useParams();

  const [searchValue, setSearchValue] = useState(filters.search);

  const mergeDisabled = useMemo(() => rowSelection.length < 2, [rowSelection]);

  const itemsCount = useMemo(() => (mergeDisabled ? "" : `(${rowSelection.length})`), [mergeDisabled, rowSelection]);

  const handleSearch = (value: string) => setSearchValue(value);

  const applySearch = (filters: TCommodityFilter) => updateFilters({ ...filters, page: "0" }, true);

  const synchronizeSearch = () => {
    const params = parseQueryParams(search);
    if (!params?.search) return handleSearch("");
    if (params?.search !== searchValue) handleSearch(params.search as string);
  };

  const debouncedApplySearch = useCallback(debounce(applySearch, 500), [search]);

  useEffect(() => synchronizeSearch(), [search]);

  useEffect(() => {
    if (searchValue !== filters.search) debouncedApplySearch({ ...filters, search: searchValue });
  }, [searchValue]);

  return (
    <CommodityHeaderContainer>
      <HeaderLeftContent>
        <Button
          size="lg"
          onClick={() => modalController({ filters: true })}
          variant="default"
          leftIcon={<SettingIcon />}
        >
          Filter
        </Button>
        <TextInput size="lg" icon={<SearchIcon />} value={searchValue} onChange={e => handleSearch(e.target.value)} />
      </HeaderLeftContent>
      {accountId && (
        <RoleGuardWrapper
          roles={[UserRoles.INTERNAL_ADMIN.value, UserRoles.INTERNAL_ANALYST.value, UserRoles.ACCOUNT_ADMIN.value]}
        >
          <Button size="lg" variant="filled" disabled={mergeDisabled} onClick={onMergeOpen}>
            Merge {itemsCount} Commodities
          </Button>
        </RoleGuardWrapper>
      )}
    </CommodityHeaderContainer>
  );
};
