import { createContext, useContext } from "react";

import { ITransactions } from "./transactions.types";

type TransactionContextOptions = {
  activeTransaction: ITransactions | null;
  onCloseDetailsTransaction: () => void;
  onOpenDetailsTransaction: (transaction: ITransactions) => void;
};

const initialTransactionContext: TransactionContextOptions = {
  activeTransaction: null,
  onOpenDetailsTransaction: () => {},
  onCloseDetailsTransaction: () => {}
};

export const TransactionContext = createContext<TransactionContextOptions>(initialTransactionContext);

export const useTransactionContext = () => {
  const context = useContext(TransactionContext);

  if (!context) {
    throw new Error("useTransactionContext must be used within a TransactionContext.Provider");
  }

  return context;
};
