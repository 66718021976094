import { useContext, useEffect, useState } from "react";

import styled from "@emotion/styled";
import { Button, LoadingOverlay, Select, TextInput } from "@mantine/core";
import { useForm, yupResolver } from "@mantine/form";
import { ErrorResponse } from "App";
import { Country, State } from "country-state-city";
import useUpdateAccountMutation from "modules/account/mutations/UpdateAccount";
import { CurrentUserContext } from "modules/login/CurrentUserContext";
import { USER_TYPE } from "modules/users/types/UserTypesEnum";
import { TAccount } from "types";
import { TAccountCreation } from "types/Account/AccountCreation";
import Loader from "ui/feedback/Loader";
import ErrorModal from "ui/overlays/ErrorModal";
import Modal from "ui/overlays/Modal";
import SuccessModal from "ui/overlays/SuccessModal";
import { getBrowserLocal } from "utils/common.utils";
import * as Yup from "yup";
const countries = Country.getAllCountries();

const Wrapper = styled.div`
  width: 100%;
  @media only screen and (max-width: ${props => props.theme.other.breakpoints.lg}) {
    display: block;
    width: 100%;
  }
`;
const StyledWrapper = styled.div``;
const ModalHeader = styled.div`
  font-family: ${props => props.theme.other.font.fontFamily};
  font-style: ${props => props.theme.other.font.fontStyle};
  font-weight: 700;
  font-size: ${props => props.theme.other.font.header2.fontSize};
  line-height: ${props => props.theme.other.font.header2.lineHeight};
  color: ${props => props.theme.other.primaryColor};
  text-align: center;
`;
const StyleModalHeaderTitle = styled.div`
  font-family: ${props => props.theme.other.font.fontFamily};
  font-style: ${props => props.theme.other.font.fontStyle};
  font-weight: 700;
  font-size: ${props => props.theme.other.font.fontSize};
  line-height: ${props => props.theme.other.font.lineHeight};
  text-align: center;
  color: ${props => props.theme.other.monochromeColor[1]};
  margin-top: 1.5rem;
`;
const StyleModalHeaderDescription = styled.div`
  font-family: ${props => props.theme.other.font.fontFamily};
  font-style: ${props => props.theme.other.font.fontStyle};
  font-weight: 400;
  font-size: ${props => props.theme.other.font.fontSize};
  line-height: ${props => props.theme.other.font.lineHeight};
  text-align: center;
  color: ${props => props.theme.other.monochromeColor[2]};
`;
const StyleAccountFormRowWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 19px;
  width: 100%;
  margin-bottom: 1.5rem;
  margin-top: 2rem;
  @media only screen and (max-width: ${props => props.theme.other.breakpoints.lg}) {
    display: block;
    width: 100%;
  }
`;
const StyleCountryFormRowWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  gap: 42px;
  margin-bottom: 28px;
  margin-top: 28px;
  @media only screen and (max-width: ${props => props.theme.other.breakpoints.lg}) {
    display: block;
    width: 100%;
  }
`;
const StyleCityFormRowWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 19px;
  width: 100%;
  margin-bottom: 30px;
  margin-top: 28px;
  @media only screen and (max-width: ${props => props.theme.other.breakpoints.lg}) {
    display: block;
    width: 100%;
  }
`;
const StyleIdsFormRowWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 19px;
  gap: 40px;
  @media only screen and (max-width: ${props => props.theme.other.breakpoints.lg}) {
    display: block;
    width: 100%;
  }
`;
const StyleSubmitButton = styled.div`
  max-width: 9.375rem;
  width: 100%;
`;
const StyleCancelButton = styled.div`
  max-width: 9.375rem;
  width: 100%;
`;
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 72px;
`;
const StyleAccountNameWrapper = styled.div`
  max-width: 655px;
  width: 100%;
  @media only screen and (max-width: ${props => props.theme.other.breakpoints.lg}) {
    max-width: 100%;
  }
`;
const StyleCityWrapper = styled.div`
  max-width: 355px;
  width: 100%;
  @media only screen and (max-width: ${props => props.theme.other.breakpoints.lg}) {
    max-width: 100%;
  }
`;
const StyleTimezoneWrapper = styled.div`
  max-width: 290px;
  min-width: 173px;
  width: 100%;
  @media only screen and (max-width: ${props => props.theme.other.breakpoints.lg}) {
    max-width: 100%;
    min-width: 100%;
    margin-top: 20px;
  }
`;
const StyleStateWrapper = styled.div`
  max-width: 113px;
  min-width: 100px;
  width: 100%;
  @media only screen and (max-width: ${props => props.theme.other.breakpoints.lg}) {
    max-width: 100%;
    min-width: 100%;
    margin-top: 25px;
  }
`;
const StyleZipCodeWrapper = styled.div`
  max-width: 113px;
  width: 100%;
  @media only screen and (max-width: ${props => props.theme.other.breakpoints.lg}) {
    max-width: 100%;
    margin-top: 25px;
  }
`;
const StyleCountryWrapper = styled.div`
  max-width: 290px;
  min-width: 159px;
  width: 100%;
  @media only screen and (max-width: ${props => props.theme.other.breakpoints.lg}) {
    max-width: 100%;
    min-width: 100%;
    margin-top: 25px;
  }
`;
const StyleIdsWrapper = styled.div`
  max-width: 290px;
  width: 100%;
  @media only screen and (max-width: ${props => props.theme.other.breakpoints.lg}) {
    max-width: 100%;
    margin-top: 25px;
  }
`;

interface UpdateAccountInterface {
  callFrom?: "accountsDetails" | "accountsListing";
  openModal: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  details?: TAccount;
}

interface DropdownDataInterface {
  label: string;
  value: string;
}

const countryDropdown = countries.map(country => ({ label: country.name, value: country.isoCode }));

const countryTimezoneDropdown = (countryCode: string): DropdownDataInterface[] => {
  const timezonesArr = Country.getCountryByCode(countryCode);
  if (timezonesArr?.timezones) {
    return timezonesArr?.timezones?.map(timezone => {
      return { label: timezone.zoneName, value: timezone.zoneName };
    });
  }
  return [];
};

const countryStatesDropdown = (countryCode: string): DropdownDataInterface[] => {
  const states = State.getStatesOfCountry(countryCode);
  return states?.map(state => {
    return { label: state.name, value: state.isoCode };
  });
};

const StyleAddNewAccountModal = styled(Modal)`
  .mantine-Modal-modal {
    max-width: 790px;
    min-height: 943px;
    padding: 66px 77px 81px 92px;
    @media only screen and (max-width: ${props => props.theme.other.breakpoints.lg}) {
      padding: 10% 10% 7.5% 10%;
    }
  }
`;
export const UpdateAccount: React.FC<UpdateAccountInterface> = ({ openModal, setOpenModal, details }): JSX.Element => {
  const { userDetails, setUserDetails } = useContext(CurrentUserContext);
  const { userType } = userDetails;
  const [isErrorModalVisible, setIsErrorModalVisible] = useState<boolean>(false);
  const [isSuccessModalVisible, setIsSuccessModalVisible] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const updateAccountMutation = useUpdateAccountMutation();

  const onModalClose = () => setOpenModal(false);

  useEffect(() => {
    if (openModal === true) {
      updateAccountForm.setFieldValue("name", details?.name ?? "");
      updateAccountForm.setFieldValue("street", details?.street ?? "");
      updateAccountForm.setFieldValue("country", details?.country ?? "");
      updateAccountForm.setFieldValue("localTimeZone", details?.localTimeZone ?? "");
      updateAccountForm.setFieldValue("city", details?.city ?? "");
      updateAccountForm.setFieldValue("region", details?.region ?? "");
      updateAccountForm.setFieldValue("postalCode", details?.postalCode ?? "");
      updateAccountForm.setFieldValue("crmId", details?.crmId ?? "");
      updateAccountForm.setFieldValue("taxId", details?.taxId ?? "");
    }
  }, [openModal]);

  const handleCountryChange = (value: string) => {
    updateAccountForm.setFieldValue("country", value);
    updateAccountForm.setFieldValue("localTimeZone", "");
    updateAccountForm.setFieldValue("region", "");
  };
  const onFormSubmitHandler = (payload: TAccountCreation) => {
    if (details?.id) {
      updateAccountMutation.mutate({
        id: details?.id,
        payLoad: {
          ...payload,
          locale: getBrowserLocal()
        }
      });
    }
  };
  useEffect(() => {
    if (updateAccountMutation.isSuccess === true) {
      setIsSuccessModalVisible(true);
      if (userType === USER_TYPE.external) {
        setUserDetails({ ...userDetails, accountName: updateAccountForm.values.name });
        localStorage.setItem("accountName", updateAccountForm.values.name);
      }
    }
    if (updateAccountMutation.isError === true) {
      const errorMessage =
        (updateAccountMutation.error as ErrorResponse).response.data.errorMessage ?? "Something Went Wrong.";
      setErrorMessage(errorMessage);
      setIsErrorModalVisible(true);
    }
  }, [updateAccountMutation.isSuccess, updateAccountMutation.isError]);

  const schema = Yup.object().shape({
    name: Yup.string().required("Account Name is required"),
    country: Yup.string().required("Country is required"),
    localTimeZone: Yup.string().required("Time Zone is required")
  });

  const updateAccountForm = useForm({
    initialValues: {
      name: "",
      localTimeZone: "",
      street: "",
      city: "",
      region: "",
      postalCode: "",
      country: "",
      taxId: "",
      crmId: ""
    },
    validate: yupResolver(schema)
  });

  if (updateAccountMutation.isLoading) {
    return <LoadingOverlay loader={<Loader />} visible />;
  }

  return (
    <StyleAddNewAccountModal
      opened={openModal}
      setOpened={setOpenModal}
      withCloseButton
      handleClose={onModalClose}
      title={
        <div>
          <ModalHeader>Edit Account</ModalHeader>
          <StyleModalHeaderTitle>Edit an existing Organization Account!</StyleModalHeaderTitle>
          <StyleModalHeaderDescription>
            Edit the account information. Required fields are marked with an asterisk*.
          </StyleModalHeaderDescription>
        </div>
      }
    >
      <Wrapper>
        <form onSubmit={updateAccountForm.onSubmit(values => onFormSubmitHandler(values))}>
          <StyledWrapper>
            <StyleAccountFormRowWrapper>
              <StyleAccountNameWrapper>
                <TextInput label="Account Name*" height="50px" {...updateAccountForm.getInputProps("name")} />
              </StyleAccountNameWrapper>
            </StyleAccountFormRowWrapper>
            <TextInput label="Address" height="50px" {...updateAccountForm.getInputProps("street")} />
            <StyleCountryFormRowWrapper>
              <StyleCountryWrapper>
                <Select
                  searchable
                  required
                  placeholder="Select Country"
                  label="Country*"
                  data={countryDropdown}
                  value={updateAccountForm.values.country}
                  error={updateAccountForm.errors.country}
                  onChange={(value: string) => handleCountryChange(value)}
                />
              </StyleCountryWrapper>
              <StyleTimezoneWrapper>
                <Select
                  searchable
                  placeholder="Select Time Zone"
                  label="Time Zone*"
                  data={countryTimezoneDropdown(updateAccountForm.values.country)}
                  value={updateAccountForm.values.localTimeZone}
                  error={updateAccountForm.errors.localTimeZone}
                  onChange={(value: string) => updateAccountForm.setFieldValue("localTimeZone", value)}
                />
              </StyleTimezoneWrapper>
            </StyleCountryFormRowWrapper>
            <StyleCityFormRowWrapper>
              <StyleCityWrapper>
                <TextInput label="City" height="50px" {...updateAccountForm.getInputProps("city")} />
              </StyleCityWrapper>
              <StyleStateWrapper>
                <Select
                  searchable
                  placeholder="State"
                  label="State"
                  data={countryStatesDropdown(updateAccountForm.values.country)}
                  value={updateAccountForm.values.region}
                  onChange={(value: string) => updateAccountForm.setFieldValue("region", value)}
                />
              </StyleStateWrapper>
              <StyleZipCodeWrapper>
                <TextInput label="Zip Code" height="50px" {...updateAccountForm.getInputProps("postalCode")} />
              </StyleZipCodeWrapper>
            </StyleCityFormRowWrapper>
            <StyleIdsFormRowWrapper>
              <StyleIdsWrapper>
                <TextInput label="VAT/Tax Id" height="50px" {...updateAccountForm.getInputProps("taxId")} />
              </StyleIdsWrapper>
              <StyleIdsWrapper>
                <TextInput label="CRM ID" height="50px" {...updateAccountForm.getInputProps("crmId")} />
              </StyleIdsWrapper>
            </StyleIdsFormRowWrapper>
            <ButtonWrapper>
              <StyleCancelButton>
                <Button
                  variant="default"
                  type="button"
                  onClick={() => {
                    setOpenModal(false);
                    updateAccountForm.reset();
                  }}
                >
                  Cancel
                </Button>
              </StyleCancelButton>
              <StyleSubmitButton>
                <Button type="submit">Save Changes</Button>
              </StyleSubmitButton>
            </ButtonWrapper>
          </StyledWrapper>
        </form>
      </Wrapper>
      <ErrorModal openModal={isErrorModalVisible} setOpenModal={setIsErrorModalVisible} message={errorMessage} />
      <SuccessModal
        openModal={isSuccessModalVisible}
        setOpenModal={setIsSuccessModalVisible}
        message={`Changes to ${updateAccountForm.values.name} account have been saved.`}
        closeOpenedModal={setOpenModal}
      />
    </StyleAddNewAccountModal>
  );
};
