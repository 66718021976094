import * as React from "react";

export const BoxIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="19" height="20" viewBox="0 0 19 20" fill="none" {...props}>
      <path
        d="M18.5819 4.41295L9.50113 0.0291211C9.42062 -0.00970703 9.32688 -0.00970703 9.24641 0.0291211L0.165586 4.41295C0.0643359 4.46182 0 4.56436 0 4.67678V15.3232C0 15.4356 0.0643359 15.5382 0.165586 15.5871L9.24637 19.9709C9.2866 19.9903 9.3302 20 9.37375 20C9.4173 20 9.46086 19.9903 9.50113 19.9709L18.5819 15.5871C18.6832 15.5382 18.7475 15.4356 18.7475 15.3232V4.67682C18.7475 4.56432 18.6831 4.46186 18.5819 4.41295ZM9.37375 0.618301L17.7807 4.67678L15.3437 5.85326C15.3282 5.8415 15.312 5.83064 15.2941 5.82201L6.94426 1.79115L9.37375 0.618301ZM6.28262 2.1224L14.6773 6.17498L12.958 7.00502L4.56676 2.95408L6.28262 2.1224ZM14.8737 6.73076V9.79896L13.2677 10.5743V7.50607L14.8737 6.73076ZM18.1616 15.1394L9.66672 19.2403V9.24447L11.693 8.26627C11.8387 8.19592 11.8998 8.0208 11.8295 7.87506C11.7591 7.72939 11.584 7.66822 11.4382 7.73861L9.37375 8.73529L8.56141 8.34311C8.41566 8.27268 8.24055 8.33385 8.1702 8.47955C8.09984 8.62525 8.16094 8.80041 8.30664 8.87076L9.08078 9.24447V19.2403L0.585938 15.1393V5.14354L7.05469 8.26639C7.09574 8.28623 7.13914 8.29561 7.18184 8.29561C7.29074 8.29561 7.39535 8.23459 7.44586 8.12994C7.51621 7.98424 7.45512 7.80908 7.30941 7.73873L0.966836 4.67678L3.87719 3.27178L12.6777 7.52033C12.679 7.52213 12.6805 7.52373 12.6818 7.52549V11.0411C12.6818 11.1419 12.7336 11.2356 12.819 11.2892C12.8664 11.319 12.9205 11.334 12.9748 11.334C13.0182 11.334 13.0618 11.3244 13.1021 11.3049L15.2941 10.2467C15.3953 10.1979 15.4596 10.0954 15.4596 9.98291V6.44795L18.1616 5.14357V15.1394Z"
        fill="currentColor"
      />
      <path
        d="M3.00365 14.0026L1.67103 13.3593C1.52525 13.2889 1.35017 13.3501 1.27982 13.4958C1.20947 13.6415 1.27056 13.8166 1.41626 13.887L2.74888 14.5303C2.78994 14.5501 2.83333 14.5595 2.87603 14.5595C2.98494 14.5595 3.08955 14.4985 3.14005 14.3939C3.21044 14.2481 3.14935 14.073 3.00365 14.0026Z"
        fill="currentColor"
      />
      <path
        d="M4.23019 13.2051L1.67296 11.9706C1.52722 11.9003 1.3521 11.9614 1.28175 12.1071C1.21143 12.2528 1.27253 12.428 1.41823 12.4983L3.97546 13.7328C4.01651 13.7526 4.05991 13.7621 4.10261 13.7621C4.21151 13.7621 4.31612 13.701 4.36663 13.5964C4.43698 13.4506 4.37589 13.2755 4.23019 13.2051Z"
        fill="currentColor"
      />
    </svg>
  );
};
