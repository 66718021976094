import { createContext, useContext } from "react";

import { ISupplierDetailsModal } from "./supplierDetails.types";

type SupplierDetailsContextOptions = {
  modalController: (modal: Partial<ISupplierDetailsModal>) => void;
};

const initialSupplierDetailsContext: SupplierDetailsContextOptions = {
  modalController: () => {}
};

export const SupplierDetailsContext = createContext<SupplierDetailsContextOptions>(initialSupplierDetailsContext);

export const useSupplierDetailsContext = () => {
  const ctx = useContext(SupplierDetailsContext);

  if (!ctx) {
    throw new Error("useSuppliersContext must be used within a SuppliersContext.Provider");
  }

  return ctx;
};
