import { DropdownDataInterface } from "../components/AddInternalUser";

export const enum USER_TYPE {
  external = "EXTERNAL",
  internal = "INTERNAL"
}

export const UserRoles = {
  ACCOUNT_ADMIN: {
    label: "External - Admin",
    value: "ACCOUNT_ADMIN"
  },
  ACCOUNT_CATEGORY_MANAGER: {
    label: "External - Category Manager",
    value: "ACCOUNT_CATEGORY_MANAGER"
  },
  INTERNAL_ADMIN: {
    label: "Kloopify - Super Admin",
    value: "INTERNAL_ADMIN"
  },
  INTERNAL_ANALYST: {
    label: "Kloopify - Analyst",
    value: "INTERNAL_ANALYST"
  }
};

export const LOGIN_TYPE = {
  google: "Google",
  azure: "Azure"
};

export const dropdownDataForRole = (userRole: string): DropdownDataInterface[] => {
  switch (userRole) {
    case UserRoles["INTERNAL_ADMIN"].value:
      return [
        {
          label: UserRoles["INTERNAL_ADMIN"].label,
          value: UserRoles["INTERNAL_ADMIN"].value,
          name: "Kloopify",
          description: " - Super Admin"
        },
        {
          label: UserRoles["INTERNAL_ANALYST"].label,
          value: UserRoles["INTERNAL_ANALYST"].value,
          name: "Kloopify",
          description: " - Analyst"
        },
        {
          label: UserRoles["ACCOUNT_ADMIN"].label,
          value: UserRoles["ACCOUNT_ADMIN"].value,
          name: "External",
          description: " - Admin"
        },
        {
          label: UserRoles["ACCOUNT_CATEGORY_MANAGER"].label,
          value: UserRoles["ACCOUNT_CATEGORY_MANAGER"].value,
          name: "External",
          description: " - Category Manager"
        }
      ];
    case UserRoles["INTERNAL_ANALYST"].value:
      return [
        {
          label: UserRoles["INTERNAL_ANALYST"].label,
          value: UserRoles["INTERNAL_ANALYST"].value,
          name: "Kloopify",
          description: " - Analyst"
        },
        {
          label: UserRoles["ACCOUNT_ADMIN"].label,
          value: UserRoles["ACCOUNT_ADMIN"].value,
          name: "External",
          description: " - Admin"
        },
        {
          label: UserRoles["ACCOUNT_CATEGORY_MANAGER"].label,
          value: UserRoles["ACCOUNT_CATEGORY_MANAGER"].value,
          name: "External",
          description: " - Category Manager"
        }
      ];
    default:
      return [
        {
          label: UserRoles["ACCOUNT_ADMIN"].label,
          value: UserRoles["ACCOUNT_ADMIN"].value,
          name: "External",
          description: " - Admin"
        },
        {
          label: UserRoles["ACCOUNT_CATEGORY_MANAGER"].label,
          value: UserRoles["ACCOUNT_CATEGORY_MANAGER"].value,
          name: "External",
          description: " - Category Manager"
        }
      ];
  }
};
