export const PlusIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="18px" height="18px" viewBox="0 0 18 18" version="1.1" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g id="out" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        d="M14.7589286,10.1517857 L10.1517857,10.1517857 L10.1517857,14.7589286 L7.84821429,14.7589286 L7.84821429,10.1517857 L3.24107143,10.1517857 L3.24107143,7.84821429 L7.84821429,7.84821429 L7.84821429,3.24107143 L10.1517857,3.24107143 L10.1517857,7.84821429 L14.7589286,7.84821429 L14.7589286,10.1517857 L14.7589286,10.1517857 L14.7589286,10.1517857 Z"
        id="path"
        fill="currentColor"
      />
    </g>
  </svg>
);
