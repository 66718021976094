import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";

import { getCoreRowModel, getSortedRowModel, SortingState, useReactTable } from "@tanstack/react-table";
import { TransactionFilters } from "modules/filters";
import { Wrapper } from "modules/users/components/styles";
import { Table } from "ui";
import Loader from "ui/feedback/Loader";
import Pagination from "ui/navigation/Pagination";
import { parseQueryParams, parseSorting, stringifyQueryParams, stringifySorting } from "utils/query.utils";

import { TransactionContext } from "./TransactionContext";
import { columns } from "./columns";
import { TransactionFilterTiles, TransactionsDetailsModal, TransactionsTableHeader } from "./components";
import { useTransactionsQuery } from "./hooks/useTransactionsQuery";
import { initialFilters, initialTransactionsModal } from "./transactions.data";
import { TableWrapper } from "./transactions.styles";
import { ITransactionFilters, ITransactions, ITransactionsModal } from "./transactions.types";

const limit = 10;

export const Transactions = () => {
  const navigate = useNavigate();
  const { search } = useLocation();

  const [searchParams] = useSearchParams();
  const params = Object.fromEntries(searchParams);
  const { id: accountId } = useParams();

  const initialQueryParams = stringifyQueryParams(parseQueryParams(search));
  const initialTransactionFilter = { ...initialFilters, ...parseQueryParams(search) };

  const [filters, setFilters] = useState(initialTransactionFilter);
  const [queryParams, setQueryParams] = useState(initialQueryParams);

  const [sorting, setSorting] = useState<SortingState>(parseSorting(initialTransactionFilter.sortBy));
  const [modal, setModal] = useState(initialTransactionsModal);
  const [activeTransaction, setActiveTransaction] = useState<ITransactions | null>(null);

  const {
    data: transactions,
    isLoading,
    isFetching,
    isInitialLoading,
    refetch
  } = useTransactionsQuery(params, limit, accountId);

  const table = useReactTable({
    data: transactions?.data || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
    state: {
      sorting
    }
  });

  const modalController = (updatedModal: Partial<ITransactionsModal>) => {
    setModal(modal => ({ ...modal, ...updatedModal }));
  };

  const onOpenDetailsTransaction = (transaction: ITransactions) => {
    setActiveTransaction(transaction);
    setModal(state => ({ ...state, detail: true }));
  };

  const onCloseDetailsTransaction = () => {
    setActiveTransaction(null);
    setModal(state => ({ ...state, detail: false }));
  };

  const updateFilters = (updatedFilters?: Partial<ITransactionFilters>, hardUpdate?: boolean) => {
    const newFilters = { ...filters, ...updatedFilters };
    const newQueryParams = stringifyQueryParams(newFilters);
    setFilters(newFilters);
    setQueryParams(newQueryParams);
    if (hardUpdate) applyFilters(newQueryParams);
  };

  const applyFilters = (updatedQueryParams?: string) => {
    const realQueryParams = updatedQueryParams || queryParams;
    const queryObject = parseQueryParams(realQueryParams);
    const resetPageQueryString = stringifyQueryParams({ ...queryObject, page: 0 });

    navigate(`/transactions${accountId ? "/" + accountId : ""}?${resetPageQueryString}`);
  };

  const handlePage = (page: number) => {
    updateFilters({ page: page - 1 });
  };

  const handleDetailsModal = (state?: boolean) => {
    modalController({ detail: state ?? true });
  };

  const handleSorting = () => {
    if (filters.sortBy !== stringifySorting(sorting)) {
      const sortBy = stringifySorting(sorting);
      updateFilters({ sortBy, page: 0 }, true);
    }
  };

  useEffect(() => {
    if (!modal.filters) {
      setFilters(initialTransactionFilter);
      setQueryParams(initialQueryParams);
    }
  }, [modal.filters]);

  useEffect(() => {
    if (search !== "?" + queryParams) {
      navigate(`/transactions${accountId ? "/" + accountId : ""}?${queryParams}`);
    }
  }, [filters.page]);

  useEffect(() => {
    if (!isInitialLoading) refetch();
  }, [search]);

  useEffect(() => {
    handleSorting();
  }, [sorting]);

  if (isLoading && isFetching) return <Loader />;

  return (
    <TransactionContext.Provider value={{ activeTransaction, onOpenDetailsTransaction, onCloseDetailsTransaction }}>
      <Wrapper>
        <TransactionsTableHeader modalController={modalController} updateFilters={updateFilters} filters={filters} />
        <TransactionFilterTiles filtersData={filters} updateFilters={updateFilters} setSorting={setSorting} />
        <TableWrapper>
          <Table<ITransactions> table={table} hoverable />
        </TableWrapper>
        <Pagination
          activePage={Number(filters.page) + 1}
          setPage={handlePage as Dispatch<SetStateAction<number>>}
          totalRecords={transactions?.meta.totalCount}
          limit={limit}
        />
        <TransactionFilters
          opened={modal.filters}
          filters={filters}
          modalController={modalController}
          applyFilters={applyFilters}
          updateFilters={updateFilters}
        />
      </Wrapper>
      {activeTransaction ? (
        <TransactionsDetailsModal
          opened={modal.detail}
          setOpened={handleDetailsModal}
          transaction={activeTransaction}
        />
      ) : null}
    </TransactionContext.Provider>
  );
};
