import { UploadIcon } from "ui/icons";

import { QCFormInstance } from "../../questionnaire.types";
import { QCSelectChoice } from "./QCSelectChoice";
import { QCLongAnswerPlaceholder, QCShortAnswerPlaceholder, QCUploadPlaceholder } from "./questionnaireCreator.styles";

export const QCPlaceholder: React.FC<{
  form: QCFormInstance;
  index: number;
  scope: "productQuestionList" | "supplierQuestionList";
}> = ({ form, index, scope }) => {
  const selectType = form.values?.[scope]?.[index].type;

  const onAddOption = () => {
    form.insertListItem(`${scope}.${index}.choices`, { choice: "" });
  };

  const onAddOther = () => {
    form.insertListItem(`${scope}.${index}.choices`, { choice: "Other" });
  };

  const onRemoveOption = (choiceIndex: number) => {
    form.removeListItem(`${scope}.${index}.choices`, choiceIndex);
  };

  if (selectType === "SHORT_TEXT") {
    return <QCShortAnswerPlaceholder>Short answer text</QCShortAnswerPlaceholder>;
  }
  if (selectType === "LONG_TEXT") {
    return (
      <>
        <QCLongAnswerPlaceholder>Long answer text</QCLongAnswerPlaceholder>
        <QCLongAnswerPlaceholder />
      </>
    );
  }
  if (selectType === "SELECT") {
    return (
      <QCSelectChoice
        onAddOption={onAddOption}
        onAddOther={onAddOther}
        form={form}
        scope={scope}
        index={index}
        onRemoveOption={onRemoveOption}
      />
    );
  }
  if (selectType === "MULTI_SELECT") {
    return (
      <QCSelectChoice
        onAddOption={onAddOption}
        onAddOther={onAddOther}
        form={form}
        scope={scope}
        index={index}
        onRemoveOption={onRemoveOption}
      />
    );
  }
  if (selectType === "FILE") {
    return (
      <QCUploadPlaceholder>
        <UploadIcon />
        Add File
      </QCUploadPlaceholder>
    );
  }

  return null;
};
