import { TTransactionsRedirectConfig } from "modules/transactions/transactions.types";
import moment from "moment";
import { TOverviewDashboardModal } from "types";

export const initialTableData = {
  standard: [],
  paginated: [],
  summary: null
};

export const defaultDateFilter = { start: "1970-01-01T00:00:00Z", end: "2100-01-01T00:00:00Z" };

export const dateFilterConfig = [
  { label: "All Time", value: "alltime" },
  { label: "Last 12 Months", value: "l12m" },
  { label: "Year to Date", value: "ytd" },
  { label: "Last Year", value: "ly" },
  { label: "Last 30 Days", value: "l30d" },
  { label: "Month to Date", value: "mtd" },
  { label: "Last Month", value: "lm" },
  { label: "Custom", value: "custom" }
];

export const groupFilterConfig = [
  { label: "By Supplier", value: "supplier" },
  { label: "By Commodity", value: "item" },
  { label: "By Cost Center", value: "costCenter" },
  { label: "By Sector", value: "naicsSector" },
  { label: "By Industry", value: "naicsIndustry" },
  { label: "By Commodity Category", value: "naicsCode" },
  { label: "By Commodity Name", value: "itemName" }
];

export const dateFilterMap = new Map<string, { end: string; start: string }>(
  Object.entries({
    alltime: defaultDateFilter,
    l12m: {
      start: moment().subtract(12, "months").startOf("day").format(),
      end: "2100-01-01T00:00:00Z"
    },
    ytd: {
      start: moment().startOf("year").format(),
      end: "2100-01-01T00:00:00Z"
    },
    ly: {
      start: moment().subtract(1, "years").startOf("year").format(),
      end: moment().startOf("year").format()
    },

    l30d: {
      start: moment().subtract(30, "days").startOf("day").format(),
      end: "2100-01-01T00:00:00Z"
    },
    mtd: {
      start: moment().startOf("month").format(),
      end: "2100-01-01T00:00:00Z"
    },
    lm: {
      start: moment().subtract(1, "months").startOf("month").format(),
      end: moment().startOf("month").format()
    }
  })
);

export const transactionRedirectConfig: TTransactionsRedirectConfig = {
  supplier: {
    redirectKey: "supplierIds",
    redirectIdKey: "supplierId",
    type: "array"
  },
  item: {
    redirectKey: "itemIds",
    redirectIdKey: "itemId",
    type: "array"
  },
  costCenter: {
    redirectKey: "costCenterIds",
    redirectIdKey: "costCenterId",
    type: "array"
  },
  naicsSector: {
    redirectKey: "naicsSectors",
    redirectIdKey: "naicsSectorId",
    type: "array"
  },
  naicsIndustry: {
    redirectKey: "naicsIndustries",
    redirectIdKey: "naicsIndustryId",
    type: "array"
  },
  naicsCode: {
    redirectKey: "naicsCodes",
    redirectIdKey: "naicsCodeId",
    type: "array"
  },
  itemName: {
    redirectKey: "itemName",
    redirectIdKey: "description",
    type: "linear"
  }
};

export const customIntervalInitial = {
  start: null,
  end: null
};

export const overviewDashboardModalInitial: TOverviewDashboardModal = {
  customInterval: false,
  additionalFilter: false
};
