import { Fragment, useEffect, useState } from "react";
import Dropzone, { defaultClassNames, IDropzoneProps } from "react-dropzone-uploader";

import { Button, LoadingOverlay } from "@mantine/core";
import { ErrorResponse } from "App";
import AWS from "aws-sdk";
import {
  ButtonWrapper,
  DropZoneStyle,
  DropZoneText,
  DropzoneWrapper,
  DropZoneWrapper,
  InnerWrapper,
  StyleButtonWrapper,
  StyleDescription,
  StyleDropzone,
  StyleDropzoneWrapper,
  StyleFooter,
  StyleSubTitle,
  StyleValidationMessagePlaceHolder
} from "modules/imports/components/Import.styles";
import { TransactionFileDataInterface } from "modules/imports/components/ImportsTypes";
import moment from "moment";
import Loader from "ui/feedback/Loader";
import { FileUploadIcon } from "ui/icons";
import ErrorModal from "ui/overlays/ErrorModal";
import Modal from "ui/overlays/Modal";
import { ModalTitle } from "ui/styles";
import { getAccountName } from "utils/common.utils";

import { useUpdateTransactionImportMutation } from "../hooks";
import { ConfirmationUploadModal } from "./ConfirmationUploadModal";

const REGION = process.env.REACT_APP_REGION;
const S3_BUCKET = process.env.REACT_APP_BUCKET_NAME;
const S3_BUCKET_URL = process.env.REACT_APP_S3_BUCKET_URL;

AWS.config.update({
  accessKeyId: process.env.REACT_APP_ACCESS,
  secretAccessKey: process.env.REACT_APP_SECRET
});

const myBucket = new AWS.S3({
  params: { Bucket: S3_BUCKET },
  region: REGION
});

type ReuploadAnalysisFileProps = {
  handleClose: () => void;
  showModal: boolean;
  transactionId: string | null;
};

export const ReUploadTransactionFile: React.FC<ReuploadAnalysisFileProps> = ({
  showModal,
  handleClose,
  transactionId
}) => {
  const [process, setProcess] = useState<boolean>(false);
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [errorModal, setErrorModal] = useState<boolean>(false); // state controller for error Modal
  const [message, setMessage] = useState<string>(""); // message to display on success or error
  const [transactionFile, setTransactionFile] = useState<TransactionFileDataInterface>();
  const updateImportMutation = useUpdateTransactionImportMutation();

  const Preview = (): JSX.Element => {
    return (
      <DropzoneWrapper key="Dropzone">
        <FileUploadIcon style={{ marginLeft: "auto", marginRight: "auto", marginBottom: "13px" }} />
        <DropZoneText>
          Drag and drop file(s) here or <span style={{ fontWeight: 700 }}>Browse Files</span>
          <div style={{ alignSelf: "flex-start", margin: "20px 3%", fontFamily: "Inter" }}>{transactionFile?.name}</div>
        </DropZoneText>
      </DropzoneWrapper>
    );
  };

  useEffect(() => {
    if (updateImportMutation.isSuccess === true) {
      setTransactionFile({});
      handleClose();
      setShowConfirmModal(true);
      setProcess(false);
    }
  }, [updateImportMutation.isSuccess]);

  useEffect(() => {
    if (updateImportMutation.isError === true) {
      setTransactionFile({});
      const errorMessage =
        (updateImportMutation.error as ErrorResponse).response.data.errorMessage ?? "Something Went Wrong.";
      setMessage(errorMessage);
      setErrorModal(true);
      setProcess(false);
    }
  }, [updateImportMutation.isError]);

  const handleUploadFile = () => {
    if (transactionFile?.name === undefined || transactionId === null) {
      return false;
    }
    if (!S3_BUCKET) return;

    setProcess(true);
    const fileName = getAccountName().toLowerCase() + moment.now() + ".csv";
    const params: AWS.S3.PutObjectRequest = {
      ACL: "private",
      Body: transactionFile,
      ContentType: transactionFile.type,
      Bucket: S3_BUCKET,
      Key: fileName
    };
    myBucket
      .putObject(params)
      .promise()
      .then(() => {
        updateImportMutation.mutate({
          transactionFileLocation: `${S3_BUCKET_URL}${fileName}`,
          filename: fileName,
          id: transactionId
        });
      })
      .catch(() => {
        setProcess(false);
        setMessage("Upload failed try again.");
        setErrorModal(true);
      });
  };

  const handleChangeStatus: IDropzoneProps["onChangeStatus"] = ({ file }, status) => {
    if (["getting_upload_params", "headers_received", "preparing", "uploading"].includes(status)) {
      setProcess(true);
    } else {
      setProcess(false);
    }
    if (status === "rejected_file_type") {
      setMessage("Accepted File Types: .csv only.");
      setErrorModal(true);
    }
    if (status === "exception_upload") {
      setTransactionFile({});
      setMessage("Upload failed try again.");
      setErrorModal(true);
    }
    if (status === "done") {
      setTransactionFile(file);
    }
  };

  const handleResetDropzone = () => {
    setTransactionFile({});
    setProcess(false);
    handleClose();
  };

  const handleSubmit: IDropzoneProps["onSubmit"] = (_, allFiles) => {
    setTransactionFile({});
    allFiles.forEach(f => f.remove());
  };

  return (
    <Fragment>
      <Modal opened={showModal} handleClose={handleClose} withCloseButton>
        <ModalTitle>Re-Upload Transactions File</ModalTitle>
        <StyleSubTitle>Upload the Transactions File</StyleSubTitle>
        <StyleDescription>
          Upload Transactions File.
          {/* See the FAQ for assistance transaction file formats. */}
        </StyleDescription>
        <LoadingOverlay visible={process || updateImportMutation.isLoading} overlayBlur={2} loader={<Loader />} />
        <StyleDropzoneWrapper>
          <StyleDropzone>
            <DropZoneStyle>
              <DropZoneWrapper>
                <InnerWrapper>
                  <Dropzone
                    maxFiles={1}
                    multiple={false}
                    onChangeStatus={handleChangeStatus}
                    onSubmit={handleSubmit}
                    submitButtonContent={"Cancel"}
                    accept=".csv"
                    disabled={files =>
                      files.some(f => ["getting_upload_params", "preparing", "uploading"].includes(f.meta.status))
                    }
                    classNames={{ inputLabelWithFiles: defaultClassNames.inputLabel }}
                    inputContent={
                      <DropzoneWrapper key="Dropzone">
                        <FileUploadIcon style={{ marginLeft: "auto", marginRight: "auto", marginBottom: "13px" }} />
                        <DropZoneText>
                          Drag and drop file(s) here or <span style={{ fontWeight: 700 }}>Browse Files</span>
                        </DropZoneText>
                      </DropzoneWrapper>
                    }
                    PreviewComponent={Preview}
                  />
                </InnerWrapper>
              </DropZoneWrapper>
            </DropZoneStyle>
          </StyleDropzone>
          <StyleValidationMessagePlaceHolder>Accepted File Type: .csv only</StyleValidationMessagePlaceHolder>
        </StyleDropzoneWrapper>
        <StyleFooter>
          <ButtonWrapper>
            <Button
              disabled={process || updateImportMutation.isLoading}
              variant="default"
              onClick={() => handleResetDropzone()}
            >
              Cancel
            </Button>
          </ButtonWrapper>
          <StyleButtonWrapper>
            <ButtonWrapper>
              <Button
                disabled={process || transactionFile?.name === undefined || updateImportMutation.isLoading}
                onClick={() => {
                  handleUploadFile();
                }}
              >
                Upload
              </Button>
            </ButtonWrapper>
          </StyleButtonWrapper>
        </StyleFooter>
      </Modal>
      <ConfirmationUploadModal
        openModal={showConfirmModal}
        setOpenModal={setShowConfirmModal}
        title="Your Transactions File has been submitted!"
        description="You may check the status of this import on the Imports page."
        addMoreBtnText="Add More Transactions Files"
      />
      <ErrorModal
        openModal={errorModal}
        setOpenModal={setErrorModal}
        message={message}
        onClose={() => setErrorModal(false)}
      />
    </Fragment>
  );
};
