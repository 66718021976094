import * as React from "react";

export const SuccessIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
    <path
      d="M7.99952 16C3.58876 16 0 12.4108 0 7.99956C0 3.58833 3.58876 3.56712e-05 7.99952 3.56712e-05C9.26446 -0.0037618 10.5119 0.295695 11.6373 0.87331C11.7453 0.928602 11.8271 1.02457 11.8644 1.1401C11.9017 1.25563 11.8916 1.38127 11.8363 1.48936C11.7811 1.59746 11.6851 1.67916 11.5696 1.7165C11.454 1.75384 11.3284 1.74375 11.2203 1.68846C10.2239 1.1771 9.11946 0.912013 7.99952 0.915418C4.09313 0.915418 0.915382 4.09317 0.915382 7.99956C0.915382 11.906 4.09313 15.0846 7.99952 15.0846C11.9059 15.0846 15.0837 11.906 15.0837 7.99956C15.084 7.39054 15.006 6.78401 14.8516 6.19488C14.8235 6.07842 14.8421 5.95557 14.9036 5.85271C14.965 5.74986 15.0644 5.67519 15.1802 5.64475C15.2961 5.61432 15.4193 5.63053 15.5234 5.68992C15.6274 5.7493 15.7041 5.84712 15.7368 5.96238C15.911 6.62757 15.999 7.31239 15.9986 8.00002C15.999 12.4108 12.4107 16 7.99952 16Z"
      fill="currentColor"
    />
    <path
      d="M7.75332 9.92913C7.63194 9.9291 7.51555 9.88087 7.42973 9.79503L5.11153 7.47682C5.03346 7.38974 4.99169 7.27607 4.99479 7.15915C4.9979 7.04223 5.04564 6.93094 5.12823 6.84812C5.21081 6.76531 5.32197 6.71725 5.43887 6.71381C5.55578 6.71037 5.66957 6.75182 5.75687 6.82965L7.75149 8.82426L14.9807 1.59686C15.067 1.51349 15.1827 1.46736 15.3027 1.4684C15.4227 1.46944 15.5375 1.51758 15.6223 1.60244C15.7072 1.6873 15.7553 1.80209 15.7564 1.9221C15.7574 2.0421 15.7113 2.15772 15.6279 2.24404L8.07599 9.79594C7.99025 9.88122 7.87425 9.9291 7.75332 9.92913Z"
      fill="currentColor"
    />
  </svg>
);
