import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Button, TextInput } from "@mantine/core";
import _ from "lodash";
import { IUserFiltersData, UserFilters, userFiltersInitialData } from "modules/filters";
import AddInternalUser from "modules/users/components/AddInternalUser";
import Loader from "ui/feedback/Loader";
import { PlusIcon, SearchIcon, SettingIcon } from "ui/icons";
import { parseQueryParams, stringifyQueryParams } from "utils/query.utils";

import { useGetUsersQuery } from "../hooks";
import { UserFilterTiles } from "./userFilterTiles/UserFilterTiles";
import { UserTable } from "./userTable/UserTable";
import { initialUserTableModal } from "./userTable/userTable.data";
import {
  StyleButtonSearchWrapper,
  StyledHeader,
  TableHeaderOptionsWrapper,
  Wrapper
} from "./userTable/userTable.styles";

const defaultLimit = 10;

export const Users = () => {
  const navigate = useNavigate();
  const { search } = useLocation();

  const initialQueryParams = stringifyQueryParams(parseQueryParams(search));
  const initialUserFilters = { ...userFiltersInitialData, ...parseQueryParams(search) };
  const [filterParams, setFilterParams] = useState(initialQueryParams);
  const [userFiltersData, setUserFiltersData] = useState(initialUserFilters);
  const [modal, setModal] = useState(initialUserTableModal);
  const [activePage, setPage] = useState<number>(1);
  const [addNewUserModal, setAddNewUserModal] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [totalRecords, setTotalRecords] = useState<number>();
  const [searchInput, setSearchInput] = useState(userFiltersData.search);
  const { data, isLoading, isError, refetch } = useGetUsersQuery(activePage - 1, defaultLimit, filterParams);

  const modalController = (updatedModal: Partial<typeof initialUserTableModal>) => {
    setModal(modal => ({ ...modal, ...updatedModal }));
  };

  const setFilters = (data?: IUserFiltersData) => {
    const filtersData = data || userFiltersData;
    const realParams = stringifyQueryParams(filtersData);
    setSearchInput(filtersData.search);
    setFilterParams(realParams);
    navigate(`/users?${realParams}`);
  };

  const setSearchFilter = (search: string) => {
    const data = { ...userFiltersData, search };
    setUserFiltersData(data);
    setFilters(data);
  };

  const debouncedSetSearchFilter = useCallback(_.debounce(setSearchFilter, 500), [userFiltersData]);

  const handleAddUserClick = () => setAddNewUserModal(true);

  useEffect(() => {
    debouncedSetSearchFilter(searchInput);
  }, [searchInput]);

  useEffect(() => {
    if (!isLoading) refetch();
  }, [filterParams]);

  // Set Error message
  useEffect(() => {
    if (isError) {
      setMessage("Something Went Wrong.");
      setTotalRecords(0);
    } else if (data?.data?.length === 0) {
      setMessage("No Results Found.");
      setTotalRecords(0);
    } else {
      setMessage("");
      if (data?.meta?.totalCount) {
        setTotalRecords(data?.meta?.totalCount);
      }
    }
  }, [data, isError]);

  if (isLoading) return <Loader />;

  return (
    <Wrapper>
      <StyledHeader>Users</StyledHeader>
      <TableHeaderOptionsWrapper>
        <StyleButtonSearchWrapper>
          <Button
            size="lg"
            onClick={() => modalController({ filters: true })}
            variant="default"
            leftIcon={<SettingIcon />}
          >
            Filter
          </Button>

          <TextInput
            size="lg"
            onChange={e => setSearchInput(e.target.value)}
            value={searchInput}
            icon={<SearchIcon />}
          />
        </StyleButtonSearchWrapper>
        <Button size="lg" onClick={() => handleAddUserClick()} leftIcon={<PlusIcon />}>
          New User
        </Button>
      </TableHeaderOptionsWrapper>
      {filterParams.length ? (
        <UserFilterTiles
          filterParams={filterParams}
          setUserFiltersData={setUserFiltersData}
          userFiltersData={userFiltersData}
          setFilters={setFilters}
        />
      ) : null}
      <UserTable
        isError={isError}
        errorMessage={message}
        userListingList={data?.data || []}
        activePage={activePage}
        setPage={setPage}
        limit={defaultLimit}
        totalRecords={totalRecords}
        modalController={modalController}
        modal={modal}
      />

      <UserFilters
        open={modal.filters}
        setOpen={() => modalController({ filters: false })}
        setUserFiltersData={setUserFiltersData}
        userFiltersData={userFiltersData}
        setFilters={setFilters}
      />

      <AddInternalUser openModal={addNewUserModal} setOpenModal={setAddNewUserModal} />
    </Wrapper>
  );
};
