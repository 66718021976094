import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ErrorResponse } from "App";
import { QUERY_KEYS } from "constants/queryKeys";
import { errorModal } from "modules/error/ErrorHandlingModals";

import { putSupplierService } from "../api";

export const usePatchSupplier = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: putSupplierService,
    mutationKey: [QUERY_KEYS.patch_supplier],
    onSuccess: () => {
      queryClient.refetchQueries({ queryKey: [QUERY_KEYS.suppliers] });
      queryClient.refetchQueries({ queryKey: [QUERY_KEYS.supplier_by_id] });
    },
    onError: (error: ErrorResponse) => errorModal(error)
  });
};
