import { FallbackProps } from "react-error-boundary";

import styled from "@emotion/styled";
import { Button } from "@mantine/core";

const ErrorHeader = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 1.625rem;
  line-height: 150%;
  color: #c10840;
`;
const ErrorMessage = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 1.3125rem;
  color: #c10840;
`;

const ErrorHandler: React.FC<FallbackProps> = ({ error, resetErrorBoundary }) => {
  return (
    <div role="alert">
      <ErrorHeader>Something went wrong:</ErrorHeader>
      <ErrorMessage>{error.message}</ErrorMessage>
      <Button color="danger.8" onClick={resetErrorBoundary}>
        Try again
      </Button>
    </div>
  );
};
export default ErrorHandler;
