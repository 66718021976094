import { useMutation, useQueryClient } from "@tanstack/react-query";
import { QUERY_KEYS } from "constants/queryKeys";
import { TBuyspacesListResponse } from "types";

import { deleteBuyspaceService } from "../api";

export const useDeleteBuyspaceMutation = () => {
  const queryClient = useQueryClient();

  const buyspaceQueryKey = QUERY_KEYS.get_buyspaces;

  const mutation = useMutation({
    mutationFn: deleteBuyspaceService,
    mutationKey: [QUERY_KEYS.delete_buyspace],
    onMutate: async ({ buyspaceId }) => {
      await queryClient.cancelQueries({ queryKey: [buyspaceQueryKey] });

      const previousData: TBuyspacesListResponse | undefined = queryClient.getQueryData([buyspaceQueryKey]);

      if (previousData) {
        const newBuyspaceList = previousData.data.filter(({ id }) => id !== buyspaceId);

        const updatedValues = {
          data: newBuyspaceList,
          meta: previousData.meta
        };

        queryClient.setQueryData([buyspaceQueryKey], updatedValues);

        return { updatedValues };
      }

      return { previousData };
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [buyspaceQueryKey] });
    }
  });
  return mutation;
};
