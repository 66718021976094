import { FC, Fragment } from "react";

import { Menu } from "@mantine/core";
import { theme } from "constants/theme";
import { MenuHamburgerIcon } from "ui/icons";

import { TableTextWrapperIcon } from "./userTable.styles";
import { IUserTableMenuConfig, UserListingInterface } from "./userTable.types";

type Props = {
  getMenuConfigFunc: (element: UserListingInterface) => IUserTableMenuConfig[];
  userListing: UserListingInterface;
};

export const UserTableMenu: FC<Props> = ({ userListing, getMenuConfigFunc }) => {
  const menuConfig = getMenuConfigFunc(userListing).filter(i => i.visible);

  const renderNoOptions = <Menu.Item>No Actions</Menu.Item>;

  const renderMenuOptions = menuConfig.map((menuOption, menuOptionIndex) => {
    const { icon, label, handler } = menuOption;
    const showDivider = menuOptionIndex !== menuConfig.length - 1;
    return (
      <Fragment key={menuOptionIndex}>
        <Menu.Item
          key={"option" + menuOptionIndex}
          icon={icon}
          onClick={() => {
            handler(userListing);
          }}
        >
          {label}
        </Menu.Item>
        {showDivider ? <Menu.Divider /> : null}
      </Fragment>
    );
  });

  const menuOptions = menuConfig.length ? renderMenuOptions : renderNoOptions;

  return (
    <Menu {...theme.other?.menu}>
      <Menu.Target>
        <TableTextWrapperIcon>
          <MenuHamburgerIcon />
        </TableTextWrapperIcon>
      </Menu.Target>
      <Menu.Dropdown>{menuOptions}</Menu.Dropdown>
    </Menu>
  );
};
