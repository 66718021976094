import { UseFormReturnType } from "@mantine/form";
import { TUserFeatureFlagModalForm } from "modules/users";
import { TFeatureFlag } from "types";
import { SliderButton } from "ui/buttons/Switch";

import { UserFeatureFlagListContainer, UserFeatureFlagListItem } from "../styles";
import { featureFlagNameMap } from "./userFeatureFlagsModal.data";

export const UserFeatureFlagsList = ({
  features,
  formInstance
}: {
  features: TFeatureFlag[];
  formInstance: UseFormReturnType<TUserFeatureFlagModalForm>;
}) => {
  const { setFieldValue } = formInstance;

  const handleSliderButton = (feature: TFeatureFlag, checked: boolean) => {
    const updatedFeatures = features.map(i => {
      if (feature.name === i.name) return { ...i, isActive: checked };
      return i;
    });

    setFieldValue("features", updatedFeatures);
  };

  if (!features.length) return null;

  return (
    <UserFeatureFlagListContainer>
      {features.map((feature, index) => (
        <UserFeatureFlagListItem key={index}>
          <p>{featureFlagNameMap[feature.name]}</p>
          <SliderButton
            checked={feature.isActive}
            onChange={event => handleSliderButton(feature, event.target.checked)}
          />
        </UserFeatureFlagListItem>
      ))}
    </UserFeatureFlagListContainer>
  );
};
