import { useQueries } from "@tanstack/react-query";
import { CLASSIFICATION_SOURCE } from "constants/classification";
import { QUERY_KEYS } from "constants/queryKeys";

import { getClassificationService } from "../api";

export const useGetClassificationListQueries = ({
  codes = [],
  sourceType = CLASSIFICATION_SOURCE.naics_2017
}: {
  codes: string[];
  sourceType?: string;
}) => {
  return useQueries({
    queries: codes.map(code => {
      return {
        queryKey: [QUERY_KEYS.get_classification, { sourceType, code }],
        queryFn: () => getClassificationService({ params: { code }, sourceType })
      };
    })
  });
};
