import { addNewSupplierInitialValues } from "modules/suppliers/suppliers.data";
import axiosInstance from "services/AxiosInstance";
import { ResponseType, TCreateSupplierResponseData } from "types";

type TAddNewSupplierBody = typeof addNewSupplierInitialValues;

type PostSupplierServiceArgs = (apiArgs: {
  accountId: string;
  payload: Omit<TAddNewSupplierBody, "accountId" | "naicsCode"> & { naicsCode: string };
}) => Promise<ResponseType<TCreateSupplierResponseData>>;

export const postSupplierService: PostSupplierServiceArgs = async apiArgs => {
  const { accountId, payload } = apiArgs;
  const response = await axiosInstance.post(`/suppliers/${accountId}`, payload);
  return response.data;
};
