import { useQuery } from "@tanstack/react-query";
import { ErrorResponse } from "App";
import { getAccounts } from "modules/account/apis/Account";
import { errorModal } from "modules/error/ErrorHandlingModals";

const useGetAccounts = () => {
  return useQuery({
    queryKey: ["accounts"],
    queryFn: getAccounts,
    onError: (error: ErrorResponse) => errorModal(error),
    useErrorBoundary: true
  });
};
export default useGetAccounts;
