import axiosInstance from "services/AxiosInstance";

type PutTransactionImportArgs = {
  filename: string;
  id: string;
  transactionFileLocation: string;
};
export const putTransactionImport = async (payLoad: PutTransactionImportArgs) => {
  const requestPayload = {
    transactionFileLocation: payLoad.transactionFileLocation,
    filename: payLoad.filename
  };
  const response = await axiosInstance.put(`imports/${payLoad.id}/transactions`, requestPayload);
  return response.data;
};
