import { createContext, useContext } from "react";

import { ISupplier, ISupplierModals } from "./suppliers.types";

type SuppliersContextOptions = {
  modalController: (updatedModal: Partial<ISupplierModals>) => void;
  onSupplierStateChange: (supplier: ISupplier | null) => void;
};

const initialSuppliersContext: SuppliersContextOptions = {
  modalController: () => {},
  onSupplierStateChange: () => {}
};

export const SuppliersContext = createContext<SuppliersContextOptions>(initialSuppliersContext);

export const useSuppliersContext = () => {
  const context = useContext(SuppliersContext);

  if (!context) {
    throw new Error("useSuppliersContext must be used within a SuppliersContext.Provider");
  }

  return context;
};
