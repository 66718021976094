import { useEffect, useState } from "react";
import { matchRoutes, useLocation, useNavigate } from "react-router-dom";

import { Button, LoadingOverlay, Text } from "@mantine/core";
import { closeAllModals } from "@mantine/modals";
import { AxiosError } from "axios";
import jwt_decode from "jwt-decode";
import { confirmationModal } from "modules/error/ErrorHandlingModals";
import { getUserInfoService, JwtTokenInterface } from "modules/users";
import Loader from "ui/feedback/Loader";

const publicRoutes = [{ path: "/" }, { path: "/unauthorized" }, { path: "/questionnaire/request/:id" }];

export const AuthGuard = ({ children }: { children: JSX.Element }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const isPublicRoute = matchRoutes(publicRoutes, location);

  const [valid, setValid] = useState(false);

  const onConfirm = () => {
    localStorage.clear();
    navigate("/");
    setValid(true);
  };

  const handleRedirectConfirmation = () => {
    const timeout = setTimeout(() => {
      closeAllModals();
      onConfirm();
    }, 5000);

    confirmationModal({
      title: <Text c="klp">You were logged out</Text>,
      message: "You will be redirected to login screen in 5 seconds",
      buttonChildren: (
        <Button
          onClick={() => {
            closeAllModals();
            clearTimeout(timeout);
            onConfirm();
          }}
          size="lg"
        >
          Redirect Now
        </Button>
      ),
      withCloseButton: false,
      zIndex: 9999999999
    });
  };

  const errorAuth = (err: AxiosError) => {
    const errorStatus = (err as AxiosError)?.response?.status;

    if (errorStatus === 403) return handleRedirectConfirmation();

    if (errorStatus === 401) return handleRedirectConfirmation();

    setValid(true);
  };

  const resolveAuth = async (JWT: string) => {
    await getUserInfoService(jwt_decode<JwtTokenInterface>(JWT).sub);

    setValid(true);
  };

  const auth = async () => {
    const JWT = localStorage.getItem("jwt_Token") ?? null;
    if (isPublicRoute) return setValid(true);

    if (JWT === null) return onConfirm();

    if (!JWT) return handleRedirectConfirmation();

    try {
      await resolveAuth(JWT);
    } catch (err) {
      errorAuth(err as AxiosError);
    }
  };

  useEffect(() => {
    auth();
  }, []);

  if (!valid) return <LoadingOverlay visible overlayBlur={2} loader={<Loader />} />;

  return <div>{children}</div>;
};
