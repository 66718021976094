import { useMemo } from "react";

import AWS from "aws-sdk";
import moment from "moment";
import { QuestionnaireValidateReady } from "types";
import { FileInput } from "ui";
import { getFileExtension } from "utils";

import { forbiddenExtensions } from "../questionnaire.data";
import { QuestionnaireFileWrapper } from "../questionnaire.styles";

const REGION = process.env.REACT_APP_REGION;
const S3_BUCKET = process.env.REACT_APP_BUCKET_NAME;

AWS.config.update({
  accessKeyId: process.env.REACT_APP_ACCESS,
  secretAccessKey: process.env.REACT_APP_SECRET
});

const myBucket = new AWS.S3({
  params: { Bucket: S3_BUCKET },
  region: REGION
});

type QuestionnaireFileProps = {
  data: QuestionnaireValidateReady;
  group: string;
  onChange: (response: string | string[], id: string, group: string, loading?: boolean, error?: string) => void;
  submitted?: boolean;
};

const QuestionnaireFile: React.FC<QuestionnaireFileProps> = ({ data, group, onChange, submitted }) => {
  const responseFile = useMemo(() => {
    if (data.response) return new File([""], data.response as string);
    return null;
  }, [data]);

  const handleChange = async (file: File | null) => {
    if (submitted) {
      return;
    }
    if (file) {
      const extension = getFileExtension(file.name);

      const isValid = !forbiddenExtensions.includes(extension);

      if (!isValid) {
        onChange(
          "",
          data.id,
          group,
          false,
          "This extension is forbidden. Please change your file to the common extension."
        );
        return;
      }

      if (S3_BUCKET) {
        onChange("", data.id, group, true, "");

        const newFileName = `${file.name}-${moment.now()}.${extension}`;

        const params: AWS.S3.PutObjectRequest = {
          ACL: "private",
          Body: file,
          ContentType: file.type,
          Bucket: S3_BUCKET,
          Key: newFileName
        };

        try {
          await myBucket.putObject(params).promise();
          onChange(newFileName, data.id, group, false, "");
        } catch (e) {
          onChange("", data.id, group, false, "Error: File has been not uploaded.");
        }
      }
      return;
    }
    onChange("", data.id, group, false, "");
  };

  return (
    <QuestionnaireFileWrapper>
      <FileInput
        onChange={handleChange}
        label={data.question}
        error={data.error}
        required={data.required}
        readOnly={submitted}
        value={responseFile}
      />
    </QuestionnaireFileWrapper>
  );
};

export default QuestionnaireFile;
