import styled from "@emotion/styled";

export const StyledMenuTarget = styled.div`
  display: flex;
  justifycontent: center;
  alignitems: center;
  cursor: pointer;
  width: fit-content;
`;

export const TableWrapper = styled.div`
  overflow: auto;
  height: auto;
  margin-top: ${props => props.theme.other.table.marginTop};
  margin-bottom: ${props => props.theme.other.table.marginBottom};
`;

export const StatusActive = styled.div`
  font-family: ${props => props.theme.other.font.fontFamily};
  font-style: ${props => props.theme.other.font.fontStyle};
  font-weight: 700;
  font-size: ${props => props.theme.other.font.fontSize};
  line-height: ${props => props.theme.other.font.lineHeight};
  color: ${props => props.theme.other.primaryColor};
  display: flex;
  align-items: center;
  gap: 6px;
`;

export const StatusPending = styled.div`
  font-family: ${props => props.theme.other.font.fontFamily};
  font-style: ${props => props.theme.other.font.fontStyle};
  font-weight: 700;
  font-size: ${props => props.theme.other.font.fontSize};
  line-height: ${props => props.theme.other.font.lineHeight};
  color: ${props => props.theme.other.otherColor[1]};
  display: flex;
  align-items: center;
  gap: 6px;
`;
export const StatusSuspended = styled.div`
  font-family: ${props => props.theme.other.font.fontFamily};
  font-style: ${props => props.theme.other.font.fontStyle};
  font-weight: 700;
  font-size: ${props => props.theme.other.font.fontSize};
  line-height: ${props => props.theme.other.font.lineHeight};
  color: ${props => props.theme.other.warningColor};
  display: flex;
  align-items: center;
  gap: 6px;
`;
