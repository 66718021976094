import styled from "@emotion/styled";
import { TAccountStatus } from "types";

export const StatusCell = styled.div<{ status: TAccountStatus }>`
  font-family: ${props => props.theme.other.font.fontFamily};
  font-style: ${props => props.theme.other.font.fontStyle};
  font-weight: 700;
  font-size: ${props => props.theme.other.font.fontSize};
  line-height: ${props => props.theme.other.font.lineHeight};
  color: ${({ theme, status }) => {
    if (status === "ACTIVE") {
      return theme.other.primaryColor;
    }
    if (status === "PENDING") {
      return theme.other.otherColor[1];
    }
    if (status === "SUSPENDED") {
      return theme.other.warningColor;
    }
    return theme.other.monochromeColor[0];
  }};
  display: flex;
  align-items: center;
  gap: 6px;
`;
