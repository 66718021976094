import { useEffect } from "react";

import { successModal } from "modules/error/ErrorHandlingModals";
import { IBuyspace } from "types";
import { ModalTitle } from "ui";
import Modal from "ui/overlays/Modal";

import { conditionKeyToDataKey } from "../buyspaces.data";
import { useUpdateBuyspaceMutation } from "../hooks";
import { BuyspaceCondition } from "../types";
import { useBuyspaceContext } from "./BuyspaceContext";
import { BuyspaceForm } from "./BuyspaceForm";

type UpdateBuyspaceModalProps = IBuyspace & { onClose: () => void; opened: boolean };

const conditionKeys = ["SUPPLIER_ID", "ITEM_ID", "COST_CENTER_ID", "NAICS_CODE", "NAICS_INDUSTRY", "NAICS_SECTOR"];

export const UpdateBuyspaceModal: React.FC<UpdateBuyspaceModalProps> = ({
  opened,
  onClose,
  name,
  conditions,
  account,
  id
}) => {
  const { handleSetData, resetBuyspaceData } = useBuyspaceContext();

  const updateBuyspaceMutation = useUpdateBuyspaceMutation();

  const onUpdateBuyspace = (payload: { conditions: BuyspaceCondition[]; name: string }) => {
    updateBuyspaceMutation.mutate(
      {
        buyspaceId: id,
        payload: { ...payload, accountId: account.id }
      },
      {
        onSuccess: () => {
          successModal("Buyspace was successfully updated");
          onClose();
        }
      }
    );
  };
  useEffect(() => {
    if (opened && conditions) {
      conditions.forEach(condition => {
        if (conditionKeys.includes(condition.key)) {
          const labels = typeof condition.label === "string" ? [condition.label] : condition.label;
          const values =
            typeof condition.value === "string" || typeof condition.value === "number"
              ? [condition.value]
              : condition.value;

          const options = values.map((value, idx) => ({ value: value.toString(), label: labels[idx] }));

          const conditionKey = condition.key as keyof typeof conditionKeyToDataKey;

          const key = conditionKeyToDataKey[conditionKey];

          handleSetData(key, options);
        }
      });
      return;
    }
    resetBuyspaceData();
  }, [opened, conditions]);

  const conditionWithProperKey = conditions.map(condition => {
    const value =
      typeof condition.value === "string" || typeof condition.value === "number"
        ? [condition.value.toString()]
        : condition.value;

    const key = [condition.key];

    return { ...condition, key, value };
  });

  return (
    <Modal opened={opened} handleClose={onClose} withCloseButton>
      <ModalTitle>Update Buyspace</ModalTitle>
      <BuyspaceForm
        onClose={onClose}
        onSubmit={onUpdateBuyspace}
        initialValues={{ name, conditions: conditionWithProperKey }}
      />
    </Modal>
  );
};
