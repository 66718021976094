import axiosInstance from "services/AxiosInstance";
import { ResponseType, TQuestionnaire, TQuestionnairePayload } from "types";

export const postQuestionnaireService = async ({
  apiArgs,
  payload
}: {
  apiArgs: { accountId: string };
  payload: TQuestionnairePayload;
}): Promise<ResponseType<TQuestionnaire>> => {
  const { accountId } = apiArgs;
  const response = await axiosInstance.post(`questionnaire/${accountId}`, payload);
  return response.data;
};
