import styled from "@emotion/styled";

export const StyleSubTitle = styled.div`
  font-family: ${props => props.theme.other.font.fontFamily};
  font-style: ${props => props.theme.other.font.fontStyle};
  font-weight: 700;
  font-size: 1rem;
  line-height: 1rem;
  color: ${props => props.theme.other.monochromeColor[1]};
  margin-top: 1.3125rem;
`;
export const StyleDescription = styled.div`
  font-family: ${props => props.theme.other.font.fontFamily};
  font-style: ${props => props.theme.other.font.fontStyle};
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  color: ${props => props.theme.other.monochromeColor[1]};
  margin-top: 1.5rem;
`;

export const StyledAccountSelectWrapper = styled.div<{ isInternal: boolean }>`
  margin-top: 1.5rem;
  width: ${props => (props.isInternal ? "50%" : "100%")};
`;

export const StyleFooter = styled.div`
  height: fit-content;
  display: flex;
  justify-content: space-between;

  @media only screen and (max-width: 610px) {
    gap: 10px;
    justify-content: center;
    align-items: center;
  }
`;
export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  padding-top: 2rem;
  gap: 28px;
  @media only screen and (max-width: 430px) {
    display: grid;
    justify-content: center;
  }
`;

export const ButtonUploadWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 0.421875rem;
  @media only screen and (max-width: 440px) {
    justify-content: center;
  }
`;
export const StyleButtonWrapper = styled.div`
  display: flex;
  gap: 13px;
  @media only screen and (max-width: 610px) {
    display: grid;
    gap: 10px;
    justify-content: center;
    align-items: center;
  }
`;
export const StyleDropzoneWrapper = styled.div`
  width: 100%;
  margin-top: 1.5rem;
  height: 100%;
  min-height: 17.1875rem;
`;
export const StyleDropzone = styled.div`
  width: 100%;
  height: 22.9375rem;
  border: 1px dashed ${props => props.theme.other.monochromeColor[2]};
  @media only screen and (max-width: 580px) {
    height: 15.625rem;
  }
  @media only screen and (max-width: 420px) {
    height: 11.25rem;
  }
`;
export const DropZoneStyle = styled.div`
  width: calc(100% - 0.75rem);
  height: calc(100% - 0.75rem);
  margin: 0.375rem;
  background-color: rgba(239, 241, 243, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;
export const DropZoneWrapper = styled.div`
  max-height: 240px;
  height: 100%;
  width: 50%;
  .dzu-input {
    display: none;
  }
  .dzu-previewContainer {
    display: none;
  }
  .dzu-submitButtonContainer {
    display: flex !important;
    margin-left: 20px;
  }
  .dzu-submitButton {
    width: 152px;
    height: 52px;
    font-size: 1rem;
    color: ${props => props.theme.other.monochromeColor[2]};
    border-radius: 50px;
    border: 1px solid ${props => props.theme.other.monochromeColor[2]} !important;
    background: transparent;
    font-family: ${props => props.theme.other.font.fontFamily};
    font-style: ${props => props.theme.other.font.fontStyle};
    line-height: 1.1875rem;
    cursor: pointer;
    padding: 0px;
    margin: 0px;
  }
`;
export const InnerWrapper = styled.div`
  width: calc(100% - 10px);
  height: calc(100% - 10px);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  cursor: pointer;
`;
export const DropZoneText = styled.div`
  font-family: ${props => props.theme.other.font.fontFamily};
  font-style: ${props => props.theme.other.font.fontStyle};
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.0625rem;
  color: ${props => props.theme.other.monochromeColor[2]};
  @media only screen and (max-width: 1000px) {
    padding: 5px;
  }
`;
export const DropzoneWrapper = styled.div`
  display: grid;
  justify-content: center;
  align-items: center;
  width: 100%;
  text-align: center;
  max-width: 180px;
`;
export const StyleValidationMessagePlaceHolder = styled.div`
  font-family: ${props => props.theme.other.font.fontFamily};
  font-style: ${props => props.theme.other.font.fontStyle};
  font-weight: 700;
  font-size: 0.75rem;
  line-height: 0.9375rem;
  color: ${props => props.theme.other.monochromeColor[2]};
  border-bottom: 1px solid #c4c4c4;
  padding-bottom: 0.2rem;
  margin-top: 1.125rem;
`;
