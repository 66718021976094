import { ColumnDef } from "@tanstack/react-table";
import { TBreakdownDashboardDataSource, TMethodologyObject } from "types";
import { prettyMoney, prettyNumber } from "utils/formatting.utils";

import { MILLIONS_OF_DOLLARS } from "../utils";
import { MethodologyCell } from "./MethodologyCell";
import {
  ColorIndicator,
  TableCell,
  TableHeadCellCentered,
  TableHeadNameCell,
  TableNameCell,
  TableNameCellWrapper
} from "./styles";

export const columns: ColumnDef<TBreakdownDashboardDataSource>[] = [
  {
    header: () => <TableHeadNameCell>Name</TableHeadNameCell>,
    accessorKey: "description",
    enableSorting: true,
    minSize: 430,
    cell: info => {
      const name = info.getValue();
      const id = info.row.original.id;

      const activeRow = info.table.options.meta?.activeRow;
      const active = id === activeRow;

      return (
        <TableNameCell>
          <TableNameCellWrapper active={active}>
            <>
              <ColorIndicator style={{ background: info?.row?.original?.colorCode }} />
              {name}
            </>
          </TableNameCellWrapper>
        </TableNameCell>
      );
    }
  },
  {
    header: "",
    accessorKey: "methodology",
    size: 50,
    enableSorting: false,
    cell: info => {
      const methodology = info.getValue() as TMethodologyObject;
      return <MethodologyCell methodology={methodology} width={50} />;
    }
  },
  {
    header: () => <TableHeadCellCentered>CO₂e Tons</TableHeadCellCentered>,
    accessorKey: "CO2Tons",
    enableSorting: true,
    size: 120,
    cell: info => {
      const value = info.getValue() as number;
      return <TableCell>{prettyNumber(value)}</TableCell>;
    }
  },
  {
    header: () => <TableHeadCellCentered>Spend</TableHeadCellCentered>,
    accessorKey: "spend",
    enableSorting: true,
    size: 90,
    cell: info => {
      const value = info.getValue() as number;
      return <TableCell>{prettyMoney(value)}</TableCell>;
    }
  },
  {
    header: () => <TableHeadCellCentered>Carbon Intensity</TableHeadCellCentered>,
    accessorKey: "carbonIntensity",
    enableSorting: true,
    sortingFn: (rowA, rowB) => {
      const previousValue = rowA.original.CO2Tons / (rowA.original.spend / MILLIONS_OF_DOLLARS);
      const nextValue = rowB.original.CO2Tons / (rowB.original.spend / MILLIONS_OF_DOLLARS);

      return previousValue < nextValue ? -1 : previousValue > nextValue ? 1 : 0;
    },
    size: 90,
    cell: info => {
      const { CO2Tons, spend } = info.row.original;
      const overallCarbonIntensity = CO2Tons / (spend / MILLIONS_OF_DOLLARS);
      const carbonIntensityValue = prettyNumber(overallCarbonIntensity);

      return <TableCell>{carbonIntensityValue}</TableCell>;
    },
    meta: {
      align: "center"
    }
  }
];
