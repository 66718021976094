import { useQuery, UseQueryOptions, UseQueryResult } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { QUERY_KEYS } from "constants/queryKeys";
import { ResponseError, TAccount } from "types";

import { getAccountListWithSummaryService } from "../api";

type ApiArgs = {
  accountIds: string[];
  limit: number;
  page: number;
};

type ApiResponse = { data: (TAccount & { suppliers: number; transactions: number })[]; meta: { totalCount: number } };

type useGetAccountListWithSummaryQueryArgs = (
  apiArgs: ApiArgs,
  options?: UseQueryOptions<ApiResponse, AxiosError<ResponseError>>
) => UseQueryResult<ApiResponse, AxiosError<ResponseError>>;

export const useGetAccountListWithSummaryQuery: useGetAccountListWithSummaryQueryArgs = (apiArgs, options = {}) =>
  useQuery({
    queryKey: [QUERY_KEYS.accounts_listing, { ...apiArgs }],
    queryFn: () => getAccountListWithSummaryService(apiArgs),
    ...options
  });
