import * as React from "react";

export const UserIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
    <path
      d="M6.48438 7.65625C6.48438 9.59477 8.06148 11.1719 10 11.1719C11.9385 11.1719 13.5156 9.59477 13.5156 7.65625C13.5156 5.71773 11.9385 4.14062 10 4.14062C8.06148 4.14062 6.48438 5.71773 6.48438 7.65625ZM12.7344 7.65625C12.7344 9.16398 11.5077 10.3906 10 10.3906C8.49227 10.3906 7.26562 9.16398 7.26562 7.65625C7.26562 6.14852 8.49227 4.92188 10 4.92188C11.5077 4.92188 12.7344 6.14852 12.7344 7.65625Z"
      fill="currentColor"
    />
    <path
      d="M17.1875 3.98438C17.4032 3.98438 17.5781 3.80949 17.5781 3.59375C17.5781 3.37801 17.4032 3.20312 17.1875 3.20312C16.9718 3.20312 16.7969 3.37801 16.7969 3.59375C16.7969 3.80949 16.9718 3.98438 17.1875 3.98438Z"
      fill="currentColor"
    />
    <path
      d="M10 20C15.5431 20 20 15.5107 20 10C20 8.16527 19.4998 6.37172 18.5535 4.8132C18.4415 4.62883 18.2013 4.57012 18.0168 4.68203C17.8324 4.79402 17.7737 5.03426 17.8857 5.21867C18.7578 6.65496 19.2188 8.30832 19.2188 10C19.2188 12.673 18.0949 15.1522 16.1142 16.9017C15.5112 14.0576 12.9894 11.9531 10 11.9531C7.01062 11.9531 4.48848 14.0578 3.88613 16.902C1.90523 15.1523 0.78125 12.673 0.78125 10C0.78125 4.91676 4.91676 0.78125 10 0.78125C12.0699 0.78125 14.0246 1.4498 15.653 2.71469C15.8233 2.84703 16.0688 2.81617 16.2011 2.64582C16.3334 2.47547 16.3026 2.23004 16.1322 2.0977C14.3656 0.725352 12.2451 0 10 0C7.32672 0 4.81492 1.03969 2.92723 2.9275C1.03957 4.81523 0 7.32695 0 10C0 15.5246 4.47105 20 10 20ZM10 12.7344C12.7616 12.7344 15.0539 14.7516 15.4188 17.4612C13.8386 18.6117 11.9699 19.2188 10 19.2188C8.03031 19.2188 6.16188 18.6116 4.5816 17.4611C4.94598 14.7515 7.23809 12.7344 10 12.7344Z"
      fill="currentColor"
    />
  </svg>
);
