import { ColumnDef } from "@tanstack/react-table";
import { TQuestionnaireRequest } from "types";
import { getFullDayYear, getFullDayYearTime } from "utils";

import { QuestionnaireStatusCell } from "../questionnaire.styles";
import { QuestionnaireTableActionCell } from "./QuestionnaireTableActionCell";

export const columns: ColumnDef<TQuestionnaireRequest>[] = [
  {
    accessorKey: "questionnaire.title",
    header: "Name",
    enableSorting: false,
    size: 150
  },
  {
    accessorKey: "sendTo",
    header: "Sent To",
    enableSorting: false
  },
  {
    accessorKey: "respondBy",
    header: "Respond By",
    enableSorting: false,
    cell: props => getFullDayYear(props.row.original.respondBy)
  },
  {
    accessorKey: "updatedAt",
    header: "Last Update",
    enableSorting: false,
    cell: props => getFullDayYearTime(props.row.original.updatedAt),
    size: 200
  },
  {
    accessorKey: "status",
    header: "Status",
    enableSorting: false,
    cell: props => (
      <QuestionnaireStatusCell>{props.row.original.status.toLowerCase().replace("_", " ")}</QuestionnaireStatusCell>
    )
  },
  {
    id: "action",
    header: "Action",
    enableSorting: false,
    cell: props => <QuestionnaireTableActionCell {...props.row.original} />,
    meta: {
      align: "center"
    },
    size: 50
  }
];
