import { useSearchParams } from "react-router-dom";

import { CLASSIFICATION_LEVEL } from "constants/classification";
import { useGetClassificationListQueries } from "modules/classification";
import { IndustryClassification } from "types";

export const useGetClassificationData = () => {
  const [searchParams] = useSearchParams();

  const naicsCodes = searchParams.getAll("naicsCodes[]");
  const naicsSectors = searchParams.getAll("naicsSectors[]");
  const naicsIndustries = searchParams.getAll("naicsIndustries[]");

  const codes = [...naicsCodes, ...naicsSectors, ...naicsIndustries];

  const queries = useGetClassificationListQueries({ codes });

  const data = queries.map(({ data }) => {
    const codeData = data?.data[0] as IndustryClassification;
    return codeData;
  });

  const naicsDetailCodes = data.filter(naicsData => naicsData?.level === CLASSIFICATION_LEVEL.detail);
  const naicsSectorCodes = data.filter(naicsData => naicsData?.level === CLASSIFICATION_LEVEL.sector);
  const naicsIndustryCodes = data.filter(naicsData => naicsData?.level === CLASSIFICATION_LEVEL.industry);

  return { naicsDetailCodes, naicsSectorCodes, naicsIndustryCodes };
};
