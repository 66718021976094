import React from "react";

import { CheckboxProps, Checkbox as MT_Checkbox } from "@mantine/core";
import CheckboxChecked from "ui/icons/CheckboxChecked.png";
import CheckboxCheckedRed from "ui/icons/CheckboxCheckedRed.png";
import CheckboxUnchecked from "ui/icons/CheckboxUnchecked.png";

interface CheckBoxInterface {
  showPrimaryChecked?: boolean;
}

const Checkbox: React.FC<CheckBoxInterface & CheckboxProps> = ({ showPrimaryChecked = true, ...props }) => {
  const CheckboxIcon: CheckboxProps["icon"] = () => <></>;

  return (
    <MT_Checkbox
      {...props}
      icon={CheckboxIcon}
      styles={{
        label: {
          fontFamily: "Inter",
          fontStyle: "normal",
          fontWeight: 400,
          fontSize: "1rem",
          lineHeight: "17px",
          color: "#5C5C5C",
          paddingLeft: "7px",
          marginTop: "auto",
          marginBottom: "auto"
        },
        input: {
          backgroundRepeat: "no-repeat",
          cursor: "pointer",
          background: `url(${CheckboxUnchecked})`,
          backgroundSize: "20px 20px",
          border: "none !important",
          borderRadius: "2px",
          width: "20px",
          height: "20px",
          "&:checked": {
            backgroundRepeat: "no-repeat",
            background: `url(${showPrimaryChecked ? CheckboxChecked : CheckboxCheckedRed})`,
            backgroundSize: "20px 20px",
            border: "none !important"
          },
          icon: {
            display: "none !important"
          }
        }
      }}
    />
  );
};
export default Checkbox;
