import { useContext } from "react";

import { getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { ImportInterface } from "modules/imports/types";
import { CurrentUserContext } from "modules/login";
import { Table } from "ui";

import { columns } from "./columns";

export const ImportTable: React.FC<{ data: ImportInterface[] }> = ({ data = [] }) => {
  const {
    userDetails: { userType }
  } = useContext(CurrentUserContext);

  const columnsByRole = userType !== "INTERNAL" ? columns.filter(column => column.header !== "Account") : columns;

  const table = useReactTable({
    data: data || [],
    columns: columnsByRole,
    getCoreRowModel: getCoreRowModel()
  });

  return <Table<ImportInterface> table={table} />;
};
