import styled from "@emotion/styled";

export const Container = styled.div`
  display: flex;
  gap: 40px;
  justify-content: center;
  height: calc(100% - 300px);
  padding-top: 60px;
  padding-bottom: 140px;
  position: relative;
  @media only screen and (max-width: 1366px) {
    display: block;
    justify-content: center;
    align-items: center;
    width: calc(100% - 20px);
    margin-left: auto;
    margin-right: auto;
  }
`;
export const TableWrapper = styled.div`
  height: 100%;
`;

export const PaginationWrapper = styled.div`
  max-width: 800px;
  @media only screen and (max-width: 1366px) {
    margin: 0 auto;
  }
`;

export const ChartWrapper = styled.div`
  @media only screen and (max-width: 1366px) {
    margin-top: 42px;
  }
`;
export const StyleSelectWrapperOverview = styled.div`
  display: flex;
  width: 100%;
  padding: 15px 0;
  gap: 12px;
  @media only screen and (max-width: 1440px) {
    gap: 15px;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    width: fit-content;
  }
  @media only screen and (max-width: 1000px) {
    .mantine-Select-root {
      margin: 0;
    }
  }
`;

export const ChartContentWrapper = styled.div`
  margin: auto;
  max-width: 225px;
  width: 100%;
  display: grid;
  justify-content: center;
  align-items: center;
`;

export const StyleLabel = styled.div`
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 1.375rem;
  text-align: center;
  margin-bottom: 24px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  gap: 7px;

  svg {
    color: ${({ theme }) => theme.other.primaryColor};
  }

  @media (max-width: 768px) {
    position: sticky;
    left: 0;
  }
`;

export const StyleValue = styled.div`
  font-weight: 700;
  font-size: 3.125rem;
  line-height: 3.8125rem;
  text-align: center;
  margin-bottom: 2px;
`;

export const StyleDescription = styled.div`
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.1875rem;
  text-align: center;
  text-transform: capitalize;
  width: 172px;
  margin-left: auto;
  margin-right: auto;
`;

export const NoResultsFound = styled.div`
  font-weight: 700;
  font-size: 2.34rem;
  line-height: 3.8125rem;
  margin-top: 1rem;
  color: #32a752;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyleCardsWrapper = styled.div`
  padding-bottom: 24px;
`;

export const ChartContainer = styled.div`
  position: relative;
  width: 550px;
  height: 250px;
  border: 1px solid ${props => props.theme.other.monochromeColor[3]};
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  &:first-of-type {
    margin-bottom: 20px;
  }
  @media only screen and (max-width: 1366px) {
    width: 100%;
    max-width: 820px;
    margin-left: auto;
    margin-right: auto;
  }
  @media only screen and (max-width: 550px) {
    display: grid;
    height: auto;
    justify-content: center;
    padding: 10px;
    width: calc(100% - 20px);
  }
`;

export const OverviewChartContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 65px;
  height: calc(100% - 251px);
  @media only screen and (max-height: 1480px) {
    margin-bottom: 100px;
  }
  @media only screen and (max-width: 1366px) {
    display: grid;
  }
  @media only screen and (max-width: 1150px) {
    display: block;
    justify-content: center;
    align-items: center;
    width: calc(100% - 20px);
    margin-left: auto;
    margin-right: auto;
  }
`;

export const ActivityTitle = styled.h3`
  margin-bottom: 24px;
  font-size: 1.125rem;
  text-align: left;
  color: ${props => props.theme.other.monochromeColor[2]};
  @media only screen and (max-width: 1366px) {
    width: 100%;
    text-align: center;
  }
`;
