import axiosInstance from "services/AxiosInstance";

import { BuyspacePayload, UpdateBuyspaceResponse } from "../types";

export const putBuyspaceService = async (apiArgs: {
  buyspaceId: string;
  payload: BuyspacePayload;
}): Promise<UpdateBuyspaceResponse> => {
  const { buyspaceId, payload } = apiArgs;
  const response = await axiosInstance.put(`buyspace/${buyspaceId}`, payload);
  return response.data;
};
