import { ErrorBoundary } from "react-error-boundary";

import { Questionnaire } from "modules/questionnaire";
import ErrorHandler from "ui/errors/ErrorHandler";
import PrivateLayout from "ui/layouts/PrivateLayout";
import PublicLayout from "ui/layouts/PublicLayout";
import isAuthenticate from "utils/auth.utils";

const QuestionnaireScreen = () => {
  const Layout = isAuthenticate() ? PrivateLayout : PublicLayout;

  return (
    <Layout showSubHeader={false}>
      <ErrorBoundary FallbackComponent={ErrorHandler}>
        <Questionnaire />
      </ErrorBoundary>
    </Layout>
  );
};

export default QuestionnaireScreen;
