import { Button, Divider, Select, TextInput } from "@mantine/core";
import { useForm, yupResolver } from "@mantine/form";
import { useQueryClient } from "@tanstack/react-query";
import { QUERY_KEYS } from "constants/queryKeys";
import { successModal } from "modules/error/ErrorHandlingModals";
import { ISupplier } from "modules/suppliers/suppliers.types";
import { QuestionnaireStatus, TQuestionnaire } from "types";

import { useGetQuestionnaireListQuery, usePostQuestionnaireRequestMutation } from "../hooks";
import { questionnaireResponseSchema } from "../questionnaire.data";
import {
  QuestionnaireInputError,
  QuestionnaireRequestCalendar,
  QuestionnaireRequestModalContainer,
  QuestionnaireRequestModalRow,
  QuestionnaireRequestModalRowContent
} from "../questionnaire.styles";

type QuestionnaireRequestModalProps = {
  opened: boolean;
  setOpened: () => void;
  supplier: ISupplier;
};

type TQuestionnaireResponseForm = {
  questionnaireId: string;
  respondBy: string;
  sendTo: string;
  sendToFirstName: string;
  sendToLastName: string;
  supplierId: string;
};

export const QuestionnaireRequestModal = ({ supplier, opened, setOpened }: QuestionnaireRequestModalProps) => {
  const { id, name, account } = supplier;

  const queryClient = useQueryClient();

  const questionnaireReqMutation = usePostQuestionnaireRequestMutation();

  const questionnaireListQuery = useGetQuestionnaireListQuery({
    accountId: account.id,
    apiParams: { status: [QuestionnaireStatus.ACTIVE] }
  });

  const questionnaireResponseForm = useForm<TQuestionnaireResponseForm>({
    initialValues: {
      supplierId: id,
      questionnaireId: "",
      sendTo: "",
      sendToFirstName: "",
      sendToLastName: "",
      respondBy: ""
    },
    validate: yupResolver(questionnaireResponseSchema)
  });

  const sendRequest = (payload: TQuestionnaireResponseForm) => {
    questionnaireReqMutation.mutate(
      {
        accountId: account.id,
        payload
      },
      {
        onSuccess: () => {
          setOpened();
          successModal(
            `Your questionnaire request for ${name} was sent to ${questionnaireResponseForm.values.sendTo}.`
          );
          questionnaireResponseForm.reset();
          queryClient.refetchQueries([QUERY_KEYS.get_questionnaire_request_list]);
        }
      }
    );
  };

  const normalizeSelectData = (questionnaireList?: TQuestionnaire[]) => {
    if (questionnaireList) {
      return questionnaireList.map(q => ({ label: q.title, value: q.id }));
    }
    return [];
  };

  return (
    <QuestionnaireRequestModalContainer opened={opened} setOpened={setOpened} withCloseButton>
      <form onSubmit={questionnaireResponseForm.onSubmit(sendRequest)}>
        <QuestionnaireRequestModalRow>
          <h1>New Supplier Request</h1>
          <p>
            <span>Supplier Name: </span>
            {name}
          </p>
          <Select
            placeholder="Select Questionnaire"
            data={normalizeSelectData(questionnaireListQuery?.data?.data)}
            {...questionnaireResponseForm.getInputProps("questionnaireId")}
          />
        </QuestionnaireRequestModalRow>

        <QuestionnaireRequestModalRow>
          <h2>Due Date</h2>
          <Divider mb={30} />
          <QuestionnaireRequestModalRowContent alc>
            <div>
              <QuestionnaireRequestCalendar
                fullWidth
                {...questionnaireResponseForm.getInputProps("respondBy")}
                error={questionnaireResponseForm.getInputProps("respondBy").error}
              />
              <QuestionnaireInputError>
                {questionnaireResponseForm.getInputProps("respondBy").error}
              </QuestionnaireInputError>
            </div>
          </QuestionnaireRequestModalRowContent>
        </QuestionnaireRequestModalRow>

        <QuestionnaireRequestModalRow>
          <h2>Contact Information</h2>
          <Divider mb={30} />
          <QuestionnaireRequestModalRowContent>
            <TextInput
              w={"100%"}
              withAsterisk
              label="Email Address"
              {...questionnaireResponseForm.getInputProps("sendTo")}
            />
            <TextInput
              w={"100%"}
              withAsterisk
              label="First Name"
              {...questionnaireResponseForm.getInputProps("sendToFirstName")}
            />
            <TextInput
              w={"100%"}
              withAsterisk
              label="Last Name"
              {...questionnaireResponseForm.getInputProps("sendToLastName")}
            />
          </QuestionnaireRequestModalRowContent>
        </QuestionnaireRequestModalRow>

        <QuestionnaireRequestModalRow>
          <QuestionnaireRequestModalRowContent alc>
            <Button type="submit">Send Request</Button>
          </QuestionnaireRequestModalRowContent>
        </QuestionnaireRequestModalRow>
      </form>
    </QuestionnaireRequestModalContainer>
  );
};
