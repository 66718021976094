import { graphColorKey } from "constants/colorPalette";
import moment from "moment-timezone";
import { TAnalyticsChartData, TAnalyticsData, TAnalyticsResponse, TAnalyticsTabularData } from "types";
import { sortArray } from "utils/common.utils";

export const MILLIONS_OF_DOLLARS = 1_000_000 * 100;

const groupBrokenValues = ["null", "n/a", "n/"];

export const convertToTimeSeriesData = (inputs: TAnalyticsData[] | null) => {
  const xAxis: string[] = [];
  const y1Axis: number[] = [];
  const y2Axis: number[] = [];
  const y3Axis: number[] = [];

  if (inputs?.length && inputs[0].interval.length) {
    inputs.forEach(input => {
      xAxis.push(input?.interval[0] ? moment(input?.interval[0]).tz("Etc/UTC").format("MMM-YYYY") : "");

      y1Axis.push(input.spend);

      const co2e = Number(input?.co2e.toFixed(2));
      y2Axis.push(co2e);

      const carbonIntensity = input.co2e / (input.spend / MILLIONS_OF_DOLLARS);

      y3Axis.push(carbonIntensity);
    });
  }
  return {
    xAxis,
    series: [
      {
        name: "Spend",
        data: y1Axis
      },
      {
        name: "CO₂ Equivalent Emissions",
        data: y2Axis
      },
      {
        name: "Carbon Intensity",
        data: y3Axis
      }
    ]
  };
};

const formatGroupItem = (item?: string) => {
  if (!item) return "";
  return groupBrokenValues.includes(item) ? "" : item;
};

export const convertToTabular = (
  groupData: TAnalyticsResponse[],
  summaryData: TAnalyticsData,
  colorCodesList: string[]
): TAnalyticsTabularData[] => {
  const tabularData = groupData
    .map((groupItem, groupIndex) => {
      const { group, data } = groupItem;
      const { co2e, co2Cost, spend, methodology } = data[0];
      const { _name, supplier, item, costCenter, naicsCode, naicsIndustry, naicsSector } = group;

      const naicsIdentifier = naicsSector || naicsIndustry || naicsCode || "n/a";
      const naicsName = groupBrokenValues.includes(naicsIdentifier) ? "Not Mapped" : naicsIdentifier;

      const CO2TonsPercent = Math.floor((co2e * 100) / summaryData.co2e) + "";
      const costPercent = Math.floor((co2Cost * 100) / summaryData.co2Cost) + "";
      const spendPercent = Math.floor((spend * 100) / summaryData.spend) + "";

      return {
        id: String(groupIndex),
        CO2Tons: co2e,
        cost: co2Cost,
        spend: spend,
        CO2TonsPercent,
        costPercent,
        spendPercent,
        description: _name || `Unspecified (${naicsName})`,
        supplierId: formatGroupItem(supplier),
        itemId: formatGroupItem(item),
        costCenterId: formatGroupItem(costCenter),
        naicsSectorId: formatGroupItem(naicsSector),
        naicsIndustryId: formatGroupItem(naicsIndustry),
        naicsCodeId: formatGroupItem(naicsCode),
        methodology
      };
    })
    .filter(d => !!d);

  const tabularDataWithColors = sortArray(tabularData, "CO2Tons", "desc").map((tabularItem, tabularIndex) => {
    const colorCode = graphColorKey[tabularIndex] || colorCodesList[tabularIndex];
    return { ...tabularItem, colorCode };
  });

  return tabularDataWithColors;
};

// For DonutChart, converting the records and summing up the values after the limit to 'Others' object
export const convertTableDataWithOthers = (
  analyticsData: TAnalyticsTabularData[],
  summary: TAnalyticsData | null,
  colorCodesList: string[]
): TAnalyticsChartData[] => {
  const independentData = analyticsData
    .filter(analyticsItem => Number(analyticsItem.CO2TonsPercent) >= 1)
    .map((analyticsItem, index) => {
      const colorCode = graphColorKey[index] || colorCodesList[index];
      return { ...analyticsItem, colorCode };
    });
  const others = analyticsData.filter(analyticsItem => Number(analyticsItem.CO2TonsPercent) < 1);
  const othersCO2Tons = others.map(other => other.CO2Tons).reduce((prev, curr) => prev + curr, 0);
  const othersCost = others.map(other => other.cost).reduce((prev, curr) => prev + curr, 0);
  const othersSpend = others.map(other => other.spend).reduce((prev, curr) => prev + curr, 0);

  const othersObject = {
    id: "tail",
    colorCode: `#8b5a19`,
    description: "Tail Spend",
    CO2Tons: othersCO2Tons,
    CO2TonsPercent: Number((othersCO2Tons / (summary?.co2e || 1)) * 100).toFixed(2),
    cost: othersCost,
    costPercent: Number((othersCost / (summary?.co2Cost || 1)) * 100).toFixed(2),
    spend: othersSpend,
    spendPercent: Number((othersSpend / (summary?.spend || 1)) * 100).toFixed(2),
    supplierId: "tail",
    // Not sure how it should like for summary Tail Spend
    methodology: {
      sb: 0,
      cb: 0,
      ss: 0
    }
  };

  return [...independentData, othersObject];
};

export const generateRandomColors = (list: unknown[]) => {
  const placeholderList = Array(list.length).fill(0);
  return placeholderList.map(
    () => "hsl(" + 360 * Math.random() + "," + (30 + 70 * Math.random()) + "%," + (40 + 10 * Math.random()) + "%)"
  );
};
