import { Button } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { ModalTitle } from "ui";
import { PlusIcon } from "ui/icons";
import Modal from "ui/overlays/Modal";

import { CreateCustomFieldForm } from "./CreateCustomFieldForm";

export const CreateCustomFieldModal = () => {
  const [opened, { close, open }] = useDisclosure();

  return (
    <>
      <Button size="xs" rightIcon={<PlusIcon />} onClick={open}>
        Add Custom Field
      </Button>
      <Modal opened={opened} withCloseButton handleClose={close}>
        <ModalTitle centered>New Custom Field</ModalTitle>
        <CreateCustomFieldForm onClose={close} />
      </Modal>
    </>
  );
};
