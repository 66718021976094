import { RejectAnalysisSuccessModalProps } from "modules/imports/components/ImportsTypes";
import Modal from "ui/overlays/Modal";
import { ModalTitle } from "ui/styles";

import { StyleDescription } from "./Import.styles";

export const SuccessModal = ({
  titleText,
  descriptionText,
  openModal,
  setOpenModal
}: RejectAnalysisSuccessModalProps) => (
  <Modal
    opened={openModal}
    setOpened={setOpenModal}
    handleClose={() => setOpenModal(false)}
    withCloseButton
    mode="message"
  >
    <ModalTitle $type="danger">{titleText}</ModalTitle>
    <StyleDescription>{descriptionText}</StyleDescription>
  </Modal>
);
