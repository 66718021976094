import { useEffect, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useNavigate } from "react-router-dom";

import styled from "@emotion/styled";
import { Route_URL } from "constants/routeUrls";
import jwt_decode from "jwt-decode";
import ErrorHandler from "modules/error/ErrorHandler";
import { JwtTokenInterface, useGetUserInfo, USER_TYPE } from "modules/users";

import { LoginButtonsCenterer, StyleModalBox } from "../styles/Login.styles";
import { AzureLogin } from "./AzureLogin";
import { GoogleLogin } from "./GoogleLogin";

const StyleHeaderText = styled.div`
  font-family: ${props => props.theme.other.font.fontFamily};
  font-style: ${props => props.theme.other.font.fontStyle};
  font-weight: 700;
  font-size: 1.875rem;
  line-height: 2.25rem;
  text-align: center;
  color: ${props => props.theme.other.primaryColor};
  margin-bottom: 4px;
  padding-top: 37px;
`;
const StyleDescriptionText = styled.div`
  font-family: ${props => props.theme.other.font.fontFamily};
  font-style: ${props => props.theme.other.font.fontStyle};
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.1875rem;
  text-align: center;
  color: ${props => props.theme.other.monochromeColor[2]};
  margin-bottom: 40px;
`;

export const Login = () => {
  const navigate = useNavigate();
  const [userId, setUserId] = useState("");
  const [queryEnabled, setQueryEnabled] = useState(false);
  const { data, isSuccess, refetch, isError } = useGetUserInfo(userId, queryEnabled);
  useEffect(() => {
    const jwtToken = localStorage.getItem("jwt_Token") ?? null;
    if (jwtToken !== null) {
      const decodedJWT_token = jwt_decode<JwtTokenInterface>(jwtToken);
      setUserId(decodedJWT_token.sub);
      setQueryEnabled(true);
    }
  }, []);
  useEffect(() => {
    if (userId !== "" && queryEnabled === true) {
      refetch();
    }
  }, [userId, queryEnabled]);
  useEffect(() => {
    if (isSuccess === true) {
      setQueryEnabled(false);
      if (data.data?.userType === USER_TYPE.internal) {
        navigate(Route_URL.accounts);
      } else {
        navigate(Route_URL.account_selection);
      }
    }
  }, [isSuccess]);
  useEffect(() => {
    if (isError === true) {
      setQueryEnabled(false);
      setUserId("");
    }
  }, [isError]);
  return (
    <>
      <StyleModalBox>
        <ErrorBoundary FallbackComponent={ErrorHandler}>
          <StyleHeaderText>Welcome to Kloopify!</StyleHeaderText>
          <StyleDescriptionText>Sign in below to access your account.</StyleDescriptionText>
          <LoginButtonsCenterer>
            <GoogleLogin />
            <AzureLogin />
          </LoginButtonsCenterer>
          {/* <StyleHelperText>
            Having trouble logging in?
            <StyleHelperTextGreen> Get help here.</StyleHelperTextGreen>
          </StyleHelperText> */}
        </ErrorBoundary>
      </StyleModalBox>
    </>
  );
};
