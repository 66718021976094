import React, { useEffect, useMemo, useState } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useNavigate } from "react-router-dom";

import { LoadingOverlay } from "@mantine/core";
import { useQueryClient } from "@tanstack/react-query";
import jwt_decode from "jwt-decode";
import {
  JwtTokenInterface,
  useGetAccountFeatureFlagList,
  useGetUserInfo,
  USER_TYPE,
  UserAccountInterface,
  UserInterface,
  UserRoles
} from "modules/users";
import { TFeatureFlagUser, TUserAccount } from "types";
import Loader from "ui/feedback/Loader";

import Router from "./AppRouter";
import { CurrentUserContext } from "./modules/login/CurrentUserContext";

import "./App.css";

export interface CurrentUserContextValues {
  userDetails: UserInterface;
  isInternal: boolean;
  userAccounts: UserAccountInterface[];
  userFeatureFlags: TFeatureFlagUser[];
  setUserDetails: React.Dispatch<React.SetStateAction<UserInterface>>;
  activeTab: string | null;
  setActiveTab: React.Dispatch<React.SetStateAction<string | null>>;
  JWT_Token: string | null;
  setJWT_Token: React.Dispatch<React.SetStateAction<string | null>>;
  setUserId: React.Dispatch<React.SetStateAction<string>>;
  logout: () => void;
}

export interface ErrorResponse {
  response: { data: { errorMessage?: string; statusCode?: number } };
  message?: string;
}

const initialUserDetails = {
  firstName: "",
  lastName: "",
  accountName: "",
  email: ""
};

const App = () => {
  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const [activeTab, setActiveTab] = useState<string | null>(null);

  const [JWT_Token, setJWT_Token] = useState<string | null>(null);

  const [userDetails, setUserDetails] = useState<UserInterface>(initialUserDetails);

  const [userAccounts, setUserAccounts] = useState<TUserAccount[]>([]);

  const [userFeatureFlags, setUserFeatureFlags] = useState<TFeatureFlagUser[]>([]);

  const [userId, setUserId] = useState("");

  const getUserInfoQuery = useGetUserInfo(userId, false);

  const getAccountFeatureFlagsQuery = useGetAccountFeatureFlagList({
    userId,
    queryEnabled: false
  });

  const isFetching = getUserInfoQuery.isFetching || getAccountFeatureFlagsQuery.isFetching;

  const isError = getUserInfoQuery.isError || getAccountFeatureFlagsQuery.isError;

  const error = getUserInfoQuery.error || getAccountFeatureFlagsQuery.error;

  const isInternal = useMemo(() => userDetails.userType === USER_TYPE.internal, [userDetails]);

  const verifyUserAuthentificated = () => {
    const jwtToken = localStorage.getItem("jwt_Token") ?? null;
    if (jwtToken !== null) {
      setJWT_Token(jwtToken);
      const decodedJWT_token = jwt_decode<JwtTokenInterface>(jwtToken);
      setUserId(decodedJWT_token.sub);
    }
  };

  const updateUserInformation = () => {
    let currentAccountIndex = 0;
    const data = getUserInfoQuery.data?.data;
    if (getUserInfoQuery.isSuccess) {
      const userDetailsObj: UserInterface = {
        firstName: data?.firstName,
        lastName: data?.lastName,
        email: data?.email,
        userType: data?.userType,
        userId: data?.id
      };
      if (data?.accounts && data?.accounts.length) {
        data?.accounts?.forEach((account: UserAccountInterface, index: number) => {
          if (data?.userType === USER_TYPE.external) {
            if (localStorage.getItem("accountId") === account?.accountId) {
              currentAccountIndex = index;
            } else {
              currentAccountIndex = 0;
            }
          } else {
            currentAccountIndex = 0;
          }
        });
        const account = data?.accounts[currentAccountIndex];
        userDetailsObj.accountId = account.accountId;
        userDetailsObj.accountName = account.accountName;
        userDetailsObj.userRole = account.role;
        localStorage.setItem("accountId", account.accountId);
        localStorage.setItem("accountName", account.accountName);
      }
      setUserDetails(userDetailsObj);
      setUserAccounts(data?.accounts || []);
    }
  };

  const logout = () => {
    queryClient.removeQueries();
    setJWT_Token(null);
    setUserId("");
    setActiveTab(null);
    localStorage.clear();
    sessionStorage.clear();
    setUserDetails(initialUserDetails);
    navigate("/");
  };

  useEffect(
    () => setUserFeatureFlags(getAccountFeatureFlagsQuery.data?.data || []),
    [getAccountFeatureFlagsQuery.isSuccess]
  );

  useEffect(() => {
    if (userDetails.userRole && userDetails.userRole !== UserRoles.INTERNAL_ADMIN.value)
      getAccountFeatureFlagsQuery.refetch();
  }, [userDetails]);

  useEffect(() => updateUserInformation(), [getUserInfoQuery.isSuccess]);

  useEffect(() => {
    if (JWT_Token && userId) getUserInfoQuery.refetch();
  }, [JWT_Token, userId]);

  useEffect(() => verifyUserAuthentificated(), []);

  if (isError) {
    const errorMessage = (error as ErrorResponse)?.response?.data?.errorMessage ?? (error as ErrorResponse)?.message;
    throw new Error(errorMessage);
  }

  if (isFetching) return <LoadingOverlay visible overlayBlur={2} loader={<Loader />} />;

  return (
    <HelmetProvider>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Kloopify</title>
      </Helmet>
      <CurrentUserContext.Provider
        value={{
          userDetails,
          isInternal,
          setUserDetails,
          setUserId,
          userAccounts,
          userFeatureFlags,
          activeTab,
          setActiveTab,
          JWT_Token,
          setJWT_Token,
          logout
        }}
      >
        <Router />
      </CurrentUserContext.Provider>
    </HelmetProvider>
  );
};

export default App;
