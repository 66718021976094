import styled from "@emotion/styled";

export const TableWrapper = styled.div`
  overflow: auto;
  height: auto;
  margin-top: ${props => props.theme.other.table.marginTop};
  margin-bottom: ${props => props.theme.other.table.marginBottom};
`;

export const CommodityTableWrapper = styled.div`
  thead {
    tr {
      th {
        border-right: 1px solid ${props => props.theme.other.monochromeColor[3]};
        background-color: ${props => props.theme.other.monochromeColor[4]};
        font-weight: 700;
        color: ${props => props.theme.other.monochromeColor[1]} !important;
        border-top-width: 1px;
      }
      th:last-child {
        border-right: none;
      }
    }
  }

  tbody {
    tr {
      td {
        text-align: left;
        border-right: 1px solid ${props => props.theme.other.monochromeColor[3]};
        font-size: 1rem;
      }

      td:last-child {
        border-right: none;
      }
    }
  }
`;
