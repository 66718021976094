import styled from "@emotion/styled";

export const WrapperModalBody = styled.div`
  position: relative;
`;

export const Description = styled.div`
  margin-top: 5px;
  margin-bottom: 11px;
  font-family: ${props => props.theme.other.font.fontFamily};
  font-style: ${props => props.theme.other.font.fontStyle};
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5625rem;
  color: ${props => props.theme.other.monochromeColor[2]};
  max-width: 368px;
  width: 100%;
`;

export const TextInputWrapper = styled.div`
  display: flex;
  margin-top: 5px;
  align-items: center;
  gap: 10px;
  @media only screen and (max-width: 430px) {
    display: grid;
  }
`;
