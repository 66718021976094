import { useParams } from "react-router-dom";

import { Button, LoadingOverlay } from "@mantine/core";
import Loader from "ui/feedback/Loader";
import { ChevronRight } from "ui/icons";

import { QuestionnaireDetailQuestions } from "./components/QuestionnaireDetailQuestions";
import { useGetQuestionnaireQuery } from "./hooks";
import {
  QuestionnaireDetailCard,
  QuestionnaireResponseCenterer,
  QuestionnaireResponseHeader,
  QuestionnairesContainer
} from "./questionnaire.styles";
import { normalizeQuestions } from "./questionnaire.utils";

export const QuestionnaireDetail = () => {
  const { questionnaireId } = useParams();

  const { data, isLoading } = useGetQuestionnaireQuery(questionnaireId);

  const onClickReturn = () => window.close();

  const questions = normalizeQuestions(data?.data?.questions, []);

  return (
    <QuestionnairesContainer>
      <LoadingOverlay visible={isLoading} overlayBlur={2} loader={<Loader />} />
      <QuestionnaireResponseCenterer>
        <QuestionnaireDetailCard>
          <QuestionnaireResponseHeader>
            <h1>{data?.data?.title}</h1>
            <p>
              <b>Description:</b> {data?.data?.description}
            </p>
            <QuestionnaireDetailQuestions questions={questions} submitted />
          </QuestionnaireResponseHeader>
          <Button
            variant="subtle"
            leftIcon={<ChevronRight style={{ rotate: "180deg" }} />}
            pos="absolute"
            top={16}
            right={16}
            onClick={onClickReturn}
          >
            Return
          </Button>
        </QuestionnaireDetailCard>
      </QuestionnaireResponseCenterer>
    </QuestionnairesContainer>
  );
};
