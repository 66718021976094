import { ImportInterface } from "modules/imports/types";

import { useImportContext } from "../ImportContext";

export const ImportAccountCell: React.FC<ImportInterface> = ({ accountId }) => {
  const { accounts } = useImportContext();

  const name = accounts.find(({ id }) => id === accountId)?.name || "";

  return <>{name}</>;
};
