export interface BreakPointInterface {
  xs: string;
  sm: string;
  md: string;
  lg: string;
  xl: string;
  xxl: string;
}
export const SCREEN_XS = "375px";
export const SCREEN_SM = "576px";
export const SCREEN_MD = "768px";
export const SCREEN_LG = "950px";
export const SCREEN_XL = "1100px";
export const SCREEN_XXL = "1366px";
