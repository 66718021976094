export const QUERY_KEYS = {
  // *USERS
  user_list: "users",
  add_user_to_Account: "add_user_to_account",
  create_user: "create_user",
  create_admin_user: "create_admin_user",
  delete_user: "delete_user",
  user_details: "user_details",
  user_notifications: "user_notifications",
  remove_user_role: "remove_user_role",
  edit_user: "edit_user",
  get_user_accounts: "get_user_accounts",
  change_user_status: "change_user_status",

  // *SUPPLIER
  supplier_analytics: "supplier_analytics",
  patch_supplier: "patch_supplier",
  suppliers: "get_suppliers",
  supplier_by_id: "supplier_by_id",
  create_new_supplier: "create_new_supplier",
  post_supplier_merge: "post_supplier_merge",

  // *FEATURE FLAG
  get_account_feature_flag_list: "get_account_feature_flag_list",
  get_feature_flag_list_account: "get_feature_flag_list_account",
  get_feature_flag_list: "get_feature_flag_list",
  put_feature_flag: "put_feature_flag",

  // *BUYSPACE
  get_buyspaces: "get_buyspaces",
  create_buyspaces: "create_buyspaces",
  delete_buyspace: "delete_buyspace",
  update_buyspace: "update_buyspace",

  // *QUESTIONNAIRE
  get_questionnaire: "get_questionnaire",
  get_questionnaire_request: "get_questionnaire_request",
  post_questionnaire_response: "post_questionnaire_response",
  post_questionnaire_request: "post_questionnaire_request",
  delete_questionnaire_request: "delete_questionnaire_request",
  delete_questionnaire: "delete_questionnaire",
  get_questionnaire_request_list: "get_questionnaire_request_list",
  post_questionnaire_request_reject: "post_questionnaire_request_reject",
  post_questionnaire_request_approve: "post_questionnaire_request_approve",
  get_questionnaire_list: "get_questionnaire_list",
  post_questionnaire: "post_questionnaire",
  put_questionnaire: "put_questionnaire",
  get_questionnaire_items: "get_questionnaire_items",
  post_commodity_merge: "post_commodity_merge",

  // *IMPORT
  imports_listing: "imports_listing",
  import_transaction_meta: "import_transaction_meta",
  get_import_errors: "get_import_errors",
  delete_import: "delete_import",
  create_import: "create_import",
  update_analysis_import: "update_analysis_import",
  update_transaction_import: "update_transaction_import",
  reject_analysis: "rejects_analysis",
  approve_file: "approve_file",
  reject_transaction: "reject_transaction",
  get_import_transaction_meta: "get_import_transaction_meta",
  get_import_transaction_file: "get_import_transaction_file",

  // *ACCOUNT
  get_account_users: "get_account_users",
  accounts_listing: "accounts",
  delete_account: "delete_account",
  change_account_status: "change_account_status",
  create_account: "create_account",
  account_details: "account_details",

  // *COMMODITY
  get_commodity: "get_commodity",
  get_items: "get_items",

  // *COST CENTER
  get_cost_centers: "get_cost_centers",

  // *ANALYTICS
  overview_dashboard: "overview_dashboard",
  breakdown_dashboard: "breakdown_dashboard",

  // *CUSTOM FIELDS
  get_custom_fields: "get_custom_fields",
  create_custom_fields: "create_custom_fields",
  delete_custom_fields: "delete_custom_fields",

  // *CLASSIFICATION
  get_classification: "get_classification",

  // *GOAL
  get_goal_list: "get_goal_list",
  get_goal_progress: "get_goal_progress",
  delete_goal: "delete_goal",
  post_goal: "post_goal",
  put_goal: "put_goal"
};
