import { useContext, useEffect, useState } from "react";

import jwt_decode from "jwt-decode";
import { pickBy } from "lodash";
import { CurrentUserContext } from "modules/login/CurrentUserContext";
import { useGetUserInfo } from "modules/users";
import { JwtTokenInterface } from "modules/users/types/UserDto";

export interface IPendoVisitor {
  email?: string;
  full_name?: string;
  id?: string;
  status?: string;
  userType?: string;
}

export interface IPendoAccount {
  id: string;
  name: string;
  role: string;
}

type TPendoWindow = Window & typeof globalThis & { pendo: pendo.Pendo };

const pendoInstance = (window as TPendoWindow).pendo;

export const usePendo = () => {
  const { JWT_Token } = useContext(CurrentUserContext);
  const isAuthenticated = JWT_Token && JWT_Token !== "";
  const [userId, setUserId] = useState("");
  const { data } = useGetUserInfo(userId, false);

  const pendoUpdate = (visitorData: IPendoVisitor, accountData: IPendoAccount) => {
    if (!pendoInstance) return;
    pendoInstance.identify({
      account: { ...accountData },
      visitor: { ...visitorData }
    });
  };

  const getAccountData = () => {
    if (data?.data) {
      const { accounts, id } = data.data;
      if (!accounts || (accounts && accounts.length === 0)) return {};
      const currentAccount = accounts.find(i => i.userId === id);
      if (!currentAccount) return {};
      const { accountId, accountName, role } = currentAccount;
      const accountData: IPendoAccount = {
        id: accountId,
        name: accountName,
        role
      };
      const cleanedAccountData = pickBy(accountData, v => v !== undefined);
      return cleanedAccountData;
    }
  };

  const getVisitorData = () => {
    if (data?.data) {
      const { email, firstName, id, lastName, status, userType } = data.data;
      const visitorData: IPendoVisitor = {
        email,
        full_name: firstName + " " + lastName,
        id,
        status,
        userType
      };
      const cleanedVisitorData = pickBy(visitorData, v => v !== undefined);
      return cleanedVisitorData;
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      if (JWT_Token !== null) {
        const decodedJWT_token = jwt_decode<JwtTokenInterface>(JWT_Token);
        setUserId(decodedJWT_token.sub);
      }
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (data?.data) {
      const visitorData = getVisitorData();
      const accountData = getAccountData();
      pendoUpdate(visitorData as IPendoVisitor, accountData as IPendoAccount);
    }
  }, [data]);

  useEffect(() => {
    pendoInstance.initialize();

    return () => {
      setUserId("");
    };
  }, []);

  return pendoInstance;
};
