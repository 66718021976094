import axiosInstance from "services/AxiosInstance";

import { CostCenterResponse } from "../types";

export const getCostCenterListService = async ({
  accountId,
  params = {}
}: {
  accountId: string;
  params: Record<string, number | string | null>;
}): Promise<CostCenterResponse> => {
  const response = await axiosInstance.get(`/costcenter/account/${accountId}`, { params });
  return response.data;
};
