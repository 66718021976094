import styled from "@emotion/styled";

const ErrorHeader = styled.div`
  font-family: ${props => props.theme.other.font.fontFamily};
  font-style: ${props => props.theme.other.font.fontStyle};
  font-weight: 700;
  font-size: 1.625rem;
  line-height: 150%;
  color: ${props => props.theme.other.dangerColor};
  margin-left: auto;
  margin-right: auto;
`;
const ErrorMessage = styled.div`
  font-family: ${props => props.theme.other.font.fontFamily};
  font-style: ${props => props.theme.other.font.fontStyle};
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 1.3125rem;
  color: ${props => props.theme.other.dangerColor};
  margin-left: auto;
  margin-right: auto;
`;
const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  text-align: center;
`;
interface ErrorHandlerInterface {
  error?: any;
}
const ErrorHandler: React.FC<ErrorHandlerInterface> = ({ error }) => {
  const errorTitle = error && error.message ? error.message + "." : "";
  const errorDescription = error && error.response && error.response.data ? error.response.data.errorMessage : "";

  const message = `${errorTitle} ${errorDescription}`;

  return (
    <Wrapper role="alert">
      <div>
        <ErrorHeader>Something went wrong!</ErrorHeader>
        <ErrorMessage>{message}</ErrorMessage>
      </div>
    </Wrapper>
  );
};
export default ErrorHandler;
