import { Strings } from "constants/translations";
import * as Yup from "yup";

export const initialUserTableModal = {
  activateUser: false,
  suspendUser: false,
  addNewUser: false,
  updateUser: false,
  deleteUser: false,
  confirmRemove: false,
  successRemove: false,
  assignUser: false,
  filters: false,
  featureFlags: false
};

export const addUserToAccountSchema = Yup.object().shape({
  account: Yup.string().required(Strings.please_select_account),
  role: Yup.string().required(Strings.please_select_role)
});
