import { uniqueId } from "lodash";
import {
  QuestionnaireNormalized,
  QuestionnaireQuestionScope,
  QuestionnaireQuestionType,
  QuestionnaireRequestStatus,
  QuestionnaireValidateReady,
  TQuestionnaireQuestion,
  TQuestionnaireQuestionPayload,
  TQuestionnaireRequestResponse,
  TQuestionnaireRequestResponseAnswer
} from "types";

const prefillQuestion = (
  q: QuestionnaireValidateReady,
  answers: TQuestionnaireRequestResponseAnswer[],
  itemIndex: number
) => {
  const matchedAnswerList = answers.filter(
    answer => answer.questionnaireQuestionId === q.id && answer.itemIndex === itemIndex
  );

  const response = matchedAnswerList.map(answer => answer.response);

  const typedResponse = q.type === QuestionnaireQuestionType.MULTI_SELECT ? response : response[0];

  return { ...q, response: matchedAnswerList ? typedResponse : q.response };
};

const normalizeProductQuestions = (
  questions: QuestionnaireValidateReady[],
  answers: TQuestionnaireRequestResponseAnswer[]
) => {
  const productQuestionList = questions.filter(i => i.scope === QuestionnaireQuestionScope.SCOPE_ITEM);

  const productAmount = answers.length ? [...answers].sort((a, b) => b.itemIndex - a.itemIndex)[0].itemIndex : 0;

  return Array(productAmount + 1)
    .fill(null)
    .map((_, index) => {
      const questions = productQuestionList.map(i => prefillQuestion(i, answers, index));

      return {
        id: uniqueId(),
        scope: QuestionnaireQuestionScope.SCOPE_ITEM,
        questions: questions
      };
    });
};

const normalizeSupplierQuestions = (
  questions: QuestionnaireValidateReady[],
  answers: TQuestionnaireRequestResponseAnswer[]
) => {
  const supplierQuestionList = questions.filter(i => i.scope === QuestionnaireQuestionScope.SCOPE_SUPPLIER);

  return supplierQuestionList.map(i => prefillQuestion(i, answers, 0));
};

export const normalizeQuestions = (
  questions?: TQuestionnaireQuestion[],
  responses?: TQuestionnaireRequestResponse[]
): QuestionnaireNormalized[] => {
  if (!questions) return [];
  if (!responses) return [];

  const answers = responses.length ? responses[0].answers : [];

  const validateReadyQuestions = questions.map(q => ({ ...q, response: "", error: "", loading: false }));

  const supplierScopeQuestions = normalizeSupplierQuestions(validateReadyQuestions, answers);

  const productScopeQuestions = normalizeProductQuestions(validateReadyQuestions, answers);

  return [
    {
      id: uniqueId(),
      scope: QuestionnaireQuestionScope.SCOPE_SUPPLIER,
      questions: supplierScopeQuestions
    },
    ...productScopeQuestions
  ];
};

export const validateSingleResponse = (response: string) => {
  if (!response) return "This question is required.";
  return "";
};

export const validateMultiResponse = (response: string | string[]) => {
  if (typeof response === "string") return "At Least One Option Has To Be Selected";
  if (response.length < 1) return "At Least One Option Has To Be Selected";
  return "";
};

export const validateFileResponse = (response: string | null) => {
  if (!response) return "The file is required";
  return "";
};

export const questionnaireRequestDataInitialValues = {
  questionnaireResponses: [],
  questionnaire: {
    id: "",
    title: "",
    description: ""
  },
  account: {
    id: "",
    name: ""
  },
  id: "",
  respondBy: "",
  sendTo: "",
  sendToFirstName: "",
  sendToLastName: "",
  status: QuestionnaireRequestStatus.IN_PROGRESS,
  supplier: {
    id: "",
    name: ""
  },
  updatedAt: ""
};

export const createQuestionInitialDataValues = (
  payloadQuestions: TQuestionnaireQuestionPayload[] = []
): TQuestionnaireQuestion[] => {
  return payloadQuestions.map((question, idx) => ({
    ...question,
    id: idx.toString(),
    accountId: "",
    createdAt: "",
    questionnaireId: "",
    updatedAt: "",
    choices:
      question?.choices?.map((choice, idx) => ({
        ...choice,
        accountId: "",
        createdAt: "",
        id: idx.toString(),
        questionId: "",
        updatedAt: ""
      })) || []
  }));
};
