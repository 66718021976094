import { Fragment } from "react";

export const formatSuggestion = (suggestion: string | undefined, userInput: string): React.ReactNode => {
  const regex = new RegExp(userInput, "gi");
  const parts: React.ReactNode[] = [];
  let lastIndex = 0;

  suggestion?.replace(regex, (match, index) => {
    const beforeMatch = suggestion.slice(lastIndex, index);
    if (beforeMatch) parts.push(beforeMatch);
    parts.push(<strong key={index}>{match}</strong>);
    lastIndex = index + match.length;
    return match;
  });

  const afterMatch = suggestion?.slice(lastIndex);
  if (afterMatch) parts.push(afterMatch);

  return (
    <div>
      {parts.map((part, index) => (
        <Fragment key={part + "-" + index}>{part}</Fragment>
      ))}
    </div>
  );
};
