import { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";

import { ExternalUserPermissionGuard } from "components";
import { usePendo } from "hooks/usePendo";
import { BreakdownDashboard, OverviewDashboard } from "modules/analytics";
import AnalyticsScreen from "screens/AnalyticsScreen";
import QuestionnaireScreen from "screens/QuestionnaireScreen";
import { Feature } from "types/FeatureFlag/FeatureFlagEnum";

import Loader from "./ui/feedback/Loader";

const Login = lazy(() => import("./screens/LoginScreen"));
const CustomFields = lazy(() => import("./screens/CustomFields"));
const AccountScreen = lazy(() => import("./screens/AccountScreen"));
const AccountsScreen = lazy(() => import("./screens/AccountsScreen"));
const UsersScreen = lazy(() => import("./screens/UsersScreen"));
const UnauthorizedScreen = lazy(() => import("./screens/UnauthorizedScreen"));
const MyProfileScreen = lazy(() => import("./screens/MyProfileScreen"));
const ImportScreen = lazy(() => import("./screens/ImportScreen"));
const ImportGlobalScreen = lazy(() => import("./screens/ImportGlobalScreen"));
const AccountNavScreen = lazy(() => import("./screens/AccountNavScreen"));
const TransactionsPage = lazy(() => import("./screens/TransactionsPage"));
const SuppliersScreen = lazy(() => import("./screens/SuppliersScreen"));
const SuppliersDetailsScreen = lazy(() => import("./screens/SupplierDetailsScreen"));
const CommodityDetailsScreen = lazy(() => import("./screens/CommodityDetailsScreen"));
const CommodityScreen = lazy(() => import("./screens/CommodityScreen"));
const BuyspacesScreen = lazy(() => import("./screens/BuyspacesScreen"));
const GoalsScreen = lazy(() => import("./screens/GoalsScreen"));
const MyQuestionnaireScreen = lazy(() => import("./screens/MyQuestionnaireScreen"));
const QuestionnaireDetailScreen = lazy(() => import("./screens/QuestionnaireDetailScreen"));
const QuestionnaireRequestDetailScreen = lazy(() => import("./screens/QuestionnaireRequestDetailScreen"));
const QuestionnaireCreatorScreen = lazy(() => import("./screens/QuestionnaireCreatorScreen"));

function AppRouter() {
  usePendo();

  return (
    <div className="App">
      <Suspense
        fallback={
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh"
            }}
          >
            <Loader variant="dots" />
          </div>
        }
      >
        <Routes>
          {/* Private Route */}
          <Route path="/profile" element={<MyProfileScreen />} />
          <Route path="/account-selection" element={<AccountNavScreen />} />
          <Route path="/account/:id/settings" element={<CustomFields />} />
          <Route path="/accounts/:id" element={<AccountScreen />} />
          <Route path="/accounts" element={<AccountsScreen />} />
          <Route path="/users" element={<UsersScreen />} />
          <Route path="/analysis/:id" element={<AnalyticsScreen />}>
            <Route
              path="overview"
              element={<OverviewDashboard start="1970-01-01T00:00:00Z" end="2100-01-01T00:00:00Z" interval="month" />}
            />
            <Route
              path="breakdown"
              element={<BreakdownDashboard start="1970-01-01T00:00:00Z" end="2100-01-01T00:00:00Z" group="supplier" />}
            />
          </Route>
          <Route path="/imports" element={<ImportGlobalScreen />} />

          <Route path="/transactions" element={<TransactionsPage />} />

          <Route path="/transactions/:id" element={<TransactionsPage />} />

          <Route path="/suppliers" element={<SuppliersScreen />} />

          <Route path="/suppliers/:id" element={<SuppliersScreen />} />

          <Route path="/supplier/:id" element={<SuppliersDetailsScreen />} />

          {/* External user */}
          <Route path="/imports/:id" element={<ImportScreen />} />

          <Route path="/commodities" element={<CommodityScreen />} />

          <Route path="/commodities/:id" element={<CommodityScreen />} />

          <Route path="/commodity/:id" element={<CommodityDetailsScreen />} />

          <Route
            path="/buyspaces/:id"
            element={
              <ExternalUserPermissionGuard
                module={Feature.BUYSPACES_MODULE}
                fallback={<>Buyspaces are currently disabled for this account</>}
              >
                <BuyspacesScreen />
              </ExternalUserPermissionGuard>
            }
          />

          <Route path="/goals/:id" element={<GoalsScreen />} />
          <Route path="/questionnaires/:id" element={<MyQuestionnaireScreen />} />
          <Route path="/questionnaires/:id/new" element={<QuestionnaireCreatorScreen />} />
          <Route path="/questionnaire/:questionnaireId/view" element={<QuestionnaireDetailScreen />} />
          <Route
            path="/supplier/:supplierId/questionnairerequest/:questionnaireRequestId"
            element={<QuestionnaireRequestDetailScreen />}
          />

          {/* Public Route */}
          <Route path="/" element={<Login />} />
          <Route path="/unauthorized" element={<UnauthorizedScreen />} />
          <Route path="/questionnaire/request/:id" element={<QuestionnaireScreen />} />
        </Routes>
      </Suspense>
    </div>
  );
}

export default AppRouter;
