import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { QUERY_KEYS } from "constants/queryKeys";
import { ResponseError } from "types";

import { postSupplierMergeService } from "../api";

export const usePostSupplierMergeMutation = ({
  apiArgs: { query, accountId }
}: {
  apiArgs: { accountId?: string; query: Record<string, number | string | string[] | null> };
}) => {
  const queryClient = useQueryClient();

  return useMutation<void, AxiosError<ResponseError>, Parameters<typeof postSupplierMergeService>[0]>({
    mutationKey: [QUERY_KEYS.post_supplier_merge],
    mutationFn: postSupplierMergeService,
    onSuccess: () => queryClient.refetchQueries([QUERY_KEYS.suppliers, { ...query }, accountId])
  });
};
