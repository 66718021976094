import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ErrorResponse } from "App";
import { QUERY_KEYS } from "constants/queryKeys";
import { Strings } from "constants/translations";
import { errorModal, successModal } from "modules/error/ErrorHandlingModals";

import { putUserDetailsService } from "../api";

export const useEditUserDetailsMutation = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: putUserDetailsService,
    mutationKey: [QUERY_KEYS.edit_user],
    onSuccess: () => {
      successModal(Strings.user_details_updated_successfully);
      queryClient.refetchQueries({ queryKey: [QUERY_KEYS.user_details] });
    },
    onError: (error: ErrorResponse) => errorModal(error)
  });
  return mutation;
};
