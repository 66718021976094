import { useQuery } from "@tanstack/react-query";
import { ErrorResponse } from "App";
import { QUERY_KEYS } from "constants/queryKeys";
import { errorModal } from "modules/error/ErrorHandlingModals";

import { getQuestionnaireItemsService } from "../api";

export const useGetQuestionnaireItemsQuery = (requestId: string) =>
  useQuery({
    queryKey: [QUERY_KEYS.get_questionnaire_items, { requestId }],
    queryFn: () => getQuestionnaireItemsService(requestId),
    useErrorBoundary: true,
    onError: (error: ErrorResponse) => errorModal(error),
    enabled: Boolean(requestId)
  });
