import { ITransactionFilters, ITransactionsModal } from "./transactions.types";

export const initialTransactionsModal: ITransactionsModal = {
  detail: false,
  filters: false
};

export const initialFilters: ITransactionFilters = {
  page: 0,
  minDate: null,
  maxDate: null,
  method: "",
  source: "",
  itemName: "",
  costCenterName: "",
  supplierName: "",
  minTotalCost: undefined,
  maxTotalCost: undefined,
  minCo2e: undefined,
  maxCo2e: undefined,
  supplierIds: [],
  itemIds: [],
  costCenterIds: [],
  naicsCodes: [],
  naicsSectors: [],
  naicsIndustries: [],
  buyspaceId: null,
  sortBy: ""
};
