import { Divider, Flex, Grid } from "@mantine/core";
import { TrashIcon } from "ui/icons";

import { QCFormInstance } from "../../questionnaire.types";
import { questionnaireOptions } from "../../questionnaireOptions";
import { QCPlaceholder } from "./QCPlaceholder";
import { QCSelect } from "./QCSelect";
import {
  QCButton,
  QCInput,
  QCPlaceholderContainer,
  QCSwitch,
  QuestionnaireCreatorCard
} from "./questionnaireCreator.styles";

type QCQuestionProps = {
  form: QCFormInstance;
  index: number;
  onRemove: () => void;
  scope: "productQuestionList" | "supplierQuestionList";
};

export const QCQuestion: React.FC<QCQuestionProps> = ({ onRemove, form, scope, index }) => {
  return (
    <QuestionnaireCreatorCard>
      <Grid gutter={5}>
        <Grid.Col span={7}>
          <QCInput size="xs" {...form.getInputProps(`${scope}.${index}.question`)} />
        </Grid.Col>
        <Grid.Col span={5}>
          <QCSelect data={questionnaireOptions} size="xs" {...form.getInputProps(`${scope}.${index}.type`)} />
        </Grid.Col>
      </Grid>

      <QCPlaceholderContainer>
        <QCPlaceholder form={form} index={index} scope={scope} />
      </QCPlaceholderContainer>
      <Flex gap="xs" justify="flex-end" align="center" direction="row" wrap="wrap">
        <Divider orientation="vertical" h={20} style={{ alignSelf: "center" }} />
        <QCButton variant="subtle" color="dark" leftIcon={<TrashIcon />} size="xs" onClick={onRemove}>
          Delete Question
        </QCButton>
        <Divider orientation="vertical" h={20} style={{ alignSelf: "center" }} />
        <QCSwitch
          labelPosition="left"
          label="Required"
          color="green"
          size="xs"
          style={{ fontFamily: "Inter", fontWeight: "bold" }}
          {...form.getInputProps(`${scope}.${index}.required`)}
        />
      </Flex>
    </QuestionnaireCreatorCard>
  );
};
