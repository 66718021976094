import { Button } from "@mantine/core";

import { UserFeatureFlagsModalFooterContainer } from "../styles";

export const UserFeatureFlagsModalFooter = ({ close }: { close: () => void }) => {
  return (
    <UserFeatureFlagsModalFooterContainer>
      <Button variant="default" type="button" onClick={close}>
        Cancel
      </Button>
      <Button type="submit">Update</Button>
    </UserFeatureFlagsModalFooterContainer>
  );
};
