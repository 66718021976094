import styled from "@emotion/styled";

export const StyledUserFiltersCheckboxHeader = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  font-size: 1rem;
  padding-bottom: 1rem;
  color: "#5C5C5C";
`;

export const CheckboxGroupWrapper = styled.div`
  display: flex;
  margin-top: 2.1rem;
  @media only screen and (max-width: 660px) {
    display: grid;
    gap: 30px;
    margin-top: 30px;
    margin-left: 30px;
  }
`;
export const StyledGroupCheckboxPaddingWrapper = styled.div<{ padding?: boolean }>`
  padding-bottom: ${props => (props.padding ? 7 : 0)}px;
`;

export const StyledUserStatusCheckboxesWrapper = styled.div`
  margin-right: 4rem;
`;

export const SearchWrapper = styled.div`
  margin-top: 1.5rem;
`;
