import * as React from "react";

export const GoogleLogo = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={props?.width ? props.width : "2em"}
    height={props?.height ? props.height : "2em"}
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.64863 18.1293L5.60438 22.0277L1.78764 22.1084C0.646992 19.9928 0 17.5722 0 14.9999C0 12.5126 0.604922 10.1669 1.67719 8.10156H1.67801L5.07598 8.72453L6.56449 12.1021C6.25295 13.0104 6.08314 13.9854 6.08314 14.9999C6.08326 16.101 6.28272 17.156 6.64863 18.1293Z"
      fill="#FBBB00"
    />
    <path
      d="M29.738 12.1978C29.9102 13.1051 30.0001 14.0422 30.0001 14.9999C30.0001 16.0738 29.8872 17.1214 29.6721 18.1318C28.9419 21.5703 27.0339 24.5728 24.3908 26.6975L24.39 26.6967L20.11 26.4783L19.5043 22.6969C21.2581 21.6684 22.6287 20.0588 23.3507 18.1318H15.3298V12.1978H23.4677H29.738Z"
      fill="#518EF8"
    />
    <path
      d="M24.3898 26.6967L24.3906 26.6975C21.82 28.7637 18.5546 30 15 30C9.2876 30 4.32113 26.8071 1.7876 22.1085L6.64859 18.1294C7.91533 21.5101 11.1766 23.9168 15 23.9168C16.6433 23.9168 18.183 23.4725 19.5041 22.697L24.3898 26.6967Z"
      fill="#28B446"
    />
    <path
      d="M24.5745 3.45328L19.7152 7.43156C18.3479 6.57691 16.7316 6.0832 15.0001 6.0832C11.0902 6.0832 7.76789 8.60022 6.56461 12.1022L1.67807 8.10164H1.67725C4.17369 3.28846 9.20279 0 15.0001 0C18.6396 0 21.9767 1.29645 24.5745 3.45328Z"
      fill="#F14336"
    />
  </svg>
);
