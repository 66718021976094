import styled from "@emotion/styled";

export const FilterMultiSelectComponent = styled.div`
  max-width: 675px;
  width: 100%;

  @media (max-width: 950px) {
    max-width: 365px;
  }
`;

export const FilterTitle = styled.div`
  padding-bottom: 0.5rem;
  font-size: ${props => props.theme.other.font.modalGroupHeader.fontSize};
  line-height: ${props => props.theme.other.font.modalGroupHeader.lineHeight};
  font-weight: ${props => props.theme.other.font.modalGroupHeader.fontWeight};
  color: ${props => props.theme.other.monochromeColor[2]};
`;
