import { Select } from "@mantine/core";
import { ExternalUserPermissionGuard } from "components";
import { useGetBuyspacesQuery } from "modules/buyspaces";
import { Feature, IBuyspace } from "types";

type BuyspaceSelectProps = {
  accountId: string;
  buyspaceId: string | null;
  error?: string;
  maxWidth?: string;
  onChange: (buyspaceId: string | null) => void;
  width?: string;
};

const defaultSelectOption = [{ label: "All Buyspaces", value: "" }];

const BuyspaceSelect = ({ accountId, buyspaceId, onChange, error }: BuyspaceSelectProps) => {
  const { data: buyspaces } = useGetBuyspacesQuery({ id: accountId });

  const createBuyspacesSelectData = (buyspacesData: IBuyspace[] | undefined) => {
    if (!buyspacesData) return [];
    return buyspacesData.map(({ name, id }) => ({ label: name, value: id }));
  };

  const selectData = [...defaultSelectOption, ...createBuyspacesSelectData(buyspaces?.data)];

  return (
    <ExternalUserPermissionGuard module={Feature.BUYSPACES_MODULE}>
      <Select
        placeholder="...Loading Buyspaces"
        readOnly={false}
        size="lg"
        data={selectData}
        error={error}
        value={buyspaceId ? buyspaceId : ""}
        onChange={onChange}
        disabled={!accountId}
      />
    </ExternalUserPermissionGuard>
  );
};

export default BuyspaceSelect;
