import { useCallback, useEffect, useState } from "react";

import { useGetCustomFields } from "modules/customFields";

import { useImportContext } from "../ImportContext";
import { useImportMapModalContext } from "./ImportMapModalContext";
import { ErrorText } from "./importMapModal.styles";
import { HeaderType } from "./importMapModal.types";

const stringValues = ["supplierName", "itemName", "costCenter"];

const dateRegex = /\d{1,4}[/-]\d{1,2}[/-]\d{1,4}/;

const stringExceptEmptyRegex = /^.+$/;

export const ImportMapErrorCell: React.FC<HeaderType> = ({ kloopifyFieldKey, samples }) => {
  const [error, setError] = useState<string | null>(null);

  const { onError } = useImportMapModalContext();
  const { selectedAccountId } = useImportContext();

  const { data: customFieldData } = useGetCustomFields({ accountId: selectedAccountId as string });

  const possibleCustomFields = customFieldData?.data?.map(({ id }) => id) || [];

  const customFieldOptions =
    customFieldData?.data?.map(({ id, field, fieldLabel, type }) => ({ id, name: field || fieldLabel, type })) || [];

  const checkIsValid = useCallback((data: string[], regex: RegExp) => {
    let error: string | null = null;
    data.forEach(d => {
      const valid = d && d.match(regex);
      if (!valid) {
        error = "Invalid Format";
      }
    });
    return error;
  }, []);

  const checkIsCustomFieldValid = useCallback((data: string[], type: "NUMERICAL" | "TEXT") => {
    let error: string | null = null;
    data.forEach(d => {
      const isNumber = !!parseFloat(d);
      const isString = d && typeof d === "string";

      if ((isNumber && type === "NUMERICAL") || (isString && type === "TEXT")) {
        return;
      }
      error = `Invalid format: custom field type is ${type}`;
      return;
    });
    return error;
  }, []);

  useEffect(() => {
    if (kloopifyFieldKey === "date") {
      const error = checkIsValid(samples, dateRegex);
      setError(error);

      onError(kloopifyFieldKey, !!error);
      return () => setError(null);
    }
    if (kloopifyFieldKey && stringValues.includes(kloopifyFieldKey)) {
      const error = checkIsValid(samples, stringExceptEmptyRegex);
      setError(error);

      onError(kloopifyFieldKey, !!error);
      return () => setError(null);
    }
    if (kloopifyFieldKey === "totalCost") {
      const errors = samples.map(sample => sample.replace(/[$, ]/g, "")).filter(sample => isNaN(parseFloat(sample)));
      const error = errors.length > 0 ? "Invalid Format" : null;
      setError(error);
      onError(kloopifyFieldKey, !!error);
      return () => setError(null);
    }

    if (kloopifyFieldKey && possibleCustomFields?.includes(kloopifyFieldKey)) {
      const field = customFieldOptions.find(field => field.id === kloopifyFieldKey);
      if (field) {
        const error = checkIsCustomFieldValid(samples, field.type);
        setError(error);
        onError(kloopifyFieldKey, !!error);
      }
      return () => setError(null);
    }
  }, [samples, kloopifyFieldKey]);

  return <ErrorText>{error}</ErrorText>;
};
