import React, { useEffect, useState } from "react";

import styled from "@emotion/styled";
import { Button, Select } from "@mantine/core";
import { UseFormReturnType } from "@mantine/form";
import useGetAccountQuery from "modules/account/queries/GetAccountsQuery";
import { TAccount } from "types";
import Modal from "ui/overlays/Modal";
import { ModalBodyMessage, ModalFooter, ModalTitle } from "ui/styles";

import { dropdownDataForRole } from "../types/UserTypesEnum";
import { selectRoleItem } from "./AddInternalUser";

const StyleAccountSelect = styled.div`
  width: 301px;
  @media only screen and (max-width: ${props => props.theme.other.breakpoints.lg}) {
    width: 100%;
    margin-bottom: 20px;
  }
`;
const StyleRoleSelect = styled.div`
  width: 298px;
  @media only screen and (max-width: ${props => props.theme.other.breakpoints.lg}) {
    width: 100%;
  }
`;

const SelectWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 25px;
  width: 100%;
  margin-top: 24px;
  @media only screen and (max-width: ${props => props.theme.other.breakpoints.lg}) {
    display: block;
  }
`;

type AssignUserToAccountModalProps = {
  addUserToAccountForm: UseFormReturnType<
    {
      account: string;
      role: string;
    },
    (values: { account: string; role: string }) => {
      account: string;
      role: string;
    }
  >;
  handleConfirm(value: { account: string; role: string }): void;
  openModal: boolean;
  selectedUserRole: string;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
};

type AccountListing = {
  label: string;
  value: string;
};
// Transformer for Get Accounts listing
const transformerForGetAccountListing = (data: TAccount[]): AccountListing[] => {
  const transformData: AccountListing[] = [];
  data.forEach((element: TAccount) => {
    transformData.push({ label: element.name, value: element.id });
  });
  return transformData;
};

const AssignUserToAccountModal: React.FC<AssignUserToAccountModalProps> = ({
  openModal,
  setOpenModal,
  handleConfirm,
  addUserToAccountForm,
  selectedUserRole
}) => {
  const [accountListing, setAccountListing] = useState<AccountListing[]>([]);

  const page = 0;
  const limit = 100;
  const { data } = useGetAccountQuery(page, limit, true); // Query to get Account listing

  const onModalClose = () => setOpenModal(false);

  useEffect(() => {
    if (data?.data?.length) {
      const transformedData = transformerForGetAccountListing(data.data);
      setAccountListing(transformedData);
    }
  }, [data]);

  // Initial states
  useEffect(() => {
    if (openModal) {
      addUserToAccountForm.reset();
    }
  }, [openModal]);

  const options = dropdownDataForRole(selectedUserRole);

  return (
    <Modal opened={openModal} setOpened={setOpenModal} withCloseButton handleClose={onModalClose}>
      <form onSubmit={addUserToAccountForm.onSubmit(values => handleConfirm(values))}>
        <ModalTitle $type="success" centered>
          Assign User to Account
        </ModalTitle>
        <ModalBodyMessage centered>Add this user to a new account.</ModalBodyMessage>
        <SelectWrapper>
          <StyleAccountSelect>
            <Select
              placeholder="Select Account"
              label="Organization Account"
              withAsterisk
              data={accountListing}
              value={addUserToAccountForm.values.account}
              onChange={(value: string) => addUserToAccountForm.setFieldValue("account", value)}
              error={addUserToAccountForm.errors.account}
            />
          </StyleAccountSelect>
          <StyleRoleSelect>
            <Select
              withAsterisk
              placeholder="Select User Role"
              label="User Role"
              data={options}
              value={addUserToAccountForm.values.role}
              itemComponent={selectRoleItem}
              onChange={(value: string) => addUserToAccountForm.setFieldValue("role", value)}
              error={addUserToAccountForm.errors.role}
            />
          </StyleRoleSelect>
        </SelectWrapper>

        <ModalFooter>
          <Button
            variant="subtle"
            type="button"
            onClick={() => {
              addUserToAccountForm.reset();
              setOpenModal(false);
            }}
          >
            Cancel
          </Button>
          <Button type="submit">Add User</Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};
export default AssignUserToAccountModal;
