import React from "react";

import { Button } from "@mantine/core";
import Modal from "ui/overlays/Modal";
import { ModalBodyMessage, ModalFooter, ModalTitle } from "ui/styles";

interface SuspendUserModalProps {
  openModal: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  handleConfirm(status: string): void;
}

export const SuspendUserModal: React.FC<SuspendUserModalProps> = ({ openModal, setOpenModal, handleConfirm }) => {
  const onCloseModal = () => setOpenModal(false);

  return (
    <Modal opened={openModal} setOpened={setOpenModal} handleClose={onCloseModal} withCloseButton>
      <ModalTitle $type="warning" centered>
        Suspend User?
      </ModalTitle>
      <ModalBodyMessage centered>
        Are you sure you want to suspend this user from all accounts? User account access can be restored by account
        admin.
      </ModalBodyMessage>
      <ModalFooter>
        <Button
          variant="subtle"
          color="warning"
          onClick={() => {
            setOpenModal(false);
          }}
        >
          Cancel
        </Button>
        <Button
          color="warning"
          onClick={() => {
            handleConfirm("SUSPENDED");
          }}
        >
          Suspend User
        </Button>
      </ModalFooter>
    </Modal>
  );
};
