import { useTheme } from "@emotion/react";
import { Box, Grid, Group, Text } from "@mantine/core";
import { useGetClassificationListQueries } from "modules/classification";
import { IndustryClassification, TAnalyticsData } from "types";
import { BoxIcon, CalendarIcon, UserIcon } from "ui/icons";
import { getCountryByIso, getFullDayYearTime, getStateByIso } from "utils";

import { ISupplier } from "../suppliers.types";

type MergeSupplierInformationProps = {
  summaryData: { summary: TAnalyticsData };
  supplierData: ISupplier;
};

export const MergeSupplierInformation: React.FC<MergeSupplierInformationProps> = ({ summaryData, supplierData }) => {
  const {
    other: { primaryColor, monochromeColor }
  } = useTheme();

  const { region, city, country, createdAt, description, naicsCode, updatedAt } = supplierData;

  const codes = useGetClassificationListQueries({ codes: naicsCode ? [naicsCode] : [] });

  const naicsCodes = codes
    .map(({ data }) => {
      const codeData = data?.data[0] as IndustryClassification;
      return codeData;
    })
    .filter(data => data);

  const renderCodes = naicsCodes.map(({ code, title }) => `${title} (${code})`).join(", ");

  const fullCountry = getCountryByIso(country);
  const fullState = getStateByIso(region, country);
  const combinedLocation = [city, fullState, fullCountry].filter(i => i).join(", ");
  const location = combinedLocation.length ? combinedLocation : "Not Specified";

  const lastUpdate = getFullDayYearTime(updatedAt);

  const supplierCreated = getFullDayYearTime(createdAt);

  const itemsCount = summaryData?.summary?.items;
  const transactionsCount = summaryData?.summary?.transactions;

  return (
    <Grid gutter={12} c={monochromeColor[2]}>
      <Grid.Col xs={12} sm={6}>
        <Group noWrap align="flex-start">
          <Box w={20} h={20} c={primaryColor}>
            <UserIcon />
          </Box>
          <Text>
            <Text span fw={700}>
              NAICS Code:{" "}
            </Text>
            {renderCodes || "Not specified"}
          </Text>
        </Group>
      </Grid.Col>
      <Grid.Col xs={12} sm={6}>
        <Group noWrap align="flex-start">
          <Box style={{ whiteSpace: "nowrap" }}>
            <Text fw={700}>Supplier Created: </Text>
          </Box>
          <Text span fw={400}>
            {supplierCreated}
          </Text>
        </Group>
      </Grid.Col>
      <Grid.Col xs={12} sm={6}>
        <Group noWrap align="flex-start">
          <Box w={20} h={20} c={primaryColor}>
            <BoxIcon />
          </Box>
          <Group noWrap align="flex-start">
            <Box style={{ whiteSpace: "nowrap" }}>
              <Text fw={700}>Location: </Text>
            </Box>
            <Text>{location}</Text>
          </Group>
        </Group>
      </Grid.Col>
      <Grid.Col xs={12} sm={6}>
        <Text fw={700}>
          Description:{" "}
          <Text span fw={400}>
            {description || "Not Specified"}
          </Text>
        </Text>
      </Grid.Col>
      <Grid.Col xs={12} sm={6}>
        <Group noWrap align="flex-start">
          <Box w={20} h={20} c={primaryColor}>
            <CalendarIcon />
          </Box>
          <Box>
            <Text fw={700} style={{ whiteSpace: "nowrap" }}>
              Last Update:
            </Text>
          </Box>
          <Text>{lastUpdate}</Text>
        </Group>
      </Grid.Col>
      <Grid.Col xs={12} sm={6}>
        <Group noWrap align="flex-start">
          <Box>
            <Text fw={700}>Number of Items: </Text>
          </Box>
          <Text fw={400}>{itemsCount}</Text>
        </Group>
      </Grid.Col>
      <Grid.Col xs={12} sm={6} offsetMd={6}>
        <Group>
          <Box>
            <Text fw={700}>Number of Transactions: </Text>
          </Box>
          <Text span fw={400}>
            {transactionsCount}
          </Text>
        </Group>
      </Grid.Col>
    </Grid>
  );
};
