import axiosInstance from "services/AxiosInstance";
import { ResponseType, TImportError } from "types";

type GetImportErrorListServiceArgs = (apiArgs: {
  importId: string;
  params?: {
    limit?: number;
    page?: number;
  };
}) => Promise<ResponseType<TImportError[], { totalCount: number }>>;

export const getImportErrorListService: GetImportErrorListServiceArgs = async apiArgs => {
  const { importId, params } = apiArgs;
  const response = await axiosInstance.get(`imports/${importId}/errors`, { params });
  return response.data;
};
