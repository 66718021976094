import { Button } from "@mantine/core";
import Modal from "ui/overlays/Modal";
import { ModalBodyMessage, ModalFooter, ModalTitle } from "ui/styles";

import { ModalProps } from "./types";

export const SuspendModal = ({ open, onClose, onConfirm }: ModalProps) => {
  return (
    <Modal opened={open} handleClose={onClose} withCloseButton mode="modal">
      <ModalTitle $type="warning" centered>
        Suspend Account?
      </ModalTitle>
      <ModalBodyMessage centered>
        Are you sure you want to suspend this organization’s account?
        <br /> Access can be restored by Kloopify admin.
      </ModalBodyMessage>
      <ModalFooter centered>
        <Button variant="subtle" color="warning" type="button" onClick={onClose}>
          Cancel
        </Button>
        <Button onClick={onConfirm} color="warning">
          Suspend Account
        </Button>
      </ModalFooter>
    </Modal>
  );
};
