import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ErrorResponse } from "App";
import { QUERY_KEYS } from "constants/queryKeys";
import { errorModal } from "modules/error/ErrorHandlingModals";

import { deleteUserService } from "../api";

export const useDeleteUserMutation = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: deleteUserService,
    mutationKey: [QUERY_KEYS.delete_user],
    onSuccess: () => {
      queryClient.refetchQueries({ queryKey: [QUERY_KEYS.user_list] });
    },
    onError: (error: ErrorResponse) => errorModal(error)
  });
  return mutation;
};
