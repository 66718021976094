import styled from "@emotion/styled";
import { monochromeColor } from "constants/colorPalette";
import Modal from "ui/overlays/Modal";

export const StyledModal = styled(Modal)`
  .mantine-Modal-modal {
    width: auto;
    min-height: 400px;
    padding: 2.8rem 3.75rem 2.8rem 3.75rem;
    @media only screen and (max-width: ${props => props.theme.other.breakpoints.lg}) {
      padding: 3rem 4rem;
    }
  }
`;

export const StyledFilterTitle = styled.div`
  font-size: ${props => props.theme.other.font.modalGroupHeader.fontSize};
  line-height: ${props => props.theme.other.font.modalGroupHeader.lineHeight};
  font-weight: ${props => props.theme.other.font.modalGroupHeader.fontWeight};
  color: ${monochromeColor[2]};
`;

export const StyledRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 2.5rem;
  gap: 4rem;
  @media only screen and (max-width: ${props => props.theme.other.breakpoints.lg}) {
    flex-direction: column;
    gap: 2rem;
  }
`;

export const StyledFilterWrapper = styled.div<{ equalLayout?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  flex: ${props => (props.equalLayout ? "1 0" : "")};
  &:nth-of-type(1) {
    min-width: 55%;
    max-width: 55%;
  }
  @media only screen and (max-width: ${props => props.theme.other.breakpoints.lg}) {
    &:nth-of-type(1) {
      max-width: unset;
    }
  }
`;

export const StyledFilterCheckboxCombiner = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

export const StyledDatePickerFilterCheckboxCombiner = styled(StyledFilterCheckboxCombiner)`
  width: 100%;
`;

export const StyledInputFilterCheckboxCombiner = styled(StyledFilterCheckboxCombiner)<{ fixed?: boolean }>`
  max-width: ${props => (props.fixed ? "200px" : "")};
  align-items: center;
  @media only screen and (max-width: ${props => props.theme.other.breakpoints.lg}) {
    max-width: unset;
    min-width: 100%;
    align-items: flex-start;
  }
`;

export const StyledDatePickerMobileWrapper = styled(StyledInputFilterCheckboxCombiner)`
  @media only screen and (max-width: ${props => props.theme.other.breakpoints.lg}) {
    flex-direction: column;
  }
`;

export const StyledCountrySelectSizer = styled.div`
  width: 100%;
  max-width: 200px;
  @media only screen and (max-width: ${props => props.theme.other.breakpoints.lg}) {
    max-width: unset;
  }
`;

export const StyledDatePickerSizer = styled.div`
  max-width: 131px;
  @media only screen and (max-width: ${props => props.theme.other.breakpoints.lg}) {
    width: 100%;
    max-width: unset;
  }
`;

export const StyledFooter = styled.div`
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  border-top: 1px solid #c4c4c4;
  padding-top: 2rem;
  margin-top: 5rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
`;
