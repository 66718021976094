import styled from "@emotion/styled";

export const Wrapper = styled.div`
  width: 100%;
  @media only screen and (max-width: 1366px) {
    width: calc(100% - 20px);
    margin-left: auto;
    margin-right: auto;
  }
`;

export const StyledNumberOfItems = styled.span`
  font-family: ${props => props.theme.other.font.fontFamily};
  font-style: ${props => props.theme.other.font.fontStyle};
  font-weight: 400;
  font-size: ${props => props.theme.other.font.fontSize};
  line-height: ${props => props.theme.other.font.lineHeight};
  color: ${props => props.theme.other.monochromeColor[2]};
  margin-left: 21px;
`;

export const CommodityHeaderContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  @media only screen and (max-width: 705px) {
    display: grid;
    gap: 12px;
    justify-content: center;
    align-items: center;
  }
`;

export const HeaderLeftContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;

  @media only screen and (max-width: 705px) {
    flex-direction: column;
  }
`;
