import { useState } from "react";

import { Button } from "@mantine/core";
import { confirmationModal } from "modules/error/ErrorHandlingModals";
import { TSupplierMergePayload } from "types";
import { ModalBodyMessage, ModalFooter, ModalTitle } from "ui";
import Modal from "ui/overlays/Modal";

import { MergeSuppliersModalContainer } from "../styles";
import { ISupplier } from "../suppliers.types";
import { MergeSuppliersModalContent } from "./MergeSuppliersModalContent";

type MergeSuppliersModalProps = {
  onClose: () => void;
  onSubmit: (payload: TSupplierMergePayload) => void;
  opened: boolean;
  rowSelection: ISupplier[];
};

export const MergeSuppliersModal: React.FC<MergeSuppliersModalProps> = ({
  opened,
  onClose,
  rowSelection = [],
  onSubmit
}) => {
  const options = rowSelection.map(selected => ({ label: selected.name, value: selected.id }));

  const [value, setValue] = useState<string | undefined>();

  const onChange = (value: string) => setValue(value);

  const onMergeModalClose = () => {
    onClose();
    setValue(undefined);
  };

  const onMerge = () => {
    if (value) {
      confirmationModal({
        title: "Confirm Merge Supplier",
        message: `Merging action is irreversible. Do you want to continue?`,
        onConfirm: () => {
          const mergeSuppliers = options.filter(option => option.value !== value).map(({ value }) => value);
          onSubmit({ targetSupplierId: value, mergeSuppliers, mergeStrategy: "AUTO_MERGE" });
          onMergeModalClose();
        }
      });
    }
  };

  return (
    <Modal opened={opened} withCloseButton handleClose={onMergeModalClose}>
      <ModalTitle centered>Merge Suppliers</ModalTitle>
      <ModalBodyMessage centered>
        Select the Target Supplier to be merged with. (Note - all Data, Transactions and Item for the other Suppliers
        will be merged into this)
      </ModalBodyMessage>
      <MergeSuppliersModalContainer>
        <MergeSuppliersModalContent options={options} onChange={onChange} value={value} />
      </MergeSuppliersModalContainer>
      <ModalFooter>
        <Button variant="subtle" onClick={onMergeModalClose}>
          Cancel
        </Button>
        <Button disabled={!value} onClick={onMerge}>
          Merge
        </Button>
      </ModalFooter>
    </Modal>
  );
};
