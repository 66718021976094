import { useParams } from "react-router-dom";

import Loader from "ui/feedback/Loader";

import { DynamicQuestionnaire, QuestionnaireHeader } from "./components";
import { useGetQuestionnaireQuery, useGetQuestionnaireRequestQuery } from "./hooks";
import { QuestionnaireResponseCenterer, QuestionnaireResponseContainer } from "./questionnaire.styles";

export const Questionnaire = () => {
  const { id } = useParams();

  const questionnaireRequest = useGetQuestionnaireRequestQuery(id);

  const questionnaire = useGetQuestionnaireQuery(questionnaireRequest?.data?.data.questionnaire.id);

  if (questionnaire.isLoading || questionnaireRequest.isLoading) {
    return <Loader />;
  }

  if (!questionnaire?.data?.data || !questionnaireRequest?.data?.data) {
    return null;
  }

  return (
    <QuestionnaireResponseCenterer>
      <QuestionnaireResponseContainer>
        <QuestionnaireHeader questionnaire={questionnaire.data.data} request={questionnaireRequest.data.data} />
        <DynamicQuestionnaire
          questionnaireData={questionnaire.data.data}
          questionnaireRequestData={questionnaireRequest.data.data}
        />
      </QuestionnaireResponseContainer>
    </QuestionnaireResponseCenterer>
  );
};
