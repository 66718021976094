import styled from "@emotion/styled";

export const MethodologyTextGroup = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
`;

export const MethodologyText = styled.p<{ color?: string }>`
  font-size: 1rem;
  text-align: left;
  line-height: 1;
  margin: 0;
  color: ${({ theme, color }) => {
    if (color) {
      return color;
    }
    return theme.other.primaryColor;
  }};
`;
