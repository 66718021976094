import moment from "moment";
import { QuestionnaireRequestStatus, TQuestionnaire, TQuestionnaireRequest } from "types";
import { formatDateStringFull } from "utils";

import { QuestionnaireResponseHeader } from "../questionnaire.styles";

type QuestionnaireHeaderProps = {
  questionnaire: TQuestionnaire;
  request: TQuestionnaireRequest;
};

export const QuestionnaireHeader = ({ questionnaire, request }: QuestionnaireHeaderProps) => {
  const responseDate = request ? moment(request.updatedAt, moment.ISO_8601).utc().local().format("MM/DD/YYYY") : "";

  const questionnaireStatusInfoMap = {
    [QuestionnaireRequestStatus.SUBMITTED]: `This questionnaire was submitted by ${request.sendToFirstName} ${request.sendToLastName} on ${responseDate}.`,
    [QuestionnaireRequestStatus.IN_PROGRESS]: `Responses were last saved on ${responseDate}.`,
    [QuestionnaireRequestStatus.APPROVED]: `This response was approved on ${responseDate}.`,
    [QuestionnaireRequestStatus.REJECTED]: `This response was rejected on ${responseDate}.`
  };

  return (
    <QuestionnaireResponseHeader>
      {request.status !== QuestionnaireRequestStatus.SENT && <h2>{questionnaireStatusInfoMap[request.status]}</h2>}
      <h1>{questionnaire.title}</h1>
      <p>
        <span>Contact: </span>
        {request.sendToFirstName} {request.sendToLastName}
      </p>
      {request && (
        <p>
          <span>Last Update: </span>
          {formatDateStringFull(request.updatedAt)}
        </p>
      )}
      <p>
        <span>Instructions: </span>
        {request?.questionnaire.description}
      </p>
    </QuestionnaireResponseHeader>
  );
};
