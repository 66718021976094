import { useContext, useState } from "react";

import { useTheme } from "@emotion/react";
import { ImportInterface } from "modules/imports/types";
import {
  ANALYSIS_REJECTED,
  COMPLETED,
  CREATED,
  PENDING_ANALYSIS,
  PENDING_ANALYSIS_PROCESSING,
  PENDING_APPROVAL,
  PROCESSING_ANALYSIS_FILE,
  PROCESSING_TRANSACTION_FILE,
  UPLOAD_REJECTED
} from "modules/imports/types/ImportTypeEnum";
import { CurrentUserContext } from "modules/login";
import { USER_TYPE } from "modules/users/types/UserTypesEnum";

import { AnalysisRejectedReasonModal } from "../AnalysisRejectedReasonModal";
import { UploadRejectedModal } from "../UploadRejectedModal";
import { Complete, Pending, Rejected, StyleText } from "./styles";

const initialModalStatus = {
  open: null,
  message: null
};

export const ImportStatusTag: React.FC<ImportInterface> = props => {
  const { userDetails } = useContext(CurrentUserContext);
  const {
    other: { monochromeColor }
  } = useTheme();

  const [modalStatus, setModalStatus] = useState<{ message: string | null; open: "analysis" | "upload" | null }>(
    initialModalStatus
  );

  const onOpenModal = (type: "analysis" | "upload") => {
    setModalStatus({ open: type, message: props.rejectedReasonNote });
  };

  const onModalClose = () => setModalStatus(initialModalStatus);

  const statusHandler = (detail: ImportInterface): JSX.Element | null => {
    switch (detail.status) {
      case UPLOAD_REJECTED.value:
        return (
          <Rejected
            onClick={() => {
              onOpenModal("upload");
            }}
          >
            <StyleText background="rgba(178, 11, 51, 0.2)">{UPLOAD_REJECTED.label}</StyleText>
          </Rejected>
        );
      case PENDING_ANALYSIS.value:
        return (
          <Pending>
            <StyleText>{PENDING_ANALYSIS.label}</StyleText>
          </Pending>
        );
      case PENDING_ANALYSIS_PROCESSING.value:
        return (
          <Pending>
            <StyleText>{PENDING_ANALYSIS_PROCESSING.label}</StyleText>
          </Pending>
        );
      case PENDING_APPROVAL.value:
        return (
          <Pending>
            <StyleText>
              {userDetails.userType === USER_TYPE.internal ? PENDING_APPROVAL.label : PENDING_ANALYSIS.label}
            </StyleText>
          </Pending>
        );
      case ANALYSIS_REJECTED.value:
        if (userDetails.userType === USER_TYPE.internal) {
          return (
            <Rejected
              onClick={() => {
                if (userDetails.userType === USER_TYPE.internal) {
                  onOpenModal("analysis");
                }
              }}
            >
              <StyleText background="rgba(178, 11, 51, 0.2)">{ANALYSIS_REJECTED.label}</StyleText>
            </Rejected>
          );
        } else {
          return (
            <Pending>
              <StyleText>{PENDING_ANALYSIS.label}</StyleText>
            </Pending>
          );
        }
      case PROCESSING_TRANSACTION_FILE.value:
        return (
          <Pending>
            <StyleText>
              {userDetails.userType === USER_TYPE.internal ? PROCESSING_TRANSACTION_FILE.label : PENDING_ANALYSIS.label}
            </StyleText>
          </Pending>
        );
      case PROCESSING_ANALYSIS_FILE.value:
        return (
          <Pending>
            <StyleText>
              {userDetails.userType === USER_TYPE.internal ? PROCESSING_ANALYSIS_FILE.label : PENDING_ANALYSIS.label}
            </StyleText>
          </Pending>
        );
      case COMPLETED.value:
        return (
          <Complete>
            <StyleText background={monochromeColor[8]}>{COMPLETED.label}</StyleText>
          </Complete>
        );
      case CREATED.value:
        return (
          <Pending>
            <StyleText>{CREATED.label}</StyleText>
          </Pending>
        );
      default:
        return null;
    }
  };

  const status = statusHandler(props);

  const reason = modalStatus.message ?? "";

  return (
    <>
      {status}
      <UploadRejectedModal openModal={modalStatus.open === "upload"} handleClose={onModalClose} reason={reason} />
      <AnalysisRejectedReasonModal
        openModal={modalStatus.open === "analysis"}
        handleClose={onModalClose}
        setOpenModal={onModalClose}
        reason={reason}
      />
    </>
  );
};
