import { useContext } from "react";

import useViewportPercent from "hooks/useViewportPercent";
import { CurrentUserContext } from "modules/login/CurrentUserContext";
import { PencilIcon } from "ui/icons";

import { HeaderWrapper, StyledHeader } from "./privateLayoutSubHeader.styles";

type PrivateLayoutSubHeaderProps = {
  accountName?: string | null;
  setShowUpdateAccountModal?: React.Dispatch<React.SetStateAction<boolean>>;
  showEditAccountDetailsIcon?: boolean;
};

const PrivateLayoutSubHeader: React.FC<PrivateLayoutSubHeaderProps> = ({
  accountName = null,
  showEditAccountDetailsIcon,
  setShowUpdateAccountModal
}) => {
  const { userDetails } = useContext(CurrentUserContext);

  const maxWidthLaptop = useViewportPercent({ percent: 75 });

  return (
    <div>
      <HeaderWrapper maxWidthLaptop={maxWidthLaptop}>
        <StyledHeader maxWidthLaptop={maxWidthLaptop}>
          {accountName ?? userDetails.accountName}
          {showEditAccountDetailsIcon && (
            <div>
              <PencilIcon
                style={{ cursor: "pointer" }}
                onClick={() => setShowUpdateAccountModal && setShowUpdateAccountModal(true)}
              />
            </div>
          )}
        </StyledHeader>
      </HeaderWrapper>
    </div>
  );
};
export default PrivateLayoutSubHeader;
