import { useQuery } from "@tanstack/react-query";
import { QUERY_KEYS } from "constants/queryKeys";
import { ResponseError, ResponseType } from "types";

import { getCustomFields } from "../api";
import { CustomField } from "../types";

export const useGetCustomFields = ({ accountId }: { accountId: string }) =>
  useQuery<ResponseType<CustomField[]>, ResponseError>({
    queryKey: [QUERY_KEYS.get_custom_fields, { accountId }],
    queryFn: () => getCustomFields(accountId),
    enabled: !!accountId,
    initialData: () => ({
      data: [],
      meta: {
        type: ""
      }
    })
  });
