import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ErrorResponse } from "App";
import { QUERY_KEYS } from "constants/queryKeys";
import { errorModal } from "modules/error/ErrorHandlingModals";

import { postQuestionnaireResponseService } from "../api";

export const usePostQuestionnaireResponseMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: postQuestionnaireResponseService,
    mutationKey: [QUERY_KEYS.post_questionnaire_response],
    onError: (error: ErrorResponse) => errorModal(error),
    onSuccess: payload => {
      queryClient.refetchQueries({
        queryKey: [QUERY_KEYS.get_questionnaire_request, payload?.data?.questionnaireRequestId]
      });
    }
  });
};
