import { Button } from "@mantine/core";
import Modal from "ui/overlays/Modal";
import { ModalBodyMessage, ModalFooter, ModalTitle } from "ui/styles";

import { ModalProps } from "./types";

export const DeleteModal = ({ open, onClose, onConfirm }: ModalProps) => {
  return (
    <Modal opened={open} handleClose={onClose} withCloseButton>
      <ModalTitle $type="danger" centered>
        Delete Account
      </ModalTitle>
      <ModalBodyMessage centered>
        Are you sure you want to delete this organization’s account? <br /> All data will be lost.
      </ModalBodyMessage>
      <ModalFooter centered>
        <Button variant="subtle" color="danger.8" onClick={onClose}>
          Cancel
        </Button>
        <Button color="danger.8" onClick={onConfirm}>
          Delete Account
        </Button>
      </ModalFooter>
    </Modal>
  );
};
