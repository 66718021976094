import { useQuery } from "@tanstack/react-query";
import { QUERY_KEYS } from "constants/queryKeys";

import { getAnalyticsSummary } from "../api";

export const useGetAnalyticsSummary = (accountId: string, startDate?: string, endDate?: string) => {
  const query = useQuery({
    queryKey: [QUERY_KEYS.overview_dashboard],
    queryFn: () => getAnalyticsSummary(accountId, startDate, endDate)
  });
  return query;
};
