import { FileInputProps, FileInput as MantineFileInput } from "@mantine/core";
import { FileUploadIcon } from "ui/icons";

export const FileInput: React.FC<FileInputProps> = props => {
  return (
    <MantineFileInput
      placeholder="Pick file"
      label="Upload File"
      {...props}
      clearable
      icon={<FileUploadIcon width={18} height={22} />}
    />
  );
};
