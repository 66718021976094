import React from "react";

import {
  QuestionnaireCreatorLayout as Layout,
  QuestionnaireCreatorBody,
  QuestionnaireCreatorContainer,
  QuestionnaireCreatorTop,
  QuestionnaireCreatorTopDescription
} from "./questionnaireCreator.styles";

type QuestionnaireCreatorLayoutProps = {
  children: React.ReactNode;
};

export const QuestionnaireCreatorLayout: React.FC<QuestionnaireCreatorLayoutProps> = ({ children }) => {
  return (
    <Layout>
      <QuestionnaireCreatorContainer>
        <QuestionnaireCreatorTop>
          <h1>Create New Questionnaire</h1>
        </QuestionnaireCreatorTop>
        <QuestionnaireCreatorTopDescription />
        <QuestionnaireCreatorBody>{children}</QuestionnaireCreatorBody>
      </QuestionnaireCreatorContainer>
    </Layout>
  );
};
