import React from "react";

import { QuestionnaireNormalized } from "types";
import { XIcon } from "ui/icons";

import { titleMap } from "../questionnaire.data";
import { QuestionnaireContentHeaderContainer } from "../questionnaire.styles";

type Props = {
  group: QuestionnaireNormalized;
  removable: boolean;
  setNormalizedQuestionnaire: React.Dispatch<React.SetStateAction<QuestionnaireNormalized[]>>;
};

export const QuestionnaireContentHeader = ({ group, removable, setNormalizedQuestionnaire }: Props) => {
  const { scope, id } = group;

  const title = titleMap[scope];

  const remove = () => {
    setNormalizedQuestionnaire(prev => prev.filter(i => i.id !== id));
  };

  return (
    <QuestionnaireContentHeaderContainer>
      <h1>{title}</h1>
      {removable && <XIcon width="15px" height="15px" onClick={remove} />}
    </QuestionnaireContentHeaderContainer>
  );
};
