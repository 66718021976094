import { useMutation, useQueryClient } from "@tanstack/react-query";
import { QUERY_KEYS } from "constants/queryKeys";

import { postBuyspaceService } from "../api";

export const useCreateBuyspace = ({ onSuccess }: { onSuccess: () => void }) => {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: postBuyspaceService,
    mutationKey: [QUERY_KEYS.create_buyspaces],
    onSuccess: () => {
      queryClient.refetchQueries({ queryKey: [QUERY_KEYS.get_buyspaces] });
      onSuccess();
    }
  });
  return mutation;
};
