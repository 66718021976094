import { IUserTableRowsItemConfig } from "../userTable/userTable.types";

export const userTableRowsItemsConfig: IUserTableRowsItemConfig[] = [
  {
    type: "avatar",
    keyToLabel: ""
  },
  {
    type: "default",
    keyToLabel: "lastName"
  },
  {
    type: "default",
    keyToLabel: "email"
  },
  {
    type: "account",
    keyToLabel: ""
  },
  {
    type: "status",
    keyToLabel: ""
  },
  {
    type: "menu",
    keyToLabel: ""
  }
];
