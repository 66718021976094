import { Button } from "@mantine/core";
import Modal from "ui/overlays/Modal";
import { ModalBodyMessage, ModalFooter, ModalTitle } from "ui/styles";

type ConfirmationDeleteModalProps = {
  buttonMarginTop?: string;
  cancelBtnText?: string;
  confirmBtnText?: string;
  descriptionLineHeight?: string;
  descriptionText: string;
  descriptionTextSecond?: string;
  handleConfirm: () => void;
  modalPadding?: string;
  openModal: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  titleText: string;
};

const ConfirmationDeleteModal = ({
  openModal = false,
  setOpenModal,
  cancelBtnText,
  confirmBtnText,
  titleText,
  descriptionText,
  handleConfirm
}: ConfirmationDeleteModalProps) => {
  const handleModalConfirm = () => handleConfirm();

  const handleModalClose = () => setOpenModal(false);

  return (
    <Modal opened={openModal} setOpened={setOpenModal} withCloseButton handleClose={handleModalClose}>
      <ModalTitle $type="danger" centered>
        {titleText}
      </ModalTitle>
      <ModalBodyMessage centered>{descriptionText}</ModalBodyMessage>
      <ModalFooter>
        <Button variant="subtle" color="danger.8" onClick={handleModalClose}>
          {cancelBtnText ?? "Cancel"}
        </Button>
        <Button color="danger.8" onClick={handleModalConfirm}>
          {confirmBtnText ?? "Confirm"}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
export default ConfirmationDeleteModal;
