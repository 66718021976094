import { useQuery } from "@tanstack/react-query";
import { ErrorResponse } from "App";
import { QUERY_KEYS } from "constants/queryKeys";
import { errorModal } from "modules/error/ErrorHandlingModals";
import { getUserInfoService } from "modules/users";

export const useGetUserInfo = (userId: string, queryEnabled: boolean) => {
  const jwtToken = localStorage.getItem("jwt_Token") ?? null;
  const query = useQuery({
    queryKey: [QUERY_KEYS.user_details, userId],
    queryFn: () => getUserInfoService(userId),
    enabled: userId !== "" && queryEnabled && jwtToken !== "",
    onError: (error: ErrorResponse) => errorModal(error),
    useErrorBoundary: true
  });
  return query;
};
