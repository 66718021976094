import { useQuery } from "@tanstack/react-query";
import { ErrorResponse } from "aws-sdk/clients/migrationhubrefactorspaces";
import { QUERY_KEYS } from "constants/queryKeys";
import { errorModal } from "modules/error/ErrorHandlingModals";

import { getAccountUsers } from "../apis/getAccountUsers";

const useGetAccountUsers = (accountId?: string) =>
  useQuery({
    queryKey: [QUERY_KEYS.get_account_users, accountId],
    queryFn: () => getAccountUsers(accountId),
    useErrorBoundary: true,
    enabled: Boolean(accountId),
    onError: (error: ErrorResponse) => errorModal(error)
  });

export default useGetAccountUsers;
