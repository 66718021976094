import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ErrorResponse } from "App";
import { QUERY_KEYS } from "constants/queryKeys";
import { errorModal } from "modules/error/ErrorHandlingModals";
import { ResponseType, TQuestionnaire } from "types";

import { deleteQuestionnaireService } from "../api";

type UseDeleteQuestionnaireMutationArgs = Partial<{ onSuccess: () => void }>;

export const useDeleteQuestionnaireMutation = ({ onSuccess }: UseDeleteQuestionnaireMutationArgs) => {
  const queryClient = useQueryClient();

  const queryKey = [QUERY_KEYS.get_questionnaire_list];

  const mutation = useMutation({
    mutationKey: [QUERY_KEYS.delete_questionnaire],
    mutationFn: deleteQuestionnaireService,
    onMutate: async ({ questionnaireId }) => {
      await queryClient.cancelQueries({ queryKey });

      const previousData: ResponseType<TQuestionnaire[]> | undefined = queryClient.getQueryData(queryKey);

      if (previousData) {
        const newQuestionnaireList = previousData.data.filter(({ id }) => id !== questionnaireId);

        const updatedValues = {
          data: newQuestionnaireList,
          meta: previousData.meta
        };

        queryClient.setQueryData(queryKey, updatedValues);

        return { updatedValues };
      }

      return { previousData };
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey });
      onSuccess?.();
    },
    onError: (error: ErrorResponse) => errorModal(error)
  });

  return mutation;
};
