export type TGroupType = "external" | "internal";

export interface IRoleData {
  label: string;
  value: string;
}

export interface IUserFiltersData {
  accountName: string;
  statuses: string[];
  roles: string[];
  search: string;
}

export interface IUserRolesCheckboxesConfig {
  id: number;
  header: string;
  group: TGroupType;
  data: IRoleData[];
}

export const userFiltersInitialData: IUserFiltersData = {
  accountName: "",
  statuses: [],
  roles: [],
  search: ""
};

export const userStatusFilterData = [
  {
    label: "Active",
    value: "ACTIVE"
  },
  {
    label: "Inactive",
    value: "INACTIVE"
  },
  {
    label: "Suspended",
    value: "SUSPENDED"
  }
];

export const externalRoleData = [
  {
    label: "Admin",
    value: "ACCOUNT_ADMIN"
  },
  {
    label: "Category Manager",
    value: "ACCOUNT_CATEGORY_MANAGER"
  }
];

export const internalRoleData = [
  {
    label: "Super Admin",
    value: "INTERNAL_ADMIN"
  },
  {
    label: "Analyst",
    value: "INTERNAL_ANALYST"
  }
];

export const rolesData = {
  internal: internalRoleData,
  external: externalRoleData
};

export const userRolesCheckboxesConfig: IUserRolesCheckboxesConfig[] = [
  {
    id: 0,
    header: "External",
    group: "external",
    data: externalRoleData
  },
  {
    id: 1,
    header: "Internal",
    group: "internal",
    data: internalRoleData
  }
];

export const groupCheckedKeysConfig = {
  internal: ["INTERNAL_ADMIN", "INTERNAL_ANALYST"],
  external: ["ACCOUNT_ADMIN", "ACCOUNT_CATEGORY_MANAGER"]
};
