import { useContext, useEffect, useMemo, useState } from "react";

import { Button, Grid, LoadingOverlay, Select, TextInput } from "@mantine/core";
import { useForm, yupResolver } from "@mantine/form";
import { ErrorResponse } from "App";
import { Country, State } from "country-state-city";
import useUpdateAccountMutation from "modules/account/mutations/UpdateAccount";
import { CurrentUserContext } from "modules/login/CurrentUserContext";
import { USER_TYPE } from "modules/users/types/UserTypesEnum";
import { TAccount } from "types";
import { TAccountCreation } from "types/Account/AccountCreation";
import Loader from "ui/feedback/Loader";
import ErrorModal from "ui/overlays/ErrorModal";
import SuccessModal from "ui/overlays/SuccessModal";
import { ModalFooter } from "ui/styles";
import { getBrowserLocal } from "utils/common.utils";

import { AccountFormWrapper } from "./AccountForm.styles";
import { validationSchema } from "./validationSchema";

const countries = Country.getAllCountries();

type Option = {
  label: string;
  value: string;
};

const countryTimezoneDropdown = (countryCode: string): Option[] => {
  const timezonesArr = Country.getCountryByCode(countryCode);
  if (timezonesArr?.timezones) {
    return timezonesArr?.timezones?.map(timezone => {
      return { label: timezone.zoneName, value: timezone.zoneName };
    });
  }
  return [];
};

const countryStatesDropdown = (countryCode: string): Option[] => {
  const states = State.getStatesOfCountry(countryCode);
  return states?.map(state => {
    return { label: state.name, value: state.isoCode };
  });
};

type AccountFormProps = { data: TAccount; onClose: () => void };

export const AccountForm = ({ data, onClose }: AccountFormProps) => {
  const { userDetails, setUserDetails } = useContext(CurrentUserContext);
  const { userType } = userDetails;
  const [isErrorModalVisible, setIsErrorModalVisible] = useState<boolean>(false);
  const [isSuccessModalVisible, setIsSuccessModalVisible] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const { isLoading, isSuccess, mutate, error, isError } = useUpdateAccountMutation();

  const { id, suppliers, transactions, ...editable } = data;

  const countryOptions = useMemo(() => countries.map(country => ({ label: country.name, value: country.isoCode })), []);

  const updateAccountForm = useForm({
    initialValues: {
      ...editable
    },
    validate: yupResolver(validationSchema)
  });

  const handleCountryChange = (value: string) => {
    updateAccountForm.setFieldValue("country", value);
    updateAccountForm.setFieldValue("localTimeZone", "");
    updateAccountForm.setFieldValue("region", "");
  };

  const handleSubmit = (payload: TAccountCreation) => {
    mutate({
      id: data.id,
      payLoad: {
        ...payload,
        locale: getBrowserLocal()
      }
    });
  };
  useEffect(() => {
    if (isSuccess) {
      setIsSuccessModalVisible(true);
      if (userType === USER_TYPE.external) {
        setUserDetails({ ...userDetails, accountName: updateAccountForm.values.name });
        localStorage.setItem("accountName", updateAccountForm.values.name);
      }
    }
    if (isError) {
      const errorMessage = (error as ErrorResponse).response.data.errorMessage ?? "Something Went Wrong.";
      setErrorMessage(errorMessage);
      setIsErrorModalVisible(true);
    }
  }, [isSuccess, isError]);

  const handleCancel = () => {
    updateAccountForm.reset();
    onClose();
  };

  return (
    <>
      <AccountFormWrapper>
        <form onSubmit={updateAccountForm.onSubmit(handleSubmit)}>
          <Grid gutter={12}>
            <Grid.Col span={12}>
              <TextInput name="name" label="Account Name" required {...updateAccountForm.getInputProps("name")} />
            </Grid.Col>
            <Grid.Col span={12}>
              <TextInput name="street" label="Address" {...updateAccountForm.getInputProps("street")} />
            </Grid.Col>
            <Grid.Col span={6}>
              <Select
                searchable
                name="country"
                required
                placeholder="Select Country"
                label="Country"
                data={countryOptions}
                value={updateAccountForm.values.country}
                error={updateAccountForm.errors.country}
                onChange={(value: string) => handleCountryChange(value)}
              />
            </Grid.Col>

            <Grid.Col span={6}>
              <Select
                searchable
                name="localTimeZone"
                required
                placeholder="Select Time Zone"
                label="Time Zone"
                data={countryTimezoneDropdown(updateAccountForm.values.country)}
                value={updateAccountForm.values.localTimeZone}
                error={updateAccountForm.errors.localTimeZone}
                onChange={(value: string) => updateAccountForm.setFieldValue("localTimeZone", value)}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <Select
                searchable
                name="region"
                placeholder="State"
                label="State"
                data={countryStatesDropdown(updateAccountForm.values.country)}
                value={updateAccountForm.values.region}
                onChange={(value: string) => updateAccountForm.setFieldValue("region", value)}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <TextInput name="city" label="City" {...updateAccountForm.getInputProps("city")} />
            </Grid.Col>
            <Grid.Col span={6}>
              <TextInput name="postalCode" label="Zip Code" {...updateAccountForm.getInputProps("postalCode")} />
            </Grid.Col>
            <Grid.Col span={6}>
              <TextInput name="taxId" label="VAT/Tax Id" {...updateAccountForm.getInputProps("taxId")} />
            </Grid.Col>
            <Grid.Col span={12}>
              <TextInput name="crmId" label="CRM ID" {...updateAccountForm.getInputProps("crmId")} />
            </Grid.Col>

            <Grid.Col span={12}>
              <ModalFooter>
                <Button variant="default" type="button" onClick={handleCancel}>
                  Cancel
                </Button>

                <Button type="submit">Save Changes</Button>
              </ModalFooter>
            </Grid.Col>
          </Grid>
        </form>
        <LoadingOverlay loader={<Loader />} visible={isLoading} transitionDuration={300} />
      </AccountFormWrapper>
      <ErrorModal
        openModal={isErrorModalVisible}
        setOpenModal={setIsErrorModalVisible}
        onClose={onClose}
        message={errorMessage}
      />
      <SuccessModal
        openModal={isSuccessModalVisible}
        setOpenModal={setIsSuccessModalVisible}
        onClose={onClose}
        message={`Changes to ${updateAccountForm.values.name} account have been saved.`}
      />
    </>
  );
};
