import { Button } from "@mantine/core";
import Modal from "ui/overlays/Modal";
import { ModalBodyMessage, ModalFooter, ModalTitle } from "ui/styles";

import { ModalProps } from "./types";

export const ReactivateModal = ({ open, onClose, onConfirm }: ModalProps) => {
  return (
    <Modal opened={open} handleClose={onClose} withCloseButton>
      <ModalTitle $type="success">Reactivate Account?</ModalTitle>
      <ModalBodyMessage>
        Are you sure you want to reactivate this account? All active users will have access to the account after
        reactivating.
      </ModalBodyMessage>
      <ModalFooter>
        <Button variant="subtle" onClick={onClose}>
          Cancel
        </Button>
        <Button onClick={onConfirm}>Activate Account</Button>
      </ModalFooter>
    </Modal>
  );
};
