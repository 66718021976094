import * as React from "react";

export const SettingIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="17" height="13" viewBox="0 0 23 18" fill="none" {...props}>
    <path
      d="M19.4441 17.9997C19.6574 17.9997 19.8619 17.9149 20.0127 17.7642C20.1634 17.6134 20.2482 17.4089 20.2482 17.1956V14.6951C20.8994 14.5205 21.4749 14.1358 21.8853 13.6009C22.2957 13.0659 22.5181 12.4105 22.5181 11.7362C22.5181 11.062 22.2957 10.4066 21.8853 9.8716C21.4749 9.33664 20.8994 8.95203 20.2482 8.7774V0.825517C20.2482 0.612274 20.1634 0.407765 20.0127 0.25698C19.8619 0.106195 19.6574 0.0214844 19.4441 0.0214844C19.2309 0.0214844 19.0264 0.106195 18.8756 0.25698C18.7248 0.407765 18.6401 0.612274 18.6401 0.825517V8.78544C17.9888 8.96007 17.4134 9.34468 17.003 9.87964C16.5926 10.4146 16.3701 11.07 16.3701 11.7443C16.3701 12.4185 16.5926 13.074 17.003 13.6089C17.4134 14.1439 17.9888 14.5285 18.6401 14.7031V17.2037C18.6422 17.4155 18.7279 17.618 18.8784 17.767C19.029 17.9161 19.2323 17.9997 19.4441 17.9997ZM17.9727 11.7362C17.9727 11.4452 18.059 11.1608 18.2207 10.9188C18.3824 10.6768 18.6122 10.4882 18.8811 10.3769C19.1499 10.2655 19.4458 10.2364 19.7312 10.2931C20.0166 10.3499 20.2788 10.49 20.4845 10.6958C20.6903 10.9016 20.8305 11.1638 20.8872 11.4492C20.944 11.7346 20.9149 12.0305 20.8035 12.2993C20.6921 12.5682 20.5035 12.798 20.2616 12.9597C20.0196 13.1213 19.7351 13.2076 19.4441 13.2076C19.0539 13.2076 18.6796 13.0526 18.4037 12.7767C18.1278 12.5007 17.9727 12.1265 17.9727 11.7362Z"
      fill="currentColor"
    />
    <path
      d="M11.2601 18C11.4734 18 11.6779 17.9153 11.8287 17.7645C11.9794 17.6137 12.0641 17.4092 12.0641 17.196V6.05207C12.7847 5.85717 13.41 5.40716 13.8236 4.78576C14.2372 4.16436 14.411 3.41387 14.3127 2.6739C14.2144 1.93393 13.8507 1.25485 13.2892 0.762999C12.7277 0.271146 12.0066 0 11.2601 0C10.5136 0 9.79258 0.271146 9.23106 0.762999C8.66954 1.25485 8.3058 1.93393 8.20749 2.6739C8.10919 3.41387 8.28301 4.16436 8.69663 4.78576C9.11025 5.40716 9.7355 5.85717 10.4561 6.05207V17.196C10.4561 17.4092 10.5408 17.6137 10.6916 17.7645C10.8424 17.9153 11.0469 18 11.2601 18ZM9.78874 3.10127C9.78714 2.80992 9.87208 2.52466 10.0328 2.28164C10.1935 2.03862 10.4227 1.84878 10.6915 1.73618C10.9602 1.62358 11.2563 1.59329 11.5422 1.64915C11.8282 1.70501 12.0911 1.84451 12.2977 2.04996C12.5042 2.25542 12.6452 2.51757 12.7026 2.80321C12.76 3.08885 12.7314 3.3851 12.6202 3.65443C12.5091 3.92376 12.3205 4.15404 12.0784 4.31607C11.8363 4.47811 11.5515 4.56461 11.2601 4.56461C10.8713 4.56462 10.4982 4.4107 10.2225 4.1365C9.94682 3.8623 9.79086 3.49011 9.78874 3.10127Z"
      fill="currentColor"
    />
    <path
      d="M2.26998 11.9694V17.1956C2.26998 17.4089 2.35469 17.6134 2.50547 17.7642C2.65626 17.9149 2.86077 17.9997 3.07401 17.9997C3.28725 17.9997 3.49176 17.9149 3.64255 17.7642C3.79333 17.6134 3.87804 17.4089 3.87804 17.1956V11.9694C4.52929 11.7948 5.10474 11.4102 5.51515 10.8752C5.92556 10.3403 6.14802 9.68483 6.14802 9.01057C6.14802 8.33631 5.92556 7.68089 5.51515 7.14593C5.10474 6.61097 4.52929 6.22636 3.87804 6.05173V0.825517C3.87804 0.612274 3.79333 0.407765 3.64255 0.25698C3.49176 0.106195 3.28725 0.0214844 3.07401 0.0214844C2.86077 0.0214844 2.65626 0.106195 2.50547 0.25698C2.35469 0.407765 2.26998 0.612274 2.26998 0.825517V6.05173C1.61872 6.22636 1.04328 6.61097 0.632866 7.14593C0.222453 7.68089 0 8.33631 0 9.01057C0 9.68483 0.222453 10.3403 0.632866 10.8752C1.04328 11.4102 1.61872 11.7948 2.26998 11.9694ZM3.07401 7.53919C3.36536 7.5376 3.65062 7.62254 3.89364 7.78325C4.13666 7.94395 4.32651 8.17319 4.43911 8.44191C4.5517 8.71062 4.58199 9.00671 4.52613 9.29266C4.47027 9.57861 4.33077 9.84154 4.12532 10.0481C3.91987 10.2547 3.65771 10.3956 3.37207 10.4531C3.08643 10.5105 2.79018 10.4818 2.52085 10.3707C2.25152 10.2596 2.02125 10.071 1.85921 9.82886C1.69717 9.58672 1.61067 9.30192 1.61067 9.01057C1.61066 8.62173 1.76458 8.24869 2.03878 7.97298C2.31298 7.69727 2.68517 7.54132 3.07401 7.53919Z"
      fill="currentColor"
    />
  </svg>
);
