import styled from "@emotion/styled";

export const CenteredCell = styled.div`
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const StyledImportFilenameCell = styled.div`
  cursor: pointer;
  :hover {
    text-decoration: underline;
  }
`;
