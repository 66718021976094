import { useEffect, useState } from "react";

import { getCoreRowModel, OnChangeFn, RowSelectionState, useReactTable } from "@tanstack/react-table";

import { columns } from "../components/columns";
import { ISupplier } from "../suppliers.types";

export const useSupplierTable = ({
  suppliers = [],
  onRowSelectionChange
}: {
  onRowSelectionChange: (rowSelected: ISupplier[]) => void;
  suppliers: ISupplier[];
}) => {
  const [rowSelectionState, setRowSelectionState] = useState({});

  const onRowSelection: OnChangeFn<RowSelectionState> = rows => setRowSelectionState(rows);

  const table = useReactTable({
    data: suppliers,
    columns,
    state: {
      rowSelection: rowSelectionState
    },
    onRowSelectionChange: onRowSelection,
    getCoreRowModel: getCoreRowModel(),
    enableRowSelection: true,
    enableMultiRowSelection: true,
    getRowId: row => JSON.stringify(row)
  });

  const onRowSelectReset = () => setRowSelectionState({});

  useEffect(() => onRowSelectionChange(Object.keys(rowSelectionState).map(i => JSON.parse(i))), [rowSelectionState]);

  return [table, { onRowSelectReset, onRowSelection }] as const;
};
