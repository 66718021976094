import styled from "@emotion/styled";

export const TooltipContent = styled.div`
  width: 100%;
`;

export const TextWithTooltip = styled.div`
  position: relative;
  width: fit-content;
  color: #5c5c5c;

  & > span {
    color: #292e2a;
  }
`;

export const TooltipIndicator = styled.div`
  position: absolute;
  top: -8px;
  right: -14px;
`;
