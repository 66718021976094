import { useMutation, useQueryClient } from "@tanstack/react-query";
import { QUERY_KEYS } from "constants/queryKeys";
import { updateAccount } from "modules/account/apis/UpdateAccount";

const useUpdateAccountMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: updateAccount,
    onSuccess: () => {
      queryClient.refetchQueries({ queryKey: [QUERY_KEYS.accounts_listing] });
      queryClient.refetchQueries({ queryKey: [QUERY_KEYS.account_details] });
      queryClient.refetchQueries({ queryKey: [QUERY_KEYS.user_details] });
    }
  });
};

export default useUpdateAccountMutation;
