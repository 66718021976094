import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";

import { Button } from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import { TAnalyticsCustomInterval, TOverviewDashboardModal, TOverviewDashboardState } from "types";
import Checkbox from "ui/inputs/Checkbox";
import { getDateNoTime } from "utils";

import { customIntervalInitial, defaultDateFilter } from "../analytics.data";
import {
  StyledCustomIntervalContainer,
  StyledCustomIntervalHeader,
  StyledCustomIntervalModal,
  StyledCustomIntervalRow,
  StyledCustomIntervalRowTitle,
  StyledCustomIntevalFooter,
  StyledCustomIntevalInputWrapper
} from "./OverviewDashboars.styles";

type CustomIntervalModalProps = {
  applyChanges: (updatedState: Partial<TOverviewDashboardState>) => void;
  customInterval: TAnalyticsCustomInterval;
  opened: boolean;
  setCustomInterval: Dispatch<SetStateAction<TAnalyticsCustomInterval>>;
  setModal: Dispatch<SetStateAction<TOverviewDashboardModal>>;
};

export const CustomIntervalModal: FC<CustomIntervalModalProps> = ({
  customInterval,
  opened,
  applyChanges,
  setModal,
  setCustomInterval
}) => {
  const [customIntervalChanges, setCustomIntervalChanges] = useState<TAnalyticsCustomInterval>(customInterval);

  const updateCustomIntervalChanges = (changes: Partial<TAnalyticsCustomInterval>) => {
    setCustomIntervalChanges(prevChanges => ({ ...prevChanges, ...changes }));
  };

  const handleApplyRange = () => {
    setCustomInterval(prevInterval => ({ ...prevInterval, ...customIntervalChanges }));
    applyChanges({
      start: customIntervalChanges.start || defaultDateFilter.start,
      end: customIntervalChanges.end || defaultDateFilter.end
    });
    setModal(prev => ({ ...prev, customInterval: false }));
  };

  const handleReset = () => updateCustomIntervalChanges(customIntervalInitial);

  useEffect(() => {
    if (opened) setCustomIntervalChanges(customInterval);
  }, [opened]);

  return (
    <StyledCustomIntervalModal
      opened={opened}
      setOpened={() => setModal(prev => ({ ...prev, customInterval: false }))}
      withCloseButton
      handleClose={() => setModal(prev => ({ ...prev, customInterval: false }))}
    >
      <StyledCustomIntervalHeader>Select Custom Data Range</StyledCustomIntervalHeader>
      <StyledCustomIntervalContainer>
        <StyledCustomIntervalRow>
          <div>
            <StyledCustomIntervalRowTitle>Start Date</StyledCustomIntervalRowTitle>
            <StyledCustomIntevalInputWrapper>
              <Checkbox
                checked={!!customIntervalChanges.start}
                onChange={() => updateCustomIntervalChanges({ start: null })}
              />
              <DatePicker
                placeholder="Start Date"
                inputFormat="MM/DD/YYYY"
                onChange={value => updateCustomIntervalChanges({ start: getDateNoTime(value as Date) })}
                value={customIntervalChanges.start ? new Date(customIntervalChanges.start) : null}
              />
            </StyledCustomIntevalInputWrapper>
          </div>
          <div>
            <StyledCustomIntervalRowTitle>End Date</StyledCustomIntervalRowTitle>
            <StyledCustomIntevalInputWrapper>
              <Checkbox
                checked={!!customIntervalChanges.end}
                onChange={() => updateCustomIntervalChanges({ end: null })}
              />
              <DatePicker
                placeholder="End Date"
                inputFormat="MM/DD/YYYY"
                onChange={value => updateCustomIntervalChanges({ end: getDateNoTime(value as Date) })}
                value={customIntervalChanges.end ? new Date(customIntervalChanges.end) : null}
              />
            </StyledCustomIntevalInputWrapper>
          </div>
        </StyledCustomIntervalRow>
      </StyledCustomIntervalContainer>
      <StyledCustomIntevalFooter>
        <Button variant="subtle" onClick={handleReset}>
          Reset Range
        </Button>
        <Button onClick={handleApplyRange}>Apply Range</Button>
      </StyledCustomIntevalFooter>
    </StyledCustomIntervalModal>
  );
};
