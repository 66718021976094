import React, { ReactNode } from "react";

import styled from "@emotion/styled";
import { BGLogo } from "ui/icons";

import { Footer } from "./common";
import { PublicHeaderScreen } from "./common/PublicHeaderScreen";

const PublicLayoutWrapper = styled.div`
  min-height: 100vh;
  position: relative;
  padding-bottom: 208px;
  box-sizing: border-box;

  @media only screen and (max-width: ${props => props.theme.other.breakpoints.xl}) {
    padding-bottom: 248px;
  }
  @media only screen and (max-width: ${props => props.theme.other.breakpoints.md}) {
    padding-bottom: 360px;
  }
`;

const StyleFooterPositionWrapper = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
`;

const StyleBackground = styled(BGLogo)`
  position: absolute;
  right: 0px;
  bottom: 0;
  z-index: -1;
  width: auto;
  height: 900px;
  @media only screen and (max-height: 1200px) {
    height: 700px;
  }
  @media only screen and (max-height: 1000px) {
    height: 500px;
  }
`;

interface PublicLayoutProps {
  children: ReactNode;
  showTabs?: boolean;
  showLoginButton?: boolean;
  showDemoButton?: boolean;
}
const PublicLayout: React.FC<PublicLayoutProps> = ({
  children,
  showTabs = true,
  showLoginButton = true,
  showDemoButton = true
}) => {
  const tabData = ["How It Works", "About Us", "Thought Leadership", "Contact Us"];
  return (
    <PublicLayoutWrapper>
      <PublicHeaderScreen
        tabs={showTabs ? tabData : []}
        showLoginButton={showLoginButton}
        showDemoButton={showDemoButton}
      />
      <>{children}</>
      <StyleFooterPositionWrapper>
        <Footer
          links={[
            { label: "How it works", link: "" },
            { label: "About Us", link: "" },
            { label: "Contact Us", link: "" }
          ]}
        />
      </StyleFooterPositionWrapper>
      <StyleBackground />
    </PublicLayoutWrapper>
  );
};

export default PublicLayout;
