import axiosInstance from "services/AxiosInstance";
import { TQuestionnaire } from "types";

export const putQuestionnaireService = async ({
  questionnaire
}: {
  questionnaire: TQuestionnaire;
}): Promise<TQuestionnaire> => {
  const { id } = questionnaire;
  const response = await axiosInstance.put(`questionnaire/${id}`, questionnaire);
  return response.data;
};
