import { ColumnDef } from "@tanstack/react-table";
import { IBuyspace } from "types";

import { BuyspacesActionCell } from "./BuyspacesActionCell";
import { BuyspacesFilterCell } from "./BuyspacesFilterCell";

export const columns: ColumnDef<IBuyspace, string>[] = [
  {
    header: "Name",
    accessorKey: "name",
    enableSorting: false,
    size: 400
  },
  {
    header: "Filters",
    id: "filters",
    enableSorting: false,
    size: 400,
    cell: props => <BuyspacesFilterCell conditions={props?.row?.original?.conditions || []} />
  },
  {
    header: "Created By",
    enableSorting: false,
    accessorKey: "createdBy.name",
    size: 150
  },
  {
    header: "Actions",
    id: "actions",
    enableSorting: false,
    size: 50,
    cell: props => <BuyspacesActionCell {...props.row.original} />
  }
];
