import { useQuery, UseQueryOptions, UseQueryResult } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { QUERY_KEYS } from "constants/queryKeys";
import { ResponseError, ResponseType, TAccount } from "types";

import { accountDetails } from "../apis/AccountDetails";

type UseGetAccountDetailsQueryArgs = (
  apiArgs: { accountId: string },
  options?: UseQueryOptions<ResponseType<TAccount>, AxiosError<ResponseError>>
) => UseQueryResult<ResponseType<TAccount>, AxiosError<ResponseError>>;

export const useGetAccountDetailsQuery: UseGetAccountDetailsQueryArgs = (apiArgs, options = {}) =>
  useQuery({
    queryKey: [QUERY_KEYS.account_details, { accountId: apiArgs.accountId }],
    queryFn: () => accountDetails(apiArgs.accountId),
    ...options
  });
