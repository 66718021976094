import { Dispatch, FC, SetStateAction } from "react";

import { Button, Select, TextInput } from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import moment from "moment";
import { ModalTitle } from "ui";
import Checkbox from "ui/inputs/Checkbox";
import { getDateNoTime } from "utils";
import { getCountries } from "utils/input.utils";

import {
  StyledCountrySelectSizer,
  StyledDatePickerFilterCheckboxCombiner,
  StyledDatePickerMobileWrapper,
  StyledDatePickerSizer,
  StyledFilterTitle,
  StyledFilterWrapper,
  StyledFooter,
  StyledInputFilterCheckboxCombiner,
  StyledModal,
  StyledRow
} from "../styles/suppliersFilters.styles";
import { initialSuppliersFilters } from "../suppliers.data";
import { ISupplierFilters, ISupplierModals } from "../suppliers.types";

type Props = {
  applyFilters: () => void;
  filters: ISupplierFilters;
  modalController: (updatedModal: Partial<ISupplierModals>) => void;
  opened: boolean;
  updateFilters: (updatedFilters?: Partial<ISupplierFilters>) => void;
};

export const SuppliersFilters: FC<Props> = ({ opened, filters, modalController, updateFilters, applyFilters }) => {
  const handleFilterModal = (state?: boolean) => {
    modalController({ filters: state ?? true });
  };

  const handleReset = () => updateFilters({ ...initialSuppliersFilters, page: filters.page });

  const handleClose = () => modalController({ filters: false });

  const handleShowResults = () => {
    applyFilters();
    modalController({ filters: false });
  };

  return (
    <StyledModal
      opened={opened}
      setOpened={handleFilterModal as Dispatch<SetStateAction<boolean>>}
      handleClose={handleClose}
      withCloseButton
    >
      <ModalTitle noMobileMargin>Supplier Filters</ModalTitle>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <StyledRow>
          <StyledFilterWrapper equalLayout>
            <StyledFilterTitle>Country</StyledFilterTitle>
            <StyledInputFilterCheckboxCombiner>
              <Checkbox checked={!!filters.country.length} onChange={() => updateFilters({ country: "" })} />
              <StyledCountrySelectSizer>
                <Select
                  searchable
                  placeholder="Select Country"
                  data={getCountries()}
                  value={filters.country}
                  onChange={(country: string) => updateFilters({ country })}
                />
              </StyledCountrySelectSizer>
            </StyledInputFilterCheckboxCombiner>
          </StyledFilterWrapper>
        </StyledRow>

        <StyledRow>
          <StyledFilterWrapper>
            <StyledFilterTitle>Date Created</StyledFilterTitle>
            <StyledDatePickerMobileWrapper>
              <StyledDatePickerFilterCheckboxCombiner>
                <Checkbox checked={!!filters.createdDateMin} onChange={() => updateFilters({ createdDateMin: null })} />
                <StyledDatePickerSizer>
                  <DatePicker
                    onChange={value => updateFilters({ createdDateMin: getDateNoTime(value as Date) })}
                    value={filters.createdDateMin ? moment(filters.createdDateMin).toDate() : null}
                    placeholder="Start Date"
                    inputFormat="MM/DD/YYYY"
                  />
                </StyledDatePickerSizer>
              </StyledDatePickerFilterCheckboxCombiner>

              <StyledDatePickerFilterCheckboxCombiner>
                <Checkbox checked={!!filters.createdDateMax} onChange={() => updateFilters({ createdDateMax: null })} />
                <StyledDatePickerSizer>
                  <DatePicker
                    onChange={value => updateFilters({ createdDateMax: getDateNoTime(value as Date) })}
                    value={filters.createdDateMax ? moment(filters.createdDateMax).toDate() : null}
                    placeholder="End Date"
                    inputFormat="MM/DD/YYYY"
                  />
                </StyledDatePickerSizer>
              </StyledDatePickerFilterCheckboxCombiner>
            </StyledDatePickerMobileWrapper>
          </StyledFilterWrapper>

          <StyledFilterWrapper>
            <StyledFilterTitle>State</StyledFilterTitle>
            <StyledInputFilterCheckboxCombiner>
              <Checkbox checked={!!filters.region.length} onChange={() => updateFilters({ region: "" })} />
              <TextInput value={filters.region} onChange={e => updateFilters({ region: e.target.value })} />
            </StyledInputFilterCheckboxCombiner>
          </StyledFilterWrapper>
        </StyledRow>

        <StyledRow>
          <StyledFilterWrapper equalLayout>
            <StyledFilterTitle>City</StyledFilterTitle>
            <StyledInputFilterCheckboxCombiner>
              <Checkbox checked={!!filters.city} onChange={() => updateFilters({ city: "" })} />
              <TextInput value={filters.city} onChange={e => updateFilters({ city: e.target.value })} />
            </StyledInputFilterCheckboxCombiner>
          </StyledFilterWrapper>
        </StyledRow>
      </div>
      <StyledFooter>
        <Button variant="white" onClick={handleReset}>
          Reset Filter
        </Button>
        <Button onClick={handleShowResults}>Show Results</Button>
      </StyledFooter>
    </StyledModal>
  );
};
