import { forwardRef, useContext, useEffect, useState } from "react";

import { Button, LoadingOverlay, Select, TextInput } from "@mantine/core";
import { useForm, yupResolver } from "@mantine/form";
import * as ColorPalette from "constants/colorPalette";
import { Strings } from "constants/translations";
import useAddUserToAccountMutation from "modules/account/mutations/AddUserToAccount";
import useGetAccounts from "modules/account/queries/Account";
import { CurrentUserContext } from "modules/login/CurrentUserContext";
import { useCreateUserMutation, useUpdateUserMutation } from "modules/users";
import Loader from "ui/feedback/Loader";
import { ItemProps } from "ui/inputs/Select";
import ConfirmationModal from "ui/overlays/ConfirmationModal";
import ErrorModal from "ui/overlays/ErrorModal";
import Modal from "ui/overlays/Modal";
import { ModalBodyMessage, ModalFooter, ModalTitle } from "ui/styles";
import { getAccountId, getBrowserLocal, getBrowserTimeZone } from "utils/common.utils";
import * as Yup from "yup";

import { usePostUserAdminMutation } from "../hooks/usePostUserAdminMutation";
import { dropdownDataForRole, USER_TYPE, UserRoles } from "../types/UserTypesEnum";
import {
  FormRowWrapper,
  StyleAccountWrapper,
  StyleEmailInfoMsg,
  StyleEmailWrapper,
  StyleFNameWrapper,
  StyleLNameWrapper,
  StyleModalHeaderDescription,
  StyleModalHeaderTitle,
  StyleRoleColorText,
  StyleRoleSelectItem,
  StyleUserRoleWrapper,
  StyleWrapper,
  Wrapper
} from "./styles";

const accountTransformer = (data: DropDownRawData[]): DropdownDataInterface[] => {
  if (data.length === 0) {
    return [{ label: "No Data", value: "", name: "", description: "" }];
  }
  return data.map((element: DropDownRawData) => ({
    label: element.name,
    value: element.id,
    name: "",
    description: ""
  }));
};

const schema = Yup.object().shape({
  firstName: Yup.string().required(`${Strings.enter__fname}`),
  lastName: Yup.string().required(`${Strings.enter__lname}`),
  email: Yup.string().email(`${Strings.invalid__email__address}`).required(`${Strings.enetr__email__address}`),
  isAccountId: Yup.boolean(),
  accountId: Yup.string().when("isAccountId", {
    is: true,
    then: Yup.string().required(`${Strings.select__account}`),
    otherwise: Yup.string()
  }),
  isUserRole: Yup.boolean(),
  userRole: Yup.string().when("isUserRole", {
    is: true,
    then: Yup.string().required(`${Strings.select__user__role}`),
    otherwise: Yup.string()
  })
});

interface AddInternalUserInterface {
  callFrom?: "accountsDetails" | "userListing";
  accountId?: string;
  openModal: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}
interface DropDownRawData {
  id: string;
  name: string;
  status: string | null;
}
export interface DropdownDataInterface {
  label: string;
  value: string;
  name: string;
  description: string;
}
export interface UserForm {
  accountId: string;
  emailFormat?: string;
  firstName: string;
  lastName: string;
  email: string;
  userRole: string;
}

export const selectRoleItem = forwardRef<HTMLDivElement, ItemProps>(
  ({ image, label, name, description, ...others }: ItemProps, ref) => (
    <div ref={ref} {...others}>
      <StyleRoleSelectItem>
        <StyleRoleColorText color={name === "Kloopify" ? ColorPalette.primaryColor : ColorPalette.monochromeColor[1]}>
          {name}
        </StyleRoleColorText>{" "}
        {description}
      </StyleRoleSelectItem>
    </div>
  )
);

const AddInternalUser: React.FC<AddInternalUserInterface> = ({
  callFrom = "userListing",
  accountId = "",
  openModal,
  setOpenModal
}): JSX.Element => {
  const addUserForm = useForm({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      isAccountId: true,
      accountId: callFrom === "accountsDetails" ? accountId : "",
      isUserRole: true,
      userRole: ""
    },
    validate: yupResolver(schema)
  });

  const postCommonUserMutation = useCreateUserMutation();
  const postAdminUserMutation = usePostUserAdminMutation();
  const getAccountsQuery = useGetAccounts();
  const addUserToAccountMutation = useAddUserToAccountMutation();
  const updateUserMutation = useUpdateUserMutation();
  const [showErrorModal, setShowErrorModal] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>("Something went wrong");
  const [openInviteConfirmModal, setOpenInviteConfirmModal] = useState(false);

  const isInternalAdminSelected = addUserForm.values.userRole === UserRoles.INTERNAL_ADMIN.value;
  const createUserMutation = isInternalAdminSelected ? postAdminUserMutation : postCommonUserMutation;

  const { userDetails } = useContext(CurrentUserContext);

  useEffect(() => {
    if (createUserMutation.isSuccess) {
      const selectedId = addUserForm.values.accountId;
      addUserToAccountMutation.mutate({
        accountId: selectedId.length ? selectedId : getAccountId(),
        userId: createUserMutation?.data?.data?.id,
        userRole: createUserMutation?.variables?.userRole || ""
      });
    }
    if (createUserMutation?.error?.response?.data?.errorCode === "USERS_ALREADY_EXISTS") {
      setOpenInviteConfirmModal(true);
    }
  }, [createUserMutation.isSuccess, createUserMutation.isError]);

  useEffect(() => {
    if (addUserToAccountMutation.isSuccess === true) {
      setOpenInviteConfirmModal(false);
      setOpenModal(false);
      addUserForm.reset();
    }
    if (addUserToAccountMutation.isError === true) {
      setOpenInviteConfirmModal(false);
      setErrorMsg(Strings.user__created__success__error__account);
      setShowErrorModal(true);
      addUserForm.reset();
    }
  }, [addUserToAccountMutation.isSuccess, addUserToAccountMutation.isError]);

  const onFormSubmitHandler = (payload: UserForm) => {
    let userType = "";
    switch (payload.userRole) {
      case UserRoles["INTERNAL_ADMIN"].value:
        userType = USER_TYPE.internal;
        break;
      case UserRoles["INTERNAL_ANALYST"].value:
        userType = USER_TYPE.internal;
        break;
      case UserRoles["ACCOUNT_ADMIN"].value:
        userType = USER_TYPE.external;
        break;
      case UserRoles["ACCOUNT_CATEGORY_MANAGER"].value:
        userType = USER_TYPE.external;
        break;
      default:
        userType = USER_TYPE.external;
        break;
    }

    const createUserPayload = {
      ...payload,
      userType: userType,
      locale: getBrowserLocal(),
      localTimeZone: getBrowserTimeZone()
    };

    if (isInternalAdminSelected) {
      postAdminUserMutation.mutate(createUserPayload);
      return;
    }

    postCommonUserMutation.mutate(createUserPayload);
  };

  useEffect(() => {
    if (updateUserMutation.isSuccess) {
      setOpenModal(false);
      addUserForm.reset();
    }
    if (updateUserMutation.isError) {
      setErrorMsg(updateUserMutation?.error?.response?.data?.errorMessage || "Error: user update failed");
      setShowErrorModal(true);
    }
  }, [updateUserMutation.status]);

  const handleAddUserToAccount = () => {
    if (createUserMutation?.error?.response?.data?.meta?.id) {
      addUserToAccountMutation.mutate({
        accountId: callFrom === "accountsDetails" ? accountId : getAccountId(),
        userId: createUserMutation?.error?.response?.data?.meta?.id,
        userRole: createUserMutation?.variables?.userRole || ""
      });
    }
  };

  const onCloseModal = () => setOpenModal(false);

  useEffect(() => {
    if (callFrom === "accountsDetails" && accountId) {
      addUserForm.setFieldValue(`accountId`, accountId);
    }
  }, []);

  if (getAccountsQuery.isLoading) {
    return <LoadingOverlay loader={<Loader />} visible />;
  }

  return (
    <>
      <Modal opened={openModal} setOpened={setOpenModal} withCloseButton handleClose={onCloseModal}>
        <ModalTitle centered>Add New User</ModalTitle>
        <ModalBodyMessage>
          <StyleModalHeaderTitle>
            {callFrom === "accountsDetails" ? "Add a New User to your Account!" : "Add a New User!"}
          </StyleModalHeaderTitle>
          <StyleModalHeaderDescription>
            Enter in the user information. Required fields are marked with an asterisk*.
          </StyleModalHeaderDescription>
        </ModalBodyMessage>

        <Wrapper>
          <LoadingOverlay
            visible={createUserMutation.isLoading || updateUserMutation.isLoading}
            overlayBlur={2}
            loader={<Loader />}
          />
          <form onSubmit={addUserForm.onSubmit(values => onFormSubmitHandler(values))}>
            <StyleWrapper>
              <FormRowWrapper
                style={{
                  marginBottom: "1.5rem",
                  marginTop: "2rem"
                }}
              >
                <StyleFNameWrapper>
                  <TextInput label="First Name" withAsterisk {...addUserForm.getInputProps("firstName")} />
                </StyleFNameWrapper>
                <StyleLNameWrapper>
                  <TextInput label="Last Name" withAsterisk {...addUserForm.getInputProps("lastName")} />
                </StyleLNameWrapper>
              </FormRowWrapper>
              <FormRowWrapper>
                <StyleEmailWrapper>
                  <TextInput label="Email Address" withAsterisk {...addUserForm.getInputProps("email")} />
                  <StyleEmailInfoMsg>
                    {
                      "New user will be notified at the email address entered above to confirm their account and complete set-up."
                    }
                  </StyleEmailInfoMsg>
                </StyleEmailWrapper>
              </FormRowWrapper>
              <FormRowWrapper
                style={{
                  marginTop: "18px",
                  gap: "42px",
                  justifyContent: callFrom === "accountsDetails" ? "flex-start" : "center"
                }}
              >
                {callFrom !== "accountsDetails" && (
                  <StyleAccountWrapper>
                    <Select
                      withAsterisk
                      placeholder="Select Account"
                      label="Account"
                      error={addUserForm.errors.accountId}
                      data={accountTransformer(getAccountsQuery.data.data)}
                      value={addUserForm.values.accountId}
                      onChange={(value: string) => addUserForm.setFieldValue("accountId", value)}
                    />
                  </StyleAccountWrapper>
                )}
                <StyleUserRoleWrapper style={{ maxWidth: callFrom === "accountsDetails" ? "298px" : "290px" }}>
                  <Select
                    withAsterisk
                    placeholder="Select User Role"
                    label="User Role"
                    itemComponent={selectRoleItem}
                    error={addUserForm.errors.userRole}
                    data={dropdownDataForRole(userDetails.userRole)}
                    value={addUserForm.values.userRole}
                    onChange={(value: string) => addUserForm.setFieldValue("userRole", value)}
                  />
                </StyleUserRoleWrapper>
              </FormRowWrapper>
            </StyleWrapper>
            <ModalFooter>
              <Button
                variant="subtle"
                type="button"
                onClick={() => {
                  setOpenModal(false);
                  addUserForm.reset();
                }}
              >
                Cancel
              </Button>
              <Button type="submit">Continue</Button>
            </ModalFooter>
          </form>
        </Wrapper>
      </Modal>
      <ErrorModal openModal={showErrorModal} setOpenModal={setShowErrorModal} message={errorMsg} />
      <ConfirmationModal
        openModal={openInviteConfirmModal}
        loading={addUserToAccountMutation.isLoading}
        setOpenModal={setOpenInviteConfirmModal}
        cancelBtnText={"No"}
        confirmBtnText={"Yes Invite"}
        titleText={"User Already Exists"}
        handleConfirm={() => handleAddUserToAccount()}
        descriptionText={Strings.email__already__exists__conf}
        descriptionTextSecond={""}
      />
    </>
  );
};
export default AddInternalUser;
