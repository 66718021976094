import { FC } from "react";

import { StyledFilterTileWrapper } from "./FIlterTile.styles";

type FilterTileProps = {
  onClick: () => void;
  text: string;
};

const FilterTile: FC<FilterTileProps> = ({ text, onClick }) => {
  return (
    <StyledFilterTileWrapper onClick={onClick}>
      <div>{text}</div>
      <div>X</div>
    </StyledFilterTileWrapper>
  );
};

export default FilterTile;
