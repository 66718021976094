import { useMemo } from "react";
import { useLocation } from "react-router-dom";

import { isArray, omit } from "lodash";
import FilterTile from "ui/tiles/FilterTile/FilterTile";
import { getCountryByIso, parseQueryParams } from "utils";

import { SupplierFilterTileWrapper } from "../styles/supplierFilterTile.styles";
import { annotationList, initialSuppliersFilters } from "../suppliers.data";
import { ISupplierFilters } from "../suppliers.types";

type SupplierFilterTileProps = {
  data: ISupplierFilters;
  update: (data?: Partial<ISupplierFilters>, hardUpdate?: boolean) => void;
};

type TNormalizedEntries = [keyof ISupplierFilters, string | string[]][];

export const SupplierFilterTile = ({ data, update }: SupplierFilterTileProps) => {
  const { search } = useLocation();

  const normalizedParams = useMemo<Partial<ISupplierFilters>>(() => omit(parseQueryParams(search), "page"), [search]);

  const normalizedEntries = useMemo(() => Object.entries(normalizedParams) as TNormalizedEntries, [normalizedParams]);

  const normalizeKey = (key: string) =>
    annotationList.find(annotation => annotation.key === key)?.label || "unknown option: ";

  const normalizeValue = (key: keyof ISupplierFilters, value: string | string[]) => {
    if (key === "country") return [getCountryByIso(value as string)];
    return isArray(value) ? value : [value];
  };

  const onClick = (key: keyof ISupplierFilters) => {
    update({ ...data, [key]: initialSuppliersFilters[key], page: 0 }, true);
  };

  const renderTiles = normalizedEntries.map((entry, index) => {
    const [key, value] = entry;
    const [annotation, optionList] = [normalizeKey(key), normalizeValue(key, value)];

    return optionList.map((normalizedValue, normalizedIndex) => (
      <FilterTile
        key={"CommodityFilterTile" + index + normalizedIndex}
        text={annotation + normalizedValue}
        onClick={() => onClick(key)}
      />
    ));
  });

  if (!normalizedEntries.length) return null;

  return <SupplierFilterTileWrapper>{renderTiles}</SupplierFilterTileWrapper>;
};
