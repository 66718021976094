import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { UseFormReturnType } from "@mantine/form";
import { CLASSIFICATION_LEVEL } from "constants/classification";
import { useGetClassification } from "modules/classification";
import { useGetCommodityListQuery } from "modules/commodity";
import { useGetCostCenterQuery } from "modules/costCenter";
import { useGetSupplierListQuery } from "modules/suppliers";
import { MultiSelect } from "ui";

import { transformEntitiesToOptions, transformNaicsToOptions } from "../buyspace.utils";
import { useBuyspaceContext } from "./BuyspaceContext";

export const BuyspaceSelectComponent = <T,>({
  name,
  formInstance
}: {
  formInstance: UseFormReturnType<T>;
  name: string;
}) => {
  const { id: accountId } = useParams() as { id: string };

  const { handleSetData, data } = useBuyspaceContext();

  const [search, setSearch] = useState<string | null>(null);
  const type = formInstance.getInputProps(`${name}.key`).value;

  const searchEnabled = !!(search && search.length >= 3);

  const onSearch = (value: string) => (value ? setSearch(value) : setSearch(null));

  const { data: supplierData, error: supplierError } = useGetSupplierListQuery(
    accountId,
    { name: search, limit: 50 },
    { enabled: type.includes("SUPPLIER_ID") }
  );

  const { data: itemData, error: itemError } = useGetCommodityListQuery({ search }, 50, accountId, {
    enabled: type.includes("ITEM_ID") && searchEnabled
  });

  const isCostCenterSelected = Boolean(type && type.includes("COST_CENTER_ID")) && searchEnabled;

  const { data: costCenterData, error: costCenterError } = useGetCostCenterQuery(
    { accountId, params: { limit: 50, name: search } },
    { enabled: isCostCenterSelected }
  );

  const isNaicsCodeSelected = Boolean(type && type.includes("NAICS_CODE")) && searchEnabled;

  const { data: naicsDetailsData, error: naicsDetailsError } = useGetClassification(
    { params: { "levels[]": CLASSIFICATION_LEVEL.detail, search } },
    { enabled: isNaicsCodeSelected }
  );

  const isNaicsIndustrySelected = Boolean(type && type.includes("NAICS_INDUSTRY")) && searchEnabled;

  const { data: naicsIndustryData, error: naicsIndustryError } = useGetClassification(
    { params: { "levels[]": CLASSIFICATION_LEVEL.industry, search } },
    { enabled: isNaicsIndustrySelected }
  );

  const isNaicsSectorSelected = Boolean(type && type.includes("NAICS_SECTOR")) && searchEnabled;

  const { data: naicsSectorData, error: naicsSectorError } = useGetClassification(
    { params: { "levels[]": CLASSIFICATION_LEVEL.sector, search } },
    { enabled: isNaicsSectorSelected }
  );

  useEffect(() => {
    if (supplierData?.data) {
      const options = transformEntitiesToOptions(supplierData.data);
      handleSetData("suppliers", options ?? []);
    }
    if (supplierError && type.includes("SUPPLIER_ID")) {
      const error = supplierError?.response?.data?.errorMessage ?? "No suppliers found.";

      formInstance.setFieldError(`${name}.value`, error);
      return () => {
        formInstance.clearErrors();
      };
    }
  }, [supplierData, type, supplierError]);

  useEffect(() => {
    if (itemData?.data) {
      const options = transformEntitiesToOptions(itemData.data);
      handleSetData("items", options ?? []);
    }
    if (itemError && type.includes("ITEM_ID")) {
      const error = itemError?.response?.data?.errorMessage ?? "No commodities found.";

      formInstance.setFieldError(`${name}.value`, error);
      return () => {
        formInstance.clearErrors();
      };
    }
  }, [itemData, type, costCenterError]);

  useEffect(() => {
    if (costCenterData?.data) {
      const options = transformEntitiesToOptions(costCenterData.data);
      handleSetData("costCenters", options ?? []);
    }
    if (costCenterError && type.includes("COST_CENTER_ID")) {
      const error = costCenterError?.response?.data?.errorMessage ?? "No Cost Center found for the provided ID.";

      formInstance.setFieldError(`${name}.value`, error);
      return () => {
        formInstance.clearErrors();
      };
    }
  }, [costCenterData, type, costCenterError]);

  useEffect(() => {
    if (naicsDetailsData?.data) {
      const options = transformNaicsToOptions(naicsDetailsData.data);
      handleSetData("naicsCodes", options ?? []);
    }
    if (naicsDetailsError && type.includes("NAICS_CODE")) {
      const error = naicsDetailsError?.response?.data?.errorMessage ?? "No NAICS code found for the provided ID.";

      formInstance.setFieldError(`${name}.value`, error);
      return () => {
        formInstance.clearErrors();
      };
    }
  }, [naicsDetailsData, type, naicsDetailsError]);

  useEffect(() => {
    if (naicsIndustryData?.data) {
      const options = transformNaicsToOptions(naicsIndustryData.data);
      handleSetData("naicsIndustries", options ?? []);
    }
    if (naicsIndustryError && type.includes("NAICS_INDUSTRY")) {
      const error = naicsIndustryError?.response?.data?.errorMessage ?? "No NAICS code found for the provided ID.";

      formInstance.setFieldError(`${name}.value`, error);
      return () => {
        formInstance.clearErrors();
      };
    }
  }, [naicsIndustryData, type, naicsIndustryError]);

  useEffect(() => {
    if (naicsSectorData?.data) {
      const options = transformNaicsToOptions(naicsSectorData.data);
      handleSetData("naicsSectors", options ?? []);
    }
    if (naicsSectorError && type.includes("NAICS_SECTOR")) {
      const error = naicsSectorError?.response?.data?.errorMessage ?? "No NAICS code found for the provided ID.";

      formInstance.setFieldError(`${name}.value`, error);
      return () => {
        formInstance.clearErrors();
      };
    }
  }, [naicsSectorData, type, naicsSectorError]);

  if (type.includes("SUPPLIER_ID")) {
    return (
      <MultiSelect
        dataSource={data["suppliers"]}
        transformData={data => data}
        onSearchChange={onSearch}
        searchValue={search ?? ""}
        {...formInstance.getInputProps(`${name}.value`)}
      />
    );
  }

  if (type.includes("ITEM_ID")) {
    return (
      <MultiSelect
        dataSource={data["items"]}
        transformData={data => data}
        onSearchChange={onSearch}
        searchValue={search ?? ""}
        {...formInstance.getInputProps(`${name}.value`)}
      />
    );
  }

  if (type.includes("COST_CENTER_ID")) {
    return (
      <MultiSelect
        dataSource={data["costCenters"]}
        transformData={data => data}
        onSearchChange={onSearch}
        searchValue={search ?? ""}
        {...formInstance.getInputProps(`${name}.value`)}
      />
    );
  }

  if (type.includes("NAICS_CODE")) {
    return (
      <MultiSelect
        dataSource={data["naicsCodes"]}
        transformData={data => data}
        onSearchChange={onSearch}
        searchValue={search ?? ""}
        {...formInstance.getInputProps(`${name}.value`)}
      />
    );
  }

  if (type.includes("NAICS_INDUSTRY")) {
    return (
      <MultiSelect
        dataSource={data["naicsIndustries"]}
        transformData={data => data}
        onSearchChange={onSearch}
        searchValue={search ?? ""}
        {...formInstance.getInputProps(`${name}.value`)}
      />
    );
  }
  if (type.includes("NAICS_SECTOR")) {
    return (
      <MultiSelect
        dataSource={data["naicsSectors"]}
        transformData={data => data}
        onSearchChange={onSearch}
        searchValue={search ?? ""}
        {...formInstance.getInputProps(`${name}.value`)}
      />
    );
  }

  return <MultiSelect dataSource={[]} transformData={data => data} placeholder="Select Filter Option first" disabled />;
};
