import { Autocomplete, AutocompleteProps } from "@mantine/core";
import { SearchIcon } from "ui/icons";

import {
  AutocompleteInputWrapper,
  AutocompleteLabel,
  StyledAutocompleteComponent
} from "./autocompleteCompontent.styles";

export const AutocompleteComponent: React.FC<AutocompleteProps & { checkboxComponent?: React.ReactNode }> = props => {
  const { label, checkboxComponent, ...autocompleteProps } = props;

  return (
    <StyledAutocompleteComponent>
      {label && <AutocompleteLabel>{label}</AutocompleteLabel>}
      <AutocompleteInputWrapper>
        {checkboxComponent}
        <Autocomplete
          icon={<SearchIcon />}
          size="sm"
          hoverOnSearchChange
          dropdownPosition="bottom"
          {...autocompleteProps}
        />
      </AutocompleteInputWrapper>
    </StyledAutocompleteComponent>
  );
};
