import { CloseButton, Grid, Stack, Text } from "@mantine/core";

import { QCFormInstance } from "../../questionnaire.types";
import {
  QCInput,
  QCSelectAddOption,
  QCSelectButtonWrapper,
  QCSelectCircle,
  QCSelectCircleWrapper,
  QCSelectLayout
} from "./questionnaireCreator.styles";

type QCSelectChoiceProps = {
  form: QCFormInstance;
  index: number;
  onAddOption: () => void;
  onAddOther: () => void;
  onRemoveOption: (index: number) => void;
  scope: "productQuestionList" | "supplierQuestionList";
};

export const QCSelectChoice: React.FC<QCSelectChoiceProps> = ({
  onAddOption,
  onAddOther,
  onRemoveOption,
  form,
  index,
  scope
}) => {
  const choices: { choice: string }[] = form.values?.[scope]?.[index].choices || [];
  const error = form.errors[`${scope}.${index}.choices`];

  return (
    <Grid>
      {choices.length > 0 && (
        <Grid.Col span={7}>
          <Stack>
            {choices.map((_, choiceIndex) => (
              <QCSelectLayout key={choiceIndex}>
                <QCSelectCircleWrapper>
                  <QCSelectCircle />
                </QCSelectCircleWrapper>
                <QCInput
                  size="xs"
                  {...form.getInputProps(`${scope}.${index}.choices.${choiceIndex}.choice`)}
                  rightSection={
                    <CloseButton type="button" radius="xl" size="xs" onClick={() => onRemoveOption(choiceIndex)} />
                  }
                />
              </QCSelectLayout>
            ))}
          </Stack>
        </Grid.Col>
      )}

      <Grid.Col span={7}>
        <QCSelectLayout>
          <QCSelectCircleWrapper>
            <QCSelectCircle />
          </QCSelectCircleWrapper>
          <QCSelectButtonWrapper>
            <QCSelectAddOption type="button" onClick={onAddOption}>
              Add Option
            </QCSelectAddOption>
            <span>or</span>
            <QCSelectAddOption type="button" onClick={onAddOther}>
              add “Other”
            </QCSelectAddOption>
          </QCSelectButtonWrapper>
        </QCSelectLayout>
        {error && (
          <Text c="red" fz="xs">
            {error}
          </Text>
        )}
      </Grid.Col>
    </Grid>
  );
};
