import React, { memo } from "react";

export const MoneyChartIcon = memo((props: React.SVGProps<SVGSVGElement>) => (
  <svg width="30" height="30" viewBox="0 0 78 79" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M16.5311 42.614C17.1634 42.614 17.676 42.1015 17.676 41.4691V40.6754C17.676 38.8497 16.281 37.3437 14.501 37.1669V35.913C14.501 35.2806 13.9884 34.7681 13.3561 34.7681C12.7239 34.7681 12.2113 35.2806 12.2113 35.913V37.2218C10.4048 37.572 9.03629 39.1651 9.03629 41.0723C9.03629 43.2354 10.7962 44.9951 12.9591 44.9951H13.7528C14.6535 44.9951 15.3861 45.7277 15.3861 46.6284C15.3861 47.529 14.6535 48.2617 13.7528 48.2617H12.5623C11.8805 48.2617 11.3259 47.707 11.3259 47.0251C11.3259 46.3927 10.8132 45.8802 10.181 45.8802C9.54877 45.8802 9.03613 46.3927 9.03613 47.0251C9.03613 48.8508 10.431 50.3569 12.2111 50.5336V51.7875C12.2111 52.4199 12.7238 52.9324 13.356 52.9324C13.9882 52.9324 14.5009 52.4199 14.5009 51.7875V50.4787C16.3073 50.1285 17.6759 48.5354 17.6759 46.6282C17.6759 44.4651 15.916 42.7054 13.753 42.7054H12.9593C12.0586 42.7054 11.326 41.9728 11.326 41.0721C11.326 40.1715 12.0586 39.4388 12.9593 39.4388H14.1499C14.8316 39.4388 15.3863 39.9935 15.3863 40.6754V41.4691C15.3863 42.1015 15.8989 42.614 16.5311 42.614Z"
      fill="currentColor"
    />
    <path
      d="M42.1752 13.3067C42.8076 13.3067 43.32 12.7942 43.32 12.1619V11.3682C43.32 9.54247 41.925 8.0364 40.1451 7.85968V6.60574C40.1451 5.97336 39.6326 5.46088 39.0002 5.46088C38.368 5.46088 37.8553 5.97336 37.8553 6.60574V7.91452C36.0488 8.26476 34.6803 9.85782 34.6803 11.765C34.6803 13.9281 36.4402 15.6879 38.6032 15.6879H39.3969C40.2975 15.6879 41.0302 16.4205 41.0302 17.3211C41.0302 18.2218 40.2975 18.9544 39.3969 18.9544H38.2063C37.5246 18.9544 36.9699 18.3997 36.9699 17.7178C36.9699 17.0855 36.4573 16.573 35.825 16.573C35.1928 16.573 34.6802 17.0855 34.6802 17.7178C34.6802 19.5435 36.075 21.0496 37.8552 21.2263V22.4803C37.8552 23.1126 38.3678 23.6251 39 23.6251C39.6324 23.6251 40.1449 23.1126 40.1449 22.4803V21.1715C41.9512 20.8212 43.3199 19.2282 43.3199 17.321C43.3199 15.1579 41.56 13.3981 39.397 13.3981H38.6033C37.7027 13.3981 36.9701 12.6655 36.9701 11.7649C36.9701 10.8642 37.7027 10.1316 38.6033 10.1316H39.7939C40.4756 10.1316 41.0303 10.6863 41.0303 11.3682V12.1619C41.0303 12.7944 41.5428 13.3067 42.1752 13.3067Z"
      fill="currentColor"
    />
    <path
      d="M64.6439 23.1675C61.3347 23.1675 58.303 24.3778 55.9673 26.3783L50.2999 21.6555C51.6017 19.5947 52.3561 17.1555 52.3561 14.5431C52.3561 7.17854 46.3646 1.18701 39 1.18701C31.6354 1.18701 25.6439 7.17854 25.6439 14.5431C25.6439 18.4073 27.2939 21.8927 29.9258 24.3337L20.5929 32.6295C18.5063 31.2792 16.0212 30.4941 13.3561 30.4941C5.99153 30.4943 0 36.4857 0 43.8504C0 51.2152 5.99153 57.2066 13.3561 57.2066C20.7207 57.2066 26.7123 51.215 26.7123 43.8504C26.7123 39.9862 25.0622 36.5009 22.4303 34.0597L31.7632 25.7639C33.8499 27.1142 36.3349 27.8993 39 27.8993C42.9302 27.8993 46.469 26.1926 48.9151 23.4819L54.3558 28.0157C52.4404 30.3278 51.2877 33.2935 51.2877 36.5235C51.2877 43.8881 57.2793 49.8796 64.6439 49.8796C72.0085 49.8796 78 43.8881 78 36.5235C78 29.1588 72.0085 23.1675 64.6439 23.1675ZM24.4227 43.8504C24.4227 49.9525 19.4583 54.917 13.3561 54.917C7.254 54.917 2.28957 49.9525 2.28957 43.8504C2.28957 37.7483 7.254 32.7839 13.3561 32.7839C19.4583 32.7839 24.4227 37.7483 24.4227 43.8504ZM39 25.6097C32.8979 25.6097 27.9334 20.6453 27.9334 14.5431C27.9334 8.44101 32.8979 3.47659 39 3.47659C45.1021 3.47659 50.0666 8.44101 50.0666 14.5431C50.0666 20.6453 45.1021 25.6097 39 25.6097ZM64.6439 47.5902C58.5417 47.5902 53.5773 42.6257 53.5773 36.5236C53.5773 33.6443 54.6833 31.019 56.492 29.0484C56.674 28.9814 56.842 28.8683 56.9753 28.7085C57.0614 28.6052 57.1249 28.4907 57.169 28.3714C59.1395 26.5632 61.7646 25.4572 64.6437 25.4572C70.7458 25.4572 75.7103 30.4216 75.7103 36.5238C75.7104 42.6257 70.746 47.5902 64.6439 47.5902Z"
      fill="currentColor"
    />
    <path
      d="M67.8189 35.2872C68.4513 35.2872 68.9638 34.7747 68.9638 34.1423V33.3486C68.9638 31.5229 67.5687 30.0169 65.7888 29.8402V28.5862C65.7888 27.9538 65.2763 27.4413 64.6439 27.4413C64.0115 27.4413 63.499 27.9538 63.499 28.5862V29.895C61.6927 30.2452 60.324 31.8383 60.324 33.7455C60.324 35.9086 62.0839 37.6683 64.2469 37.6683H65.0404C65.9411 37.6683 66.6737 38.401 66.6737 39.3016C66.6737 40.2023 65.9411 40.9349 65.0404 40.9349H63.8499C63.1681 40.9349 62.6135 40.3802 62.6135 39.6983C62.6135 39.0659 62.101 38.5535 61.4686 38.5535C60.8362 38.5535 60.3237 39.0659 60.3237 39.6983C60.3237 41.524 61.7186 43.0301 63.4987 43.2068V44.4607C63.4987 45.0931 64.0112 45.6056 64.6436 45.6056C65.276 45.6056 65.7885 45.0931 65.7885 44.4607V43.1519C67.5948 42.8017 68.9635 41.2087 68.9635 39.3015C68.9635 37.1383 67.2036 35.3786 65.0406 35.3786H64.247C63.3464 35.3786 62.6138 34.646 62.6138 33.7453C62.6138 32.8447 63.3464 32.1121 64.247 32.1121H65.4376C66.1193 32.1121 66.674 32.6667 66.674 33.3486V34.1423C66.6742 34.7747 67.1865 35.2872 67.8189 35.2872Z"
      fill="currentColor"
    />
    <path
      d="M24.3465 61.0226H14.5775C13.9453 61.0226 13.4326 61.5351 13.4326 62.1674V76.8211C13.4326 77.4535 13.9453 77.966 14.5775 77.966H24.3465C24.9788 77.966 25.4914 77.4535 25.4914 76.8211V62.1674C25.4914 61.5349 24.9788 61.0226 24.3465 61.0226ZM23.2017 75.6762H15.7222V63.3122H23.2017V75.6762Z"
      fill="currentColor"
    />
    <path
      d="M71.9705 61.0226H62.2015C61.5691 61.0226 61.0566 61.5351 61.0566 62.1674V76.8211C61.0566 77.4535 61.5691 77.966 62.2015 77.966H71.9705C72.6029 77.966 73.1154 77.4535 73.1154 76.8211V62.1674C73.1153 61.5349 72.6029 61.0226 71.9705 61.0226ZM70.8257 75.6762H63.3462V63.3122H70.8257V75.6762Z"
      fill="currentColor"
    />
    <path
      d="M40.2212 65.8313C40.8536 65.8313 41.3661 65.3188 41.3661 64.6865V47.5138C41.3661 46.8814 40.8536 46.369 40.2212 46.369H30.452C29.8198 46.369 29.3071 46.8814 29.3071 47.5138V76.8211C29.3071 77.4535 29.8198 77.966 30.452 77.966H40.221C40.8534 77.966 41.3659 77.4535 41.3659 76.8211V70.0289C41.3659 69.3965 40.8534 68.884 40.221 68.884C39.5887 68.884 39.0762 69.3965 39.0762 70.0289V75.6762H31.5967V48.6585H39.0762V64.6863C39.0763 65.3188 39.5888 65.8313 40.2212 65.8313Z"
      fill="currentColor"
    />
    <path
      d="M56.096 53.6958H46.327C45.6946 53.6958 45.1821 54.2083 45.1821 54.8407V76.8211C45.1821 77.4535 45.6946 77.966 46.327 77.966H56.096C56.7284 77.966 57.2409 77.4535 57.2409 76.8211V54.8407C57.2409 54.2083 56.7284 53.6958 56.096 53.6958ZM54.9513 75.6763H47.4719V55.9854H54.9513V75.6763Z"
      fill="currentColor"
    />
  </svg>
));
