import { useTheme } from "@emotion/react";
import { Box, Grid, Group, Text } from "@mantine/core";
import { TCommodity } from "types";
import { BoxIcon, CalendarIcon, UserIcon } from "ui/icons";
import { getFullDayYearTime } from "utils";

export const CommodityMergeInformation: React.FC<{ commodity: TCommodity; transactionCount?: number }> = ({
  commodity,
  transactionCount
}) => {
  const {
    other: { monochromeColor }
  } = useTheme();

  const commodityCreated = getFullDayYearTime(commodity?.createdAt);

  return (
    <Grid gutter={12} c={monochromeColor[2]}>
      <Grid.Col xs={12} sm={6}>
        <Group noWrap align="flex-start">
          <Box w={20} h={20} c="klp">
            <UserIcon />
          </Box>
          <Text>
            <Text span fw={700} pr="md">
              Classification:
            </Text>
            {commodity.naicsCodeName || "Not specified"}
          </Text>
        </Group>
      </Grid.Col>
      <Grid.Col xs={12} sm={6}>
        <Group noWrap align="flex-start">
          <Box style={{ whiteSpace: "nowrap" }}>
            <Text fw={700}>Cost Center: </Text>
          </Box>
          <Text span fw={400}>
            {commodity?.costCenter?.name || "Not Specified"}
          </Text>
        </Group>
      </Grid.Col>
      <Grid.Col xs={12} sm={6}>
        <Group noWrap align="flex-start">
          <Box w={20} h={20} c="klp">
            <BoxIcon />
          </Box>
          <Group noWrap align="flex-start">
            <Box style={{ whiteSpace: "nowrap" }}>
              <Text fw={700}>Description: </Text>
            </Box>
            <Text>{commodity?.description || "Not Specified"}</Text>
          </Group>
        </Group>
      </Grid.Col>
      <Grid.Col xs={12} sm={6}>
        <Text fw={700}>
          <Text span pr="md">
            Supplier:
          </Text>
          <Text span fw={400}>
            {commodity?.supplier?.name || "Not Specified"}
          </Text>
        </Text>
      </Grid.Col>
      <Grid.Col xs={12} sm={6}>
        <Group noWrap align="flex-start">
          <Box w={20} h={20} c="klp">
            <CalendarIcon />
          </Box>
          <Box>
            <Text fw={700} style={{ whiteSpace: "nowrap" }}>
              Commodity Created:
            </Text>
          </Box>
          <Text>{commodityCreated}</Text>
        </Group>
      </Grid.Col>
      <Grid.Col xs={12} sm={6}>
        <Group noWrap align="flex-start">
          <Box>
            <Text fw={700}>Number of Transactions: </Text>
          </Box>
          <Text fw={400}>{transactionCount || "0"}</Text>
        </Group>
      </Grid.Col>
    </Grid>
  );
};
