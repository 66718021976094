import { useMutation, useQueryClient } from "@tanstack/react-query";
import { QUERY_KEYS } from "constants/queryKeys";
import { TBuyspacesListResponse } from "types";

import { putBuyspaceService } from "../api";

export const useUpdateBuyspaceMutation = () => {
  const queryClient = useQueryClient();

  const buyspaceQueryKey = QUERY_KEYS.get_buyspaces;

  const mutation = useMutation({
    mutationFn: putBuyspaceService,
    mutationKey: [QUERY_KEYS.update_buyspace],
    onSuccess: async (response, { buyspaceId }) => {
      await queryClient.cancelQueries({ queryKey: [buyspaceQueryKey] });

      const previousData: TBuyspacesListResponse | undefined = queryClient.getQueryData([buyspaceQueryKey]);

      if (previousData) {
        const newUpdatedBuyspaceList = previousData.data.map(buyspaceItem => {
          if (buyspaceItem.id === buyspaceId) {
            return response.data;
          }
          return buyspaceItem;
        });

        const updatedValues = {
          data: newUpdatedBuyspaceList,
          meta: response.meta
        };

        queryClient.setQueryData([buyspaceQueryKey], updatedValues);

        return { updatedValues };
      }

      return { previousData };
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [buyspaceQueryKey] });
    }
  });
  return mutation;
};
