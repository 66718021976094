import axiosInstance from "services/AxiosInstance";
import { TSupplierMergePayload } from "types";

type PostSupplierMergeServiceArgs = {
  accountId: string;
  payload: TSupplierMergePayload;
};

export const postSupplierMergeService = async (apiArgs: PostSupplierMergeServiceArgs): Promise<void> => {
  const { accountId, payload } = apiArgs;
  return await axiosInstance.post(`suppliers/account/${accountId}/merge`, payload);
};
