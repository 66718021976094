import React, { ReactNode, useContext } from "react";

import styled from "@emotion/styled";
import { Container } from "@mantine/core";
import useViewportPercent from "hooks/useViewportPercent";
import { CurrentUserContext } from "modules/login/CurrentUserContext";

import { Footer } from "./common";
import { HeaderScreen } from "./common/HeaderScreen";
import PrivateLayoutSubHeader from "./common/PrivateLayoutSubHeader";

const StyleFooterPositionWrapper = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 10;
`;
const ContentContainerWrapper = styled.div`
  max-width: 100%;
  flex: 1;
`;
const PrivateLayoutWrapper = styled.div`
  position: relative;
  min-height: 100vh;
  box-sizing: border-box;

  padding-bottom: 208px;

  @media only screen and (max-width: ${props => props.theme.other.breakpoints.xl}) {
    padding-bottom: 248px;
  }
  @media only screen and (max-width: ${props => props.theme.other.breakpoints.md}) {
    padding-bottom: 360px;
  }
`;
const ContentContainer = styled(Container)<{ maxwidthlaptop: number }>`
  display: flex;
  max-width: 1366px;
  width: 100%;
  padding-left: 0px;
  padding-right: 0px;
  @media only screen and (max-width: 1440px) {
    max-width: ${props => props.maxwidthlaptop}px;
  }
`;
interface PrivateLayoutProps {
  children: ReactNode;
  beforeContainer?: ReactNode;
  showSubHeader?: boolean;
  accountName?: string | null;
  preview?: boolean;
  showEditAccountDetailsIcon?: boolean;
  setShowUpdateAccountModal?: React.Dispatch<React.SetStateAction<boolean>>;
}
const PrivateLayout: React.FC<PrivateLayoutProps> = ({
  children,
  beforeContainer,
  showSubHeader = true,
  accountName = null,
  showEditAccountDetailsIcon = false,
  setShowUpdateAccountModal,
  preview
}) => {
  const { userDetails } = useContext(CurrentUserContext);
  const maxwidthlaptop = useViewportPercent({ percent: 75 });

  return (
    <PrivateLayoutWrapper>
      <HeaderScreen
        user={{
          name: `${userDetails?.firstName} ${userDetails?.lastName?.charAt(0)}${userDetails?.lastName ? "." : ""}`,
          image: "",
          accountName: accountName ?? userDetails?.accountName
        }}
        preview={preview}
      />
      {showSubHeader && (
        <PrivateLayoutSubHeader
          accountName={accountName}
          showEditAccountDetailsIcon={showEditAccountDetailsIcon}
          setShowUpdateAccountModal={setShowUpdateAccountModal}
        />
      )}
      {beforeContainer}
      <ContentContainerWrapper>
        <ContentContainer maxwidthlaptop={maxwidthlaptop}>{children}</ContentContainer>
      </ContentContainerWrapper>
      <StyleFooterPositionWrapper>
        <Footer
          preview={preview}
          links={[
            { label: "How it works", link: "" },
            { label: "About Us", link: "" },
            { label: "Contact Us", link: "" }
          ]}
        />
      </StyleFooterPositionWrapper>
    </PrivateLayoutWrapper>
  );
};

export default PrivateLayout;
