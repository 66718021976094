import { ColumnDef } from "@tanstack/react-table";
import { TQuestionnaire } from "types";

import { QuestionnaireListActionCell } from "./QuestionnaireListActionCell";

export const questionnaireListColumns: ColumnDef<TQuestionnaire>[] = [
  {
    accessorKey: "title",
    header: "Name",
    enableSorting: false,
    size: 500
  },
  {
    accessorKey: "description",
    header: "Description",
    enableSorting: false,
    size: 500
  },
  {
    id: "action",
    header: "Action",
    enableSorting: false,
    cell: props => <QuestionnaireListActionCell {...props.row.original} />,
    meta: {
      align: "center"
    },
    size: 50
  }
];
