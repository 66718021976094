import styled from "@emotion/styled";

export const ImportErrorText = styled.div`
  color: ${({ theme }) => theme.other.dangerColor};
  text-decoration: underline;
  font-weight: 700;
  padding: 20px 16px;
  padding-left: 0;
`;

export const ImportErrorName = styled.div`
  margin-top: 15px;
  padding-bottom: 16px;
  line-height: 1.2;
`;

export const ImportErrorModalText = styled(ImportErrorText)`
  cursor: pointer;
`;
