import { ReactNode } from "react";

import { ButtonProps, Button as MTButton, useMantineTheme } from "@mantine/core";
import { theme } from "constants/theme";

interface CustomButtonProps {
  width?: string;
  height?: string;
  children?: ReactNode;
  buttonVariant: "filled" | "outline";
  color?: string;
  fontSize?: string;
  fontWeight?: number | string;
  lineHeight?: string;
  borderRadius?: string;
  background?: string | null;
  onClick?: () => void;
  borderColor?: string;
  margin?: string;
  padding?: string;
  disabled?: boolean;
  maxWidth?: string;
  btnType?: "button" | "submit";
}

/**
 * @deprecated Please use { Button } from '@mantine/core'
 * To use primary color just render Button with proper variant (subtle, default, outline, light, filled)
 * To use warning type add color='warning' prop to achieve deep yellow/orange color
 * To use error type add color='danger.8' to achieve the dark red color from the kloopify theme
 * Filled type is default
 */
const Button = ({
  width = "100%",
  height = "3.125rem",
  children,
  buttonVariant,
  fontSize = `${theme?.other?.font.fontSize}`,
  color,
  fontWeight,
  lineHeight = `${theme?.other?.font.lineHeight}`,
  borderRadius = "3.125rem",
  background,
  borderColor,
  disabled,
  margin = "0px",
  padding = "0px",
  maxWidth,
  btnType,
  ...props
}: CustomButtonProps & ButtonProps) => {
  const mantineTheme = useMantineTheme();

  let styles = {
    width: width,
    maxWidth: maxWidth ?? "100%",
    height: height,
    fontSize: fontSize,
    color: color,
    borderRadius: borderRadius,
    border: borderColor ? `1px solid ${borderColor} !important` : `1px solid ${mantineTheme?.other?.primaryColor}`,
    background: "transparent",
    "&:hover": {
      background: "transparent"
    },
    fontFamily: "Inter",
    lineHeight: lineHeight,
    fontStyle: "normal",
    cursor: "pointer",
    padding: padding,
    margin: margin
  };

  const filled = {
    color: color ?? mantineTheme?.other?.monochromeColor[6],
    border: "none",
    background: background ?? mantineTheme?.other?.primaryColor,
    "&:hover": {
      background: background ?? mantineTheme?.other?.primaryColor
    },
    fontWeight: fontWeight ?? 700
  };

  const outline = {
    color: color ?? mantineTheme?.other?.primaryColor,
    fontWeight: fontWeight ?? 400,
    background: background ?? "transparent",
    border: disabled ? "1px solid transparent" : styles.border,

    "&:hover": {
      background: background ?? "transparent"
    }
  };

  switch (buttonVariant) {
    case "filled":
      styles = {
        ...styles,
        ...filled
      };
      break;
    case "outline":
      styles = {
        ...styles,
        ...outline
      };
      break;
  }

  return (
    <MTButton
      disabled={disabled}
      type={btnType ?? "submit"}
      variant={buttonVariant}
      color={color}
      styles={() => ({
        root: styles
      })}
      {...props}
    >
      {children}
    </MTButton>
  );
};
export default Button;
