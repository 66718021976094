import { ActionIcon, Menu } from "@mantine/core";
import { MenuHamburgerIcon, ViewIcon } from "ui/icons";

import { useTransactionContext } from "../TransactionContext";
import { ITransactions } from "../transactions.types";
import { CenterCell } from "./styles";

export const TransactionMenu: React.FC<{ transaction: ITransactions }> = ({ transaction }) => {
  const { onOpenDetailsTransaction } = useTransactionContext();

  return (
    <div>
      <Menu withinPortal position="bottom-end">
        <Menu.Target>
          <CenterCell>
            <ActionIcon variant="transparent" c="gray.8">
              <MenuHamburgerIcon />
            </ActionIcon>
          </CenterCell>
        </Menu.Target>
        <Menu.Dropdown>
          <Menu.Item icon={<ViewIcon />} onClick={() => onOpenDetailsTransaction(transaction)}>
            View Details
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
    </div>
  );
};
