import { TAccountStatus } from "types";
import { CancelIcon, SuccessIcon } from "ui/icons";

import { StatusCell } from "./AccountListingStatusCell.styles";

export const AccountListingStatusCell = ({ status }: { status: TAccountStatus }) => {
  switch (status) {
    case "ACTIVE":
      return (
        <StatusCell status={status}>
          <SuccessIcon />
          Active
        </StatusCell>
      );
    case "PENDING":
      return (
        <StatusCell status={status}>
          <SuccessIcon />
          Pending
        </StatusCell>
      );
    case "SUSPENDED":
      return (
        <StatusCell status={status}>
          <CancelIcon />
          Suspended
        </StatusCell>
      );
    default:
      return null;
  }
};
