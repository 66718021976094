import { useTheme } from "@emotion/react";
import { Text } from "@mantine/core";
import { UseQueryResult } from "@tanstack/react-query";
import { ColumnDef, getCoreRowModel, TableOptions, useReactTable } from "@tanstack/react-table";
import { ErrorResponse } from "App";
import { ResponseType } from "types";
import { Table } from "ui";

type QuestionnaireListProps<T> = {
  columns: ColumnDef<T>[];
  options?: TableOptions<T>;
  query: UseQueryResult<ResponseType<T[]>, ErrorResponse>;
};

export const QuestionnaireList = <T,>({ columns, options, query }: QuestionnaireListProps<T>) => {
  const { data, isLoading } = query;

  const questionnaireListData = data?.data || [];

  const {
    other: { monochromeColor }
  } = useTheme();

  const table = useReactTable({
    data: questionnaireListData,
    columns,
    getCoreRowModel: getCoreRowModel(),
    ...options
  });

  if (questionnaireListData.length === 0 && !isLoading)
    return (
      <Text c={monochromeColor[2]} size="xs" ta="center">
        No Questionaires Created
      </Text>
    );

  return <Table<T> table={table} rowSize="30px" />;
};
