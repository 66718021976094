import axiosInstance from "services/AxiosInstance";
import { ResponseType } from "types";

interface PropsPayload {
  transactionFileLocation: string;
  filename: string;
  accountId: string;
}

// extend response with the proper type
export const postImportService = async (payLoad: PropsPayload): Promise<ResponseType<{ id: string }>> => {
  const response = await axiosInstance.post(`imports`, payLoad);
  return response.data;
};
