import { FC } from "react";

import { isArray } from "lodash";
import { dateFilter, statusFilter } from "modules/filters/importsFilters/importsFilters.data";
import moment from "moment";
import FilterTile from "ui/tiles/FilterTile/FilterTile";
import { formatDateString } from "utils";
import { flattenObject } from "utils";
import { parseQueryParams } from "utils/query.utils";

import { IimportFiltersData } from "../../types/ImportDto";
import { StyledUserFilterTilesWrapper } from "./ImportsFilterTiles.styles";

type Props = {
  filterParams: string;
  filtersData: IimportFiltersData;
  setFilters: (data?: IimportFiltersData) => void;
};

const replacementList = [...statusFilter, ...dateFilter];

export const ImportsFilterTiles: FC<Props> = ({ filterParams, filtersData, setFilters }) => {
  const modifyStringFilter = (key: keyof IimportFiltersData, label: string) => {
    const filterByKey = filtersData[key] as string;
    const isIncludes = filterByKey.includes(label);
    if (isIncludes) setFilters({ ...filtersData, [key]: "" });
  };

  const modifyArrayFilter = (key: keyof IimportFiltersData, label: string) => {
    const filterByKey = filtersData[key] as string[];
    const isIncludes = filterByKey.includes(label);
    if (isIncludes) setFilters({ ...filtersData, [key]: [...filterByKey].filter(i => i !== label) });
  };

  const modifyDateFilter = (key: keyof IimportFiltersData, label: string) => {
    const filterObject = parseQueryParams(filterParams);
    const filterBykey = filterObject[key] as string;
    if (filterBykey === label) setFilters({ ...filtersData, [key]: null });
  };

  const handleOnClick = (label: string) => {
    const filterKeysList = Object.keys(filtersData) as Array<keyof typeof filtersData>;

    filterKeysList.forEach(key => {
      const dataByKey = filtersData[key];

      if (typeof dataByKey === "string") modifyStringFilter(key, label);

      if (isArray(dataByKey)) modifyArrayFilter(key, label);

      if (dataByKey instanceof Date) modifyDateFilter(key, label);
    });
  };

  const getLabel = (text: string) => {
    if (!replacementList) return text;
    const replacement = replacementList.find(replacementItem => replacementItem.value === text);
    if (replacement) return replacement.label;
    if (moment(text, moment.ISO_8601, true).isValid()) return formatDateString(text);
    return text;
  };

  const renderFilterTiles = () => {
    const filterObject = parseQueryParams(filterParams);
    delete filterObject.page;
    if (filterObject.dates) {
      delete filterObject.lastUpdatedMax;
      delete filterObject.lastUpdatedMin;
    }
    const filterValuesList = flattenObject(filterObject);

    return filterValuesList.map((filterValue, index) => (
      <FilterTile
        key={"importsFilterTile" + index}
        text={getLabel(filterValue)}
        onClick={() => {
          handleOnClick(filterValue);
        }}
      />
    ));
  };

  return <StyledUserFilterTilesWrapper>{renderFilterTiles()}</StyledUserFilterTilesWrapper>;
};
