import { useContext, useMemo } from "react";
import { useParams } from "react-router-dom";

import { CurrentUserContext } from "modules/login";
import { dropdownRoutesConfig, routesConfig } from "router";
import { RoutesConfig } from "router/types";
import { Feature, TFeatureFlagUser } from "types";

export const useRoutesNavigation = () => {
  const { id } = useParams();

  const { userDetails, userFeatureFlags } = useContext(CurrentUserContext);

  const nestedAccountRestrictedFeatureList = useMemo(
    () =>
      (userFeatureFlags as TFeatureFlagUser[]).map(i => {
        const accountPermissionList = i[id as string];
        if (!accountPermissionList) return [];
        return accountPermissionList
          .map(permission => {
            const [featureName, enabled] = Object.entries(permission)[0];
            return enabled ? null : featureName;
          })
          .filter(a => a) as NonNullable<Feature[]>;
      }),
    [userFeatureFlags]
  );

  const accountRestrictedFeatureList = useMemo(
    () => ([] as Feature[]).concat(...nestedAccountRestrictedFeatureList),
    [nestedAccountRestrictedFeatureList]
  );

  const transformRoutesToAllowed = (routes: RoutesConfig[]) =>
    routes.filter(({ allowRoles, restrictFeatures }) => {
      const isAllowedRole = allowRoles.includes(userDetails?.userRole);

      const isRestrictedRoute = accountRestrictedFeatureList.some((p: Feature) => restrictFeatures.includes(p));

      return isAllowedRole && !isRestrictedRoute;
    });

  const transformRoutesToAccount = (routes: RoutesConfig[]) =>
    routes.map(({ path, name, ...rest }) => ({
      ...rest,
      path: path.replace(":accountId", userDetails?.accountId),
      name: name.replace(":accountName", userDetails?.accountName ?? "Account")
    }));

  const routes = transformRoutesToAccount(transformRoutesToAllowed(routesConfig));

  const dropdownRoutes = dropdownRoutesConfig.map(({ name, routes }) => {
    const allowedRoutes = transformRoutesToAllowed(routes);

    const allowedAccountRoutes = transformRoutesToAccount(allowedRoutes);

    return { name, routes: allowedAccountRoutes };
  });

  return { tabs: routes, dropdowns: dropdownRoutes };
};
