import { useEffect } from "react";

import { useClipboard } from "@mantine/hooks";

export const CopyButton = ({
  value,
  onSuccess,
  onReject,
  children
}: {
  children: (payload: { copied: boolean; copy: () => void }) => JSX.Element;
  onReject?: () => void;
  onSuccess?: () => void;
  value: string;
}) => {
  const { copied, copy, error } = useClipboard({ timeout: 500 });

  useEffect(() => {
    if (error && onReject) return onReject();
    if (copied && onSuccess) onSuccess();
  }, [copied, error]);

  return children({ copied, copy: () => copy(value) });
};
