import { TooltipConteiner } from "./customFieldTooltipContent.styles";

export const CustomFieldTooptipContent = () => (
  <TooltipConteiner>
    <h2>Custom Fields</h2>
    <p>
      We've introduced a new feature that empowers our customers to <b>create custom groupings</b> beyond <br />
      the standard cost center and GLCode. For instance, departments can now have their own unique groupings.
    </p>
    <p>
      With this update, you have the flexibility to add extra attributes for grouping purposes, going beyond the
      limitations of our static list of groupings.
    </p>
    <p>Here's a breakdown of the new feature:</p>
    <div>
      <p>
        <b>Field ID</b> - This represents the identifier for the column in Excel.
      </p>
      <p>
        <b>Data Type</b> - You can specify whether the data in the field is numerical, text, or other.
      </p>
      <p>
        <b>Field Label</b> - In the app, this will be displayed as the user-friendly label.
      </p>
    </div>
  </TooltipConteiner>
);
