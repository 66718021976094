import axiosInstance from "services/AxiosInstance";
import { TCommodityListResponse } from "types";

export const getCommodityListService = async (
  query: Record<string, number | string | string[] | null>,
  limit: number,
  accountId?: string
): Promise<TCommodityListResponse> => {
  const internalUrl = "/items";
  const externalUrl = "/items/account/" + accountId;
  const url = accountId ? externalUrl : internalUrl;
  const response = await axiosInstance.get(url, {
    params: { limit, ...query }
  });
  return response.data;
};
