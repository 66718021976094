import { useMutation, useQueryClient } from "@tanstack/react-query";
import { QUERY_KEYS } from "constants/queryKeys";

import { putApproveImportService } from "../api";

export const useApproveImportMutation = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: putApproveImportService,
    mutationKey: [QUERY_KEYS.approve_file],
    onSuccess: () => {
      queryClient.refetchQueries({ queryKey: [QUERY_KEYS.imports_listing] });
    }
  });
  return mutation;
};
