import { FC, Fragment } from "react";
import { useNavigate } from "react-router-dom";

import { useGetClassification } from "modules/classification";
import { useGetImportByIdQuery } from "modules/imports";
import moment from "moment";
import { getImportStatus } from "utils/common.utils";

import { transactionDetailConfig } from "../transactions.config";
import {
  StyledImportDetailModal,
  StyledTransactionDetailsWrapper,
  StyleHead,
  StyleHeadDetail,
  StyleMessage
} from "../transactions.styles";
import { ITransactions } from "../transactions.types";

type Props = {
  opened: boolean;
  setOpened: (state?: boolean) => void;
  transaction: ITransactions;
};

export const TransactionsDetailsModal: FC<Props> = ({ opened, setOpened, transaction }) => {
  const navigate = useNavigate();

  const { data, isLoading } = useGetImportByIdQuery(transaction.importId);

  const { data: industryCategoryData } = useGetClassification({
    params: { code: transaction.naicsCode }
  });

  const { data: industryIndustryData } = useGetClassification({
    params: { code: transaction.naicsIndustry }
  });

  const { data: industrySectorData } = useGetClassification({
    params: { code: transaction.naicsSector }
  });

  const detail = data?.data;

  const handleTransationRedirect = () => {
    if (transaction.supplierId) navigate(`/supplier/${transaction.supplierId}`);
  };

  const transactionDetails = transactionDetailConfig.map((detail, detailIndex) => {
    const { label, formatting, key, link } = detail;
    const detailValue = transaction[key as keyof ITransactions];
    return detailValue ? (
      <Fragment key={detailIndex}>
        <StyleHead>{label}</StyleHead>
        <StyleHeadDetail link={link} onClick={link ? handleTransationRedirect : undefined}>
          {formatting ? formatting(detailValue as string) : detailValue}
        </StyleHeadDetail>
      </Fragment>
    ) : null;
  });

  const category = industryCategoryData?.data?.[0]?.title ? (
    <>
      <StyleHead>Category:</StyleHead>
      <StyleHeadDetail>{industryCategoryData.data?.[0]?.title}</StyleHeadDetail>
    </>
  ) : null;

  const industry = industryIndustryData?.data?.[0]?.title ? (
    <>
      <StyleHead>Industry:</StyleHead>
      <StyleHeadDetail>{industryIndustryData?.data?.[0]?.title}</StyleHeadDetail>
    </>
  ) : null;
  const sector = industrySectorData?.data?.[0]?.title ? (
    <>
      <StyleHead>Sector:</StyleHead>
      <StyleHeadDetail>{industrySectorData.data?.[0]?.title}</StyleHeadDetail>
    </>
  ) : null;

  return (
    <StyledImportDetailModal
      opened={opened}
      setOpened={setOpened as React.Dispatch<React.SetStateAction<boolean>>}
      withCloseButton
      onClose
    >
      <StyledTransactionDetailsWrapper>
        <StyleMessage>Transactions Details</StyleMessage>
        {transactionDetails}
        {sector}
        {industry}
        {category}
      </StyledTransactionDetailsWrapper>
      {!isLoading ? (
        <Fragment>
          <StyleMessage>Import Details</StyleMessage>
          <StyleHead>Status:</StyleHead>
          {detail?.status === "COMPLETED" ? (
            <StyleHeadDetail>
              {detail?.approvedOn !== null
                ? `${getImportStatus(detail?.status)} on ${moment(detail?.approvedOn).format("MM/DD/YYYY")}`
                : "Approved Date not found."}
            </StyleHeadDetail>
          ) : (
            <StyleHeadDetail>{getImportStatus(detail?.status)}</StyleHeadDetail>
          )}
          <StyleHead>Activity:</StyleHead>
          {detail?.originatedBy !== null && (
            <StyleHeadDetail>
              Uploaded by {`${detail?.originatedBy?.firstName} ${detail?.originatedBy?.lastName}`} on{" "}
              {moment(detail?.createdAt).format("MM/DD/YYYY")}
            </StyleHeadDetail>
          )}
          {detail?.analyzedBy !== null && (
            <StyleHeadDetail>
              Analysis by {`${detail?.analyzedBy?.firstName} ${detail?.analyzedBy?.lastName}`} on{" "}
              {moment(detail?.analyzedOn).format("MM/DD/YYYY")}
            </StyleHeadDetail>
          )}
          {detail?.approvedBy !== null && (
            <StyleHeadDetail>
              Approved by {`${detail?.approvedBy?.firstName} ${detail?.approvedBy?.lastName}`} on{" "}
              {moment(detail?.approvedOn).format("MM/DD/YYYY")}
            </StyleHeadDetail>
          )}
          <StyleHead>Meta Data:</StyleHead>
          <StyleHeadDetail>File Name: {detail?.localname || detail?.filename?.split("_klp_")[0]}</StyleHeadDetail>
        </Fragment>
      ) : null}
    </StyledImportDetailModal>
  );
};
