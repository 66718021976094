import styled from "@emotion/styled";

export const Wrapper = styled.div`
  width: 100%;
  position: relative;
  @media only screen and (max-width: 1366px) {
    width: calc(100% - 20px);
    margin-left: auto;
    margin-right: auto;
  }
`;
export const TableHeaderOptionsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  @media only screen and (max-width: 705px) {
    display: grid;
    gap: 12px;
    justify-content: center;
    width: 100%;
  }
`;
export const StyleButtonSearchWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  @media only screen and (max-width: 705px) {
    display: grid;
    gap: 12px;
    justify-content: center;
    align-items: center;
  }
`;

export const StyledHeader = styled.div`
  font-family: ${props => props.theme.other.font.fontFamily};
  font-style: ${props => props.theme.other.font.fontStyle};
  font-weight: ${props => props.theme.other.font.header2.fontWeight};
  padding-top: ${props => props.theme.other.font.header2.paddingTop};
  padding-bottom: ${props => props.theme.other.font.header2.paddingBottom};
  font-size: ${props => props.theme.other.font.header2.fontSize};
  line-height: ${props => props.theme.other.font.header2.lineHeight};
  color: ${props => props.theme.other.primaryColor};
  text-align: left;
  @media only screen and (max-width: 705px) {
    text-align: center;
  }
`;
export const StyleNumberOfAccount = styled.span`
  font-family: ${props => props.theme.other.font.fontFamily};
  font-style: ${props => props.theme.other.font.fontStyle};
  font-weight: 400;
  font-size: ${props => props.theme.other.font.fontSize};
  line-height: ${props => props.theme.other.font.lineHeight};
  color: ${props => props.theme.other.monochromeColor[2]};
  margin-left: 21px;
`;
export const ErrorMessage = styled.div`
  font-family: ${props => props.theme.other.font.fontFamily};
  font-style: ${props => props.theme.other.font.fontStyle};
  font-weight: 700;
  font-size: 2.5rem;
  line-height: 3rem;
  color: ${props => props.theme.other.primaryColor};
  display: flex;
  align-items: center;
  gap: 15px;
  text-align: center;
  width: 100%;
  justify-content: center;
`;
