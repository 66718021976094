import { Button } from "@mantine/core";
import { ModalBodyMessage, ModalFooter, ModalTitle } from "ui";
import Modal from "ui/overlays/Modal";

type DeleteBuyspaceModalProps = { name: string; onClose: () => void; onDelete: () => void; opened: boolean };

export const DeleteBuyspaceModal: React.FC<DeleteBuyspaceModalProps> = ({ name, opened, onClose, onDelete }) => (
  <Modal opened={opened} withCloseButton handleClose={onClose}>
    <ModalTitle $type="danger" centered>
      Delete Buyspace?
    </ModalTitle>
    <ModalBodyMessage centered>
      Are you sure you want to delete Buyspace: <b>{name}</b>?
    </ModalBodyMessage>
    <ModalFooter>
      <Button variant="outline" color="danger.8" onClick={onClose}>
        Cancel
      </Button>
      <Button color="danger.8" onClick={onDelete}>
        Yes
      </Button>
    </ModalFooter>
  </Modal>
);
