import { useQuery, UseQueryOptions, UseQueryResult } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { QUERY_KEYS } from "constants/queryKeys";
import { identity, pickBy } from "lodash";
import { IndustryClassification, ResponseError, ResponseType } from "types";

import { getClassificationService } from "../api";

type UseGetClassificationArgs = (
  apiArgs: Parameters<typeof getClassificationService>[0],
  options?: UseQueryOptions<ResponseType<IndustryClassification[]>, AxiosError<ResponseError>>
) => UseQueryResult<ResponseType<IndustryClassification[]>, AxiosError<ResponseError>>;

export const useGetClassification: UseGetClassificationArgs = (apiArgs, options) => {
  const { sourceType, ...queryParams } = apiArgs;

  const params = pickBy(queryParams, identity);

  return useQuery<ResponseType<IndustryClassification[]>, AxiosError<ResponseError>>({
    queryFn: () => getClassificationService({ sourceType, ...params }),
    queryKey: [QUERY_KEYS.get_classification, { sourceType, ...params }],
    ...options
  });
};
