import { Dispatch, FC, SetStateAction } from "react";
import { useParams } from "react-router-dom";

import { Button, NumberInput } from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import { ClassificationMultiSelect } from "components";
import { useGetCommodityListQuery } from "modules/commodity";
import { useGetCostCenterQuery } from "modules/costCenter";
import { ISupplier, useGetSupplierListQuery } from "modules/suppliers";
import { initialFilters, ITransactionFilters, ITransactionsModal, methodDropdownConfig } from "modules/transactions";
import moment from "moment";
import { AutocompleteComponent } from "ui";
import Checkbox from "ui/inputs/Checkbox";
import GroupCheckbox from "ui/inputs/GroupCheckbox";
import { getDateNoTime } from "utils";
import { prettyCents } from "utils/formatting.utils";

import { useGetClassificationData } from "../../transactions/hooks";
import {
  Footer,
  StyledFiltersContainer,
  StyledFilterTitle,
  StyledInputBoundariesSetter,
  StyledInputWrapper,
  StyledRangeWrapper,
  StyledRow,
  StyledTopSpacer,
  StyledTransactionsFilterModal,
  StyledTransactionsFiltersHeader
} from "./TransactionFilters.styles";

type Props = {
  applyFilters: () => void;
  filters: ITransactionFilters;
  modalController: (updatedModal: Partial<ITransactionsModal>) => void;
  opened: boolean;
  updateFilters: (updatedFilters?: Partial<ITransactionFilters>) => void;
};

export const TransactionFilters: FC<Props> = ({ opened, filters, modalController, applyFilters, updateFilters }) => {
  const handleFilterModal = (state?: boolean) => {
    modalController({ filters: state ?? true });
  };

  const { id } = useParams();

  const handleReset = () => updateFilters({ ...initialFilters, page: filters.page });

  const handleShowResults = () => {
    applyFilters();
    modalController({ filters: false });
  };

  const { naicsDetailCodes, naicsIndustryCodes, naicsSectorCodes } = useGetClassificationData();

  const { data: supplierData } = useGetSupplierListQuery(
    id,
    { name: filters.supplierName, limit: 5 },
    { enabled: !!id && filters.supplierName.length >= 3 }
  );

  const supplierOptions: string[] = supplierData?.data?.map((supplier: ISupplier) => supplier.name) || [];

  const { data: commodityData } = useGetCommodityListQuery({ name: filters.itemName }, 5, id, {
    enabled: filters.itemName.length >= 3 && !!id
  });

  const commodityOptions = commodityData?.data?.map(commodity => commodity.name) || [];

  const { data: costCenterData } = useGetCostCenterQuery(
    {
      accountId: id as string,
      params: { limit: 5, name: filters.costCenterName }
    },
    { enabled: !!id && filters.costCenterName.length >= 3 }
  );

  const costCenterDataOptions = costCenterData?.data?.map(costCenter => costCenter.name) || [];

  return (
    <StyledTransactionsFilterModal
      opened={opened}
      setOpened={handleFilterModal as Dispatch<SetStateAction<boolean>>}
      withCloseButton
      handleClose={() => modalController({ filters: false })}
    >
      <StyledTransactionsFiltersHeader>Transaction Filters</StyledTransactionsFiltersHeader>
      <StyledFiltersContainer>
        <StyledRow>
          <div>
            <StyledFilterTitle>Date Range</StyledFilterTitle>
            <StyledRangeWrapper>
              <StyledInputWrapper>
                <Checkbox checked={!!filters.minDate} onChange={() => updateFilters({ minDate: null })} />
                <StyledInputBoundariesSetter>
                  <DatePicker
                    placeholder="Start Date"
                    inputFormat="MM/DD/YYYY"
                    onChange={value => updateFilters({ minDate: getDateNoTime(value as Date) })}
                    value={filters.minDate ? moment(filters.minDate).toDate() : null}
                  />
                </StyledInputBoundariesSetter>
              </StyledInputWrapper>

              <StyledInputWrapper>
                <Checkbox checked={!!filters.maxDate} onChange={() => updateFilters({ maxDate: null })} />
                <StyledInputBoundariesSetter>
                  <DatePicker
                    placeholder="End Date"
                    inputFormat="MM/DD/YYYY"
                    onChange={value => updateFilters({ maxDate: getDateNoTime(value as Date) })}
                    value={filters.maxDate ? moment(filters.maxDate).toDate() : null}
                  />
                </StyledInputBoundariesSetter>
              </StyledInputWrapper>
            </StyledRangeWrapper>
          </div>

          <div>
            <StyledInputWrapper>
              <AutocompleteComponent
                data={supplierOptions}
                label="Supplier Name"
                placeholder="Search Supplier"
                checkboxComponent={
                  <Checkbox checked={!!filters.supplierName} onChange={() => updateFilters({ supplierName: "" })} />
                }
                value={filters.supplierName}
                onChange={value => {
                  updateFilters({ supplierName: value, supplierIds: [] });
                }}
                onItemSubmit={item => {
                  updateFilters({ supplierName: item.value, supplierIds: [] });
                }}
              />
            </StyledInputWrapper>
          </div>
        </StyledRow>

        <StyledRow>
          <div>
            <StyledFilterTitle>Cost Range</StyledFilterTitle>
            <StyledRangeWrapper>
              <StyledInputWrapper>
                <Checkbox
                  checked={filters.minTotalCost !== undefined}
                  onChange={() => updateFilters({ minTotalCost: undefined })}
                />
                <StyledInputBoundariesSetter>
                  <NumberInput
                    placeholder="Minimum Cost"
                    precision={2}
                    parser={value => value?.replace(/\$\s?|(,*)/g, "")}
                    formatter={value =>
                      !Number.isNaN(parseFloat(value as string))
                        ? `$ ${value}`.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
                        : ""
                    }
                    onChange={minTotalCost =>
                      updateFilters({ minTotalCost: minTotalCost ? prettyCents(minTotalCost) : minTotalCost })
                    }
                    value={filters.minTotalCost ? Number(filters.minTotalCost) / 100 : undefined}
                    hideControls
                  />
                </StyledInputBoundariesSetter>
              </StyledInputWrapper>

              <StyledInputWrapper>
                <Checkbox
                  checked={filters.maxTotalCost !== undefined}
                  onChange={() => updateFilters({ maxTotalCost: undefined })}
                />
                <StyledInputBoundariesSetter>
                  <NumberInput
                    placeholder="Maximum Cost"
                    precision={2}
                    parser={value => value?.replace(/\$\s?|(,*)/g, "")}
                    formatter={value =>
                      !Number.isNaN(parseFloat(value as string))
                        ? `$ ${value}`.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
                        : ""
                    }
                    onChange={maxTotalCost =>
                      updateFilters({ maxTotalCost: maxTotalCost ? prettyCents(maxTotalCost) : maxTotalCost })
                    }
                    value={filters.maxTotalCost ? Number(filters.maxTotalCost) / 100 : undefined}
                    hideControls
                  />
                </StyledInputBoundariesSetter>
              </StyledInputWrapper>
            </StyledRangeWrapper>
          </div>

          <div>
            <StyledInputWrapper>
              <AutocompleteComponent
                data={commodityOptions}
                label="Commodity Name"
                placeholder="Search Commodity"
                checkboxComponent={
                  <Checkbox checked={!!filters.itemName} onChange={() => updateFilters({ itemName: "" })} />
                }
                value={filters.itemName}
                onChange={value => {
                  updateFilters({ itemName: value, itemIds: [] });
                }}
                onItemSubmit={item => {
                  updateFilters({ itemName: item.value, itemIds: [] });
                }}
              />
            </StyledInputWrapper>
          </div>
        </StyledRow>

        <StyledRow>
          <div>
            <StyledFilterTitle>CO₂E Range</StyledFilterTitle>
            <StyledRangeWrapper>
              <StyledInputWrapper>
                <Checkbox
                  checked={filters.minCo2e !== undefined}
                  onChange={() => updateFilters({ minCo2e: undefined })}
                />
                <StyledInputBoundariesSetter>
                  <NumberInput
                    placeholder="Minimum CO₂E"
                    precision={2}
                    onChange={minCo2e => updateFilters({ minCo2e })}
                    value={filters.minCo2e ? Number(filters.minCo2e) : undefined}
                    hideControls
                  />
                </StyledInputBoundariesSetter>
              </StyledInputWrapper>

              <StyledInputWrapper>
                <Checkbox
                  checked={filters.maxCo2e !== undefined}
                  onChange={() => updateFilters({ maxCo2e: undefined })}
                />
                <StyledInputBoundariesSetter>
                  <NumberInput
                    placeholder="Maximum CO₂E"
                    precision={2}
                    onChange={maxCo2e => updateFilters({ maxCo2e })}
                    value={filters.maxCo2e ? Number(filters.maxCo2e) : undefined}
                    hideControls
                  />
                </StyledInputBoundariesSetter>
              </StyledInputWrapper>
            </StyledRangeWrapper>
          </div>

          <div>
            <StyledInputWrapper>
              <AutocompleteComponent
                data={costCenterDataOptions}
                label="Cost Center Name"
                placeholder="Search Cost Center"
                checkboxComponent={
                  <Checkbox checked={!!filters.costCenterName} onChange={() => updateFilters({ costCenterName: "" })} />
                }
                value={filters.costCenterName}
                onChange={value => {
                  updateFilters({ costCenterName: value, costCenterIds: [] });
                }}
                onItemSubmit={item => {
                  updateFilters({ costCenterName: item.value, costCenterIds: [] });
                }}
              />
            </StyledInputWrapper>
          </div>
        </StyledRow>

        <div>
          <StyledInputWrapper>
            <Checkbox checked={!!filters.method} onChange={() => updateFilters({ method: "" })} />
            <StyledFilterTitle inline>Method</StyledFilterTitle>
          </StyledInputWrapper>
          <StyledTopSpacer>
            <GroupCheckbox
              nonArray
              radioMode
              value={[filters.method]}
              setValue={(method: any) => updateFilters({ method })}
              data={methodDropdownConfig}
              offset={0}
            />
          </StyledTopSpacer>
        </div>

        <div>
          <ClassificationMultiSelect
            title="NAICS Code"
            placeholder="Search NAICS Code"
            params={{ "levels[]": "DETAIL" }}
            onChange={values => updateFilters({ naicsCodes: values })}
            value={filters.naicsCodes}
            initialData={naicsDetailCodes}
          />
        </div>
        <div>
          <ClassificationMultiSelect
            title="NAICS Sector"
            placeholder="Search NAICS Sector"
            params={{ "levels[]": "SECTOR" }}
            onChange={values => updateFilters({ naicsSectors: values })}
            value={filters.naicsSectors}
            initialData={naicsSectorCodes}
          />
        </div>
        <div>
          <ClassificationMultiSelect
            title="NAICS Industry"
            placeholder="Search NAICS Industry"
            params={{ "levels[]": "INDUSTRY" }}
            onChange={values => updateFilters({ naicsIndustries: values })}
            value={filters.naicsIndustries}
            initialData={naicsIndustryCodes}
            placement="top"
          />
        </div>
      </StyledFiltersContainer>

      <Footer>
        <Button variant="white" onClick={handleReset}>
          Reset Filter
        </Button>
        <Button onClick={handleShowResults}>Show Results</Button>
      </Footer>
    </StyledTransactionsFilterModal>
  );
};
