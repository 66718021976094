import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { useDisclosure } from "@mantine/hooks";
import { showNotification } from "@mantine/notifications";
import { TSupplierMergePayload } from "types";
import Pagination from "ui/navigation/Pagination";
import { parseQueryParams, stringifyQueryParams } from "utils/query.utils";

import { SuppliersContext } from "./SuppliersContext";
import {
  AddSupplierModal,
  MergeSuppliersModal,
  SupplierFilterTile,
  SuppliersFilters,
  SuppliersTable,
  SuppliersTableHeader,
  UpdateSupplierModal
} from "./components";
import { useGetSupplierListQuery, usePostSupplierMergeMutation, useSupplierTable } from "./hooks";
import { Wrapper } from "./styles/suppliersTable.styles";
import { initialSupplierModals, initialSuppliersFilters } from "./suppliers.data";
import { ISupplier, ISupplierFilters, ISupplierModals } from "./suppliers.types";

const limit = 10;

export const Suppliers = () => {
  const navigate = useNavigate();

  const { search } = useLocation();

  const { id: accountId } = useParams();

  const initialQueryParams = stringifyQueryParams(parseQueryParams(search));

  const initialFilters = { ...initialSuppliersFilters, ...parseQueryParams(search) };

  const [filters, setFilters] = useState(initialFilters);

  const [queryParams, setQueryParams] = useState(initialQueryParams);

  const [modal, setModal] = useState(initialSupplierModals);

  const [activeSupplier, setActiveSupplier] = useState<ISupplier | null>(null);

  const [rowSelection, setRowSelection] = useState<ISupplier[]>([]);

  const onRowSelection = (values: ISupplier[]) => setRowSelection(values);

  const { data, isLoading, isFetching, isError, isInitialLoading, refetch } = useGetSupplierListQuery(accountId, {
    ...initialFilters,
    limit
  });

  const [opened, { close, open }] = useDisclosure();

  const onMergeModalOpen = () => {
    if (rowSelection.length === 1) {
      showNotification({
        message: "Select more than one supplier to merge",
        color: "green"
      });
      return;
    }
    open();
  };

  const modalController = (updatedModal: Partial<ISupplierModals>) => {
    setModal(prevModal => ({ ...prevModal, ...updatedModal }));
  };

  const onSupplierStateChange = (supplier: ISupplier | null) => {
    setActiveSupplier(supplier);
  };

  const updateFilters = (updatedFilters?: Partial<ISupplierFilters>, hardUpdate?: boolean) => {
    const newFilters = { ...filters, ...updatedFilters };
    const newQueryParams = stringifyQueryParams(newFilters);
    setFilters(newFilters);
    setQueryParams(newQueryParams);
    if (hardUpdate) applyFilters(newQueryParams);
  };

  const applyFilters = (updatedQueryParams?: string) => {
    const realQueryParams = updatedQueryParams || queryParams;
    navigate(`/suppliers${accountId ? "/" + accountId : ""}?${realQueryParams}`);
  };

  const { mutate: handlePostSupplierMutation } = usePostSupplierMergeMutation({
    apiArgs: { query: filters, accountId }
  });

  const onMergeSubmit = (payload: TSupplierMergePayload) => {
    if (accountId) {
      handlePostSupplierMutation(
        { payload, accountId: accountId },
        {
          onSuccess: () => {
            onRowSelection([]);
            onRowSelectReset();
            refetch();
            showNotification({
              title: "Merge suppliers",
              message: "Suppliers have been merged successfully",
              color: "green"
            });
          },
          onError: error => {
            showNotification({
              title: "Merge suppliers",
              message: error?.response?.data.errorMessage || "Error: suppliers cannot be merged",
              color: "red"
            });
          }
        }
      );
    }
  };

  const handlePage = (page: number) => {
    updateFilters({ page: page - 1 });
  };

  const [table, { onRowSelectReset }] = useSupplierTable({
    suppliers: data?.data || [],
    onRowSelectionChange: onRowSelection
  });

  const resetPagination = () => {
    const totalCount = data?.meta.totalCount as number;
    const pageMax = totalCount / limit;
    if (filters.page > pageMax) updateFilters({ page: 0 });
  };

  useEffect(() => {
    if (!modal.filters) {
      setFilters(initialFilters);
      setQueryParams(initialQueryParams);
    }
  }, [modal.filters]);

  useEffect(() => {
    if (search !== "?" + queryParams) {
      navigate(`/suppliers${accountId ? "/" + accountId : ""}?${queryParams}`);
    }
  }, [filters.page]);

  useEffect(() => {
    resetPagination();
  }, [data]);

  useEffect(() => {
    if (!isInitialLoading) refetch();
  }, [search]);

  return (
    <SuppliersContext.Provider value={{ modalController, onSupplierStateChange }}>
      <Wrapper>
        <SuppliersTableHeader
          filters={filters}
          onMergeModalOpen={onMergeModalOpen}
          modalController={modalController}
          rowSelection={rowSelection}
          applyFilters={applyFilters}
          updateFilters={updateFilters}
        />
        <SupplierFilterTile data={filters} update={updateFilters} />
        <SuppliersTable
          table={table}
          loading={isLoading && isFetching}
          onRowSelection={onRowSelection}
          suppliers={data?.data || []}
          isError={isError}
        />
        <Pagination
          activePage={Number(filters.page) + 1}
          setPage={handlePage as Dispatch<SetStateAction<number>>}
          totalRecords={data?.meta.totalCount}
          limit={limit}
        />
        <AddSupplierModal
          createSupplierOpened={modal.addSupplier}
          setOpened={() => modalController({ addSupplier: false, updateSupplier: false })}
        />
        {activeSupplier && (
          <UpdateSupplierModal
            opened={modal.updateSupplier}
            supplier={activeSupplier}
            setOpened={() => modalController({ addSupplier: false, updateSupplier: false })}
          />
        )}
        <MergeSuppliersModal opened={opened} onClose={close} rowSelection={rowSelection} onSubmit={onMergeSubmit} />

        <SuppliersFilters
          opened={modal.filters}
          applyFilters={applyFilters}
          modalController={modalController}
          updateFilters={updateFilters}
          filters={filters}
        />
      </Wrapper>
    </SuppliersContext.Provider>
  );
};
