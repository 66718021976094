export const UPLOAD_REJECTED = {
  label: "Upload Rejected",
  value: "UPLOAD_REJECTED"
};
export const PENDING_ANALYSIS = {
  label: "Pending Analysis",
  value: "PENDING_ANALYSIS"
};
export const PENDING_ANALYSIS_PROCESSING = {
  label: "Pending Analysis",
  value: "PENDING_ANALYSIS_PROCESSING"
};
export const PENDING_APPROVAL = {
  label: "Pending Approval",
  value: "PENDING_APPROVAL"
};
export const ANALYSIS_REJECTED = {
  label: "Analysis Rejected",
  value: "ANALYSIS_REJECTED"
};
export const PROCESSING_TRANSACTION_FILE = {
  label: "Processing Transactions",
  value: "PROCESSING_TRANSACTION_FILE"
};
export const PROCESSING_ANALYSIS_FILE = {
  label: "Processing Analysis",
  value: "PROCESSING_ANALYSIS_FILE"
};
export const COMPLETED = {
  label: "Completed",
  value: "COMPLETED"
};
export const CREATED = {
  label: "Created",
  value: "CREATED"
};
