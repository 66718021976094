import { useContext, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

import { Button } from "@mantine/core";
import { LinkComponent } from "components";
import useGetAccountUsers from "modules/account/queries/useGetAccountUsers";
import { useGetAnalyticsSummary } from "modules/analytics";
import { useGetUserImportsQuery } from "modules/imports";
import { CurrentUserContext } from "modules/login/CurrentUserContext";
import { initialUserTableModal, UserTable } from "modules/users";
import AddInternalUser from "modules/users/components/AddInternalUser";
import { USER_TYPE, UserRoles } from "modules/users/types/UserTypesEnum";
import { TAccount } from "types";
import Loader from "ui/feedback/Loader";
import { BoxIcon, CalendarIcon, PlusIcon } from "ui/icons";
import { formatDateStringFull } from "utils";
import { convertToPaginated } from "utils";

import { UpdateAccount } from "../UpdateAccount";
import {
  AccountDetailsWrapper,
  AccountLinkWrapper,
  StyleAccountLabelDetails,
  StyleAccountValueDetails,
  StyleNumberOfAccount,
  StyleTableHeader,
  StyleTableHeaderWrapper,
  Wrapper
} from "./accountDetails.styles";

interface AccountDetailsProps {
  accountDetails?: TAccount;
  showUpdateAccountModal: boolean;
  setShowUpdateAccountModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const defaultLimit = 10;

export const AccountDetails: React.FC<AccountDetailsProps> = ({
  accountDetails,
  showUpdateAccountModal,
  setShowUpdateAccountModal
}) => {
  const { id: accountId = "" } = useParams();
  const { userDetails, isInternal } = useContext(CurrentUserContext);
  const { userRole } = userDetails;

  const [activePage, setPage] = useState(1);
  const [addNewUserModal, setAddNewUserModal] = useState(false);
  const [modal, setModal] = useState(initialUserTableModal);

  const { data: analyticsData, isSuccess: isAnalyticsSuccess } = useGetAnalyticsSummary(accountId);

  const { data: importsData, isSuccess: isImportsSuccess } = useGetUserImportsQuery(
    activePage - 1,
    defaultLimit,
    USER_TYPE.external,
    undefined,
    accountId
  );

  const { data: usersData, isError, isSuccess: isUsersSuccess } = useGetAccountUsers(accountId);

  const paginatedUsersData = useMemo(() => convertToPaginated(usersData?.data || [], defaultLimit), [usersData]);

  const totalCount = useMemo(() => usersData?.data?.length || 0, [usersData]);

  const isLoaded = isAnalyticsSuccess && isImportsSuccess && isUsersSuccess;

  const accountCreated = useMemo(() => formatDateStringFull(accountDetails?.createdAt || ""), [accountDetails]);

  const lastAccountUpdate = useMemo(() => {
    const userImports = importsData?.data;
    if (!userImports?.length) return;
    const mostRecentDate = userImports[0].createdAt;
    return formatDateStringFull(mostRecentDate);
  }, [importsData]);

  const modalController = (updatedModal: Partial<typeof initialUserTableModal>) => {
    setModal(modal => ({ ...modal, ...updatedModal }));
  };

  return isLoaded ? (
    <Wrapper>
      <AccountDetailsWrapper>
        <div>
          <StyleAccountLabelDetails>
            <BoxIcon /> Suppliers:
            <StyleAccountValueDetails>{analyticsData?.data.summary.suppliers}</StyleAccountValueDetails>
          </StyleAccountLabelDetails>
          <StyleAccountLabelDetails>
            <CalendarIcon />
            Last Update:
            <StyleAccountValueDetails>{lastAccountUpdate ?? accountCreated}</StyleAccountValueDetails>
          </StyleAccountLabelDetails>
        </div>
        <div>
          <StyleAccountLabelDetails>
            Account Created:
            <StyleAccountValueDetails>{accountCreated}</StyleAccountValueDetails>
          </StyleAccountLabelDetails>
        </div>
        {isInternal && (
          <AccountLinkWrapper>
            <LinkComponent to={`/transactions/${accountId}`}>View Transactions</LinkComponent>
            <LinkComponent to={`/commodities/${accountId}`}>View Commodities</LinkComponent>
            <LinkComponent to={`/buyspaces/${accountId}`}>View Buyspaces</LinkComponent>
            <LinkComponent to={`/goals/${accountId}`}>View Goals</LinkComponent>
          </AccountLinkWrapper>
        )}
      </AccountDetailsWrapper>
      <StyleTableHeaderWrapper>
        <StyleTableHeader>
          Users <StyleNumberOfAccount>{`${totalCount} Users`}</StyleNumberOfAccount>
        </StyleTableHeader>
        {userRole !== UserRoles.ACCOUNT_CATEGORY_MANAGER.value && (
          <Button
            onClick={() => {
              setAddNewUserModal(true);
            }}
            size="lg"
            leftIcon={<PlusIcon />}
          >
            Add User
          </Button>
        )}
      </StyleTableHeaderWrapper>

      <UserTable
        userListingList={paginatedUsersData[activePage - 1] || []}
        isError={isError}
        activePage={activePage}
        setPage={setPage}
        errorMessage="No Results Found."
        totalRecords={totalCount}
        limit={defaultLimit}
        getMenuConfigFunc={() => []}
        modalController={modalController}
        modal={modal}
      />

      {/* Update Account Details */}
      <UpdateAccount
        callFrom="accountsDetails"
        openModal={showUpdateAccountModal}
        setOpenModal={setShowUpdateAccountModal}
        details={accountDetails}
      />

      {/* Add new user modal */}
      <AddInternalUser
        accountId={accountId}
        callFrom="accountsDetails"
        openModal={addNewUserModal}
        setOpenModal={setAddNewUserModal}
      />
    </Wrapper>
  ) : (
    <Loader />
  );
};
