import { useLocation, useNavigate, useParams } from "react-router-dom";

import { MantineTheme, Tabs } from "@mantine/core";
import useViewportPercent from "hooks/useViewportPercent";

import { MenuHeaderWrapper, TabWrapper } from "./privateLayoutSubHeader.styles";

const tabStyling = (theme: MantineTheme) => {
  return {
    tab: {
      ...theme.fn.focusStyles(),
      backgroundColor: theme.other.monochromeColor[6],
      border: `1px solid ${theme.other.primaryColor}`,
      padding: `${0}px ${26}px`,
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "50px",
      fontFamily: theme.other.font.fontFamily,
      fontStyle: theme.other.font.fontStyle,
      fontWeight: 700,
      fontSize: "1.125rem",
      lineHeight: "1.1875rem",
      color: theme.other.primaryColor,

      "&:first-of-type": {
        borderRadius: "4px"
      },

      "&:last-of-type": {
        borderRadius: "4px"
      },

      "&[data-active]": {
        backgroundColor: theme.other.primaryColor,
        border: "none",
        color: theme.other.monochromeColor[6],
        pointerEvents: "none"
      }
    },
    tabsList: {
      display: "flex",
      paddingTop: "15px",
      paddingBottom: "15px",
      paddingRight: "15px",
      gap: "15px",
      [theme.fn.smallerThan(1100)]: {
        justifyContent: "center"
      }
    }
  };
};

type AnalyticSubNavigationProps = {
  extra?: React.ReactNode;
};

export const AnalyticSubNavigation: React.FC<AnalyticSubNavigationProps> = ({ extra }) => {
  const maxWidthLaptop = useViewportPercent({ percent: 75 });
  const { id } = useParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const reportType = pathname.split("/")[3];

  return (
    <MenuHeaderWrapper>
      <TabWrapper maxWidthLaptop={maxWidthLaptop}>
        <Tabs
          unstyled
          value={reportType}
          onTabChange={(currentTab: string) => {
            navigate(`/analysis/${id}/${currentTab}`);
          }}
          styles={tabStyling}
        >
          <Tabs.List>
            <Tabs.Tab value="overview">Overview</Tabs.Tab>
            <Tabs.Tab value="breakdown">Breakdown</Tabs.Tab>
          </Tabs.List>
        </Tabs>
        <div>{extra}</div>
      </TabWrapper>
    </MenuHeaderWrapper>
  );
};
