import styled from "@emotion/styled";
import Modal from "ui/overlays/Modal";

const Message = styled.div`
  font-family: ${props => props.theme.other.font.fontFamily};
  font-style: ${props => props.theme.other.font.fontStyle};
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.1875rem;
  color: ${props => props.theme.other.monochromeColor[2]};
  margin-top: 5px;
`;
const HeaderText = styled.div`
  font-family: ${props => props.theme.other.font.fontFamily};
  font-style: ${props => props.theme.other.font.fontStyle};
  font-weight: 700;
  font-size: 1.5625rem;
  line-height: 1.875rem;
  text-align: left;
  color: ${props => props.theme.other.dangerColor};
`;
interface ErrorModalProps {
  openModal: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  onClose?: () => void;
  header?: string;
  message?: string;
  closeOpenedModal?: React.Dispatch<React.SetStateAction<boolean>> | null;
}

const StyledErrorModal = styled(Modal)`
  .mantine-Modal-modal {
    max-width: 469px;
    min-height: 194px;
    padding: 37px 56px 20px 45px;
    @media only screen and (max-width: ${props => props.theme.other.breakpoints.lg}) {
      padding: 10px;
    }
  }
`;
const ErrorModal: React.FC<ErrorModalProps> = ({
  openModal = true,
  setOpenModal,
  onClose,
  header = "Error",
  message = "Message",
  closeOpenedModal = null
}) => {
  return (
    <StyledErrorModal
      opened={openModal}
      setOpened={() => {
        setOpenModal(false);
        if (closeOpenedModal !== null) closeOpenedModal(false);
      }}
      onClose={false}
      handleClose={onClose}
      withCloseButton={true}
      title={<HeaderText>{header}</HeaderText>}
    >
      <Message>{message}</Message>
    </StyledErrorModal>
  );
};
export default ErrorModal;
