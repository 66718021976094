import * as React from "react";

export const ChevronDown = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="2rem" height="0.625rem" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M0.180615 1.29041C-0.0609742 1.542 -0.060093 1.9478 0.182588 2.19827L7.55919 9.81155C7.80265 10.0628 8.19736 10.0628 8.44081 9.81155L15.8174 2.19827C16.0601 1.9478 16.061 1.542 15.8194 1.29042L14.7632 0.190492C14.5186 -0.064165 14.1196 -0.0633957 13.876 0.192202L8.44442 5.89095C8.20016 6.14722 7.79984 6.14722 7.55559 5.89095L2.12402 0.1922C1.88041 -0.0633968 1.48137 -0.0641655 1.23683 0.190491L0.180615 1.29041Z"
      fill="currentColor"
    />
  </svg>
);
