import { NestedLi, TooltipContainer } from "./naicsTooltipContent.styles";

const NaicsTooltipContent = () => {
  return (
    <TooltipContainer>
      <h2>NAICS Code</h2>
      <p>
        The North American Industry Classification System (NAICS) is the standard used by <br /> Federal statistical
        agencies in classifying entity establishments for the purpose of <br /> collecting, analyzing, and publishing
        statistical data related to the U.S. business <br /> economy.
      </p>
      <p>
        NAICS uses a hierarchical structure. A "hierarchy" is the relationship of one item to a <br /> particular
        category.
      </p>

      <p style={{ marginBottom: 0 }}>The organization of NAICS is as follows:</p>
      <ul>
        <NestedLi level={1}>
          <p>
            <b>Sector:</b> 2-digit code
          </p>
        </NestedLi>
        <NestedLi level={2}>
          <p>Subsector: 3-digit code</p>
        </NestedLi>
        <NestedLi level={3}>
          <p>Industry Group: 4-digit code</p>
        </NestedLi>
        <NestedLi level={4}>
          <p>
            <b>NAICS Industry:</b> 5-digit code
          </p>
        </NestedLi>
        <NestedLi level={5}>
          <p>
            <b>National Industry (Code):</b> 6-digit code
          </p>
        </NestedLi>
      </ul>
      <a
        href="https://www.census.gov/programs-surveys/economic-census/year/2022/guidance/understanding-naics.html#:~:text=The%20North%20American%20Industry%20Classification,to%20the%20U.S.%20business%20economy."
        target="_blank"
        rel="noreferrer"
      >
        Read More
      </a>
    </TooltipContainer>
  );
};

export default NaicsTooltipContent;
