export const dateFilter = [
  {
    label: "Today",
    value: "today"
  },
  {
    label: "Past 7 Days",
    value: "7days"
  },
  {
    label: "Past 30 Days",
    value: "30days"
  },
  {
    label: "Past 6 months",
    value: "6months"
  },
  {
    label: "Past Year",
    value: "1year"
  },
  {
    label: "Older than 1 Year",
    value: "1yearMore"
  }
];
export const statusFilter = [
  {
    label: "Analysis Rejected",
    value: "ANALYSIS_REJECTED"
  },
  {
    label: "Completed",
    value: "COMPLETED"
  },
  {
    label: "Pending Analysis",
    value: "PENDING_ANALYSIS"
  },
  {
    label: "Pending Approval",
    value: "PENDING_APPROVAL"
  },
  {
    label: "Processing Analysis",
    value: "PROCESSING_ANALYSIS_FILE"
  },
  {
    label: "Processing Transactions",
    value: "PROCESSING_TRANSACTION_FILE"
  },
  {
    label: "Upload Rejected",
    value: "UPLOAD_REJECTED"
  }
];
