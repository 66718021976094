import { QuestionnaireModal } from "types";
import SuccessModal from "ui/overlays/SuccessModal";

type QuestionnaireOverlaysProps = {
  questionnaireModal: QuestionnaireModal | undefined;
  setQuestionnaireModal: (questionnaireModal: QuestionnaireModal | undefined) => void;
};

export const QuestionnaireOverlay = ({ questionnaireModal, setQuestionnaireModal }: QuestionnaireOverlaysProps) => {
  const close = () => setQuestionnaireModal(undefined);

  return (
    <SuccessModal
      openModal={Boolean(questionnaireModal)}
      setOpenModal={close}
      onClose={close}
      message={questionnaireModal}
    />
  );
};
