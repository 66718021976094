export const Route_URL = {
  login: `/`,
  somethingwentwrong: "/something_went_wrong",
  kloopify_error: "/kloopify_error",
  accessdenied: "/access_denied",
  dashboard: "/dashboard",
  account_selection: "account-selection",
  accounts: "accounts",
  local_storage_enable_url: "https://www.bublup.com/support/enable-cookies-local-storage-in-your-browser/"
};
