import styled from "@emotion/styled";
import { MultiSelect, ScrollArea } from "@mantine/core";

export const StyledMultiSelect = styled(MultiSelect, { shouldForwardProp: propName => propName !== "simpleSelect" })<{
  simpleSelect?: boolean;
}>`
  .mantine-MultiSelect-values {
    & > div {
      position: relative;

      ${({ simpleSelect }) => {
        if (!simpleSelect) {
          return `
          ::after {
            content: ",";
            bottom: 0;
            right: 0;
          }
          `;
        }
      }}
    }
  }

  .mantine-MultiSelect-value {
    margin-left: 2px;
    margin-right: 2px;
  }

  .mantine-MultiSelect-item {
    padding: 4px 12px;
  }
`;

export const DropdownContainer = styled(ScrollArea)`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
`;

export const DropdownHeader = styled.div`
  box-sizing: border-box;
  position: sticky;
  top: 0px;
  background-color: ${({ theme }) => theme.other.monochromeColor[6]};
  z-index: 1;
  padding: 16px 4px 8px 16px;
  width: calc(100% - 12px);

  display: flex;
  justify-content: space-between;
  align-items: center;

  h3 {
    font-size: 1rem;
    margin: 0;
  }

  button {
    color: ${({ theme }) => theme.other.primaryColor};

    :hover {
      background-color: transparent;
    }
  }
`;

export const NoDataFound = styled.div`
  padding: 16px;
`;
