import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { QUERY_KEYS } from "constants/queryKeys";
import { ResponseError, ResponseType } from "types";

import { postCustomField } from "../api";
import { CustomField } from "../types";

export const useCreateCustomField = ({ onSuccess }: { onSuccess: () => void }) => {
  const queryClient = useQueryClient();

  return useMutation<ResponseType<CustomField>, AxiosError<ResponseError>, { payload: Omit<CustomField, "id"> }>({
    mutationFn: postCustomField,
    mutationKey: [QUERY_KEYS.create_custom_fields],
    onSuccess: (newCustomField, { payload }) => {
      const queryKey = [QUERY_KEYS.get_custom_fields, { accountId: payload.accountId }];
      const previousData: ResponseType<CustomField[]> | undefined = queryClient.getQueryData(queryKey);

      if (previousData) {
        queryClient.setQueryData(queryKey, {
          ...previousData,
          data: [...previousData.data, newCustomField.data]
        });
        onSuccess();
      }
    }
  });
};
