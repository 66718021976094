import styled from "@emotion/styled";
import Modal from "ui/overlays/Modal";
import { ModalTitle } from "ui/styles";

import { AnalysisRejectedReasonModalProps } from "../ImportsDto";

const Message = styled.div`
  font-family: ${props => props.theme.other.font.fontFamily};
  font-style: ${props => props.theme.other.font.fontStyle};
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  color: ${props => props.theme.other.monochromeColor[2]};
  margin-top: 5px;
`;

export const AnalysisRejectedReasonModal: React.FC<AnalysisRejectedReasonModalProps> = ({
  openModal = true,
  setOpenModal,
  reason
}) => {
  const onModalClose = () => setOpenModal?.(false);

  return (
    <Modal
      opened={openModal}
      setOpened={setOpenModal}
      withCloseButton
      handleClose={onModalClose}
      title={<ModalTitle $type="danger">Analysis Rejected</ModalTitle>}
      mode="message"
    >
      <Message>This analysis was rejected for the following reason: {reason}</Message>
    </Modal>
  );
};
