import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ErrorResponse } from "App";
import { QUERY_KEYS } from "constants/queryKeys";
import { errorModal } from "modules/error/ErrorHandlingModals";

import { removeUserRole } from "../api/removeUserRole";

export const useRemoveUserRole = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: removeUserRole,
    mutationKey: [QUERY_KEYS.remove_user_role],
    useErrorBoundary: true,
    onSuccess: () => {
      queryClient.refetchQueries({ queryKey: [QUERY_KEYS.user_list] });
      queryClient.refetchQueries({ queryKey: [QUERY_KEYS.get_account_users] });
    },
    onError: (error: ErrorResponse) => errorModal(error)
  });
};
