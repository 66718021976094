import * as React from "react";

export const UploadIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="16" height="15" viewBox="0 0 21 20" fill="none" {...props}>
    <path
      d="M20.0013 15.7143V18.5717C20.0013 19.3596 19.3606 20.0003 18.5727 20.0003H1.42867C0.640757 20.0003 0 19.3596 0 18.5717V15.7143C0 15.32 0.319307 15 0.714333 15C1.10936 15 1.42867 15.32 1.42867 15.7143V18.5717H18.5727V15.7143C18.5727 15.32 18.892 15 19.287 15C19.682 15 20.0013 15.32 20.0013 15.7143Z"
      fill="currentColor"
    />
    <path
      d="M4.49537 6.21836C4.21606 5.93906 4.21606 5.4876 4.49537 5.2083L9.49499 0.208677C9.77429 -0.0713419 10.2308 -0.0677703 10.5065 0.208677L15.5061 5.2083C15.7854 5.4876 15.7854 5.93906 15.5061 6.21836C15.3668 6.35766 15.1839 6.42766 15.0011 6.42766C14.8182 6.42766 14.6353 6.35766 14.496 6.21836L10.7151 2.4374V14.9997C10.7151 15.394 10.3958 15.714 10.0007 15.714C9.60571 15.714 9.2864 15.394 9.2864 14.9997V2.4374L5.50543 6.21836C5.22613 6.49767 4.77467 6.49767 4.49537 6.21836Z"
      fill="currentColor"
    />
  </svg>
);
