import { useState } from "react";

import { Button, LoadingOverlay, TextInput } from "@mantine/core";
import { useCurrentUserContext } from "modules/login";
import { UserRoles } from "modules/users/types/UserTypesEnum";
import Loader from "ui/feedback/Loader";
import { PlusIcon, SearchIcon, SettingIcon } from "ui/icons";

import { useGetAccountListWithSummaryQuery } from "../hooks";
import { AccountListingTable } from "./AccountListingTable";
import {
  ErrorMessage,
  StyleButtonSearchWrapper,
  StyledHeader,
  StyleNumberOfAccount,
  TableHeaderOptionsWrapper,
  Wrapper
} from "./AccountListingView.styles";
import { AddNewAccount } from "./AddNewAccount";

const defaultLimit = 10;

export const AccountListingView = () => {
  const [addNewAccountModal, setAddNewAccountModal] = useState(false);
  const {
    userDetails: { userRole }
  } = useCurrentUserContext();

  const [activePage, setPage] = useState(1);

  const { data, isLoading, isError } = useGetAccountListWithSummaryQuery({
    page: activePage - 1,
    limit: defaultLimit,
    accountIds: []
  });

  const totalCount = data?.meta?.totalCount || 0;

  if (isError) return <ErrorMessage>Something went wrong</ErrorMessage>;

  return (
    <Wrapper>
      <LoadingOverlay visible={isLoading} overlayBlur={2} loader={<Loader />} />
      <StyledHeader>
        Accounts
        <StyleNumberOfAccount>{`${totalCount} Accounts`}</StyleNumberOfAccount>
      </StyledHeader>
      <TableHeaderOptionsWrapper>
        <StyleButtonSearchWrapper>
          <Button size="lg" variant="default" leftIcon={<SettingIcon />}>
            Filter
          </Button>
          <TextInput size="lg" icon={<SearchIcon />} />
        </StyleButtonSearchWrapper>
        {userRole === UserRoles["INTERNAL_ADMIN"].value && (
          <Button size="lg" onClick={() => setAddNewAccountModal(true)} leftIcon={<PlusIcon />}>
            New Account
          </Button>
        )}
      </TableHeaderOptionsWrapper>
      <AccountListingTable
        data={data?.data || []}
        activePage={activePage}
        setPage={setPage}
        totalRecords={totalCount}
      />
      <AddNewAccount openModal={addNewAccountModal} setOpenModal={setAddNewAccountModal} />
    </Wrapper>
  );
};
