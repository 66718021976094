import styled from "@emotion/styled";
import { Button } from "@mantine/core";
import Modal from "ui/overlays/Modal";

const Message = styled.div`
  font-family: ${props => props.theme.other.font.fontFamily};
  font-style: ${props => props.theme.other.font.fontStyle};
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.1875rem;
  letter-spacing: 0em;
  text-align: center;
  color: ${props => props.theme.other.monochromeColor[2]};
  @media only screen and (max-width: ${props => props.theme.other.breakpoints.lg}) {
    margin-top: 30px;
  }
`;
const HeaderText = styled.div`
  font-family: ${props => props.theme.other.font.fontFamily};
  font-style: ${props => props.theme.other.font.fontStyle};
  font-weight: 700;
  font-size: 3.125rem;
  line-height: 3.8125rem;
  text-align: center;
  color: ${props => props.theme.other.primaryColor};
  @media only screen and (max-width: ${props => props.theme.other.breakpoints.lg}) {
    margin-top: 30px;
  }
`;
const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin: 49px 0 0 auto;
  @media only screen and (max-width: ${props => props.theme.other.breakpoints.lg}) {
    margin: 60px 0 0 auto;
  }
`;
interface SuccessModalProps {
  openModal: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  onClose?: () => void;
  header?: string;
  message?: string;
  closeOpenedModal?: React.Dispatch<React.SetStateAction<boolean>> | null;
}

const StyleModal = styled(Modal)`
  .mantine-Modal-modal {
    max-width: 618px !important;
    min-height: 300px !important;
    padding: 68.75px 24px 36px 24px !important;
  }
`;
const SuccessModal: React.FC<SuccessModalProps> = ({
  openModal = true,
  setOpenModal,
  onClose,
  header = "Success!",
  message = "",
  closeOpenedModal = null
}) => {
  return (
    <StyleModal
      opened={openModal}
      setOpened={() => {
        setOpenModal(false);
        if (closeOpenedModal !== null) closeOpenedModal(false);
      }}
      handleClose={onClose}
      onClose={false}
      withCloseButton={true}
      title={<HeaderText>{header}</HeaderText>}
    >
      <Message>{message}</Message>
      <ButtonWrapper>
        <Button
          onClick={() => {
            setOpenModal(false);
            if (closeOpenedModal !== null) {
              closeOpenedModal(false);
            }
            onClose?.();
          }}
        >
          Close
        </Button>
      </ButtonWrapper>
    </StyleModal>
  );
};
export default SuccessModal;
