import axiosInstance from "services/AxiosInstance";
import { TFeatureFlagListResponse } from "types";

export const getFeatureFlagsAccountService = async (
  accountId: string,
  userId: string
): Promise<TFeatureFlagListResponse> => {
  const response = await axiosInstance.get(`featureflags/${accountId}/${userId}`);
  return response.data;
};
