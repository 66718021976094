import { useParams } from "react-router-dom";

import { useQueryClient } from "@tanstack/react-query";
import { QUERY_KEYS } from "constants/queryKeys";
import { useGetAccountDetailsQuery } from "modules/account";
import { ResponseType, TAccount } from "types";

/**
 * Get account from earlier fetched list of accounts from the react-query cache
 *
 * If the account is not exising or the list was not fetched before - fetch one account from :id url param
 * @returns account: Account | undefined
 */
export const useGetAccount = () => {
  const queryClient = useQueryClient();
  const { id } = useParams() as { id: string };

  const allAccountCachedQueries = queryClient.getQueriesData([QUERY_KEYS.accounts_listing]) || [];

  const cachedAccountResponseListData = (allAccountCachedQueries?.flatMap(([_, data]) => data) ||
    []) as ResponseType<TAccount>[];

  const cachedAccountListData = cachedAccountResponseListData.flatMap(data => data?.data).filter(Boolean) || [];

  const cachedAccount = cachedAccountListData.find(account => account.id === id);

  const enableAccountRequest = !!(id && !cachedAccount);

  const { data } = useGetAccountDetailsQuery({ accountId: id }, { enabled: enableAccountRequest });

  const account = cachedAccount || data?.data;

  return account;
};
