import { uniqueId } from "lodash";
import { QuestionnaireNormalized, QuestionnaireQuestionScope } from "types";

import { QuestionnaireFactoryContainer } from "../questionnaire.styles";

type QuestionnaireFactoryProps = {
  questions: QuestionnaireNormalized[];
  setNormalizedQuestionnaire: React.Dispatch<React.SetStateAction<QuestionnaireNormalized[]>>;
  submitted?: boolean;
};

export const QuestionnaireFactory = ({
  questions,
  setNormalizedQuestionnaire,
  submitted
}: QuestionnaireFactoryProps) => {
  const add = () => {
    const itemScopeQuestions = questions.find(i => i.scope === QuestionnaireQuestionScope.SCOPE_ITEM);
    if (itemScopeQuestions) {
      const questions = itemScopeQuestions.questions.map(i => ({ ...i, response: "", error: "" }));
      const emptyItemScopeQuestions = { ...itemScopeQuestions, questions };
      setNormalizedQuestionnaire(prev => [...prev, { ...emptyItemScopeQuestions, id: uniqueId() }]);
    }
  };

  if (submitted) {
    return null;
  }

  return (
    <QuestionnaireFactoryContainer>
      <p onClick={add}>+ Add another item</p>
    </QuestionnaireFactoryContainer>
  );
};
