import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { ErrorResponse } from "App";
import { QUERY_KEYS } from "constants/queryKeys";
import { errorModal } from "modules/error/ErrorHandlingModals";

import { getUserInfoService } from "../api";
import { UserAccountInterface } from "../types";

export const useGetUserAccountsQuery = (userId: string): UseQueryResult<UserAccountInterface[]> =>
  useQuery({
    queryKey: [QUERY_KEYS.get_user_accounts, userId],
    queryFn: () => getUserInfoService(userId),
    enabled: Boolean(userId),
    onError: (error: ErrorResponse) => errorModal(error),
    useErrorBoundary: true,
    select: res => res.data.accounts
  });
