import { createContext, useContext } from "react";

type MultiSelectContextOptions = {
  onClear: () => void;
  search: string;
  selectedCount: number;
  simpleSelect?: boolean;
};

const initialMultiSelectContext: MultiSelectContextOptions = {
  selectedCount: 0,
  onClear: () => {},
  search: "",
  simpleSelect: false
};

export const MultiSelectContext = createContext<MultiSelectContextOptions>(initialMultiSelectContext);

export const useMultiSelectProvider = () => {
  const context = useContext(MultiSelectContext);

  if (!context) {
    throw new Error("useMultiSelectContext must be used within a MultiSelectContext.Provider");
  }

  return context;
};
