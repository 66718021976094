import { FC, Fragment, ReactNode, useContext } from "react";

import { CurrentUserContext } from "modules/login/CurrentUserContext";

type Props = {
  children: ReactNode;
  fallback?: JSX.Element;
  roles: string[];
};

const RoleGuardWrapper: FC<Props> = ({ children, roles, fallback = null }) => {
  const { userDetails } = useContext(CurrentUserContext);

  const roleGuard = roles.includes(userDetails.userRole);

  return <Fragment>{roleGuard ? children : fallback}</Fragment>;
};

export default RoleGuardWrapper;
