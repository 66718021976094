import { useContext } from "react";

import { Menu } from "@mantine/core";
import { ImportInterface } from "modules/imports/types";
import { CurrentUserContext } from "modules/login";
import { UserRoles } from "modules/users/types/UserTypesEnum";
import { CancelIcon, CheckmarkIcon, DownloadIcon, EditIcon, SuccessIcon, TrashIcon, UploadIcon } from "ui/icons";
import RoleGuardWrapper from "ui/wrappers/RoleGuardWrapper";

import { useImportContext } from "../ImportContext";
import { ImportKeys } from "../types";

const analysisRoles = [UserRoles.INTERNAL_ADMIN.value, UserRoles.INTERNAL_ANALYST.value];

const transactionRoles = [
  UserRoles.INTERNAL_ADMIN.value,
  UserRoles.INTERNAL_ANALYST.value,
  UserRoles.ACCOUNT_ADMIN.value
];

const MenuFallback = <Menu.Item>No Actions</Menu.Item>;

export const InternalUserImportMenu: React.FC<ImportInterface> = props => {
  const { status } = props;

  const { onDownloadFile, onDownloadStreamlinedFile, onModalOpen, onSelectedAccountId } = useImportContext();

  const handleModalOpen = (type: ImportKeys) => onModalOpen({ active: props, open: type });

  const {
    userDetails: { userRole }
  } = useContext(CurrentUserContext);

  const renderMenu = () => {
    switch (status) {
      case "CREATED":
        return (
          <>
            <Menu.Item
              icon={<EditIcon />}
              onClick={() => {
                onSelectedAccountId(props.accountId);
                handleModalOpen("editMapping");
              }}
            >
              Edit the Column Map
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item icon={<TrashIcon />} onClick={() => handleModalOpen("importDelete")}>
              Delete
            </Menu.Item>
          </>
        );
      case "UPLOAD_REJECTED":
        return (
          <>
            {userRole === "INTERNAL_ADMIN" && (
              <>
                <Menu.Item icon={<TrashIcon />} onClick={() => handleModalOpen("importDelete")}>
                  Delete
                </Menu.Item>
                <Menu.Divider />
              </>
            )}
            <RoleGuardWrapper roles={transactionRoles}>
              <Menu.Item
                icon={<DownloadIcon />}
                onClick={() => {
                  onDownloadFile("transactions", props);
                }}
              >
                Download Transactions File
              </Menu.Item>
              <Menu.Divider />
            </RoleGuardWrapper>
            <RoleGuardWrapper roles={analysisRoles}>
              <Menu.Item icon={<DownloadIcon />} onClick={() => onDownloadStreamlinedFile(props)}>
                Download Streamlined File
              </Menu.Item>
              <Menu.Divider />
            </RoleGuardWrapper>
            <Menu.Item icon={<UploadIcon />} onClick={() => handleModalOpen("reuploadTransaction")}>
              Upload Transactions File
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item icon={<CancelIcon />} onClick={() => handleModalOpen("importDetailError")}>
              Show Errors
            </Menu.Item>
          </>
        );
      case "PENDING_ANALYSIS":
        return (
          <>
            {userRole === "INTERNAL_ADMIN" && (
              <>
                <Menu.Item icon={<TrashIcon />} onClick={() => handleModalOpen("importDelete")}>
                  Delete
                </Menu.Item>
                <Menu.Divider />
              </>
            )}
            <RoleGuardWrapper roles={transactionRoles}>
              <Menu.Item
                icon={<DownloadIcon />}
                onClick={() => {
                  onDownloadFile("transactions", props);
                }}
              >
                Download Transactions File
              </Menu.Item>
              <Menu.Divider />
            </RoleGuardWrapper>
            <RoleGuardWrapper roles={analysisRoles}>
              <Menu.Item icon={<DownloadIcon />} onClick={() => onDownloadStreamlinedFile(props)}>
                Download Streamlined File
              </Menu.Item>
              <Menu.Divider />
            </RoleGuardWrapper>
            <Menu.Item onClick={() => handleModalOpen("rejectTransaction")} icon={<CancelIcon />}>
              Reject Transactions File
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item icon={<SuccessIcon />} onClick={() => handleModalOpen("uploadAnalysis")}>
              Upload Analysis File
            </Menu.Item>
          </>
        );
      case "PENDING_APPROVAL":
        return (
          <>
            {userRole === "INTERNAL_ADMIN" && (
              <>
                <Menu.Item onClick={() => handleModalOpen("approveAnalysis")} icon={<CheckmarkIcon />}>
                  Approve
                </Menu.Item>
                <Menu.Divider />
                <Menu.Item onClick={() => handleModalOpen("rejectAnalysis")} icon={<CancelIcon />}>
                  Reject
                </Menu.Item>
                <Menu.Divider />
                <Menu.Item icon={<TrashIcon />} onClick={() => handleModalOpen("importDelete")}>
                  Delete
                </Menu.Item>
                <Menu.Divider />
              </>
            )}
            <RoleGuardWrapper roles={transactionRoles}>
              <Menu.Item
                icon={<DownloadIcon />}
                onClick={() => {
                  onDownloadFile("transactions", props);
                }}
              >
                Download Transactions File
              </Menu.Item>
              <Menu.Divider />
            </RoleGuardWrapper>
            <RoleGuardWrapper roles={analysisRoles}>
              <Menu.Item icon={<DownloadIcon />} onClick={() => onDownloadStreamlinedFile(props)}>
                Download Streamlined File
              </Menu.Item>
              <Menu.Divider />
            </RoleGuardWrapper>
            <RoleGuardWrapper roles={analysisRoles}>
              <Menu.Item
                icon={<DownloadIcon />}
                onClick={() => {
                  onDownloadFile("analysis", props);
                }}
              >
                Download Analysis File
              </Menu.Item>
            </RoleGuardWrapper>
          </>
        );
      case "ANALYSIS_REJECTED":
        return (
          <>
            {userRole === "INTERNAL_ADMIN" && (
              <>
                <Menu.Item icon={<TrashIcon />} onClick={() => handleModalOpen("importDelete")}>
                  Delete
                </Menu.Item>
                <Menu.Divider />
              </>
            )}
            <RoleGuardWrapper roles={transactionRoles}>
              <Menu.Item
                icon={<DownloadIcon />}
                onClick={() => {
                  onDownloadFile("transactions", props);
                }}
              >
                Download Transactions File
              </Menu.Item>
              <Menu.Divider />
            </RoleGuardWrapper>
            <RoleGuardWrapper roles={analysisRoles}>
              <Menu.Item icon={<DownloadIcon />} onClick={() => onDownloadStreamlinedFile(props)}>
                Download Streamlined File
              </Menu.Item>
              <Menu.Divider />
            </RoleGuardWrapper>
            <RoleGuardWrapper roles={analysisRoles}>
              <Menu.Item
                icon={<DownloadIcon />}
                onClick={() => {
                  onDownloadFile("analysis", props);
                }}
              >
                Download Analysis File
              </Menu.Item>
              <Menu.Divider />
            </RoleGuardWrapper>
            <Menu.Item icon={<UploadIcon />} onClick={() => handleModalOpen("uploadAnalysis")}>
              Upload Analysis File
            </Menu.Item>
          </>
        );
      case "COMPLETED":
        return (
          <RoleGuardWrapper roles={transactionRoles} fallback={<Menu.Item>No Actions</Menu.Item>}>
            <RoleGuardWrapper roles={["INTERNAL_ADMIN"]}>
              <Menu.Item icon={<TrashIcon />} onClick={() => handleModalOpen("importDelete")}>
                Delete
              </Menu.Item>
              <Menu.Divider />
            </RoleGuardWrapper>

            <RoleGuardWrapper roles={analysisRoles}>
              <Menu.Item
                icon={<DownloadIcon />}
                onClick={() => {
                  onDownloadFile("analysis", props);
                }}
              >
                Download Analysis File
              </Menu.Item>
              <Menu.Divider />
            </RoleGuardWrapper>
            <RoleGuardWrapper roles={transactionRoles}>
              <Menu.Item
                icon={<DownloadIcon />}
                onClick={() => {
                  onDownloadFile("transactions", props);
                }}
              >
                Download Transactions File
              </Menu.Item>
            </RoleGuardWrapper>
            <RoleGuardWrapper roles={analysisRoles}>
              <Menu.Divider />
              <Menu.Item icon={<DownloadIcon />} onClick={() => onDownloadStreamlinedFile(props)}>
                Download Streamlined File
              </Menu.Item>
            </RoleGuardWrapper>
          </RoleGuardWrapper>
        );
      default:
        return (
          <RoleGuardWrapper roles={[UserRoles.INTERNAL_ADMIN.value]} fallback={MenuFallback}>
            <Menu.Item icon={<TrashIcon />} onClick={() => handleModalOpen("importDelete")}>
              Delete
            </Menu.Item>
          </RoleGuardWrapper>
        );
    }
  };

  const menu = renderMenu();

  return menu;
};
