import axiosInstance from "services/AxiosInstance";
import { ResponseType, TQuestionnaireRequest, TQuestionnaireRequestPayload } from "types";

type PostQuestionnaireRequestApiArgs = {
  accountId: string;
  payload: TQuestionnaireRequestPayload;
};

export const postQuestionnaireRequestService = async (
  apiArgs: PostQuestionnaireRequestApiArgs
): Promise<ResponseType<TQuestionnaireRequest>> => {
  const { accountId, payload } = apiArgs;
  const response = await axiosInstance.post(`questionnaire/${accountId}/request`, payload);
  return response.data;
};
