import styled from "@emotion/styled";
import { Loader as MTLoader, useMantineTheme } from "@mantine/core";

const LoaderWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
interface LoaderProps {
  variant?: "bars" | "dots";
}

const Loader: React.FC<LoaderProps> = ({ variant = "dots" }) => {
  const theme = useMantineTheme();
  return (
    <LoaderWrapper>
      <MTLoader variant={variant} color={theme?.other?.primaryColor} size={60} />
    </LoaderWrapper>
  );
};
export default Loader;
