import { FC, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { Grid, LoadingOverlay, Select } from "@mantine/core";
import { useQuery } from "@tanstack/react-query";
import { QUERY_KEYS } from "constants/queryKeys";
import { pickBy } from "lodash";
import { TAnalyticsCustomInterval, TOverviewDashboardModal, TOverviewDashboardState } from "types";
import Card from "ui/data-display/Card";
import Loader from "ui/feedback/Loader";
import LineChart from "ui/graphs/line-chart/LineChart";
import { CashLogo } from "ui/icons";
import { IndustryLogo, MoneyChartIcon, PriceTagIcon } from "ui/icons";
import BuyspaceSelect from "ui/inputs/BuyspaceSelect/BuyspaceSelect";
import { AnalyticSubNavigation } from "ui/layouts/common";
import { parseQueryParams, stringifyQueryParams } from "utils";
import { prettyMoney, prettyNumber } from "utils/formatting.utils";

import {
  customIntervalInitial,
  dateFilterConfig,
  dateFilterMap,
  defaultDateFilter,
  overviewDashboardModalInitial
} from "../analytics.data";
import { getAnalyticsSummaryService } from "../api";
import { CustomIntervalModal } from "./CustomIntervalModal";
import { LabelWrapper, OverviewDashboardContainer } from "./OverviewDashboars.styles";
import { ActivityTitle, StyleCardsWrapper, StyleLabel, StyleSelectWrapperOverview } from "./style/StyleComponents";
import { convertToTimeSeriesData, MILLIONS_OF_DOLLARS } from "./utils";

type OverviewDashboardProps = {
  end: string;
  interval: string;
  start: string;
};

export const OverviewDashboard: FC<OverviewDashboardProps> = props => {
  const navigate = useNavigate();

  const { search } = useLocation();

  const { id: accountId } = useParams() as { id: string };

  const [modal, setModal] = useState<TOverviewDashboardModal>(overviewDashboardModalInitial);

  const [customInterval, setCustomInterval] = useState<TAnalyticsCustomInterval>(customIntervalInitial);

  const bookmarkedParams = parseQueryParams(search, { decoder: c => c });

  const initialFilters: TOverviewDashboardState = {
    ...props,
    timeWindow: "alltime",
    buyspaceId: null,
    accountId
  };

  const [state, setState] = useState<TOverviewDashboardState>({
    ...initialFilters,
    ...bookmarkedParams
  });

  const { data, isLoading, isError } = useQuery({
    queryKey: [
      QUERY_KEYS.overview_dashboard,
      { accountId: state.accountId, start: state.start, end: state.end, buyspace: state.buyspaceId }
    ],
    queryFn: () => getAnalyticsSummaryService(state.accountId, state.start, state.end, state.buyspaceId),
    enabled: Boolean(state.accountId)
  });

  const updateState = (updatedState: Partial<TOverviewDashboardState>) => {
    setState(prevState => ({ ...prevState, ...updatedState }));
  };

  const setTimeWindow = (windowName: string) => {
    if (windowName === "custom") {
      setModal(prev => ({ ...prev, customInterval: true }));
      updateState({ timeWindow: windowName });
    } else {
      const selected = dateFilterMap.get(windowName) || defaultDateFilter;
      setCustomInterval(customIntervalInitial);
      setState({
        accountId: state.accountId,
        start: selected.start,
        end: selected.end,
        interval: state.interval,
        timeWindow: windowName,
        buyspaceId: state.buyspaceId
      });
    }
  };

  const handleBuyspaceSelect = (buyspaceId: string | null) => {
    setState(prevState => ({ ...prevState, buyspaceId }));
  };

  const bookmarkParams = () => {
    const copy = structuredClone(state);
    const bookmark = pickBy(copy, (v, k) => {
      const key = k as keyof TOverviewDashboardState;
      return v !== initialFilters[key] && key !== "accountId";
    });
    navigate("?" + stringifyQueryParams(bookmark));
  };

  useEffect(() => {
    bookmarkParams();
  }, [state]);

  if (isError) {
    return <em>Houston we have a problem</em>;
  }

  if (!data?.summary && !isLoading) {
    return <em>There is no data</em>;
  }

  const seriesData = convertToTimeSeriesData(data?.data || []);

  const summaryData = {
    spend: data?.summary?.spend,
    co2e: data?.summary?.co2e,
    suppliers: data?.summary?.suppliers,
    transactions: data?.summary?.transactions,
    co2Cost: data?.summary?.co2Cost
  };

  const overallCarbonIntensity = data?.summary ? data.summary.co2e / (data.summary.spend / MILLIONS_OF_DOLLARS) : 0;

  const cards = (
    <StyleCardsWrapper>
      <ActivityTitle>Activity</ActivityTitle>
      <Grid gutter={16} align="stretch">
        <Grid.Col lg={3} md={12}>
          <Card
            showLabel={false}
            cardName="Spend"
            cardIcon={<CashLogo />}
            cardUnit={prettyMoney(summaryData.spend)}
            cardDescription={`Across ${summaryData.suppliers} suppliers and ${prettyNumber(
              summaryData.transactions
            )} transactions`}
            backgroundColor="#eeeeee"
          />
        </Grid.Col>
        <Grid.Col lg={3} md={12}>
          <Card
            showLabel={false}
            label="Impact"
            labelIcon={<CashLogo />}
            cardName="CO₂e Emissions"
            cardIcon={<IndustryLogo />}
            cardUnit={prettyNumber(summaryData.co2e)}
            cardDescription="Tons of CO₂ equivalent emissions"
            backgroundColor="#eeeeee"
          />
        </Grid.Col>
        <Grid.Col lg={3} md={12}>
          <Card
            showLabel={false}
            label="Cost"
            labelIcon={<CashLogo />}
            cardName="Carbon Price"
            cardIcon={<PriceTagIcon />}
            cardUnit={prettyMoney(summaryData.co2Cost)}
            cardDescription="USD $66 per ton of CO₂e emissions"
            backgroundColor="#eeeeee"
          />
        </Grid.Col>
        <Grid.Col lg={3} md={12}>
          <Card
            showLabel={false}
            label="Carbon Intensity"
            labelIcon={<MoneyChartIcon />}
            cardName="Overall Carbon Intensity"
            cardIcon={<MoneyChartIcon />}
            cardUnit={prettyNumber(overallCarbonIntensity)}
            cardDescription="Tons of CO₂e emissions per $1M USD"
            backgroundColor="#eeeeee"
          />
        </Grid.Col>
      </Grid>
    </StyleCardsWrapper>
  );

  return (
    <div>
      <AnalyticSubNavigation
        extra={
          <StyleSelectWrapperOverview>
            <Select
              placeholder="Select"
              size="lg"
              readOnly={false}
              data={dateFilterConfig}
              value={state.timeWindow}
              onChange={setTimeWindow}
            />
            <BuyspaceSelect accountId={state.accountId} buyspaceId={state.buyspaceId} onChange={handleBuyspaceSelect} />
          </StyleSelectWrapperOverview>
        }
      />
      <OverviewDashboardContainer>
        <LoadingOverlay visible={isLoading} overlayBlur={2} loader={<Loader />} />
        {cards}
        <div>
          <LabelWrapper>
            <StyleLabel>Spend vs. CO₂e Emissions by Month</StyleLabel>
            <LineChart series={seriesData.series} xAxis={seriesData.xAxis} />
          </LabelWrapper>
        </div>
        <CustomIntervalModal
          opened={modal.customInterval}
          setModal={setModal}
          customInterval={customInterval}
          setCustomInterval={setCustomInterval}
          applyChanges={updateState}
        />
      </OverviewDashboardContainer>
    </div>
  );
};
