import { Feature } from "types";

import { RoutesConfig } from "./types";

export const dropdownRoutesConfig: { name: string; routes: RoutesConfig[] }[] = [
  {
    name: "Procurement",
    routes: [
      {
        name: "Buyspaces",
        path: "/buyspaces/:accountId",
        allowRoles: ["ACCOUNT_ADMIN", "ACCOUNT_CATEGORY_MANAGER"],
        restrictFeatures: [Feature.BUYSPACES_MODULE]
      },
      {
        name: "Goal Tracking",
        path: "/goals/:accountId",
        allowRoles: ["ACCOUNT_ADMIN", "ACCOUNT_CATEGORY_MANAGER"],
        restrictFeatures: [Feature.GOAL_MODULE]
      },
      {
        name: "Questionnaires",
        path: "/questionnaires/:accountId",
        allowRoles: ["ACCOUNT_ADMIN", "ACCOUNT_CATEGORY_MANAGER"],
        restrictFeatures: [Feature.SUPPLIER_QUESTIONNAIRES]
      }
    ]
  }
];
