export interface IHeaderStyle {
  fontSize: string;
  lineHeight: string;
  paddingTop?: string;
  paddingBottom?: string;
  fontWeight?: string;
}
export interface FontTypographyInterface {
  fontFamily: string;
  fontStyle: string;
  fontSize: string;
  lineHeight: string;
  navigation: IHeaderStyle;
  header1: IHeaderStyle;
  header2: IHeaderStyle;
  header3: IHeaderStyle;
  paragraph: IHeaderStyle;
  modalGroupHeader: IHeaderStyle;
}

export const fontFamily = "Inter";
export const fontStyle = "normal";

export const font: FontTypographyInterface = {
  fontFamily,
  fontStyle,
  fontSize: "1rem",
  lineHeight: "1.3rem",
  navigation: {
    fontSize: "1.2rem",
    lineHeight: "1.4rem"
  },
  header1: {
    fontSize: "2.6rem",
    lineHeight: "3rem"
  },
  header2: {
    fontSize: "2.3rem",
    lineHeight: "2.6rem",
    paddingTop: "2.5rem",
    paddingBottom: "2rem",
    fontWeight: "700"
  },
  header3: {
    fontSize: "2rem",
    lineHeight: "2.3rem"
  },
  paragraph: {
    fontSize: "0.875rem",
    lineHeight: "1rem"
  },
  modalGroupHeader: {
    fontSize: "1.25rem",
    lineHeight: "1.5rem",
    fontWeight: "700"
  }
};
