export const AccountStatus = {
  active: {
    label: "Active",
    value: "ACTIVE"
  },
  pending: {
    label: "Pending",
    value: "PENDING"
  },
  suspended: {
    label: "Suspended",
    value: "SUSPENDED"
  }
};
