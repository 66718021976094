import * as React from "react";

export const LogoutIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="27" height="22" viewBox="0 0 27 22" fill="none" {...props}>
    <path
      d="M16.6367 14.4616V20.9869H0.686035V0.686035H16.6367V7.21132M7.21132 10.8365H25.3371M25.3371 10.8365L21.7119 7.21132M25.3371 10.8365L21.7119 14.4616"
      stroke="currentColor"
      strokeWidth="0.988679"
    />
  </svg>
);
