import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { QUERY_KEYS } from "constants/queryKeys";
import { Strings } from "constants/translations";
import { errorModal, successModal } from "modules/error/ErrorHandlingModals";
import { ResponseError } from "types";

import { postUserAdminService } from "../api/postUserAdminService";

export const usePostUserAdminMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: postUserAdminService,
    mutationKey: [QUERY_KEYS.create_admin_user],
    onSuccess: () => {
      successModal(Strings.account__created__success);
      queryClient.refetchQueries({ queryKey: [QUERY_KEYS.user_list] });
    },
    onError: (error: AxiosError<ResponseError & { errorCode: string; meta: { id: string } }>) => {
      if (error.response?.data?.errorCode !== "USERS_ALREADY_EXISTS") {
        errorModal(error);
      }
    }
  });
};
