import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { ActionIcon, Menu } from "@mantine/core";
import { useCurrentUserContext } from "modules/login";
import { UserRoles } from "modules/users/types/UserTypesEnum";
import { CancelIcon, EditIcon, MenuHamburgerIcon, TrashIcon, ViewIcon } from "ui/icons";

import useDeleteAccountMutation from "../../mutations/DeleteAccountMutation";
import useUpdateAccountStatusMutation from "../../mutations/UpdateAccountStatusMutation";
import { DeleteModal, ReactivateModal, SuspendModal, UpdateModal } from "../AccountListingModals";
import { AccountListing } from "../types";
import { AccountListingCenterAlign } from "./AccountListingTable.styles";

type OpenModalKeyState = "delete" | "reactivate" | "suspend" | "update";

export const AccountListingActionCell = (props: AccountListing) => {
  const navigate = useNavigate();

  const [openModalKey, setOpenModalKey] = useState<OpenModalKeyState | null>(null);

  const {
    userDetails: { userRole }
  } = useCurrentUserContext();

  const isAdminOrAnalyst = userRole === UserRoles.INTERNAL_ADMIN.value || userRole === UserRoles.INTERNAL_ANALYST.value;
  const isAdmin = userRole === UserRoles.INTERNAL_ADMIN.value;

  const closeModal = () => setOpenModalKey(null);

  const handleAccountStatusChange = useUpdateAccountStatusMutation();

  const handleAccountDelete = useDeleteAccountMutation();

  const handleAccountSuspension = () => {
    handleAccountStatusChange.mutate({ userId: props.id, status: "SUSPENDED" });
    closeModal();
  };

  const handleAccountActivation = () => {
    handleAccountStatusChange.mutate({ userId: props.id, status: "ACTIVE" });
    closeModal();
  };

  const handleAccountDeletion = () => {
    handleAccountDelete.mutate(props.id);
    closeModal();
  };

  const handleViewAccount = () => {
    localStorage.setItem("accountName", props.name);
    navigate(`/accounts/${props.id}`);
  };

  const onNavigate = (to: string) => {
    navigate(to);
  };

  return (
    <>
      <AccountListingCenterAlign>
        <Menu position="bottom-end" withinPortal>
          <Menu.Target>
            <ActionIcon variant="transparent" c="gray.8">
              <MenuHamburgerIcon />
            </ActionIcon>
          </Menu.Target>
          <Menu.Dropdown>
            {isAdminOrAnalyst && (
              <>
                <Menu.Item icon={<EditIcon />} onClick={() => setOpenModalKey("update")}>
                  Edit Account
                </Menu.Item>
                <Menu.Divider />
              </>
            )}
            {isAdmin && (
              <>
                <Menu.Item icon={<TrashIcon />} onClick={() => setOpenModalKey("delete")}>
                  Delete Account
                </Menu.Item>
                <Menu.Divider />
              </>
            )}

            {isAdmin && props.status === "SUSPENDED" && (
              <>
                <Menu.Item icon={<CancelIcon />} onClick={() => setOpenModalKey("reactivate")}>
                  Activate Account
                </Menu.Item>
                <Menu.Divider />
              </>
            )}
            {isAdmin && props.status === "ACTIVE" && (
              <>
                <Menu.Item icon={<CancelIcon />} onClick={() => setOpenModalKey("suspend")}>
                  Suspend Account
                </Menu.Item>
                <Menu.Divider />
              </>
            )}
            {isAdminOrAnalyst && (
              <>
                <Menu.Item icon={<ViewIcon />} onClick={() => onNavigate(`/analysis/${props.id}/overview`)}>
                  View Dashboard
                </Menu.Item>
                <Menu.Divider />

                <Menu.Item icon={<ViewIcon />} onClick={() => onNavigate(`/suppliers/${props.id}`)}>
                  View Suppliers
                </Menu.Item>
                <Menu.Divider />

                <Menu.Item icon={<ViewIcon />} onClick={() => onNavigate(`/transactions/${props.id}`)}>
                  View Transactions
                </Menu.Item>
                <Menu.Divider />

                <Menu.Item icon={<ViewIcon />} onClick={() => onNavigate(`/commodities/${props.id}`)}>
                  View Commodities
                </Menu.Item>
                <Menu.Divider />

                <Menu.Item icon={<ViewIcon />} onClick={() => onNavigate(`/buyspaces/${props.id}`)}>
                  View Buyspaces
                </Menu.Item>
                <Menu.Divider />

                <Menu.Item icon={<ViewIcon />} onClick={handleViewAccount}>
                  View Account
                </Menu.Item>
                <Menu.Divider />

                <Menu.Item icon={<ViewIcon />} onClick={() => onNavigate(`/goals/${props.id}`)}>
                  View Goals
                </Menu.Item>
                <Menu.Divider />

                <Menu.Item icon={<ViewIcon />} onClick={() => onNavigate(`/questionnaires/${props.id}`)}>
                  View Questionnaires
                </Menu.Item>
              </>
            )}
          </Menu.Dropdown>
        </Menu>
      </AccountListingCenterAlign>

      {/* Modals */}
      <SuspendModal open={openModalKey === "suspend"} onClose={closeModal} onConfirm={handleAccountSuspension} />
      <ReactivateModal open={openModalKey === "reactivate"} onClose={closeModal} onConfirm={handleAccountActivation} />
      <DeleteModal open={openModalKey === "delete"} onClose={closeModal} onConfirm={handleAccountDeletion} />
      <UpdateModal open={openModalKey === "update"} onClose={closeModal} data={props} />
    </>
  );
};
