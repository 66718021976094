import { FC, useMemo } from "react";

import { Button } from "@mantine/core";
import { groupBy } from "lodash";
import { useGetClassificationListQueries } from "modules/classification";
import { useGetQuestionnaireRequestListQuery } from "modules/questionnaire";
import { ISupplier } from "modules/suppliers/suppliers.types";
import { IndustryClassification, TAnalyticsData } from "types";
import { BoxIcon, CalendarIcon, UserIcon } from "ui/icons";
import { fitElement, formatDateStringFull } from "utils";
import { getCountryByIso, getStateByIso } from "utils/input.utils";
import { stringifyQueryParams } from "utils/query.utils";

import { QuestionnaireStatusMap } from "../supplierDetails.data";
import {
  HiddenBox,
  StyledDetail,
  StyledDetailsContainer,
  StyledDetailsWrapper,
  StyledDetailValue
} from "../supplierDetails.styles";
import { SupplierMethodDetail } from "./SupplierMethodInformation";

type SupplierInformationProps = {
  showTransactionButton?: boolean;
  summary: TAnalyticsData;
  supplierData: ISupplier;
};

export const SupplierInformation: FC<SupplierInformationProps> = ({ supplierData, summary }) => {
  const { region, city, country, createdAt, description, naicsCode } = supplierData;

  const codes = useGetClassificationListQueries({ codes: naicsCode ? [naicsCode] : [] });

  const questionnaireRequestListQuery = useGetQuestionnaireRequestListQuery({
    pathArgs: {
      accountId: supplierData.account.id,
      supplierId: supplierData.id
    },
    apiArgs: {
      page: 0,
      limit: 99999999
    }
  });

  const naicsCodes = codes
    .map(({ data }) => {
      const codeData = data?.data[0] as IndustryClassification;
      return codeData;
    })
    .filter(data => data);

  const transactionRedirectLink = useMemo(() => {
    const filters = stringifyQueryParams({ supplierIds: [supplierData.id] });
    return "/transactions?" + filters;
  }, [supplierData]);

  const renderClassifications = useMemo(() => {
    const renderCodes = naicsCodes.map(({ code, title }) => `${title} (${code})`).join(", ");
    return (
      <StyledDetail>
        <UserIcon /> Classifications:
        <StyledDetailValue>{renderCodes}</StyledDetailValue>
      </StyledDetail>
    );
  }, [naicsCodes]);

  const renderLocation = useMemo(() => {
    const fullCountry = getCountryByIso(country);
    const fullState = getStateByIso(region, country);
    const combinedLocation = [fullState, city, fullCountry].filter(i => i).join(", ");
    const location = combinedLocation.length ? combinedLocation : "Not Specified";
    return (
      <StyledDetail>
        <BoxIcon /> Location:
        <StyledDetailValue italic={!combinedLocation.length}>{location}</StyledDetailValue>
      </StyledDetail>
    );
  }, [region, city, country]);

  const renderLastUpdate = useMemo(
    () => (
      <StyledDetail>
        <CalendarIcon /> Last Update:
        <StyledDetailValue>{formatDateStringFull(createdAt)}</StyledDetailValue>
      </StyledDetail>
    ),
    [createdAt]
  );

  const renderSupplierCreated = useMemo(
    () => (
      <StyledDetail>
        Supplier Created:
        <StyledDetailValue>{formatDateStringFull(createdAt)}</StyledDetailValue>
      </StyledDetail>
    ),
    [createdAt]
  );

  const renderDescription = useMemo(() => {
    const showDescription = !!description;
    return (
      <StyledDetail>
        Description:
        <StyledDetailValue italic={!showDescription}>
          {showDescription ? description : "Not Specified"}
        </StyledDetailValue>
      </StyledDetail>
    );
  }, [description]);

  const renderQuestionnaire = useMemo(() => {
    const list = questionnaireRequestListQuery.data?.data || [];

    if (!list.length) return null;

    const normalizedList = list.map(i => ({ ...i, status: QuestionnaireStatusMap[i.status] }));

    const grouped = groupBy(normalizedList, "status");

    const info = Object.entries(grouped)
      .reduce((prev: string[], acc) => [...prev, acc[1].length + " " + acc[0]], [])
      .join(", ");

    return (
      <StyledDetail>
        <HiddenBox>
          <CalendarIcon />
        </HiddenBox>{" "}
        Questionnaire:
        <StyledDetailValue link onClick={() => fitElement("questionnaireTableHeader")}>
          {info} Requests
        </StyledDetailValue>
      </StyledDetail>
    );
  }, [questionnaireRequestListQuery]);

  return (
    <StyledDetailsContainer>
      <StyledDetailsWrapper>
        <div>
          {renderClassifications}
          {renderLocation}
          {renderLastUpdate}
          {renderQuestionnaire}
        </div>
        <div>
          {renderSupplierCreated}
          {renderDescription}
          <SupplierMethodDetail summary={summary} />
        </div>
      </StyledDetailsWrapper>
      <a href={transactionRedirectLink}>
        <Button variant="outline" size="lg">
          Transactions
        </Button>
      </a>
    </StyledDetailsContainer>
  );
};
