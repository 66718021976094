import * as React from "react";

export const AddIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={props.width ?? "19"} height={props.height ?? "19"} viewBox="0 0 19 19" fill="none" {...props}>
    <path
      d="M5 9.39999H14M9.5 5V14M17.5 9.5C17.5 13.9183 13.9183 17.5 9.5 17.5C5.08172 17.5 1.5 13.9183 1.5 9.5C1.5 5.08172 5.08172 1.5 9.5 1.5C13.9183 1.5 17.5 5.08172 17.5 9.5Z"
      stroke="currentColor"
      strokeWidth="1.2"
    />
  </svg>
);
