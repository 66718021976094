import { useParams } from "react-router-dom";

import { Menu, Text } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import { theme } from "constants/theme";
import { confirmationModal, successModal } from "modules/error/ErrorHandlingModals";
import { UserRoles } from "modules/users/types/UserTypesEnum";
import { TQuestionnaireRequest } from "types";
import { CopyButton } from "ui/buttons";
import { CancelIcon, CheckmarkIcon, CopyIcon, MenuHamburgerIcon, SuccessIcon, TrashIcon, ViewIcon } from "ui/icons";
import RoleGuardWrapper from "ui/wrappers/RoleGuardWrapper";

import {
  useDeleteQuestionnaireRequestMutation,
  usePostQuestionnaireRequestApproveMutation,
  usePostQuestionnaireRequestRejectMutation
} from "../hooks";
import { QuestionnaireActionCellMenuTarget } from "../questionnaire.styles";

export const QuestionnaireTableActionCell = (questionnaireRequest: TQuestionnaireRequest) => {
  const { id, questionnaire, status } = questionnaireRequest;

  const { id: supplierId } = useParams();

  const questionnaireRequestRejectMutation = usePostQuestionnaireRequestRejectMutation();

  const questionnaireRequestApproveMutation = usePostQuestionnaireRequestApproveMutation();

  const onSuccessNotification = () =>
    showNotification({
      message: "Questionnaire Request has been successfully deleted",
      color: "klp",
      icon: <SuccessIcon />
    });

  const { mutate: handleDeleteQuestionnaireRequest } = useDeleteQuestionnaireRequestMutation({
    onSuccess: onSuccessNotification
  });

  const detailsLink = `${window.location.origin}/supplier/${supplierId}/questionnairerequest/${id}`;

  const requestLink = `${window.location.origin}/questionnaire/request/${id}`;

  const onDeleteQuestionnaireRequest = () =>
    confirmationModal({
      message: "Are you sure you want to delete this Questionnaire Request?",
      title: <Text c="danger.8">Confirm Delete</Text>,
      color: "danger.8",
      onConfirm: () => handleDeleteQuestionnaireRequest({ questionnaireRequestId: id })
    });

  const reject = () =>
    questionnaireRequestRejectMutation.mutate(id, {
      onSuccess: () => {
        successModal(`Questionnaire Request ${questionnaire.title} Successfully Rejected.`);
      }
    });

  const approve = () =>
    questionnaireRequestApproveMutation.mutate(id, {
      onSuccess: () => {
        successModal(`Questionnaire Request ${questionnaire.title} Successfully Approved.`);
      }
    });

  const handleApprove = () => {
    confirmationModal({
      title: <Text color="klp.4">Approve Questionnaire Request</Text>,
      message:
        "This action will merge Products(s) that might be attached to the QUESTIONNAIRE, approving this QUESTIONNAIRE might update some Products in our app.",
      onConfirm: approve
    });
  };

  const handleReject = () => {
    confirmationModal({
      title: <Text color="klp.4">Reject Questionnaire Request</Text>,
      message: "This action is irreversible, are you sure you want to reject this questionnaire request?",
      onConfirm: reject
    });
  };

  const clipboardSuccess = () =>
    showNotification({
      message: "Link copied to clipboard.",
      autoClose: 4000,
      color: "green"
    });

  const clipboardReject = () =>
    showNotification({
      message: "Link could not be copied, please check you browser permissions",
      autoClose: 4000,
      color: "red"
    });

  return (
    <Menu {...theme?.other?.menu} withinPortal zIndex={20}>
      <Menu.Target>
        <QuestionnaireActionCellMenuTarget>
          <MenuHamburgerIcon />
        </QuestionnaireActionCellMenuTarget>
      </Menu.Target>
      <Menu.Dropdown>
        <Menu.Item icon={<ViewIcon />} component="a" href={detailsLink} target="_blank" rel="noopener noreferrer">
          View Details
        </Menu.Item>
        <CopyButton value={requestLink} onSuccess={clipboardSuccess} onReject={clipboardReject}>
          {({ copy }) => (
            <Menu.Item icon={<CopyIcon height={19} width={19} />} component="div" onClick={copy}>
              Copy Link
            </Menu.Item>
          )}
        </CopyButton>
        <RoleGuardWrapper roles={[UserRoles.INTERNAL_ADMIN.value, UserRoles.ACCOUNT_ADMIN.value]}>
          <Menu.Item icon={<TrashIcon width={19} />} onClick={onDeleteQuestionnaireRequest}>
            Delete Request
          </Menu.Item>
        </RoleGuardWrapper>
        {status === "SUBMITTED" ? (
          <RoleGuardWrapper roles={[UserRoles.INTERNAL_ADMIN.value]}>
            <Menu.Divider />
            <Menu.Item icon={<CancelIcon />} onClick={handleReject}>
              Reject
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item icon={<CheckmarkIcon />} onClick={handleApprove}>
              Approve
            </Menu.Item>
          </RoleGuardWrapper>
        ) : null}
      </Menu.Dropdown>
    </Menu>
  );
};
