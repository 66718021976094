import { useContext } from "react";
import { useNavigate } from "react-router-dom";

import { useTheme } from "@emotion/react";
import { Container, createStyles, Group, Menu, UnstyledButton } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import useViewportPercent from "hooks/useViewportPercent";
import { CurrentUserContext } from "modules/login/CurrentUserContext";
import { UserRoles } from "modules/users/types/UserTypesEnum";
import { EditIcon, LogoutIcon, MenuHamburgerIcon, SmallUserIcon } from "ui/icons";
import RoleGuardWrapper from "ui/wrappers/RoleGuardWrapper";

import { NavigationMenu } from "./NavigationMenu";
import { NavigationTabs } from "./NavigationTabs";
import {
  StyledKloopifyLogo,
  StyleMenuWrapper,
  StyleTabBackgroundWrapper,
  StyleUserProfileImage,
  StyleUserProfileName
} from "./styles";

const useStyles = createStyles(theme => ({
  header: {
    paddingTop: "30px",
    backgroundColor: theme?.other?.monochromeColor[6]
  },

  mainSection: {
    paddingBottom: "18px",
    backgroundColor: theme?.other?.monochromeColor[6],
    maxWidth: "1366px",
    width: "100%",
    paddingLeft: "0px",
    paddingRight: "0px",
    [theme.fn.smallerThan(1440)]: {
      maxWidth: `${(window.innerWidth / 100) * 75}px`
    }
  },

  user: {
    color: theme.colorScheme === "dark" ? theme.colors.dark[0] : theme.black,
    borderRadius: theme.radius.sm,
    transition: "background-color 100ms ease",

    "&:hover": {
      backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[8] : theme.white
    },

    [theme.fn.smallerThan("xs")]: {
      display: "none"
    }
  },

  burger: {
    [theme.fn.largerThan("xs")]: {
      display: "none"
    }
  },

  userActive: {
    backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[8] : theme.white
  },

  tabs: {
    padding: "0",
    [theme.fn.smallerThan(1100)]: {
      display: "none"
    }
  },

  tabsList: {
    border: "0px !important",
    display: "flex",
    gap: "45px"
  },

  tab: {
    height: "52px",
    padding: "18px 0 18px 0",
    backgroundColor: theme?.other?.monochromeColor[5],
    border: "0px",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: theme.other.font.fontSize,
    lineHeight: theme.other.font.lineHeight,
    color: theme?.other?.monochromeColor[1],
    borderBottom: `4px solid ${theme?.other?.monochromeColor[5]}`,
    width: "auto",

    "&:hover": {
      backgroundColor: theme?.other?.monochromeColor[5]
    },

    "&[data-active]": {
      backgroundColor: theme?.other?.monochromeColor[5],
      borderBottom: `4px solid ${theme?.other?.primaryColor}`,
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: theme.other.font.fontSize,
      lineHeight: theme.other.font.lineHeight,
      color: theme?.other?.primaryColor,
      transition: "border 0.2s, color 0.1s",
      pointerEvents: "none"
    }
  },
  mobileView: {
    [theme.fn.largerThan(1100)]: {
      display: "none"
    }
  },
  desktopView: {
    [theme.fn.smallerThan(1100)]: {
      display: "none"
    }
  }
}));

type HeaderTabsProps = {
  preview?: boolean;
  user: { accountName: string; image: string; name: string };
};

export const HeaderScreen: React.FC<HeaderTabsProps> = ({ user, preview }) => {
  const [opened, { close, open }] = useDisclosure();

  const { classes, cx } = useStyles();
  const {
    other: { primaryColor }
  } = useTheme();

  const navigate = useNavigate();

  const {
    userDetails: { userRole, accountId },
    logout
  } = useContext(CurrentUserContext);

  const maxwidthlaptop = useViewportPercent({ percent: 75 });

  const handleAccountSettingsNavigate = () => {
    navigate(`/account/${accountId}/settings`);
  };

  const handleUsersNavigate = () => {
    navigate("/users");
  };

  const handleAccountsNavigate = () => {
    navigate("/accounts");
  };

  const handleExternalAccountNavigate = () => {
    navigate(`/accounts/${accountId}`);
  };

  return (
    <div className={classes.header} style={{ pointerEvents: preview ? "none" : "auto" }}>
      <Container className={classes.mainSection}>
        <Group position="apart">
          <Group>
            <StyledKloopifyLogo />
          </Group>
          <Group className={classes.mobileView}>
            <NavigationMenu />
          </Group>
          <Group className={classes.desktopView}>
            <Menu width={260} position="bottom-end" transition="pop-top-right" onClose={close} onOpen={open}>
              <Menu.Target>
                <UnstyledButton className={cx(classes.user, { [classes.userActive]: opened })}>
                  <Group
                    spacing={10}
                    style={{
                      cursor: "pointer",
                      color: primaryColor
                    }}
                  >
                    <StyleUserProfileName>
                      <div>{user?.name}</div>
                      {userRole !== UserRoles.INTERNAL_ADMIN.value && <div>{user?.accountName}</div>}
                    </StyleUserProfileName>
                    <div onClick={() => navigate(`/profile`)}>
                      <StyleUserProfileImage />
                    </div>
                    <MenuHamburgerIcon />
                  </Group>
                </UnstyledButton>
              </Menu.Target>
              <Menu.Dropdown>
                <RoleGuardWrapper roles={[UserRoles.INTERNAL_ADMIN.value, UserRoles.INTERNAL_ANALYST.value]}>
                  <Menu.Item icon={<SmallUserIcon />} id="accountsHeaderBtn" onClick={handleAccountsNavigate}>
                    Accounts
                  </Menu.Item>
                  <Menu.Divider />
                </RoleGuardWrapper>

                <RoleGuardWrapper roles={[UserRoles.ACCOUNT_ADMIN.value, UserRoles.ACCOUNT_CATEGORY_MANAGER.value]}>
                  <Menu.Item icon={<SmallUserIcon />} id="accountNameHeaderBtn" onClick={handleExternalAccountNavigate}>
                    Account
                  </Menu.Item>
                  <Menu.Divider />
                </RoleGuardWrapper>
                {userRole !== "ACCOUNT_CATEGORY_MANAGER" && (
                  <>
                    <Menu.Item
                      icon={<EditIcon />}
                      onClick={handleAccountSettingsNavigate}
                      id="accountSettingsHeaderBtn"
                    >
                      Account Settings
                    </Menu.Item>
                    <Menu.Divider />
                  </>
                )}
                <RoleGuardWrapper roles={["INTERNAL_ADMIN", "INTERNAL_ANALYST"]}>
                  <Menu.Item onClick={handleUsersNavigate} icon={<SmallUserIcon />} id="usersHeaderBtn">
                    Users
                  </Menu.Item>
                  <Menu.Divider />
                </RoleGuardWrapper>
                <Menu.Item onClick={() => logout()} icon={<LogoutIcon width="1.5rem" />} id="logoutHeaderBtn">
                  Log Out
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          </Group>
        </Group>
      </Container>
      <StyleTabBackgroundWrapper>
        <StyleMenuWrapper maxwidthlaptop={maxwidthlaptop}>
          <NavigationTabs />
        </StyleMenuWrapper>
      </StyleTabBackgroundWrapper>
    </div>
  );
};
