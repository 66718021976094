import * as React from "react";

export const MenuHamburgerIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="20" height="15" viewBox="0 0 27 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M0 1.68C0 0.76 0.720721 0 1.64164 0H25.025C25.9459 0 26.6667 0.72 26.6667 1.68C26.6667 2.6 25.9459 3.32 25.025 3.32H1.64164C0.720721 3.32 0 2.6 0 1.68ZM0 10C0 9.08 0.720721 8.32 1.64164 8.32H25.025C25.9459 8.32 26.6667 9.08 26.6667 10C26.6667 10.92 25.9459 11.68 25.025 11.68H1.64164C0.720721 11.68 0 10.92 0 10ZM0 18.32C0 17.4 0.720721 16.68 1.64164 16.68H25.025C25.9459 16.68 26.6667 17.4 26.6667 18.32C26.6667 19.24 25.9459 20 25.025 20H1.64164C0.720721 20 0 19.24 0 18.32Z"
      fill="currentColor"
    />
  </svg>
);
