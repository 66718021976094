import { formatDateString } from "utils";
import { formatCO2eValue, prettyMoney } from "utils/formatting.utils";

import { ITransactionFilters } from "./transactions.types";

export const methodDropdownConfig = [
  {
    label: "Spend-Based",
    value: "SB"
  },
  {
    label: "Commodity-Based",
    value: "CB"
  },
  {
    label: "Supplier-Specific",
    value: "SS"
  }
];

export const sortByConfig = [
  {
    value: "totalCost,desc",
    label: "Total Cost, Descending Order"
  },
  {
    value: "totalCost,asc",
    label: "Total Cost, Ascending Order"
  },
  {
    value: "co2e,desc",
    label: "CO₂e, Descending Order"
  },
  {
    value: "co2e,asc",
    label: "CO₂e, Ascending Order"
  }
];

export const transactionDetailConfig = [
  {
    label: "Date:",
    formatting: formatDateString,
    key: "date",
    link: false
  },
  {
    label: "Supplier:",
    formatting: null,
    key: "supplierName",
    link: true
  },
  {
    label: "Commodity:",
    formatting: null,
    key: "itemName",
    link: false
  },
  {
    label: "Total Cost:",
    formatting: prettyMoney,
    key: "totalCost",
    link: false
  },
  {
    label: "CO₂e:",
    formatting: formatCO2eValue,
    key: "co2e",
    link: false
  },
  {
    label: "Method:",
    formatting: null,
    key: "method",
    link: false
  }
];

export const annotationReplacementList: { key: keyof ITransactionFilters; label: string }[] = [
  {
    key: "supplierName",
    label: "Supplier Name: "
  },
  {
    key: "minDate",
    label: "Minimum Transaction Date: "
  },
  {
    key: "maxDate",
    label: "Maximum Transaction Date: "
  },
  {
    key: "method",
    label: "Method: "
  },
  {
    key: "source",
    label: "Source: "
  },
  {
    key: "itemName",
    label: "Commodity Name: "
  },
  {
    key: "costCenterName",
    label: "Cost Center Name: "
  },
  {
    key: "minTotalCost",
    label: "Minimum Total Cost: "
  },
  {
    key: "maxTotalCost",
    label: "Maximum Total Cost: "
  },
  {
    key: "minCo2e",
    label: "Minimum CO₂e Containment: "
  },
  {
    key: "maxCo2e",
    label: "Maximum CO₂e Containment: "
  },
  {
    key: "supplierIds",
    label: "Supplier Name: "
  },
  {
    key: "itemIds",
    label: "Commodity Name: "
  },
  {
    key: "costCenterIds",
    label: "Cost Center Name: "
  },
  {
    key: "naicsCodes",
    label: "NAICS Codes: "
  },
  {
    key: "naicsSectors",
    label: "NAICS Sectors: "
  },
  {
    key: "naicsIndustries",
    label: "NAICS Industries: "
  },
  {
    key: "buyspaceId",
    label: "Buyspace: "
  },
  {
    key: "sortBy",
    label: "Sorted By: "
  }
];

export const valueReplacementList = [...methodDropdownConfig, ...sortByConfig];

export const valueReplacementKeyList = ["method", "sortBy"];
