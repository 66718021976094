import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ErrorResponse } from "App";
import { QUERY_KEYS } from "constants/queryKeys";
import { addUserToAccount } from "modules/account/apis/Account";
import { errorModal } from "modules/error/ErrorHandlingModals";

const useAddUserToAccountMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: addUserToAccount,
    mutationKey: [QUERY_KEYS.add_user_to_Account],
    onSuccess: () => {
      queryClient.refetchQueries({ queryKey: [QUERY_KEYS.user_list] });
    },
    onError: (error: ErrorResponse) => errorModal(error)
  });
};

export default useAddUserToAccountMutation;
