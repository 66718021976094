import { ISupplierDetailsModal } from "modules/supplierDetails/supplierDetails.types";

import {
  QuestionnaireNewRequestButton,
  QuestionnaireTableHeaderContainer,
  QuestionnaireTableHeaderTitle
} from "../questionnaire.styles";

type QuestionnaireTableHeaderProps = {
  modalController: (updatedModal: Partial<ISupplierDetailsModal>) => void;
};

export const QuestionnaireTableHeader = ({ modalController }: QuestionnaireTableHeaderProps) => {
  const handleNewRequest = () => modalController({ questionnaireRequest: true });

  return (
    <QuestionnaireTableHeaderContainer id="questionnaireTableHeader">
      <QuestionnaireTableHeaderTitle>Questionnaire Requests</QuestionnaireTableHeaderTitle>
      <QuestionnaireNewRequestButton onClick={handleNewRequest}>+ New Request</QuestionnaireNewRequestButton>
    </QuestionnaireTableHeaderContainer>
  );
};
