import React from "react";

export const ShortAnswerIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="17" height="6" viewBox="0 0 17 6" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M1.0625 0.1H15.9375C16.4724 0.1 16.9 0.510853 16.9 1C16.9 1.48915 16.4724 1.9 15.9375 1.9H1.0625C0.527556 1.9 0.1 1.48915 0.1 1C0.1 0.510853 0.527556 0.1 1.0625 0.1ZM1.0625 4.1H9.5625C10.0974 4.1 10.525 4.51085 10.525 5C10.525 5.48915 10.0974 5.9 9.5625 5.9H1.0625C0.527556 5.9 0.1 5.48915 0.1 5C0.1 4.51085 0.527556 4.1 1.0625 4.1Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.2"
      />
    </svg>
  );
};
