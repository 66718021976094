import Modal from "ui/overlays/Modal";
import { ModalBodyMessage, ModalTitle } from "ui/styles";

import { AccountForm } from "../AccountForm";
import { AccountListing } from "../types";
import { ModalProps } from "./types";

export const UpdateModal = ({
  open,
  onClose,
  data
}: Pick<ModalProps, "onClose" | "open"> & { data: AccountListing }) => {
  return (
    <Modal opened={open} handleClose={onClose} withCloseButton>
      <ModalTitle $type="success" centered>
        Edit Account
      </ModalTitle>
      <ModalBodyMessage centered>
        Edit the account information. Required fields are marked with an asterisk*.
      </ModalBodyMessage>
      <ModalBodyMessage>
        <AccountForm data={data} onClose={onClose} />
      </ModalBodyMessage>
    </Modal>
  );
};
