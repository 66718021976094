import { useParams } from "react-router-dom";

import { useCurrentUserContext } from "modules/login";

import { QuestionnaireList, questionnaireListColumns, QuestionnaireListHeader } from "./components";
import { useGetQuestionnaireListQuery } from "./hooks";
import { QuestionnairesContainer, QuestionnaireTableWrapper } from "./questionnaire.styles";

export const MyQuestionnaireList = () => {
  const { id } = useParams() as { id: string };

  const { isInternal } = useCurrentUserContext();

  const questionnaireListQuery = useGetQuestionnaireListQuery({ accountId: id });

  const href = `${window.location.origin}/questionnaires/${id}/new`;

  return (
    <QuestionnairesContainer>
      <QuestionnaireListHeader href={href} isInternal={isInternal} />
      <QuestionnaireTableWrapper>
        <QuestionnaireList columns={questionnaireListColumns} query={questionnaireListQuery} />
      </QuestionnaireTableWrapper>
    </QuestionnairesContainer>
  );
};
