import styled from "@emotion/styled";

export const TableContainer = styled.div`
  height: 32rem;
  overflow-y: auto;
  position: relative;
`;

export const DangerText = styled.div`
  margin: 40px 0;
  color: ${({ theme }) => theme.other.dangerColor};
  font-size: 0.875rem;
`;

export const BottomDangerText = styled.div`
  margin: 24px 0;
  text-align: center;
  color: ${({ theme }) => theme.other.dangerColor};
  font-size: 0.875rem;
`;

export const ErrorText = styled.div`
  color: ${({ theme }) => theme.other.dangerColor};
`;
