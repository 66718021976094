import Dropzone, { defaultClassNames, IDropzoneProps, IPreviewProps } from "react-dropzone-uploader";

import { FileUploadIcon } from "ui/icons";

import { DropzoneContainer, DropzoneFilename, DropzoneText, DropzoneWrapper } from "./fileDropzone.styles";

export const FileDropzonePreview = (props: IPreviewProps) => {
  return (
    <DropzoneWrapper key="Dropzone">
      <FileUploadIcon />
      <DropzoneText>
        Drag and drop file(s) here or <span style={{ fontWeight: 700 }}>Browse Files</span>
        <DropzoneFilename>{props.files[0].file.name}</DropzoneFilename>
      </DropzoneText>
    </DropzoneWrapper>
  );
};

type FileDropzoneProps = Pick<IDropzoneProps, "onChangeStatus" | "onSubmit">;

export const FileDropzone: React.FC<FileDropzoneProps> = props => {
  return (
    <DropzoneContainer>
      <Dropzone
        {...props}
        maxFiles={1}
        multiple={false}
        submitButtonContent="Cancel"
        accept=".csv"
        disabled={files => files.some(f => ["getting_upload_params", "preparing", "uploading"].includes(f.meta.status))}
        classNames={{ inputLabelWithFiles: defaultClassNames.inputLabel }}
        inputContent={
          <DropzoneWrapper key="Dropzone">
            <FileUploadIcon />
            <DropzoneText>
              Drag and drop file(s) here or <span style={{ fontWeight: 700 }}>Browse Files</span>
            </DropzoneText>
          </DropzoneWrapper>
        }
        PreviewComponent={FileDropzonePreview}
      />
    </DropzoneContainer>
  );
};
