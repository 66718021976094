import { FC, useMemo } from "react";

import { Menu } from "@mantine/core";
import { theme } from "constants/theme";
import { StyledLink } from "modules/supplierDetails/supplierDetails.styles";
import { EditIcon, MenuHamburgerIcon, ViewIcon } from "ui/icons";
import RoleGuardWrapper from "ui/wrappers/RoleGuardWrapper";

import { useSuppliersContext } from "../SuppliersContext";
import { StyledMenuTarget } from "../styles/suppliersTable.styles";
import { ISupplier } from "../suppliers.types";

type SuppliersTableBodyMenuProps = {
  supplier: ISupplier;
};

export const SuppliersTableBodyMenu: FC<SuppliersTableBodyMenuProps> = ({ supplier }) => {
  const viewDetailsLink = useMemo(() => `/supplier/${supplier.id}`, [supplier]);

  const { onSupplierStateChange, modalController } = useSuppliersContext();

  const handleBurgetClick = () => onSupplierStateChange(supplier);

  const handleUpdateSupplier = () => modalController({ updateSupplier: true });

  return (
    <Menu {...theme.other?.menu} withinPortal>
      <Menu.Target>
        <StyledMenuTarget onClick={handleBurgetClick}>
          <MenuHamburgerIcon />
        </StyledMenuTarget>
      </Menu.Target>
      <Menu.Dropdown>
        <RoleGuardWrapper roles={["ACCOUNT_ADMIN", "INTERNAL_ADMIN", "INTERNAL_ANALYST"]}>
          <Menu.Item onClick={handleUpdateSupplier} icon={<EditIcon />}>
            Edit Supplier
          </Menu.Item>
        </RoleGuardWrapper>
        <StyledLink href={viewDetailsLink}>
          <Menu.Item icon={<ViewIcon width="17.4" height="11.2" />}>View Details</Menu.Item>
        </StyledLink>
      </Menu.Dropdown>
    </Menu>
  );
};
