import { ImportInterface } from "modules/imports/types";

import { useImportContext } from "../ImportContext";
import { StyledImportFilenameCell } from "./styles";

export const ImportFilenameCell: React.FC<ImportInterface> = props => {
  const { onModalOpen, accounts } = useImportContext();

  const accountName = accounts?.find(account => account.id === props.accountId)?.name ?? "";

  const onFilenameClick = () => {
    onModalOpen({ active: { ...props, accountName }, open: "importDetails" });
  };

  return <StyledImportFilenameCell onClick={onFilenameClick}>{props.filename}</StyledImportFilenameCell>;
};
