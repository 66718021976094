import { ChangeEvent, useEffect, useState } from "react";

import { Textarea } from "@mantine/core";
import { Button } from "@mantine/core";
import Modal from "ui/overlays/Modal";
import { ModalFooter, ModalTitle } from "ui/styles";

import { useRejectTransactionImportMutation } from "../hooks";
import { ConfirmationDeleteModal } from "./ConfirmationDeleteModal";
import { Description, TextInputWrapper, WrapperModalBody } from "./RejectTransactionsModal.styles";
import { SuccessModal } from "./SuccessModal";

type RejectTransactionsModalProps = {
  handleClose: () => void;
  importId: string | null;
  openModal: boolean;
};

export const RejectTransactionsModal = ({
  openModal = true,
  importId = null,
  handleClose
}: RejectTransactionsModalProps) => {
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [reason, setReason] = useState("");

  const rejectImportMutation = useRejectTransactionImportMutation();

  const handleOpenConfirmationModal = () => setOpenConfirmation(true);

  const handleReasonInput = (event: ChangeEvent<HTMLTextAreaElement>) => setReason(event.currentTarget.value);

  const handleRejectTransactions = (importId: string | null, reason: string) => {
    if (importId) {
      const id = importId;
      const payload = {
        reason: reason.trim()
      };
      if (id) {
        rejectImportMutation.mutate({ id, payload });
      }
    }
  };

  const handleCloseInfoModals = () => {
    setOpenConfirmation(false);
    handleClose();
  };

  const handleSubmit = () => {
    handleRejectTransactions(importId, reason);
    handleCloseInfoModals();
  };

  useEffect(() => {
    if (rejectImportMutation.isSuccess) setOpenSuccessModal(true);
  }, [rejectImportMutation.isSuccess]);

  useEffect(() => {
    if (!openModal) setReason("");
  }, [openModal]);

  return (
    <>
      <Modal
        opened={openModal}
        handleClose={handleClose}
        withCloseButton
        title={<ModalTitle $type="danger">Reject Transactions File</ModalTitle>}
        mode="modal"
      >
        <WrapperModalBody>
          <Description>Please specify the reason this Transactions File was rejected below:</Description>
          <TextInputWrapper>
            <Textarea
              value={reason}
              onChange={handleReasonInput}
              placeholder="Please specify..."
              w={"100%"}
              minRows={6}
            />
          </TextInputWrapper>
          <ModalFooter>
            <Button variant="subtle" onClick={handleCloseInfoModals}>
              Cancel Rejection
            </Button>
            <Button onClick={handleOpenConfirmationModal}>Submit</Button>
          </ModalFooter>
        </WrapperModalBody>
      </Modal>
      <ConfirmationDeleteModal
        openModal={openConfirmation}
        handleClose={() => setOpenConfirmation(false)}
        handleConfirm={handleSubmit}
        cancelBtnText="Cancel"
        confirmBtnText="Reject"
        titleText="Transaction Rejected?"
        descriptionText="Are you certain that you want to reject this transaction? Transaction file will be deleted. This operation can not be reversed."
      />
      <SuccessModal
        openModal={openSuccessModal}
        setOpenModal={setOpenSuccessModal}
        titleText="Transaction File Rejected"
        descriptionText={`This transaction file was rejected because ${rejectImportMutation.data?.data.rejectedReasonNote}`}
      />
    </>
  );
};
