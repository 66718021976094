import styled from "@emotion/styled";

export const BuyspaceFormContainer = styled.div`
  padding-top: 32px;
`;

export const BuyspaceHeader = styled.h2`
  line-height: 1.5;
  font-size: 1.25rem;
  color: ${({ theme }) => theme.other.primaryColor};
  margin: 0;
`;

export const ActionButton = styled.div<{
  btnStyle?: "default" | "primary";
}>`
  button {
    background-color: ${({ theme, btnStyle }) =>
      btnStyle === "primary" ? theme.other.secondaryColor : theme.other.monochromeColor[4]};
    color: ${({ theme, btnStyle }) =>
      btnStyle === "primary" ? theme.other.primaryColor : theme.other.monochromeColor[2]};

    &:hover {
      filter: brightness(1.05);
      background-color: ${({ theme, btnStyle }) =>
        btnStyle === "primary" ? theme.other.secondaryColor : theme.other.monochromeColor[4]};
    }
  }
`;
