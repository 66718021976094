import { FC, useMemo } from "react";
import { ErrorBoundary } from "react-error-boundary";

import { Table as TableType } from "@tanstack/react-table";
import { StyleHeaderTable } from "modules/users/components/userTable/userTable.styles";
import { Table } from "ui";
import ErrorHandler from "ui/errors/ErrorHandler";
import Loader from "ui/feedback/Loader";

import { TableWrapper } from "../styles/suppliersTable.styles";
import { ISupplier } from "../suppliers.types";

type Props = {
  isError: boolean;
  loading: boolean;
  onRowSelection: (values: ISupplier[]) => void;
  suppliers: ISupplier[];
  table: TableType<ISupplier>;
};

export const SuppliersTable: FC<Props> = ({ suppliers, isError, loading, table }) => {
  const errorMessage = useMemo(() => (isError ? "Something Went Wrong." : "No Results Found."), [isError, suppliers]);

  const isShowMessage = suppliers.length === 0 || isError;

  if (loading) return <Loader />;

  return (
    <ErrorBoundary FallbackComponent={ErrorHandler}>
      <TableWrapper>
        <Table<ISupplier> table={table} hoverable />
      </TableWrapper>
      {isShowMessage && <StyleHeaderTable>{errorMessage}</StyleHeaderTable>}
    </ErrorBoundary>
  );
};
