import styled from "@emotion/styled";

export const ImportErrorItemContainer = styled.div`
  padding: 16px 28px;
  width: 100%;
  background-color: inherit;
  table {
    margin-top: 8px;
    border-collapse: collapse;
    width: 100%;
  }
  td,
  th {
    white-space: nowrap;
    text-align: left;
    padding: 8px;
    color: ${({ theme }) => theme.other.monochromeColor[2]};
  }
`;

export const ImportErrorItemErrorText = styled.div`
  color: ${({ theme }) => theme.other.dangerColor};
`;

export const ImportErrorItemErrorRowText = styled.div`
  color: ${({ theme }) => theme.other.monochromeColor[2]};
`;
