import { Button } from "@mantine/core";

import { QuestionnaireResponseFooter } from "../questionnaire.styles";

type QuestionnaireApproveFooterProps = {
  disabled: boolean;
  onApprove: () => void;
  onReject: () => void;
};

export const QuestionnaireApproveFooter = ({ onApprove, onReject, disabled }: QuestionnaireApproveFooterProps) => (
  <QuestionnaireResponseFooter>
    <Button type="button" onClick={onApprove} disabled={disabled}>
      Approve
    </Button>
    <Button color="danger.8" type="button" onClick={onReject} disabled={disabled}>
      Reject
    </Button>
  </QuestionnaireResponseFooter>
);
