import styled from "@emotion/styled";
import Modal from "ui/overlays/Modal";

export const ColorIndicator = styled.div`
  width: 14px;
  height: 14px;
  border-radius: 50%;
  float: left;
  min-width: 14px;
`;

export const TableHeadNameCell = styled.div`
  color: ${props => props.theme.other.primaryColor};
`;

export const TableHeadCellCentered = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${props => props.theme.other.primaryColor};
`;

export const TableNameCell = styled.div`
  border: none !important;
  text-align: left;
  font-family: ${props => props.theme.other.font.fontFamily};
  font-style: ${props => props.theme.other.font.fontStyle};
  font-weight: 400;
  font-size: 1rem;
  text-transform: capitalize;
  color: ${props => props.theme.other.monochromeColor[2]};
  padding: 10px !important;
`;

export const TableNameCellWrapper = styled.div<{ active?: boolean }>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 7px;
  gap: 5px;

  ${({ active }) => {
    if (active) {
      return `    
      text-decoration: underline;
      cursor: pointer;`;
    }
  }}

  :hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const TableCell = styled.div`
  color: ${props => props.theme.other.monochromeColor[2]};
  font-size: 1rem;
`;

export const Wrapper = styled.div`
  position: relative;
  max-width: 800px;
  width: 100%;
  border: 1px solid ${props => props.theme.other.monochromeColor[3]};
  border-radius: 4px;
  padding-left: 18px;
  overflow: auto;

  th {
    height: 60px;
    background-color: #fff !important;
    border: 0 !important;
  }

  tbody {
    tr,
    td {
      border: 0 !important;
    }
  }

  @media only screen and (max-width: 1366px) {
    width: 100%;
    margin-left: auto !important;
    margin-right: auto !important;
  }
  @media only screen and (max-width: 1366px) {
    width: calc(100% - 20px);
    margin-left: auto !important;
    margin-right: auto !important;
  }
  /* Scroll CSS */
  /* width */
  ::-webkit-scrollbar {
    width: 8px;
  }
  /* Track */
  ::-webkit-scrollbar-track {
    background: rgba(217, 217, 217, 0.2);
    border-radius: 10px;
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #d9d9d9;
    border-radius: 60px;
  }
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #d9d9d9;
  }
`;

export const AdditionalFilterModal = styled(Modal)`
  .mantine-Modal-modal {
    padding: 2.8rem 3.75rem 2.8rem 3.75rem;
    @media only screen and (max-width: ${props => props.theme.other.breakpoints.lg}) {
      padding: 3rem 4rem;
    }
  }
`;

export const AdditionalFilterHeader = styled.div`
  font-family: ${props => props.theme.other.font.fontFamily};
  font-style: ${props => props.theme.other.font.fontStyle};
  font-weight: 700;
  font-size: 1.8rem;
  line-height: 3.8125rem;
  text-align: left;
  color: ${props => props.theme.other.primaryColor};
  margin-bottom: 1.5rem;
`;

export const AdditionalFilterTitle = styled.div<{ inline?: boolean }>`
  padding-bottom: ${props => (props.inline ? "" : "0.5rem")};
  font-size: ${props => props.theme.other.font.modalGroupHeader.fontSize};
  line-height: ${props => props.theme.other.font.modalGroupHeader.lineHeight};
  font-weight: ${props => props.theme.other.font.modalGroupHeader.fontWeight};
  color: ${({ theme }) => theme.other.monochromeColor[2]};
`;

export const AdditionalFilterInputWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  gap: 1rem;
`;

export const AdditionalFilterContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 3rem;

  @media screen and (max-width: ${props => props.theme.other.breakpoints.lg}) {
    gap: 2rem;
  }
`;

export const AdditionalFilterTopSpacer = styled.div`
  padding-top: 1rem;
`;
