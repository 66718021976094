import { ImportInterface } from "modules/imports/types";
import moment from "moment";

export const ImportApproveCell: React.FC<ImportInterface> = props => (
  <>
    {props.status === "UPLOAD_REJECTED" || props.status === "ANALYSIS_REJECTED" ? (
      <b>Rejected</b>
    ) : props.status === "PENDING_ANALYSIS" || props.status === "PENDING_APPROVAL" ? (
      <i>Pending</i>
    ) : props.approvedOn ? (
      moment(props.approvedOn).format("MM/DD/YYYY")
    ) : (
      "-"
    )}
  </>
);
