import { useSearchParams } from "react-router-dom";

import { useTheme } from "@emotion/react";
import { Text } from "@mantine/core";
import { getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { ISupplier } from "modules/suppliers/suppliers.types";
import { TQuestionnaireRequest } from "types";
import { Table } from "ui";
import Loader from "ui/feedback/Loader";
import Pagination from "ui/navigation/Pagination";

import { useGetQuestionnaireRequestListQuery } from "../hooks";
import { QuestionnaireTableWrapper } from "../questionnaire.styles";
import { columns } from "./QuestionnaireTableColumns";

const limit = 10;

type QuestionnaireTableProps = {
  supplier: ISupplier;
};

export const QuestionnaireTable = ({ supplier }: QuestionnaireTableProps) => {
  const {
    other: { monochromeColor }
  } = useTheme();

  const [searchParams, setSearchParams] = useSearchParams();

  const { page = 0 } = Object.fromEntries(searchParams);

  const activePage = Number(page) + 1;

  const questionnaireRequestListQuery = useGetQuestionnaireRequestListQuery({
    pathArgs: {
      accountId: supplier.account.id,
      supplierId: supplier.id
    },
    apiArgs: {
      page: +page,
      limit
    }
  });

  const table = useReactTable({
    data: questionnaireRequestListQuery?.data?.data || [],
    columns,
    getCoreRowModel: getCoreRowModel()
  });

  const onPageChange = (pageCount: number) => {
    if (pageCount === 1) {
      searchParams.delete("page");
      setSearchParams(searchParams);
      return;
    }

    const newPage = (pageCount - 1).toString();

    setSearchParams({ page: newPage });
  };

  if (questionnaireRequestListQuery.isLoading)
    return (
      <QuestionnaireTableWrapper>
        <Loader />
      </QuestionnaireTableWrapper>
    );

  if (questionnaireRequestListQuery?.data?.data.length === 0) {
    return (
      <QuestionnaireTableWrapper>
        <Text c={monochromeColor[2]} size="xs" ta="center">
          No Requests created for {supplier.name}
        </Text>
      </QuestionnaireTableWrapper>
    );
  }

  return (
    <>
      <QuestionnaireTableWrapper>
        <Table<TQuestionnaireRequest> table={table} rowSize="30px" />
      </QuestionnaireTableWrapper>
      <Pagination
        activePage={activePage}
        setPage={onPageChange}
        totalRecords={questionnaireRequestListQuery.data?.meta.totalCount}
        limit={limit}
      />
    </>
  );
};
