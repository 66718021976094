import { useQuery } from "@tanstack/react-query";
import { ErrorResponse } from "App";
import { QUERY_KEYS } from "constants/queryKeys";
import { errorModal } from "modules/error/ErrorHandlingModals";

import { getQuestionnaireListService, TGetQuestionnaireListServiceArgs } from "../api";

export const useGetQuestionnaireListQuery = ({ accountId, apiParams }: TGetQuestionnaireListServiceArgs) =>
  useQuery({
    queryKey: [QUERY_KEYS.get_questionnaire_list],
    queryFn: () => getQuestionnaireListService({ accountId, apiParams }),
    useErrorBoundary: true,
    onError: (error: ErrorResponse) => errorModal(error),
    enabled: !!accountId
  });
