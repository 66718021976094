import styled from "@emotion/styled";

export const Form = styled.form`
  margin-top: 40px;
`;

export const FormFooter = styled.div`
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  gap: 28px;

  @media only screen and (max-width: 430px) {
    justify-content: center;
    gap: 16px;
    flex-wrap: wrap-reverse;
  }
`;

export const FormErrorText = styled.p`
  color: #fa5252; // mantine red 6 to be consistent across the form
  font-size: 0.875rem;
  margin: 0;
  margin-top: 5px;
`;
