import axiosInstance from "services/AxiosInstance";
import { ResponseType, TUser } from "types";

export interface GetUserInterface {
  page: number;
  limit?: number;
}
export const getUserListingService = async (
  pageNo: number,
  limit: number,
  filterParams: string
): Promise<ResponseType<TUser[], { totalCount: number }>> => {
  const params: GetUserInterface = {
    page: pageNo,
    limit: limit
  };
  const response = await axiosInstance.get(`/users?${filterParams}`, { params });
  return response.data;
};
