import { useGetAccount } from "hooks";
import { useCurrentUserContext } from "modules/login";

import { TopHeaderContainer, TopHeaderDescription, TopHeaderSubtitle, TopHeaderTitle } from "./topHeader.styles";

type TopHeaderProps = {
  description?: string;
  noPadding?: boolean;
  title: string;
};

export const TopHeader: React.FC<TopHeaderProps> = ({ title, description, noPadding }) => {
  const account = useGetAccount();
  const { isInternal } = useCurrentUserContext();

  return (
    <TopHeaderContainer noPadding={noPadding}>
      {account?.name && isInternal && <TopHeaderSubtitle>{account.name}</TopHeaderSubtitle>}
      <TopHeaderTitle>{title}</TopHeaderTitle>
      {description && <TopHeaderDescription>{description}</TopHeaderDescription>}
    </TopHeaderContainer>
  );
};
