import { forwardRef } from "react";

import { SelectItem } from "@mantine/core";

import Checkbox from "../Checkbox";
import { useMultiSelectProvider } from "./MultiSelectContext";
import { formatSuggestion } from "./utils";

type ItemProps = SelectItem & { search?: string };

export const MultiSelectItem = forwardRef<HTMLDivElement, ItemProps>(
  ({ selected, value, label, ...rest }: ItemProps, ref) => {
    const { search } = useMultiSelectProvider();

    const suggestion = formatSuggestion(label || "", search);
    const newLabel = <div onClick={e => e.preventDefault()}>{suggestion}</div>;

    return (
      <div ref={ref} {...rest}>
        <Checkbox checked={selected ?? false} label={newLabel} readOnly />
      </div>
    );
  }
);
