import styled from "@emotion/styled";

export const StyledFilterTileWrapper = styled.div`
  display: flex;
  gap: 1rem;
  font-family: ${props => props.theme.other.font.fontFamily};
  font-style: ${props => props.theme.other.font.fontStyle};
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.3125rem;
  color: #ffffff;
  background-color: ${props => props.theme.other.primaryColor};
  padding: 0.5rem 1rem;
  border-radius: 1.5rem;
  cursor: pointer;
  user-select: none;
`;
