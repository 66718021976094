import { useContext } from "react";
import { useNavigate } from "react-router-dom";

import { Burger, Group, Menu } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { theme } from "constants/theme";
import { CurrentUserContext } from "modules/login";
import { UserRoles } from "modules/users/types/UserTypesEnum";
import { LogoutIcon, SmallUserIcon } from "ui/icons";
import { EditIcon } from "ui/icons";
import RoleGuardWrapper from "ui/wrappers/RoleGuardWrapper";

import { StyledNavLink, StyleLogoutButton, StyleUserProfileImage, StyleUserProfileName } from "./styles";
import { useRoutesNavigation } from "./useRoutesNavigation";

export const NavigationMenu = () => {
  const [opened, { open, close }] = useDisclosure();

  const navigate = useNavigate();

  const {
    userDetails: { userRole, firstName, lastName, accountName, accountId },
    logout
  } = useContext(CurrentUserContext);

  const { tabs: routes } = useRoutesNavigation();

  const userName = [firstName, lastName].filter(name => name).join(" ");

  const handleAccountSettingsNavigate = () => {
    navigate(`/account/${accountId}/settings`);
  };

  const handleUsersNavigate = () => {
    navigate("/users");
  };

  const handleAccountsNavigate = () => {
    navigate("/accounts");
  };

  const handleExternalAccountNavigate = () => {
    navigate(`/accounts/${accountId}`);
  };

  const renderRoutes = routes.map((route, index) => {
    if (index === routes?.length - 1) {
      return (
        <div key={index}>
          <StyledNavLink to={route.path} id={route.name + "NavMenu"}>
            {({ isActive }) => <Menu.Item>{isActive ? <b>{route.name}</b> : <>{route.name}</>}</Menu.Item>}
          </StyledNavLink>

          <Group spacing={10}>
            <div onClick={() => navigate(`/profile`)}>
              <StyleUserProfileImage />
            </div>
            <StyleUserProfileName>
              <div>{userName}</div>
              {userRole !== UserRoles.INTERNAL_ADMIN.value && <div>{accountName}</div>}
            </StyleUserProfileName>
          </Group>
          <RoleGuardWrapper roles={[UserRoles.INTERNAL_ADMIN.value, UserRoles.INTERNAL_ANALYST.value]}>
            <Menu.Item icon={<SmallUserIcon />} id="accountsHeaderBtn" onClick={handleAccountsNavigate}>
              Accounts
            </Menu.Item>
            <Menu.Divider />
          </RoleGuardWrapper>

          <RoleGuardWrapper roles={[UserRoles.ACCOUNT_ADMIN.value, UserRoles.ACCOUNT_CATEGORY_MANAGER.value]}>
            <Menu.Item icon={<SmallUserIcon />} id="accountNameHeaderBtn" onClick={handleExternalAccountNavigate}>
              Account
            </Menu.Item>
            <Menu.Divider />
          </RoleGuardWrapper>
          {userRole !== "ACCOUNT_CATEGORY_MANAGER" && (
            <>
              <Menu.Item icon={<EditIcon />} onClick={handleAccountSettingsNavigate} id="accountSettingsNavMenu">
                Account Settings
              </Menu.Item>
            </>
          )}
          <RoleGuardWrapper roles={["INTERNAL_ADMIN", "INTERNAL_ANALYST"]}>
            <Menu.Divider />
            <Menu.Item onClick={handleUsersNavigate} icon={<SmallUserIcon />} id="usersNavMenu">
              Users
            </Menu.Item>
          </RoleGuardWrapper>

          <Menu.Divider />
          <Menu.Item>
            <StyleLogoutButton
              style={{ border: "1px solid #32A752", width: "100%" }}
              onClick={() => logout()}
              id="logoutNavMenu"
            >
              <LogoutIcon width="24.65" height="20.3" />
              Log Out
            </StyleLogoutButton>
          </Menu.Item>
        </div>
      );
    }
    return (
      <StyledNavLink to={route.path} key={index} id={route.name + "NavMenu"}>
        {({ isActive }) => <Menu.Item>{isActive ? <b>{route.name}</b> : <>{route.name}</>}</Menu.Item>}
      </StyledNavLink>
    );
  });

  return (
    <Menu
      width={260}
      styles={{
        item: {
          fontFamily: "Inter",
          fontStyle: "normal",
          fontWeight: 400,
          fontSize: "16px",
          lineHeight: "19px",
          color: theme?.other?.primaryColor,
          padding: "10px"
        }
      }}
      position="bottom-end"
      transition="pop-top-right"
      onClose={close}
      onOpen={open}
    >
      <Menu.Target>
        <Burger opened={opened} color={theme?.other?.primaryColor} />
      </Menu.Target>
      <Menu.Dropdown>{renderRoutes}</Menu.Dropdown>
    </Menu>
  );
};
