import { useQuery } from "@tanstack/react-query";
import { ErrorResponse } from "App";
import { QUERY_KEYS } from "constants/queryKeys";
import { errorModal } from "modules/error/ErrorHandlingModals";

import { getUserListingService } from "../api";

export const useGetUsersQuery = (page: number, limit: number, filterParams: string) => {
  const query = useQuery({
    queryKey: [QUERY_KEYS.user_list, page, limit],
    queryFn: () => getUserListingService(page, limit, filterParams),
    onError: (error: ErrorResponse) => errorModal(error),
    useErrorBoundary: true
  });
  return query;
};
