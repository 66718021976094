import { UseFormReturnType } from "@mantine/form";
import { TUserFeatureFlagModalForm } from "modules/users";
import ModalAccountSelect from "ui/inputs/ModalAccountSelect/ModalAccountSelect";

import { UserFeatureFlagsAccountWrapper, UserFeatureFlagsRowWrapper } from "../styles";
import { UserFeatureFlagsList } from "./UserFeatureFlagsList";

export const UserFeatureFlagsModalBody = ({
  formInstance,
  userId
}: {
  formInstance: UseFormReturnType<TUserFeatureFlagModalForm>;
  userId: string;
}) => {
  const { getInputProps, setFieldValue } = formInstance;

  const accountId = getInputProps("accountId").value;

  const features = getInputProps("features").value;

  return (
    <UserFeatureFlagsRowWrapper alignLeft>
      <UserFeatureFlagsAccountWrapper>
        <p>Organisation Account</p>
        <ModalAccountSelect
          value={getInputProps("accountId").value}
          errorMsg={getInputProps("accountId").error}
          onChange={(value: string) => setFieldValue("accountId", value)}
          userId={userId}
        />
        {accountId && <UserFeatureFlagsList features={features} formInstance={formInstance} />}
      </UserFeatureFlagsAccountWrapper>
    </UserFeatureFlagsRowWrapper>
  );
};
