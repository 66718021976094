import React, { useContext, useEffect, useState } from "react";
import { IDropzoneProps } from "react-dropzone-uploader";

import { Button, LoadingOverlay } from "@mantine/core";
import { useForm, yupResolver } from "@mantine/form";
import { ErrorResponse } from "App";
import AWS from "aws-sdk";
import {
  ButtonUploadWrapper,
  ButtonWrapper,
  StyleButtonWrapper,
  StyledAccountSelectWrapper,
  StyleDescription,
  StyleDropzoneWrapper,
  StyleFooter,
  StyleSubTitle
} from "modules/imports/components/Import.styles";
import { CurrentUserContext } from "modules/login/CurrentUserContext";
import { UserRoles } from "modules/users";
import moment from "moment";
import { FileDropzone } from "ui";
import Loader from "ui/feedback/Loader";
import { UploadIcon } from "ui/icons";
import ModalAccountSelect from "ui/inputs/ModalAccountSelect/ModalAccountSelect";
import ErrorModal from "ui/overlays/ErrorModal";
import Modal from "ui/overlays/Modal";
import { ModalTitle } from "ui/styles";
import RoleGuardWrapper from "ui/wrappers/RoleGuardWrapper";
import * as Yup from "yup";

import { useCreateImportMutation } from "../hooks";
import { useImportContext } from "./ImportContext";

const REGION = process.env.REACT_APP_REGION;
const S3_BUCKET = process.env.REACT_APP_BUCKET_NAME;

AWS.config.update({
  accessKeyId: process.env.REACT_APP_ACCESS,
  secretAccessKey: process.env.REACT_APP_SECRET
});

const myBucket = new AWS.S3({
  params: { Bucket: S3_BUCKET },
  region: REGION
});

const uploadTransactionSchema = Yup.object().shape({
  accountId: Yup.string().required("Account is required")
});

export const UploadTransactionFile = () => {
  const { isInternal } = useContext(CurrentUserContext);
  const [process, setProcess] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false); // state controller for error Modal
  const [message, setMessage] = useState<string>(""); // message to display on success or error
  const createImportMutation = useCreateImportMutation();

  const { onUploadFile, file, onSelectedAccountId, onModalOpen, onSelectImportId } = useImportContext();

  const uploadTransactionForm = useForm({
    initialValues: {
      accountId: "",
      accountName: ""
    },
    validate: yupResolver(uploadTransactionSchema)
  });

  useEffect(() => {
    if (createImportMutation.isSuccess) {
      onUploadFile(undefined);
      setOpenModal(false);
      setProcess(false);
    }
  }, [createImportMutation.isSuccess]);

  useEffect(() => {
    if (createImportMutation.isError) {
      onUploadFile(undefined);
      const errorMessage =
        (createImportMutation.error as ErrorResponse).response.data.errorMessage ?? "Something Went Wrong.";
      setMessage(errorMessage);
      setErrorModal(true);
      setProcess(false);
    }
  }, [createImportMutation.isError]);

  const handleUploadFile = () => {
    if (file?.name === undefined) {
      return false;
    }
    if (!S3_BUCKET) return;

    setProcess(true);
    const accountId = uploadTransactionForm.values.accountId;
    const fileName = uploadTransactionForm.values.accountName.toLowerCase() + moment.now() + ".csv";

    const params: AWS.S3.PutObjectRequest = {
      ACL: "private",
      Body: file,
      ContentType: file.type,
      Bucket: S3_BUCKET,
      Key: fileName
    };

    myBucket
      .putObject(params)
      .promise()
      .then(async () => {
        const response = await createImportMutation.mutateAsync({
          transactionFileLocation: fileName,
          filename: file.name as string,
          accountId
        });
        onSelectImportId(response.data.id);
        onSelectedAccountId(accountId);
        onModalOpen({ open: "mapTransactionFile", active: null });
      })
      .catch(() => {
        setProcess(false);
        setMessage("Upload failed try again.");
        setErrorModal(true);
      });
  };

  const handleChangeStatus: IDropzoneProps["onChangeStatus"] = ({ file }, status) => {
    if (["getting_upload_params", "headers_received", "preparing", "uploading"].includes(status)) {
      setProcess(true);
    } else {
      setProcess(false);
    }
    if (status === "rejected_file_type") {
      setMessage("Accepted File Types: .csv only.");
      setErrorModal(true);
    }
    if (status === "exception_upload") {
      onUploadFile(undefined);
      setMessage("Upload failed try again.");
      setErrorModal(true);
    }
    if (status === "done") {
      onUploadFile(file);
    }
  };

  const handleResetDropzone = () => {
    onUploadFile(undefined);
    setProcess(false);
    setOpenModal(!openModal);
  };

  const onCloseModal = () => {
    setOpenModal(false);
  };

  const handleSubmit: IDropzoneProps["onSubmit"] = (_, allFiles) => {
    onUploadFile(undefined);
    allFiles.forEach((f: { remove: () => void }) => f.remove());
  };

  return (
    <React.Fragment>
      <RoleGuardWrapper
        roles={[UserRoles.ACCOUNT_ADMIN.value, UserRoles.INTERNAL_ADMIN.value, UserRoles.INTERNAL_ANALYST.value]}
      >
        <ButtonUploadWrapper>
          <Button size="lg" onClick={() => setOpenModal(!openModal)} leftIcon={<UploadIcon />}>
            Upload New Import
          </Button>
        </ButtonUploadWrapper>
      </RoleGuardWrapper>
      <Modal opened={openModal} handleClose={onCloseModal} setOpened={setOpenModal} withCloseButton>
        <form onSubmit={uploadTransactionForm.onSubmit(() => handleUploadFile())}>
          <ModalTitle>Add Transactions File</ModalTitle>
          <StyleSubTitle>Upload the Transactions File</StyleSubTitle>
          <StyleDescription>
            Upload a new Transactions File.
            {/* See the FAQ for{" "} <StyleDescTextUnderLine>assistance transaction file formats</StyleDescTextUnderLine> */}
          </StyleDescription>
          <StyledAccountSelectWrapper isInternal={isInternal}>
            <ModalAccountSelect
              value={uploadTransactionForm.values.accountId}
              errorMsg={uploadTransactionForm.getInputProps("accountId").error}
              onChange={(accountId: string, accountName: string) => {
                uploadTransactionForm.setValues({ accountId, accountName });
              }}
            />
          </StyledAccountSelectWrapper>
          <LoadingOverlay visible={process || createImportMutation.isLoading} overlayBlur={2} loader={<Loader />} />
          <StyleDropzoneWrapper>
            <FileDropzone onSubmit={handleSubmit} onChangeStatus={handleChangeStatus} />
          </StyleDropzoneWrapper>
          <StyleFooter>
            <ButtonWrapper>
              <Button
                disabled={process || createImportMutation.isLoading}
                onClick={() => handleResetDropzone()}
                variant="default"
              >
                Cancel
              </Button>
            </ButtonWrapper>
            <StyleButtonWrapper>
              <ButtonWrapper>
                <Button type="submit" disabled={process || file?.name === undefined || createImportMutation.isLoading}>
                  Upload
                </Button>
              </ButtonWrapper>
            </StyleButtonWrapper>
          </StyleFooter>
        </form>
      </Modal>
      <ErrorModal
        openModal={errorModal}
        setOpenModal={setErrorModal}
        onClose={() => setErrorModal(false)}
        message={message}
      />
    </React.Fragment>
  );
};
