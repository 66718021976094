import { useMemo, useState } from "react";
import { useParams } from "react-router-dom";

import { Divider } from "@mantine/core";
import { ExternalUserPermissionGuard } from "components";
import { useGetAnalyticsGrouped } from "modules/analytics";
import { convertToTimeSeriesData } from "modules/analytics/components/utils";
import { QuestionnaireRequestModal, QuestionnaireTable, QuestionnaireTableHeader } from "modules/questionnaire";
import { UpdateSupplierModal } from "modules/suppliers";
import { Feature } from "types";
import Loader from "ui/feedback/Loader";
import LineChart from "ui/graphs/line-chart/LineChart";

import { SupplierDetailsContext } from "./SupplierDetails.context";
import { SupplierDetailsCards, SupplierDetailsHeader, SupplierInformation } from "./components";
import { useGetSupplierAnalytics } from "./hooks";
import { useGetSupplierById } from "./hooks/useGetSupplierById";
import { defaultAnalyticsFilters, initialSupplierDetailsModal } from "./supplierDetails.data";
import { StyledGraphLabel, StyledLabelWrapper, StyledSupplierDetailsContainer } from "./supplierDetails.styles";
import { ISupplierDetailsModal } from "./supplierDetails.types";

export const SupplierDetails = () => {
  const { id } = useParams<{ id: string }>();

  const [modal, setModal] = useState(initialSupplierDetailsModal);

  const { data: supplierData, isLoading: supplierLoading } = useGetSupplierById(id);

  const { data: analyticsData, isLoading: analyticsLoading } = useGetAnalyticsGrouped({
    supplier: id,
    accountId: supplierData?.account.id,
    ...defaultAnalyticsFilters
  });

  const { data: lineGraphData, isLoading: isLineGraphDataLoading } = useGetSupplierAnalytics({
    id: supplierData?.account.id,
    start: "1970-01-01T00:00:00Z",
    end: "2100-01-01T00:00:00Z",
    interval: "month",
    includePending: false,
    supplier: id
  });

  const modalController = (updatedModal: Partial<ISupplierDetailsModal>) => {
    setModal(modal => ({ ...modal, ...updatedModal }));
  };

  const onEditSupplier = () => modalController({ updateSupplier: true });

  const closeSupplierModal = () => modalController({ updateSupplier: false });

  const closeQuestionnaireRequestModal = () => modalController({ questionnaireRequest: false });

  const seriesData = useMemo(() => convertToTimeSeriesData(lineGraphData?.data || null), [lineGraphData]);

  if (supplierLoading || analyticsLoading || isLineGraphDataLoading) return <Loader />;

  if (!supplierData || !analyticsData || !lineGraphData) return <div>No suppliers data available</div>;

  return (
    <SupplierDetailsContext.Provider value={{ modalController }}>
      <StyledSupplierDetailsContainer>
        <SupplierDetailsHeader
          onEdit={onEditSupplier}
          accountName={supplierData.account.name}
          name={supplierData.name}
        />
        <Divider />
        <SupplierInformation supplierData={supplierData} summary={analyticsData.summary} />
        <SupplierDetailsCards summary={analyticsData.summary} />
        <Divider my="3.125rem" />
        <StyledLabelWrapper>
          <StyledGraphLabel>Supplier Activity Over Time</StyledGraphLabel>
          <LineChart series={seriesData.series} xAxis={seriesData.xAxis} />
        </StyledLabelWrapper>
        <ExternalUserPermissionGuard module={Feature.SUPPLIER_QUESTIONNAIRES}>
          <Divider pb={15} />
          <QuestionnaireTableHeader modalController={modalController} />
          <QuestionnaireTable supplier={supplierData} />
        </ExternalUserPermissionGuard>
      </StyledSupplierDetailsContainer>
      <UpdateSupplierModal supplier={supplierData} opened={modal.updateSupplier} setOpened={closeSupplierModal} />
      <ExternalUserPermissionGuard module={Feature.SUPPLIER_QUESTIONNAIRES}>
        <QuestionnaireRequestModal
          supplier={supplierData}
          opened={modal.questionnaireRequest}
          setOpened={closeQuestionnaireRequestModal}
        />
      </ExternalUserPermissionGuard>
    </SupplierDetailsContext.Provider>
  );
};
