import React from "react";

import { Menu } from "@mantine/core";
import { ImportInterface } from "modules/imports/types";
import { UserRoles } from "modules/users";
import { DownloadIcon, EditIcon, TrashIcon, UploadIcon } from "ui/icons";
import RoleGuardWrapper from "ui/wrappers/RoleGuardWrapper";

import { useImportContext } from "../ImportContext";
import { ImportKeys } from "../types";

const analysisRoles = ["INTERNAL_ANALYST", "INTERNAL_ADMIN"];

const transactionRoles = ["INTERNAL_ANALYST", "INTERNAL_ADMIN", "ACCOUNT_ADMIN"];

const MenuFallback = <Menu.Item>No Actions</Menu.Item>;

export const ExternalUserImportMenu: React.FC<ImportInterface> = props => {
  const { status } = props;

  const { onDownloadFile, onModalOpen, onSelectedAccountId } = useImportContext();

  const handleModalOpen = (type: ImportKeys) => onModalOpen({ active: props, open: type });

  const externalMenuHandler = () => {
    switch (status) {
      case "CREATED":
        return (
          <RoleGuardWrapper roles={[UserRoles.ACCOUNT_ADMIN.value]} fallback={MenuFallback}>
            <Menu.Item
              icon={<EditIcon />}
              onClick={() => {
                onSelectedAccountId(props.accountId);
                handleModalOpen("editMapping");
              }}
            >
              Edit the Column Map
            </Menu.Item>
          </RoleGuardWrapper>
        );
      case "UPLOAD_REJECTED":
        return (
          <>
            <Menu.Item icon={<TrashIcon />} onClick={() => handleModalOpen("importDelete")}>
              Delete
            </Menu.Item>
            <Menu.Divider />
            <RoleGuardWrapper roles={transactionRoles}>
              <Menu.Item
                icon={<DownloadIcon />}
                onClick={() => {
                  onDownloadFile("transactions", props);
                }}
              >
                Download Transactions File
              </Menu.Item>
              <Menu.Divider />
            </RoleGuardWrapper>
            <Menu.Item icon={<UploadIcon />} onClick={() => handleModalOpen("reuploadTransaction")}>
              Re-upload Transactions File
            </Menu.Item>
          </>
        );
      case "COMPLETED":
        return (
          <RoleGuardWrapper roles={transactionRoles} fallback={MenuFallback}>
            <RoleGuardWrapper roles={analysisRoles}>
              <Menu.Item
                icon={<DownloadIcon />}
                onClick={() => {
                  onDownloadFile("analysis", props);
                }}
              >
                Download Analysis File
              </Menu.Item>
              <Menu.Divider />
            </RoleGuardWrapper>
            <RoleGuardWrapper roles={transactionRoles}>
              <Menu.Item
                icon={<DownloadIcon />}
                onClick={() => {
                  onDownloadFile("transactions", props);
                }}
              >
                Download Transactions File
              </Menu.Item>
            </RoleGuardWrapper>
          </RoleGuardWrapper>
        );
      default:
        return MenuFallback;
    }
  };

  const menu = externalMenuHandler();

  return menu;
};
