import * as React from "react";

export const XIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={props.width ?? "9"} height={props.height ?? "11"} viewBox="0 0 9 11" fill="none" {...props}>
    <path
      d="M7.80001 1.31995L1.20001 9.67995M1.20001 1.31995L7.80001 9.67995"
      stroke="currentColor"
      strokeWidth="1.2"
    />
  </svg>
);
