import { ErrorBoundary } from "react-error-boundary";

import styled from "@emotion/styled";
import { Anchor } from "@mantine/core";
import { dangerColor } from "constants/colorPalette";
import { Route_URL } from "constants/routeUrls";
import ErrorHandler from "modules/error/ErrorHandler";

const StyleText = styled.div`
  font-family: ${props => props.theme.other.font.fontFamily};
  font-style: ${props => props.theme.other.font.fontStyle};
  font-weight: 700;
  font-size: 1.875rem;
  line-height: 3.8125rem;
  color: ${props => props.theme.other.dangerColor};
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
`;
const LocalStorageDisabledError = () => {
  return (
    <ErrorBoundary FallbackComponent={ErrorHandler}>
      <StyleText>
        Hey, Please check your browser cookies settings to enable!
        <div>Having trouble to enable?</div>
        <div>
          <Anchor underline={false} color={dangerColor} href={Route_URL?.local_storage_enable_url} target="_blank">
            Get help here.
          </Anchor>
        </div>
      </StyleText>
    </ErrorBoundary>
  );
};
export default LocalStorageDisabledError;
