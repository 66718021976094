import { FC, Fragment, useContext, useEffect, useState } from "react";

import { Button, Grid, Select, Textarea, TextInput } from "@mantine/core";
import { useForm, yupResolver } from "@mantine/form";
import { CurrentUserContext } from "modules/login";
import { usePatchSupplier } from "modules/supplierDetails";
import { ModalHeader, StyleModalHeaderDescription } from "modules/users/components/styles";
import { ModalFooter } from "ui";
import Modal from "ui/overlays/Modal";
import SuccessModal from "ui/overlays/SuccessModal";
import { getCountries, getStates } from "utils/input.utils";

import { FormRowWrapper, StyledAccountName } from "../styles/addSupplierModal.styles";
import { initialMutationResultModal, updateSupplierSchema } from "../suppliers.data";
import { IAddSupplierMutationResultModal, ISupplier } from "../suppliers.types";
import { SupplierSelectComponent } from "./SupplierSelectComponent";

type Props = {
  opened: boolean;
  setOpened: () => void;
  supplier: ISupplier;
};

export const UpdateSupplierModal: FC<Props> = ({ opened, setOpened, supplier }) => {
  const { userDetails } = useContext(CurrentUserContext);

  const [mutationResultModal, setMutationResultModal] = useState(initialMutationResultModal);

  const { isSuccess, isError, error, mutate, reset } = usePatchSupplier();

  const initialSupplierValues = {
    id: supplier?.id,
    name: supplier?.name || "",
    description: supplier?.description || "",
    city: supplier?.city || "",
    region: supplier?.region || "",
    country: supplier?.country || "",
    postalCode: supplier?.postalCode || "",
    naicsCode: supplier?.naicsCode ? [supplier.naicsCode.toString()] : []
  };

  const updateSupplierForm = useForm({
    initialValues: { ...initialSupplierValues },
    validate: yupResolver(updateSupplierSchema)
  });

  useEffect(() => {
    if (supplier) {
      updateSupplierForm.setValues(initialSupplierValues);
    }
  }, [supplier]);

  const updateSupplier = (values: typeof initialSupplierValues) => {
    const payload = {
      ...values,
      naicsCode: values?.naicsCode ? values.naicsCode[0] : ""
    };
    if (supplier?.id && supplier?.account?.id) {
      mutate({ accountId: supplier?.account?.id, payload });
    }
  };

  const handleMutationResultModal = (update: Partial<IAddSupplierMutationResultModal>) => {
    setMutationResultModal(prevResultModal => ({ ...prevResultModal, ...update }));
    reset();
  };

  const closeModals = () => handleMutationResultModal(initialMutationResultModal);

  const onCloseModal = () => opened && setOpened();

  useEffect(() => {
    if (isSuccess) {
      setOpened();
      handleMutationResultModal({ success: true });
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      setOpened();
      handleMutationResultModal({ error: true, errorMessage: error.message });
    }
  }, [isError]);

  const modalTitle = (
    <div>
      <ModalHeader>Edit Supplier</ModalHeader>
      <StyleModalHeaderDescription>
        Edit in the Supplier information. Required fields are marked with an asterisk*.
      </StyleModalHeaderDescription>
    </div>
  );

  return (
    <Fragment>
      <Modal opened={opened} setOpened={setOpened} title={modalTitle} handleClose={onCloseModal} withCloseButton>
        <form onSubmit={updateSupplierForm.onSubmit(updateSupplier)}>
          <FormRowWrapper alignLeft>
            <StyledAccountName>{`Account: ${userDetails.accountName}`}</StyledAccountName>
          </FormRowWrapper>
          <Grid gutter={24}>
            <Grid.Col xs={12} sm={6}>
              <TextInput label="Supplier Name*" {...updateSupplierForm.getInputProps("name")} />
            </Grid.Col>
            <Grid.Col xs={12} sm={6}>
              <SupplierSelectComponent
                onChange={value => updateSupplierForm.setFieldValue("naicsCode", value)}
                value={updateSupplierForm.getInputProps("naicsCode").value}
                label="NAICS Code"
              />
            </Grid.Col>
            <Grid.Col xs={6} sm={2}>
              <Select
                searchable
                placeholder="Country"
                label="Country"
                data={getCountries()}
                value={updateSupplierForm.values.country}
                onChange={(value: string) => {
                  updateSupplierForm.setFieldValue("country", value);
                  updateSupplierForm.setFieldValue("region", "");
                }}
              />
            </Grid.Col>
            <Grid.Col xs={6} sm={2}>
              <Select
                searchable
                label="State"
                placeholder="State"
                data={getStates(updateSupplierForm.values.country)}
                value={updateSupplierForm.values.region}
                onChange={(value: string) => updateSupplierForm.setFieldValue("region", value)}
              />
            </Grid.Col>
            <Grid.Col xs={6} sm={6}>
              <TextInput label="City" {...updateSupplierForm.getInputProps("city")} />
            </Grid.Col>
            <Grid.Col xs={6} sm={2}>
              <TextInput label="Zip Code" {...updateSupplierForm.getInputProps("postalCode")} />
            </Grid.Col>
            <Grid.Col>
              <Textarea
                label="Description"
                minRows={6}
                w={"100%"}
                {...updateSupplierForm.getInputProps("description")}
              />
            </Grid.Col>
          </Grid>

          <ModalFooter>
            <Button
              variant="subtle"
              type="button"
              onClick={() => {
                setOpened();
                updateSupplierForm.reset();
              }}
            >
              Cancel
            </Button>

            <Button type="submit">UPDATE</Button>
          </ModalFooter>
        </form>
      </Modal>
      <SuccessModal
        openModal={mutationResultModal.success}
        setOpenModal={closeModals}
        message="Supplier is successfuly updated!"
        closeOpenedModal={closeModals}
      />
    </Fragment>
  );
};
