import { ColumnDef } from "@tanstack/react-table";
import { LinkComponent } from "components";
import { TAccountStatus } from "types";

import { AccountListing } from "../types";
import { AccountListingActionCell } from "./AccountListingActionCell";
import { AccountListingStatusCell } from "./AccountListingStatusCell";

export const columns: ColumnDef<AccountListing, TAccountStatus>[] = [
  {
    header: "Account Name",
    accessorKey: "name",
    enableSorting: false,
    size: 600,
    cell: props => <LinkComponent to={`/accounts/${props.row.original.id}`}>{props.row.original.name}</LinkComponent>
  },
  {
    header: "Suppliers",
    accessorKey: "suppliers",
    enableSorting: false,
    cell: props => (
      <LinkComponent to={`/suppliers/${props.row.original.id}`}>{props.row.original.suppliers}</LinkComponent>
    )
  },
  {
    header: "Transactions",
    accessorKey: "transactions",
    enableSorting: false,
    cell: props => (
      <LinkComponent to={`/transactions/${props.row.original.id}`}>{props.row.original.transactions}</LinkComponent>
    )
  },
  {
    header: "Status",
    accessorKey: "status",
    enableSorting: false,
    size: 150,
    cell: props => <AccountListingStatusCell status={props.getValue()} />
  },
  {
    header: "Action",
    id: "action",
    cell: props => <AccountListingActionCell {...props.row.original} />,
    size: 50,
    meta: {
      align: "center"
    }
  }
];
