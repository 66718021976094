import axiosInstance from "services/AxiosInstance";
import { ResponseType, TUser } from "types";

import { UserInterface } from "../types/UserDto";

export const putUserDetailsService = async (requestValue: {
  id: string;
  payload: UserInterface;
}): Promise<ResponseType<TUser>> => {
  const response = await axiosInstance.put(`/users/${requestValue.id}`, requestValue.payload);
  return response.data;
};
