import axiosInstance from "services/AxiosInstance";

export const getAnalyticsSummary = async (
  id: string,
  start = "1970-01-01T00:00:00Z",
  end = "2030-01-01T00:00:00Z",
  interval = "month"
) => {
  const params = {
    start,
    end,
    interval
  };

  const response = await axiosInstance.get(`/analytics/${id}/summary`, { params });
  return response;
};
