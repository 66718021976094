import axiosInstance from "services/AxiosInstance";
import { TAccount } from "types";
import { TAccountCreation } from "types/Account/AccountCreation";
interface UpdateAccountPayload {
  id: string;
  payLoad: TAccountCreation;
}
export const updateAccount = async (value: UpdateAccountPayload) => {
  const response: {data: TAccount} = await axiosInstance.put(`accounts/${value.id}`, value.payLoad);
  return response.data;
};
