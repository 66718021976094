import { useTheme } from "@emotion/react";
import { flexRender, Row, Table as TableType } from "@tanstack/react-table";

import { SortIcon, TableHead, TableHeadCell, TableWrapper } from "./Table.styles";

export type TableProps<T> = {
  hoverable?: boolean;
  onRow?: {
    onClick?: (rowData: Row<T>) => void;
    onMouseLeave?: () => void;
    onMouseOver?: (rowData: Row<T>) => void;
  };
  rowSize?: string;
  table: TableType<T>;
};

export const Table = <T,>({
  table,
  onRow = { onClick: () => {}, onMouseOver: () => {}, onMouseLeave: () => {} },
  hoverable,
  rowSize = "80px"
}: TableProps<T>) => {
  const { onClick, onMouseOver, onMouseLeave } = onRow;

  const {
    other: { monochromeColor, primaryColor }
  } = useTheme();

  return (
    <TableWrapper hoverable={hoverable} rowSize={rowSize}>
      <thead>
        {table.getHeaderGroups().map(headerGroup => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map(header => {
              const isSortable = header.column.getCanSort();

              const showSortIcon = isSortable ? <SortIcon style={{ color: monochromeColor[3] }} /> : null;

              const centered = header.column.columnDef.meta?.align === "center";

              const tableHeaderProps = {
                style: {
                  width: header.getSize(),
                  textAlign: header.column.columnDef.meta?.align ?? "left"
                },
                onClick: header.column.getToggleSortingHandler()
              };

              const tableHeaderRender = header.isPlaceholder
                ? null
                : flexRender(header.column.columnDef.header, header.getContext());

              return (
                <TableHead key={header.id} {...tableHeaderProps}>
                  <TableHeadCell centered={centered}>
                    {tableHeaderRender}
                    {isSortable && (
                      <div>
                        {{
                          asc: (
                            <SortIcon
                              style={{
                                transform: "rotate(180deg)",
                                color: primaryColor
                              }}
                            />
                          ),
                          desc: <SortIcon style={{ color: primaryColor }} />
                        }[header.column.getIsSorted() as string] ?? showSortIcon}
                      </div>
                    )}
                  </TableHeadCell>
                </TableHead>
              );
            })}
          </tr>
        ))}
      </thead>
      <tbody>
        {table.getRowModel().rows.map((row, index) => (
          <tr
            key={row.id}
            style={{
              cursor: "default",
              backgroundColor: index % 2 !== 0 ? "#eff1f34d" : monochromeColor[6]
            }}
            onMouseOver={() => onMouseOver?.(row)}
            onMouseLeave={() => onMouseLeave?.()}
            onClick={() => onClick?.(row)}
          >
            {row.getVisibleCells().map(cell => (
              <td key={cell.id} style={{ textAlign: cell.column.columnDef.meta?.align ?? "left" }}>
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </TableWrapper>
  );
};
