import { useNavigate } from "react-router-dom";

import { ActionIcon, Menu } from "@mantine/core";
import { TCommodity } from "types";
import { MenuHamburgerIcon, ViewIcon } from "ui/icons";
import { useRoutesNavigation } from "ui/layouts/common/useRoutesNavigation";
import { stringifyQueryParams } from "utils";

import { CommodityActionCellCenterAlign } from "../styles";

export const CommodityActionCell = ({ commodity }: { commodity: TCommodity }) => {
  const navigate = useNavigate();

  const { tabs } = useRoutesNavigation();

  const handleViewTransactions = () => {
    const transactionPathname = tabs.find(i => i.name === "Transactions")?.path;
    if (transactionPathname) {
      navigate({
        pathname: transactionPathname,
        search: stringifyQueryParams({ itemIds: [commodity.id] })
      });
    }
  };

  const handleViewDetails = () => navigate(`/commodity/${commodity.id}`);

  return (
    <>
      <CommodityActionCellCenterAlign>
        <Menu position="bottom-end" withinPortal>
          <Menu.Target>
            <ActionIcon variant="transparent" c="gray.8">
              <MenuHamburgerIcon />
            </ActionIcon>
          </Menu.Target>
          <Menu.Dropdown>
            <Menu.Item icon={<ViewIcon width="17.4" height="11.2" />} onClick={handleViewTransactions}>
              View Transactions
            </Menu.Item>
            <Menu.Item icon={<ViewIcon width="17.4" height="11.2" />} onClick={handleViewDetails}>
              View Details
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      </CommodityActionCellCenterAlign>
    </>
  );
};
